.contact-container{
    display: flex;
    padding: 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid #EEE;
    background: #FFF;
  }
  .contact-instructions-title {
    max-width: 266px;
  }
  .contact-instructions-title img {
    width: 100%;
    height: auto;
    max-width: 100%;
    display: block;
  }
  .contact-button {
    display: block;
    color: var(--bt-color-neutral-10); ;
    font-size: 16px;
    font-weight: bold;
    text-rendering: geometricprecision;
    cursor: pointer;
    background: var(--bt-color-primary);
    border: var(--bt-color-primary);
    color: var(--bt-color-neutral-10); ;
    width: 100%;
    border-radius: 50px;
    transition: 0.3s;
    transition-property: background, font-size;
    height: 44px;
    margin: auto;
    max-width: 300px;
  }
  .contact-button:active:hover {
    font-size: 14.5px;
  }
  .contact-container > .email-lead-form {
    border: none;
    padding: 0;
  }
  .contact-container > .email-lead-form div[class*="sellerName"] {
    display: none;
  }
  .contact-container > .email-lead-form button[type="submit"] {
    height: 44px;
  }
  .contact-container > .email-lead-form button[type="submit"] > label {
    visibility: hidden;
    font-size: 0;
  }
  .contact-container > .email-lead-form button[type="submit"] > label::after {
    content: "Contact Seller";
    visibility: visible;
    font-size: 16px;
  }
  .email-lead-form{
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      border: 1px solid var(--bt-color-neutral-30); ;
      border-radius: 8px;
      background: var(--bt-color-neutral-10); ;
    }
    
    .email-lead-form>div{
      box-shadow: none;
      padding: 0;
      width: 100%;
    }
    .contact-instructions-description-container {
      background-color: #f5f9ff;
      border-radius: 12px;
      padding: 0 12px;
      @media only screen and (max-width: 970px) {
        padding: 0;
        width: 100%;
      }
  }
  .contact-instructions-description-container b {
      color: #404040;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
  }
  .contact-instructions-description-container span {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
  }
  .contact-instructions-description-container .contact-instructions-description-main {
      color: #404040;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: left;
  }
  /* Toast */
  .inline-prequalified-success,
  .inline-contact-success{
    display: flex;
    padding: 16px 16px 20px 16px;
    align-items: flex-start;
    gap: 12px;
    border-radius: 12px;
    border: 1px solid var(--bt-color-neutral-30); ;
    background: var(--bt-color-neutral-10); ;
    color: var(--bt-color-neutral-90); ;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: -webkit-fill-available;
  }
  .inline-prequalified-success-logo,
  .inline-contact-success-logo {
    width: 24px;
    height: 24px;
    background: url(//boattrader.com/static/boat-loans/finance-variant/check-circle.svg) no-repeat center center transparent;
    border: none;
  }
  .inline-contact-success-info{
    display: flex !important;
    gap: 8px;
    color:var(--bt-color-neutral-90); ;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; 
  }
  .inline-prequalified-text,
  .inline-contact-text{
    line-height: normal;
    font-size: 14px;
    max-width: 323px;
  }