.heart {
  z-index: 20;
  outline: 0;
  position: absolute;
  width: 20px;
  height: 20px;
  background: url(../../icons/unfilled_heart.svg) no-repeat;
  cursor: pointer;
  border: none;
  top: 7px;
  right: 8px;
  background-size: 100%;
  box-shadow: none;
}

.heart:hover, .heart.liked:hover {
  box-shadow: none;
  border-color: inherit;
  background-color: transparent;
}

.heart.liked {
  background: url(../../icons/filled_heart.svg) no-repeat;
  background-size: 100%;
}

.imageCarousel .heart.liked {
  background-position: 52% 50%;
  background-size: 50%;
}

.next-previous-info-container .next-previous-info .next-previous-listing-heart .heart.liked {
  background-position: 50% 6% !important;
  background-size: 35% !important;
}

@media screen and (max-width:768px) {
  .heart.discount:not(ol.boat-list li .image-container .image .heart.discount) {
    margin-top: 26px;
  }
  .heart {
    top: 6px;
    right: 5px;
    width: 17px;
    height: 17px;
  }
}