.title-bg {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  text-align: center;
}

.title-container {
  width: 100%;
  display: grid;
}

.title-container > .title-text {
  margin: 18px;
  color: white;
  font-size: 40px;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15), 0px 0px 0px rgba(0, 0, 0, 0.25);
}

.title-container > .title-text.bold {
  font-weight: bold;
}

@media screen and (max-width: 970px) {
  .title-bg {
    height: 80px;
  }

  .title-container > .title-text {
    font-size: 24px;
    margin: 4px;
  }
}
