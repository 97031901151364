/* BASE font-size: 15px */

/* Desktop */
.rates-calculator {
  width: 100%;
  display: flex;
  justify-content: center;
}

.calculator-body {
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;
  width: 780px;

  border-radius: 8px;
  border: 1px solid #F5F9FF;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.20);
}

.calculator-form {
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  background-color: var(--bt-color-neutral-10);
  padding: 48px;
}

.calculator-form-title{
  color:  #404040;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
}

.calculator-form-subtitle{
  color: #404040;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
}

.month-label{
  font-size: 16px;
}

.form-fieldset {
  display: contents;
}

.form-data {
  font-style: normal;
  font-weight: 300;
  font-size: 1.06rem;
  line-height: 1.26rem;
  color: #555555;
  margin-bottom: 16px;
  width: 20rem;
}

.form-data.dropdown {
  appearance: none;
  background-image: url(https://www.boattrader.com/static/img/icons/select-chevron.png);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 8px;
  margin-bottom: 16px;
  width: 100%;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #EDEDED;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.10) inset;
  height: 40px;
}
.form-data.dropdown:last-of-type{
  margin-bottom: 0;
}

.form-data.input {
  width: 92%;
  width: -webkit-fill-available;
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid #EDEDED;
  background: #FFF;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.10) inset;
  padding: 8px 12px;
  color: #404040;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  height: 24px;
}

.form-data.input::placeholder {
  color: #555;
  opacity: .3;
  font-size: 1rem;
}

.form-fieldset .calc-form-label {
  overflow: hidden;
  color: #404040;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding: 0;
  white-space: nowrap;
  margin-bottom: 6px;
}

.calculator-summary {
  box-sizing: border-box;
  height: 100%;
  width: 50%;
  background-color: #F5F9FF;
  padding: 48px;
}

.summary-wrapper {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.summary-small-print {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 100% */
  margin-top: 16px;
  margin-top: 50px;
}

.summary-data {
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: #404040;
}

.summary-data-title {
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.summary-data-apr {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.8px;
}

.summary-data-monthly-payment {
  text-align: center;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.summary-data-term-length {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.8px;
}

.summary-data-call-message {
  text-align: center;
  margin-bottom: .8rem;
  font-size: 1rem;
  font-style: normal;
  line-height: 170%;
  font-family: Roboto;
  color: #404040;
}

.contactus-label {
  text-align: center;
  margin-bottom: 0.8rem;
  font-size: 1rem;
}

a.contactus-label {
  text-decoration: underline;
  color: #404040;
}

.summary-apply-button {
  text-align: center;
  margin-top: 48px;
  justify-items: center;
}

.hide {
  display: none;
}

.api-call-status {
  color: red;
  margin-top: 0.66rem;
  text-align: center;
  font-style: normal;
  font-size: 0.73rem;
}

.free-text-input-error {
  color: red;
  margin-top: -1rem;
  font-style: normal;
  font-size: 0.73rem;
}

.main-footer {
  margin-top: .53rem;
}

/* Mobile */
@media only screen and (max-width: 991px) {
  
  .calculator-title {
    font-size: 2.13rem;
    line-height: 2.53rem;
    width: 16.66rem;
  }

  .calculator-form {
    height: auto;
    width: 100%;
    background-color: var(--bt-color-neutral-10);
    padding: 48px 24px;
  }

  .calculator-summary {
    width: 100%;
    padding: 48px 24px;
    height: auto;
  }

  .calculator-body {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .summary-wrapper {
    padding: 0;
  }

  @-moz-document url-prefix() {
    .summary-apply-button {
      display: grid;
    }
  }

  @supports (-webkit-backdrop-filter: blur(0)) {
    .summary-apply-button {
      display: grid;
    }
  }

}