.summary {
  color: #474c4a;
  font-size: 16px;
  padding : 0 0 20px 0;
}

.summary .heading {
  width: 100%;
  font-size: 20px;
  line-height: 26px;
  font-weight: normal;
  margin: 0;
  display: inline;
}

.summary-top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.summary .heading + span {
  display: none;
}

.hidden-price {
  display: none !important;
}

.summary .prices {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.summary .prices {
  font-size: 20px;
}

.summary .price, .summary .price-cut {
  padding: 10px 0 0 0;
}

.summary .price:empty {
  padding: 0;
}

.summary .payment-total {
  color: #07324f;
  font-size: 22px;
  font-weight: bold;
  margin-right: 8px;
}

.summary .price-cut span {
  font-size: 16px;
  color: #db5140;
  vertical-align: top;
}

.summary .price-payments {
  font-size: 18px;
  line-height: 27px;
  width: 100%;
  margin-top: 5px;
}

.summary .monthly-payment-container{
  display: flex;
  align-items: center;
  gap: 5px
}

.summary .monthly-payment-container .info-icon{
  width: 14px;
  height: 14px;
  cursor: pointer;
  background: url(./Info.svg) no-repeat;
}

.summary .price-payments .payment-month-old {
  font-size: 14px;
  line-height: 19px;
  padding-right: 8px;
  border-right: 1px solid #000;
}

.summary .price-payments .payment-month {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.5px;
  color: var(--bt-color-secondary_action-main);
}

.summary .link {
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  border-bottom: 1px dashed;
  margin-left: 8px;
}

.flush-left{
  padding-left: 0px !important;
}

.summary .dollar-icon {
  background: var(--bt-color-secondary_action-main);
  color: #fff;
  margin-right: 5.33px;
  border-radius: 15px;
  font-size: 1rem;
  padding: 2px;
  font-style: normal;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 13.33px;
  height: 13.33px;
}

.summary .location {
  cursor: pointer;
  font-size: 14px;
  line-height: 22px;
  margin-top: 6px;
  font-weight: normal;
  margin-bottom: 5px;
}

.summary .location.no-map {
  cursor: initial;
  background: none;
  padding-left: 0;
}

.summary .location .link {
  background: url(./Transport.svg) no-repeat;
  background-position: 0px 3px;
}

.summary .fsbo-cta {
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  color: #474c4a;
  border-bottom: 1px dashed;
}

.summary div.delivery {
  font-size: 14px;
  color: #656766;
  display: block;
  margin: 4px 10px -2px 0;
}

span.icon-delivery {
  background: url("../../icons/Shipping.svg") no-repeat;
  width: 29px;
  height: 13px;
  display: inline-block;
  filter: invert(44%) sepia(6%) saturate(109%) hue-rotate(98deg) brightness(85%) contrast(79%);
}

.summary .payment-total-oem {
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 4px 13px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  height: 40px;
  line-height: 22px;
  box-sizing: border-box;
  border-radius: 50px !important;
  background: var(--bt-color-primary) !important;
  border: none !important;
  color: var(--bt-color-neutral-10) !important;
}

.summary .summary-services {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #404040;
  margin-top: 12px;
}

.summary .btn {
  align-items: center;
  padding: 4px 8px;
  gap: 5px;
  height: 26px;
  background: #F5F5F5;
  border-radius: 4px;
  margin-right: 4px;
  cursor: pointer;
}

@media screen and (min-width:768px) {

  .summary .heading + span {
    font-size: 24px;
    font-weight: normal;
    display: inline;
  }

  .summary .heading + span:before {
    content: '|';
    margin: 0 10px;
  }

  .summary {
    padding-top: 21px;
  }
  .summary .price, .summary .price-cut {
    padding: 0;
  }

  .summary .prices {
    flex-direction: row;
    margin-top: 7px;
  }
  .summary .payment-total {
    font-weight: bold;
    color: #07324f;
  }

  .summary .heading {
    font-size: 24px;
    line-height: 33px;
  }

  .summary .loading-text {
    width: 50%;
  }

}

@media screen and (max-width:970px) {
  .summary {
    padding : 20px;
  }

  .summary .price-payments .payment-total {
    cursor: pointer;
    color: var(--bt-color-secondary_action-main);
  }

  .summary div.delivery {
    display: block;
    margin: 6px 0 0 0;
  }

  .summary .price-payments .payment-month {
    letter-spacing: 0.75px;
  }

  .summary .summary-services {
    margin-top: 16px;
  }

  .summary-top-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
  }

}

.monthly-payment-tooltip-wrapper .react-tooltip-lite,  .finance-summary-tooltip .react-tooltip-lite {
  display: flex;
  flex-flow: column nowrap;
  justify-items: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid var(--bt-color-neutral-30);
  box-shadow: -10px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: auto;
  color: #404040;
  padding: 7px !important;
  gap: 8px;
  margin-left: 9px;
  width: 215px !important;
}

.finance-summary-tooltip .react-tooltip-lite {
  font-size: 12px;
  margin-left: -93px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.10)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.10)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.10));
}

.finance-summary-tooltip .react-tooltip-lite-arrow {
  margin-top: -1px;
}

.monthly-payment-tooltip-wrapper .react-tooltip-lite-right-arrow {
  margin-left: 10px;
  margin-top: -1px;
}

.monthly-payment-tooltip-wrapper .react-tooltip-lite-up-arrow {
  margin-top: -1px;
}

@media screen and (min-width: 970px) {
  .top .summary {
    padding-top: 0px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 969px) {
  .top .summary {
    padding: 0 10px;
  }
}

.broker-summary-section{
  display:flex;
  flex-direction: column;
  row-gap: 8px;
}