.item-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.item-wrapper .centered {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
}

.carousel .slide iframe {
  height: 100%;
  margin: 0 4em 0;
  width: 100%;
}

.carousel .item-wrapper .blur {
  object-fit: cover;
  height: 100%;
  filter: blur(25px) brightness(.75);
}

.carousel .item-wrapper.video {
  background: #000;
}

.carousel .slide img {
  height: auto;
}
