/* -- page overrides --*/
.fsbo-landing #root,
.fsbo-landing #root>.page-container {
  height: 100%;
}
.fsbo-landing #root>.page-container>.content {
  height: calc(100% - 51px);
}

/* -- element disables --*/
.fsbo-landing .navbar-toggle,
[lang].bt-banner .fsbo-landing .arc-banner {
  display: none;
}

/* -- page --*/
.fsbo-landing-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fsbo-landing-container img {
  margin-bottom: 30px;
  margin-top: -75px;
}

.fsbo-landing-container .button {
  color: #fff;
  background: #73B666;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  width: 300px;
  line-height: 40px;
  border-radius: 4px;
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
  display: block;
}

.fsbo-landing-container p {
  font-size: 12px;
  color: #000;
  max-width: 80%;
  text-align: center;
  margin: 0;
}

.fsbo-landing-container .anchor {
  font-size: 12px;
  color: #4E4E4E;
}
