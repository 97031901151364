.loading-text {
  background: linear-gradient( 90deg,#D8D8D8 0%, #EAEAEA 100%, #D8D8D8 200%);
  background-size: 200% 200%;
  height: 22px;
  margin-bottom: 10px;
  animation: AnimationName 3s ease infinite;
}

@keyframes AnimationName { 
    0%{background-position:0% 0%}
    50%{background-position:-100% 0%}
    100%{background-position:-200% 0%}
}