.prequalified-collapsible {
    border-radius: 5px;
    margin-top: 20px;
    margin-right: 0;
    display: block; 
    box-shadow: 0 1px 4px 1px rgb(0 0 0 / 10%);
    padding: 4px;
  }

  .prequalified-collapsible .collapse-content.open .collapsible{
    overflow: visible;
  }

  @media (max-width: 969px) {
    .prequalified-collapsible {
      margin-top: 5px;
    }
  }
