/* BASE font-size: 15px */

/* Desktop */
.boat-loans-rates-info-container {
  width: 100%;
  background: #FFF;
}


.boat-loans-rates-info-container .rates-info-content {
  display: flex;
  flex-direction: row;
  gap: 72px;
  margin: 0 auto;
  padding: 48px 0;
  max-width: 1150px;
}

.rate-examples-list {
  margin-left: 12px;
}

.ai-image-disclaimer-text {
  text-align: center;
}

.boat-loans-rates-info-container .rates-info-content .title {
  color: var(--bt-color-tertiary);
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 12px 0;
}

.boat-loans-rates-info-container .rates-info-content ol, 
.boat-loans-rates-info-container .rates-info-content ul {
  margin-top: 0 !important;
  margin-bottom: 24px !important;
  padding-inline-start: 24px !important;
}

.boat-loans-rates-info-container .rates-info-content .paragraph {
  margin-bottom: 24px !important;
}

.boat-loans-rates-info-container .rates-info-content .info-text,
.boat-loans-rates-info-container .rates-info-content li {
  color: #404040;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 143.75% */
}

.boat-loans-rates-info-container .rates-info-content .rates-info-image{
  border-radius: 8px;
  width: 392px;
  object-fit: cover;
}

.boat-loans-rates-info-container.bg-light-blue{
  background-color: #F5F9FF;
}
.boat-loans-rates-info-container.bg-light-blue .rates-info-content{
  display: flex;
  flex-direction: row-reverse;
}


.boat-loans-rates-info-container.bg-dark-blue {
  background-color: var(--bt-color-tertiary);
}

.boat-loans-rates-info-container.bg-dark-blue .rates-info-content .title {
  color:#C9DCF2;
}

.boat-loans-rates-info-container.bg-dark-blue .rates-info-content .info-text,
.boat-loans-rates-info-container.bg-dark-blue .rates-info-content li {
  color:#FFF;
}


.boat-loans-rates-info-container .button-container.right{
  float: right;
}

.bullet-title {
  font-weight: bold;
}

/* Small Mobile */
@media only screen and (max-width: 991px) {
  .boat-loans-rates-info-container .rates-info-content {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .boat-loans-rates-info-container .rates-info-content .rates-info-image{
    height: 200px;
  }
}

@media only screen and (max-width: 1280px) {
  .boat-loans-rates-info-container .rates-info-content {
    margin: 0 120px;
    flex-direction: column !important;
    gap: 24px;
  }

  .boat-loans-rates-info-container .button-container {
    float: none !important;
    justify-items: center;
  }

  @-moz-document url-prefix() {
    .boat-loans-rates-info-container .button-container {
      display: grid;
    }
  }

  @supports (-webkit-backdrop-filter: blur(0)) {
    .boat-loans-rates-info-container .button-container {
      display: grid;
    }
  }

  .boat-loans-rates-info-container .rates-info-image{
    width: 100% !important;
  }
}