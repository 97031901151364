.search-tools.sort {
  width: auto;
  height: auto;
  position: static;
  top: 0;
  right: 0;
  background: transparent;
  text-align: right;
  padding: 0;
  font-size: 0.8em;
  overflow: visible;
}

.search-tools.sort .collapse-content {
  border: 0;
}

.search-tools.sort .collapsible {
  position: relative;
  overflow: visible;
}

.search-tools.sort .tool-set {
  transform: none;
  width: auto;
  left: 0;
  position: static;
  display: block;
  overflow-y: visible;
  padding: 0;
  background: 0;
}

.search-tools.sort .tool-set .inner {
  float: right;
  padding-bottom: 0;
  outline: none;
  overflow: visible;
}

.search-tools.sort .tool-set .title {
  background: 0;
  padding: 0;
  border: 0 none;
}

.search-tools.sort .tool-set .header h2 {
  box-sizing: unset;
  display: inline-block;
  border-radius: 4px;
  width: auto;
  font-size: 10px;
  height: 2.2em;
  font-weight: normal;
  line-height: 2.2em;
  padding: 0 2em 0 10px;
  margin: 0;
  position: relative;
  color: #474c4a;
}

.search-tools.sort .tool-set h2:before {
  content: "Sort By";
  color: #0b3f65;
  font-size: 10px;
  font-weight: bold;
  margin-right: 5px;
}

.search-tools.sort .tool-set h2:hover {
  cursor: pointer;
}

.search-tools.sort .tool-set .header .criteria {
  display: inline;
  font-size: 1em;
}

.search-tools.sort .tool-set .header h2 a {
  font-weight: normal;
  display: inline;
  font-size: 1em;
  position: static;
}

.search-tools.sort .tool-set .header h2 span {
  color: #474c4a;
}

.search-tools.sort .tool-set ul.opts {
  transition: max-height 0.2s ease-in-out;
  position: absolute;
  z-index: 99;
  right: 0;
  top: 0;
  margin: 4px 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
  text-align: left;
  padding: 5px 0;
  height: auto;
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
}

.search-tools.sort .tool-set .open > .collapsible > ul.opts {
  visibility: visible;
  max-height: none;
  overflow-y: auto;
}

.search-tools.sort .tool-set ul.opts li {
  border: none;
  white-space: nowrap;
  padding: 0;
}

.search-tools.sort .tool-set ul.opts li .custom-option,
.search-tools.sort .tool-set ul.opts li label {
  border-width: 0;
  padding: 3px 20px;
  font-size: 13px;
  color: #474c4a;
  cursor: pointer;
}

.search-tools.sort .tool-set ul.opts li .custom-option.selected,
.search-tools.sort .tool-set ul.opts li label.selected {
  color: #fff;
  font-weight: normal;
  background: #2876aa;
}

.search-tools.sort .tool-set li .custom-option:after {
  display: none;
}

.search-tools.sort .tool-set li input {
  position: absolute !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
}

.sort-title, .sort-separator {
  display: inline-block;
}

@media screen and (min-width: 768px) {
  .search-tools.sort .tool-set .header h2 {
    font-size: 13px;
  }

  .search-tools.sort .tool-set h2:before {
    font-size: 13px;
  }


  .collapse-content .header span .ref-length-longest-first {
    margin-right: 20px;
  }
}

@media screen and (max-width: 969px) {
  .search-tools.sort .collapsible {
    top: -300px;
  }

  .sort-title, .sort-separator {
    display: none;
  }
}
