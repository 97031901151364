
.payment-calculator .collapsible.no-pad{
  padding: 0;
}

.payment-calculator-wrapper {
  position: relative;
}

#payment-calculator-anchor {
  position: absolute;
  top: -50px;
}

.payment-calculator .price {
  font-size: 20px;
  line-height: 27px;
  width: 100%;
  margin-top: 5px;
}

.payment-calculator .loan-amount-input-container,
.payment-calculator .loan-terms-input-container,
.payment-calculator .interest-rate-input-container {
  position: relative;
}

.payment-calculator .loan-amount-input-container > span {
  position: absolute;
  padding: 9px 8px;
  margin: 3px;
  font-size: 10px;
  line-height: normal;
  left: 0;
  color: #a5a5a5;
}

.payment-calculator .loan-terms-input-container > span {
  position: absolute;
  padding: 9px 0px;
  margin: 3px;
  font-size: 10px;
  line-height: normal;
  left: 25px;
  color: #a5a5a5;
}

.payment-calculator .interest-rate-input-container > span {
  position: absolute;
  padding: 9px 0px;
  margin: 3px;
  font-size: 10px;
  line-height: normal;
  left: 34px;
  color: #a5a5a5;
}

.payment-calculator .payment-result-container {
  font-size: 12px;
  line-height: 15px;
  width: 100%;
  margin-top: 12px;
  display: flex;
  text-align: center;
}

.payment-calculator .payment-input-container {
  display: flex;
  width: 100%;
}

.payment-calculator .payment-input-container div {
  flex-basis: 140px;
}

.payment-calculator .interest-rate, .loan-terms {
  flex-shrink: 4;
}

.payment-calculator .loan-amount {
  flex-shrink: 1;
}

.payment-calculator .payment-input-container div label {
  font-size: 12px;
  margin-left: 5px;
}

.payment-calculator .payment-result-container .payment-month {
  font-size: 14px;
  line-height: 19px;
  color: #46AE8A;
}

.payment-calculator .payment-input-container input {
  border: 1px solid #CCCCCC;
  padding: 8px 8px 9px;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  box-sizing: border-box;
  font-size: 10px;
  margin: 3px;
  width: 90%;
  min-width: 0;
}

.payment-calculator .interest-rate-input-container input {
  padding-right: 52%;
}

.payment-calculator .loan-terms-input-container input {
  padding-right: 64%;
}

.payment-calculator .loan-amount-input-container input {
  padding-left: 17px;
}

.payment-calculator .payment-calculator-button {
  width: 75%;
  color: white;
  background: #119BF5;
  border: 1px solid #119BF5;
  box-sizing: border-box;
  border-radius: 5px;
}

.payment-calculator .calc-form-label {
  font-weight: bold;
}

.payment-calculator .result-presentation {
  margin-left: 15px;
}

.payment-calculator .calc-calculator-footer-container {
  display: flex;
  justify-content: center;
  margin: 45px 30px 0 30px;
}
.payment-calculator .calc-calculator-footer {
  width: 100%;
  max-width: 400px;
  height: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: var(--bt-color-neutral-100);
}


.payment-calculator #calc-summary{
  display: flex;
  align-items: center;
  justify-content: center;
}


@media screen and (min-width: 768px) {
  .payment-calculator .content-wrapper {
    background: var(--bt-color-neutral-10);
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display:flex;
    padding: 3px;
  }

  .payment-calculator .payment-result-container .payment-month {
    margin: auto;
    padding-left: 8px;
  }

  .payment-calculator .payment-calculator-button {
    width: 30%;
    margin-top: auto;
    height: 31px;
  }

  .payment-calculator .payment-input-container {
    display: flex;
    width: 100%;
  }

  .payment-calculator .payment-result-container {
    line-height: 27px;
    margin-top: 0;
    margin-bottom: 3px;
    justify-content: space-between;
  }

  .payment-calculator .result-presentation {
    display: flex;
    margin-top: auto;
    margin-right: 7px;
  }

  .payment-calculator .interest-rate, .loan-terms {
    width: 5rem;
  }

  .payment-calculator .loan-amount {
    width: 7rem;
  }
}

@media screen and (min-width:970px) {
  .payment-calculator .interest-rate, .loan-terms {
    width: 6rem;
  }

  .payment-calculator .loan-amount {
    width: 8rem;
  }
}

/*
  BOAT DETAILS AUTO CALCULATOR STYLES
  BASE font-size: 15px
*/

/* Desktop */

.auto-calculator {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.auto-calculator .calc-calculator-container {
  align-self: flex-start;
  justify-content: unset;
}

.auto-calculator .calc-calculator-sub-container {
  margin-top: .1rem;
  padding-left: 1.85rem;
}

.auto-calculator .calc-calculator-mode-selector {
  width: 100%;
}

.auto-calculator .calc-calculator-body {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin-top: 0.666rem;
  margin-bottom: 1.06rem;
  box-sizing: border-box;
}

.payment-calculator .calc-calculator-body {
  margin-bottom: 1.4rem;
}

.auto-calculator .calc-calculator-form {
  padding: 1.375rem 1.25rem;
  height: 500px;
}

.auto-calculator .calc-form-label {
  font-size: 0.875rem;
  padding-bottom: 0.5rem;
}

.auto-calculator .calc-form-data.input {
  margin-bottom: .5rem;
}

.auto-calculator .calc-form-data.input#interest-rate {
  margin-bottom: 1.8rem;
}

.auto-calculator .calc-calculator-summary {
  height: auto;
  background-color: #F5F5F5;
  padding: 20px 20px 13px;
}

.summary-wrapper {
  padding: 0;
}

.auto-calculator .calc-summary-title {
  font-size: 1.467rem;
  font-weight: 900;
  color: #404040;
  margin-bottom: 2px;
}

.auto-calculator .calc-summary-small-print {
  display: none;
}

.auto-calculator .calc-summary-data {
  font-size: 1.067rem;
  color: #404040;
}

.auto-calculator .calc-payment-label {
  padding-top: 2.66rem;
  font-weight: 700;
  font-size: 1.067rem;
  margin-top: 1.13rem;
}

.auto-calculator .calc-monthly-payment, .auto-calculator .calc-loan-amount {
  font-size: 1.467rem !important;
  font-weight: 900;
  line-height: unset;
  padding-bottom: 2.5rem;
  color: #404040;
}

.auto-calculator .calc-calculator-selector-button {
  font-weight: 400;
  font-size: 0.935rem;
  color: #616161;
  width: 10.1875rem;
}

.auto-calculator .calc-calculator-selector-button.monthly-mode,
.auto-calculator .calc-calculator-selector-button.loan-amount-mode {
  font-size: .934rem !important;
}

.auto-calculator .calc-calculator-selector-button.selected {
  border: 1px solid #757575;
  color: #404040;
  background-color: #F5F5F5;
  font-weight: 700;
  font-size: .935rem;
}

.calc-summary-wrapper {
  padding-left: 0;
  padding-right: 0;
}

.auto-calculator .calc-summary-apply-button {
  border-radius: 6.66rem;
  background-color: var(--bt-color-secondary_action-main);
  width: 10.066rem;
  font-weight: 500;
  font-size: 0.93rem;
  margin: unset;
}

.auto-calculator .calc-summary.button-row {
  padding-top: 2.86rem;
  margin-left: auto;
  margin-right: auto;
}

.auto-calculator .calc-summary-apply-button > a {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.auto-calculator .cta-icon {
  display: inline-block;
  padding-right: 0.4rem;
  padding-top: 4px;
}

.auto-calculator .calc-summary-apply-button .cta {
  color: var(--bt-color-neutral-10);
}

.auto-calculator .calc-free-text-input-error {
  margin-top: -0.5rem;
  margin-left: 70%;
}

.auto-calculator .calc-form-label.loan-amount-or-monthly-payment,
.auto-calculator .calc-form-label.interest-rate {
  margin-top: 0;
  width: 100%
}

.auto-calculator .calc-free-text-input-error.interest-rate {
  margin-left: 30%;
  width: 100%;
  position: absolute;
  margin-top: -26px;
}

.auto-calculator .calc-get-prequal-button {
  display: flex; 
}

.auto-calculator .calc-or {
  display: inline; 
}

.auto-calculator .calc-or-text {
  font-size: 0.875rem;
  color: #404040;
}

.auto-calculator .calc-percent-sign {
  color: #404040;
}

/* Mobile */
@media only screen and (max-width: 991px) {
  .auto-calculator .calc-get-prequal-button {
    margin-top: unset;
  }
}

@media only screen and (max-width: 1096px) {
  .auto-calculator .calc-calculator-sub-container {
    padding-left: 0.6rem !important;
    padding-right: 0.6rem;
  }
}

@media only screen and (max-width: 1367px) {
  .auto-calculator .calc-calculator-container {
    margin-top: 0;
  }

  .auto-calculator .calc-calculator-sub-container {
    padding-top: 0;
    padding-left: 1.6rem;
  }

  .auto-calculator .calc-calculator-body {
    width: auto;
  }

  .auto-calculator .calc-calculator-form {
    padding: 1.333rem 1.333rem;
    width: 100%;
    height: 545px;
  }

  .auto-calculator .calc-calculator-summary {
    padding: .86rem 1.333rem 1.03rem;
    width: 100%;
    margin-top: unset;
  }

  .auto-calculator .calc-summary-apply-button .cta {
    font-size: .934rem;
    font-weight: 700;
  }

  .auto-calculator .calc-form-label.loan-amount-or-monthly-payment,
  .auto-calculator .calc-form-label.interest-rate {
    font-size: .934rem;
  }

  .auto-calculator .calc-calculate-row {
    flex-flow: column nowrap;
  }
  .auto-calculator .calc-form-data.input#interest-rate {
    width: 100%;
  }

  .auto-calculator .calc-or-text {
    font-size: 0.934rem;
    font-weight: 500;
  }

  .auto-calculator .calc-calculator-selector-button {
    width: 50%;
    height: 2.53rem;
  }

  .auto-calculator .calc-summary-title {
    font-size: 1.2rem;
    margin-bottom: -6px;
  }

  .auto-calculator .calc-summary-data {
    font-size: .934rem;
    font-weight: 400;
  }

  .auto-calculator .calc-payment-label {
    font-size: 1.06rem;
    font-weight: 700;
    padding-top: 0.6rem !important;
    margin-top: .5rem;
  }

  .auto-calculator .calc-monthly-payment,
  .auto-calculator .calc-loan-amount {
    font-size: 1.2rem !important;
    font-weight: 900;
    line-height: 1.73rem;
    padding-bottom: .2rem;
    margin-top: -0.6rem;
  }

  .auto-calculator .calc-summary-button-row {
    padding-top: 0.5rem;
  }

  .auto-calculator .calc-free-text-input-error {
    margin-left: 70%;
    width: 100%;
  }

  .auto-calculator .calc-form-data.input#interest-rate {
    margin-bottom: 1.13rem;
  }

  .auto-calculator .calc-form-data {
    width: 100%;
  }

  .auto-calculator .calc-free-text-input-error.interest-rate {
    margin-left: 71%;
    width: 100%;
  }

  .payment-calculator .calc-calculate-button {
    width: unset;
    margin-left: unset;
    font-weight: 700;
  }

  .auto-calculator .calc-calculator-footer {
    padding-left: .6rem;
  }
}

/* Small Mobile */
@media only screen and (max-width: 450px) {
  .payment-calculator .calc-calculator-body {
    width: 100%;
  }

  .payment-calculator .calc-calculator-form {
    width: 100%;
    height: 545px;
  }

  .payment-calculator .calc-calculator-summary {
    width: 100%;
  }

  .payment-calculator .calc-calculate-row {
    flex-flow: column nowrap;
  }

  .auto-calculator .calc-free-text-input-error {
    margin-left: 68%;
  }

  .auto-calculator .calc-free-text-input-error.interest-rate {
    margin-left: 68%;
  }

  .auto-calculator .calc-calculator-summary {
    padding: .33rem 1.333rem 1.03rem;
  }

  .payment-calculator .calc-calculator-footer {
    width: 100%;
    max-width: unset;
  }
}

@media only screen and (max-width: 425px) {
  .auto-calculator .calc-summary-button-row {
    flex-flow: row wrap;
  }

  .auto-calculator .calc-summary-button-row > * {
    width: 100%;
    margin-top: .5rem;
  }

  .auto-calculator .calc-summary-button-row .calc.calc-summary-apply-button {
    width: 100%;
  }
}

.payment-calculator-card {
  margin-top: 1.33rem;
  margin-bottom: 20px;
  background: var(--bt-color-neutral-10);
  border-radius: 5px 5px 5px 5px;
  filter: drop-shadow(0px 1px 4px rgb(0, 0, 0, 0.25));
}

.payment-calculator-card .calc-card-title-container {
  display: flex;
  align-items: center;
  font-weight: 300;
}

.payment-calculator-card .calc-card-title {
  margin-left: 0.688rem;
}

.payment-calculator-card .calc-card-text {
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 15px !important;
  line-height: 25px !important;
  color: #0b3f65 !important;
}

.payment-calculator-card .calc-calculator-body {
  border: unset;
  border-radius: unset;
  width: 100%;
}

.payment-calculator-card .calc-calculator-form {
  width: 100%;
  height: 34.67rem;
}

.payment-calculator-card .calc-calculator-body .calc-calculator-form {
  width: unset;
}

.payment-calculator-card .calc-calculator-body {
  flex-flow: column nowrap;
}

.payment-calculator-card .calc-form-data.input#interest-rate {
  width: 100%;
}

.payment-calculator-card .auto-calculator .calc-calculator-container {
  align-self: flex-start;
  justify-content: center;
}

.payment-calculator-card .auto-calculator .calc-calculator-sub-container {
  padding-left: unset !important;
  padding-right: unset !important; 
  margin-top: unset;
  width: 100%;
}

.payment-calculator-card .auto-calculator .calc-calculator-body {
  margin-top: unset;
  margin-bottom: unset;
}

.payment-calculator-card .auto-calculator .calc-calculator-form {
  padding-top: unset;
}

.payment-calculator-card .auto-calculator .calc-calculate-row {
  flex-flow: column nowrap;
  gap: 8px;
}

.payment-calculator-card .calc-calculator-summary {
  width: unset;
  border-radius: 5px;
  border: 1px solid #F5F5F5;
}

.payment-calculator-card .auto-calculator .calc-free-text-input-error {
  margin-left: unset;
  margin-top: 37px;
}

.payment-calculator-card .auto-calculator .calc-form-data.input#interest-rate {
    margin-bottom: 0.3rem;
}

.payment-calculator-card .auto-calculator .calc-monthly-payment {
  padding-bottom: unset;
}

.payment-calculator-card .auto-calculator .calc-payment-label {
  padding-top: unset;
}

.payment-calculator-card .auto-calculator .calc-summary-apply-button {
  width: 100%;
  margin-top: 0.8rem;
}

.payment-calculator-card .calc-calculator-footer-container {
  margin-top: 1.063rem;
}

.payment-calculator-card .auto-calculator .calc-calculator-footer {
  background-color: #F5F5F5;
  padding-left: 1.063rem;
  padding-right: 1.063rem;
}

.payment-calculator-card .collapse-content .header a .icon {
  top: 0.867rem;
}

.payment-calculator-card .auto-calculator .calc-form-label {
  font-size: 0.934rem;
}
