
.contact-group-in-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}
.contact-group-in-line div {
  width: 100%;
  display: flex;
  height: 40px;
  border-radius: 4px;
  text-transform: uppercase;
}

.contact-group-in-line button {
  margin: 10px;
  text-transform: uppercase;
}

.contact-group-in-line a {
  height: 30px;
  min-height: 30px;
}