.dealer-makes-container {
  color: var(--bt-color-secondary_action-main);
}

.dealer-makes-container h2 {
  font-size: 24px;
  color: black;
  padding-top: 0;
  padding-bottom: 1rem;
  margin: 0;
}

.dealer-makes a {
  width: 100%;
  display: inline-block;
  text-align: center;
}

.dealer-makes ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.all-makes {
  font-weight: bold;
  line-height: 40px;
  position: relative;
  top: -20px;
  text-align: right;
}

.all-makes a {
  cursor: pointer;
}

.dealer-makes img {
  max-height: 100px;
  width: 85%;
}

.dealer-makes li {
  flex: 1 1 30%;
  padding: 0.8em;
}


@media only screen and (min-width: 768px) and (max-width: 1080px) {
  .dealer-makes {
    display: flex;
    flex-direction: row;
  }

  .all-makes {
    position: relative;
    top: -20px;
    text-align: right;
  }

  .dealer-makes li {
    flex: 1 1 12%;
  }
}

@media screen and (min-width: 1081px) {
  .dealer-makes {
    display: flex;
    flex-direction: row;
  }

  .all-makes {
    position: relative;
    top: -20px;
    text-align: right;
  }

  .dealer-makes li {
    flex: 1 1 9%;
    max-width: 11%;
  }
}
