.dealers-articles .articles::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 0.25em;
}

.dealers-articles .article::-webkit-scrollbar-thumb {
  border-radius: 0.25em;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.dealers-articles .recent-articles-review .articles {
  display: flex;
  background-color: #fff;
  overflow-x: scroll;
  padding: 1em;
}

.dealers-articles .recent-articles-review .articles div {
	cursor: pointer;
}

.dealers-articles .recent-articles-review h2 {
  font-size: 24px;
  color: black;
  padding-top: 0;
  padding-bottom: 1rem;
  margin: 0;
}

.dealers-articles .recent-articles-review .articles > div {
	flex: 1 0 20%;
	max-width: 100%;
	margin-right: 0.8em;
	box-sizing: border-box;
	border: 0.1em solid #EAEAEA;
	border-radius: 0.25em;
}

.dealers-articles .recent-articles-review .articles img {
    max-width:100%;
    width: 100%;
		height: 200px;
    object-fit: cover;
}

.dealers-articles .recent-articles-review .content{
	padding: 1em;
}

.dealers-articles .recent-articles-review .content > div{
	padding: 0.3em 0;
}

.dealers-articles .recent-articles-review .category{
	font-weight: 400;
	font-size: 0.75em;
	line-height: 0.9em;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	color: #595959;
}

.dealers-articles .recent-articles-review .title-link{
	font-weight: 700;
	font-size: 1em;
	line-height: 1.2em;
	color: #334556;
}

.dealers-articles .recent-articles-review .excerpt{
	font-weight: 400;
	font-size: 0.9em;
	line-height: 1.1em;
	color: #686868;
}

.dealers-articles .recent-articles-review .see-more-articles {
	padding: 0 1em;
}

.dealers-articles .recent-articles-review .see-more-articles:link,
.dealers-articles .recent-articles-review .see-more-articles:visited,
.dealers-articles .recent-articles-review .see-more-articles:active {
	font-weight: 400;
	font-size: 1em;
	line-height: 1.2em;
	display: flex;
	align-items: center;
	color: #3B96D2;
	text-decoration: none;
	width: fit-content;
}

.dealers-articles .recent-articles-review .see-more-articles:hover {
	text-decoration: underline;
}

@media (min-width: 960px) {
	.dealers-articles .recent-articles-review .articles {
		overflow-x: visible;
	}
}

@media screen and (max-width: 959px) {
	.dealers-articles .recent-articles-review .articles {
		padding: 1em 0;
	}

	.dealers-articles .recent-articles-review .articles > div {
		flex: 1 0 75%;
		margin-right: 0.6em;
	}

	.dealers-articles .recent-articles-review h1 {
		padding: 0;
		margin: 0;
	}

	.dealers-articles .recent-articles-review .see-more-articles {
		padding: 1em 0em;
	}
}

@media screen and (max-width: 500px) {
	.dealers-articles .recent-articles-review .articles {
		padding: 1em 0;
	}

	.dealers-articles .recent-articles-review .articles > div {
		flex: 1 0 75%;
		margin-right: 0.6em;
	}

	.dealers-articles .recent-articles-review .articles > div:last-child {
		margin-right: 0;
	}

	.dealers-articles .recent-articles-review h1 {
		padding: 0;
		margin: 0;
	}

	.dealers-articles .recent-articles-review .see-more-articles {
		padding: 1em 0em;
	}
}