#recent-articles-review .articles::-webkit-scrollbar-thumb {
    border-radius: 0.25em;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
#recent-articles-review a.rticles::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 0.25em;
}

#homepage #recent-articles-review {
	padding: 2rem 4em;
	font-style: normal;
}

#homepage #recent-articles-review .articles {
  display: flex;
  background-color: #fff;
  overflow-x: scroll;
  padding: 1em;
}

#homepage #recent-articles-review h1 {
	font-weight: 700;
	font-size: 1.5em;
	line-height: 1.8em;
	color: #2C272D;
	padding: 0 0.7em;
	margin: 0;
}

#homepage #recent-articles-review .see-more-articles {
	padding: 0 1em;
}

#homepage #recent-articles-review .see-more-articles:link,
#homepage #recent-articles-review .see-more-articles:visited,
#homepage #recent-articles-review .see-more-articles:active {
	font-weight: 400;
	font-size: 1em;
	line-height: 1.2em;
	display: flex;
	align-items: center;
	color: #3B96D2;
	text-decoration: none;
	width: fit-content;
}

#homepage #recent-articles-review .see-more-articles:hover {
	text-decoration: underline;
}

@media (min-width: 960px) {
	#homepage #recent-articles-review .articles {
		overflow-x: visible;
	}
}

@media screen and (min-width: 768px) {
  #homepage #recent-articles-review {
    max-width: none;
  }
}

@media screen and (max-width: 959px) {
	#homepage #recent-articles-review {
		padding: 1em 0.5em 1em 1em;
	}

	#homepage #recent-articles-review .articles {
		padding: 1em 0;
	}

	#homepage #recent-articles-review h1 {
		padding: 0;
		margin: 0;
	}

	#homepage #recent-articles-review .see-more-articles {
		padding: 1em 0em;
	}
}

@media screen and (max-width: 500px) {
	#homepage #recent-articles-review {
		padding: 1em 0.5em 1em 1em;
	}

	#homepage #recent-articles-review .articles {
		padding: 1em 0;
	}

	#homepage #recent-articles-review h1 {
		padding: 0;
		margin: 0;
	}

	#homepage #recent-articles-review .see-more-articles {
		padding: 1em 0em;
	}
}