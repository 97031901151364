/* BASE font-size: 15px */

/* Desktop */

.boat-loans-process-component{
  width: 100%;
  background-color: white;
}

.loan-process-body p {
  margin-left: 0;
}

.loan-process-body {
  display: flex;
  align-items: flex-start;
  text-align: justify;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  color: #222222;
  color: var(--bt-color-neutral-90, #222222);
  font-family: Roboto;
  font-size: 16px;
  line-height: 24px; /* 150% */
  padding: 0 16rem 0 16rem;
}

.loan-process-body a{
  color: var(--bt-color-neutral-90, #404040);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
}

@media only screen and (max-width: 991px) {
  .loan-process-body {
    padding: 0 1rem 0 1rem;
  }
}
