#home-popular-boats {
  background: #EAEAEA;
  padding: 3em 6em 3em 4em;
}

#home-popular-boats h2 {
  font-size: 1.5em;
  line-height: 1.75em;
  color: #2C272D;
  font-weight: 700;
}

#home-popular-boats input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

#home-popular-boats ul {
  list-style: none;
  padding: 1em 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

#home-popular-boats ul li{
  padding: 0.3em 2em;
  display: inline-block;
  width: calc(100% / 5);
}

#home-popular-boats ul li:last-child{
  margin-bottom:  1em;
}

#home-popular-boats ul li a {
  font-size: 0.9em;
}

#home-popular-boats ul li:last-child a:link,
#home-popular-boats ul li:last-child a:visited,
#home-popular-boats ul li:last-child a:hover,
#home-popular-boats ul li:last-child a:active {
  color: var(--bt-color-secondary_action-main);
}

#home-popular-boats ul li:last-child a:after {
  content:'>';
  padding-left: 0.5em;
}

#home-popular-boats ul li a:link,
#home-popular-boats ul li a:visited,
#home-popular-boats ul li a:active {
  color:#2C272D;
  text-decoration: none;
}

#home-popular-boats ul li a:hover {
  color: var(--bt-color-secondary_action-main);
  text-decoration: underline;
}

#home-popular-boats .row {
  display: flex;
}

#home-popular-boats .row .col {
  flex: 1;
}

#home-popular-boats .row .col:last-child {
  margin-left: 1em;
}

 /* Accordion styles */

#home-popular-boats .tab {
  width: 100%;
  color: #000;
  overflow: hidden;
}

#home-popular-boats .tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em 1em 1em 2em;
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.2em;
  cursor: pointer;
  color: #2C272D;
}

#home-popular-boats .tab-label::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transform: rotate(90deg);
  transition: all 0.35s;
}

#home-popular-boats .tab-content {
  max-height: 0;
  padding: 0 1em;
  margin-top: 0;
  color: #2c3e50;
  border-bottom: 1px solid #A5A5A5;
  transition: all 0.35s;
}

#home-popular-boats input:checked + .tab-label {
  background: #CCCCCC;
  opacity: 0.5;
}

#home-popular-boats input:checked + .tab-label::after {
  transform: rotate(-90deg);
}

#home-popular-boats input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 0 1em;
}


@media screen and (max-width: 820px) {
  #home-popular-boats{
    padding: 1em 1em 2em 0em;
  }

  #home-popular-boats ul li {
    padding: 0.3em 2em;
    display: inline-block;
    width: calc(100% / 3);
    line-height: 200%;
  }
}

@media screen and (max-width: 500px) {
  #home-popular-boats{
    padding: 1em 1em 2em 0em;
  }

  #home-popular-boats ul li{
    padding: 0.3em 2em;
    display: inline-block;
    width: 100%;
    line-height: 200%;
  }
}
