@import url('./styles/utils.css');

/* base styles */

body, html {
  margin: 0;
  font-size: 15px;
  line-height: 1.3em;
  font-family: var(--bt-font-family);
  color: #333;
  height: 100%;
}

a {
  text-decoration: none;
}

img {
  font-size: 0;
}

/* Iconography */

[class^="icon-"], [class*=" icon-"] {
  background: none;
  display: inherit;
  height: auto;
  line-height: inherit;
  margin-top: auto;
  vertical-align: inherit;
  width: inherit;
}

/* buttons */

.btn-action {
  box-shadow: 0 2px 0 rgba(255,255,255,0.35) inset;
  border-radius: 4px;
  font-size: 1.2em;
  display: block;
  cursor: pointer;
  border: 1px solid #eea236;
  padding: 12px 0;
  background-color: #e47e30;
  color: #fff;
  width: 100%;
  height: 50px;
  line-height: 1.33em;
}

.btn-primary {
  border-radius: 50px !important;
  background: var(--bt-color-primary) !important;
  border: none !important;
  color: var(--bt-color-neutral-10) !important;
}

.btn-primary:hover {
  background: var(--bt-color-secondary_action-pressed) !important;
}

.btn-primary:disabled {
  background: var(--bt-color-neutral-30) !important;
  color: var(--bt-color-neutral-60) !important;
}

.btn-secondary {
  border-radius: 50px !important;
  background: var(--bt-color-neutral-10) !important;
  border: 1px solid var(--bt-color-primary) !important;
  color: var(--bt-color-primary) !important;
}

.btn-secondary:hover {
  background: var(--bt-color-neutral-20) !important;
}

.btn-secondary:disabled {
  background: var(--bt-color-neutral-10) !important;
  color: var(--bt-color-neutral-60) !important;
  border: 1px solid var(--bt-color-neutral-60) !important;
}

/* listing banners */
section.boat-listings.available-banner ol li .banner {
  display: flex;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.4px;
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 1;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #E3F1FE;
  color: #07324F;
  height: auto;
}
section.boat-listings.available-banner ol li .banner-variant {
  display: none;
}
section.boat-listings.available-banner-variant ol li .banner {
  display: none;
}
section.boat-listings.available-banner-variant ol li .banner-variant {
  display: block;
  border-radius: 4px;
  margin-bottom: 8px;
  width: fit-content;
  padding: 4px 8px;
  height: auto;
}

.banner.AVAILABLE_SOON {
  background-color: #e37a1f;
}

.banner.IN_STOCK {
  background-color: #5cb85c;
}

.banner.SALE_PENDING {
  background-color: #feba02;
}

.banner.NEW_ARRIVAL {
  background-color: var(--bt-color-secondary_action-main);
}

.banner.banner-variant.AVAILABLE_SOON {
  color: #E37A1F;
  background-color: #F9E4D2;
}

.banner.banner-variant.IN_STOCK {
  color: #269726;
  background-color: #DEF1DE;
}

.banner.banner-variant.SALE_PENDING {
  color: #feba02;
  background-color: #fff3d1;
}

.banner.banner-variant.NEW_ARRIVAL {
  color: var(--bt-color-secondary_action-main);
  background-color: #D0EBFD;
}

.body .content .summary .banner {
  padding : 2px 8px;
  font-size : 12px;
  border-radius : 4px;
  display: inline-block;
  margin-bottom: 6px;
}

.boat-details.variant .body .content .summary .banner{
  display: none;
}

.body .content .summary .banner.AVAILABLE_SOON {
  background-color : #F9E4D2;
  color : #E37A1F;
}
.body .content .summary .banner.IN_STOCK {
  background-color : rgb(222, 241, 222);
  color : rgb(38, 151, 38);
}
.body .content .summary .banner.SALE_PENDING {
  background-color : rgb(249, 228, 210);
  color : rgb(227, 122, 31);
}
.body .content .summary .banner.NEW_ARRIVAL {
  background-color : #D0EBFD;
  color : var(--bt-color-secondary_action-main);
}

/* helper classes */

.visible-xs {
  display: block;
}

.hidden-all {
  display: none;
}

.hidden-xs {
  display: none;
}

.bp2, .bp3, .bp4 {
  display: none
}

@media screen and (min-width: 500px) {
  .bp2 {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .bp1max {
    display: none;
  }
  section.boat-listings.available-banner ol li .banner {
    font-size: 12px;
    line-height: 14px;
    top: 8px;
    left: 8px;
  }
}

@media screen and (min-width: 970px) {
  .visible-xs {
    display: none;
  }
  .hidden-xs {
    display: block;
  }
  .bp1max, .bp2max {
    display: none;
  }
  .bp3 {
    display: block;
  }
}

@media screen and (min-width: 1360px) {
  .bp1max, .bp2max, .bp4max {
    display: none;
  }
  .bp4 {
    display: block;
  }
}

.selectdiv:after {
  position: relative;
  content: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCA4IDYiIHdpZHRoPSI4IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im00IDUuNDY5OTcgNC00LS45NC0uOTM5OTk5LTMuMDYgMy4wNTMzMjktMy4wNi0zLjA1MzMyOS0uOTQuOTM5OTk5eiIgZmlsbD0iIzI1NjZiMCIvPjwvc3ZnPg==");
  color: var(--bt-color-secondary_action-main);
  right: 11px;
  max-width: 33px;
  padding: 0 0 0 21px;
  pointer-events: none;
  display: inline-block;
}

/* page container */

.page-container > .content.nav-slide {
  margin-top: 50px;
  background-color: var(--bt-color-neutral-10);
}

.hide-overflow {
 overflow-x: hidden;
}

.overflow-hidden {
 overflow: hidden;
}

@media screen and (min-width: 970px) {
  .page-container > .content.nav-slide {
    margin-top: 0;
  }
}

@media screen and (max-width: 969px) {
  .page-container > .content.nav-slide.extra-padding {
    padding-bottom: 96px;
    margin-top: 87px;
  }
}

/* Used to hide content for accessibility purposes */
.visuallyhidden {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.react-tooltip-lite {
  border-radius: 8px;
  box-sizing: border-box;
  width: 190px !important;
  font-size: 12px !important;
  color: var(--bt-color-neutral-100) !important;
  background: var(--bt-color-neutral-10) !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.react-tooltip-lite-arrow {
  transform: translateY(-2px) !important;
  border-top: 10px solid var(--bt-color-neutral-10) !important;
}

body:has(#branch-banner-iframe) { 
  overflow: hidden !important; 
} 
.branch-animation { bottom: 0 !important }