.dealer-locations-container h2 {
  font-size: 24px;
  color: black;
  padding-top: 0;
  padding-bottom: 1rem;
  margin: 0;
}

.dealer-locations, .dealer-locations-modal {
  display: flex;
  flex-direction: row;
}

.dealer-locations a, .dealer-locations a:active, .dealer-locations a:visited,
.dealer-locations-modal a, .dealer-locations-modal a:active, .dealer-locations-modal a:visited,
.all-dealers a, .all-dealers a:active, .all-dealers a:visited {
  color: var(--bt-color-secondary_action-main);
}

.dealer-locations-container .dealer-locations-container.dealer-location-column:not(:first-child) {
  display: none;
}

.dealer-location-column {
  flex: 1 1 auto;
  line-height: 40px;
}

.dealer-location-column > div {
  padding: 0px 5px;
}

.all-dealers {
  font-weight: bold;
  line-height: 40px;
}

.all-dealers a {
  cursor: pointer;
}

@media screen and (max-width: 768px){
  .dealer-locations-container h2 {
    margin: 0px 10px 0px 0px;
    line-height: 1em;
  }
}

@media screen and (min-width: 768px) {
  .dealer-location-column:not(:first-child) {
    display: block;
  }
  .dealer-location-column {
    flex: 1 1 auto;
  }

  .dealer-locations-modal .dealer-location-column {
    flex: 1 1 25%;
  }

  .all-dealers {
    position: relative;
    text-align: right;
  }
}

.dealer-locations-container-modal button.contact-close {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 50px;
  height: 50px;
  border-width: 0;
  background: url(//boattrader.com/static/img/icons/close-x-mobile.png) no-repeat center center transparent;
  display:block;
}

.dealer-locations-container-modal .visuallyhidden {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

@media (min-width: 970px) {
  .dealer-locations-container-modal button.contact-close {
    float: right;
    clear: both;
    width: 16px;
    height: 16px;
    margin: 15px;
    background: url('//boattrader.com/static/img/icons/close-x.png') no-repeat 0 top;
  }

  .dealer-locations-container-modal button.contact-close:hover, .dealer-locations-container button.contact-close:hover {
    background-position: 0 bottom;
    cursor: pointer;
  }
}
