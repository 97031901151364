.boat-loans-and-services-wrapper {
  display: flex;
  gap: 16px;
  flex-flow: row nowrap;
  min-height: 264px;
}

.boat-loans-and-services-wrapper .get-prequal-container-blob {
  display: flex;
  width: 50%;
  padding: 40px 24px 32px;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: lightgray;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 78%;
}

.boat-loans-and-services-wrapper .get-prequal-container-blob .get-prequal-title-blob {
  color: #FFF;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.boat-loans-and-services-wrapper .get-prequal-container-blob .get-prequal-text-blob {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.boat-loans-and-services-wrapper .get-prequal-container-blob .get-prequal-button-blob {
  display: flex;
  height: 40px;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border: none;
  border-radius: 100px;
  background: var(--bt-color-secondary_action-main);
  cursor: pointer;
  color:  var(--bt-color-neutral-10);
}

.boat-loans-and-services-wrapper .get-protected-container {
  width: 50%;
  display: flex;
  padding: 40px 24px 32px;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background-color: lightgray;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.boat-loans-and-services-wrapper .get-protected-container-full {
  width: unset;
}

.boat-loans-and-services-wrapper .get-protected-container .get-protected-title {
  color: #404040;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.boat-loans-and-services-wrapper .get-protected-container .get-protected-text {
  color: #404040;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.boat-loans-and-services-wrapper .get-protected-container-full p {
  margin-top: 0px;
  margin-bottom: 0px;
}

/*
  RESPONSIVE STYLES
*/

@media only screen and (max-width: 700px) {
  .boat-loans-and-services-wrapper {
    flex-flow: column nowrap;
    height: fit-content;
  }

  .boat-loans-and-services-wrapper .get-prequal-container-blob {
    width: 100%;
    height: auto;
    padding: 40px 32px 32px;
    box-sizing: border-box;
  }

  .boat-loans-and-services-wrapper .get-protected-container {
    height: auto;
    width: 100%;
    padding: 40px 32px 32px;
    box-sizing: border-box;
  }

  .boat-loans-and-services-wrapper .get-protected-container-full p {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 450px) {
  .boat-loans-and-services-wrapper .get-protected-container, .get-prequal-container-blob {
    min-height: 356px;
  }

}
