@media screen and (max-width:767px) {

  .detail-data-table.pricing-details .datatable-right-section {
    margin-top: 7px;
    padding-top: 7px;
    border-top: 1px solid #cccccc;
  }

  .detail-data-table.pricing-details .datatable-right-section .datatable-item {
    display: block;
  }

}

.detail-data-table .datatable-section {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.detail-data-table .datatable-category {
  padding: 0;
  margin-top: 0;
  margin-bottom: 15px;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  display: block;
}

.detail-data-table .datatable-category .sub-title {
  font-size: 14px;
  line-height: 24px;
  color: #222222;
  margin-bottom: 5px;
  font-weight: bold;
  font-style:italic;
}

.detail-data-table .datatable-category .datatable-section {
  padding-left: 20px;
}

.detail-data-table .datatable-item {
  display: flex;
}

.detail-data-table .datatable-title{
  font-size: 14px;
  line-height: 24px;
  color: #222222;
  min-width: 150px;
  flex-shrink: 0;
}

.detail-data-table.measurements .datatable-title {
  font-size: 14px;
  line-height: 24px;
  color: #222222;
  min-width: 195px;
  flex-shrink: 0;
}

.detail-data-table.features .datatable-title {
  font-size: 14px;
  line-height: 24px;
  color: #222222;
  min-width: 195px;
  flex-shrink: 0;
}

.detail-data-table .datatable-title:after {
  content: ':';
}

.detail-data-table .datatable-value {
  font-size: 14px;
  line-height: 24px;
  color: #222222;
  text-transform: capitalize;
}

.detail-data-table.pricing-details {
  display: flex;
  flex-direction: column;
}

.detail-data-table.pricing-details .subtitle {
  text-indent: 10px;
}

.detail-data-table.pricing-details .datatable-title:after {
  content: normal;
}

.detail-data-table.pricing-details .datatable-left-section .datatable-value-bold {
  font-weight: bold;
}

.detail-data-table.pricing-details .datatable-right-section .datatable-value {
  text-transform: none;
}

@media screen and (min-width:768px) {

  .detail-data-table .datatable-item {
    max-width: 50%;
  }

  .detail-data-table .datatable-section {
    max-height: 150px;
  }

  /* add extra depth to the table if items greater than 10 */
  .detail-data-table .datatable-section.datatable-items-7,
  .detail-data-table .datatable-section.datatable-items-11,
  .detail-data-table .datatable-section.datatable-items-12,
  .detail-data-table .datatable-section.datatable-items-13,
  .detail-data-table .datatable-section.datatable-items-14 {
    max-height: 170px;
  }

  .detail-data-table .datatable-value {
    margin-right: 10px;
  }

  .detail-data-table.pricing-details {
    display: flex;
    flex-direction: row;
  }

  .detail-data-table.pricing-details .datatable-item {
    max-width: 100%;
  }

  .detail-data-table.pricing-details .datatable-left-section .datatable-value {
    margin-right: 50px;
  }

  .detail-data-table.pricing-details .datatable-right-section .datatable-title {
    min-width: 110px;
  }

  

}
