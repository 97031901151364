[class^="icon-"],
[class*=" icon-"] {
  font-family: "Material Icons", sans-serif;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-info:before {
  content: "\e980";
}

.tooltip {
  padding: 0 10px;
  order: 2;
  display: inline;
}

.tooltip i {
  vertical-align: top;
  color: #0b3f65;
  font-size: 18px;
  border: none;
}

.react-tooltip-lite {
  background: linear-gradient(to right, #0e4d77, #0b3f65);
  font-weight: 200;
  font-size: 0.8em;
  color: #ececec;
  z-index: 999999 !important;
}

.react-tooltip-lite-arrow {
  z-index: 999999 !important;
  border-color: #0b3f65;
}

.switch {
  height: 20px;
}

.switch.on {
  background: linear-gradient(90deg, #0e4d77, #0b3f65);
}

.switch.disabled {
  background-color: #ccc;
  opacity: 0.25;
}
.switch .switch-toggle {
  height: 18px;
}

/* ANA-1738 ANA-1745 */
  .tooltip i.material-icons {
    color: #c2c2c2;
    font-size: 24px;
  }

  .switch {
    width: 48px;
    height: 24px;
    border-radius: 24px;
    background-color: #ccc;
  }
  .switch .switch-toggle {
    height: 20px;
    width: 20px;
    top: 1px;
    border: 1px solid #ccc;
    box-shadow: none;
  }
  .switch.on {
    background: var(--bt-color-primary);
    border: 1px solid var(--bt-color-primary);
    position: relative;
  }
  .switch.on .switch-toggle {
    left: 26px;
    border: 1px solid var(--bt-color-primary);
  }
  .switch.on::before {
    content: "";
    position: absolute;
    height: 16px;
    width: 16px;
    display: block;
    top: 7px;
    left: 7px;
    background: #fff;
    -webkit-mask-image: url(../../../icons/Check.svg);
    mask-image: url(../../../icons/Check.svg);
    -webkit-mask-size: 80%;
    mask-size: 80%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }

