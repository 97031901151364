/* BASE font-size: 15px */

/* Desktop */
.main-section .tire-wheel-info {
  background-color: #FFF;
  padding: 0 0 2.6rem;
}

.breadcrumb-container {
  height: 2.666rem;
  background-color: #FFF;
  width: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-left: 1.533rem;
}

.main-section .tire-wheel-info .extended-services-info .bottom-section .card img {
  width: 105px;
  height: 100px;
}

.main-section .tire-wheel-info .extended-services-info .bottom-section .card .card-title {
  margin-top: 8px;
  margin-bottom: 12px;
}

footer.main-footer {
  margin-top: 0;
}

/* Mobile */
@media only screen and (max-width: 991px) {
  .main-section .tire-wheel-info {
    padding: 2.4rem 0 1.2rem;
  }

  .breadcrumb-container {
    height: 2.266rem;
    padding-left: 0.4rem;
  }

  .extended-services-hero-container .hero-title {
    font-size: 40px;
    line-height: normal;
    font-weight: 900;
  }

  footer.main-footer {
    margin-top: 1.466rem;
  }
}
