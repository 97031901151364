#main-content.error-msg {
  display: block;
}
.error-msg h1 {
  font-size: 22px;
  font-weight: bold;
  line-height: 24px;
  margin: 15px 0;
  padding: 0 0 7px 15px;
  border-bottom: 1px dotted #333;
}

.error-msg p {
  margin: 0 15px;
}

.error-msg ul {
  list-style: none outside none;
  margin: 0px;
}

.error-msg ul li {
  line-height: 2em;
}

.error-msg ul li img {
  vertical-align: middle;
}

.error-msg ul li ul li {
  position: relative;
  left: 40px;
}

@media screen and (min-width: 800px) {
  .error-msg img.error {
    float: left;
    width: auto;
    margin-right: 10px
  }

  .error-msg p {
    margin: 15px;
  }
}