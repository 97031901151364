.private-seller-info {
    padding: 24px 10px;
}

.private-seller-info > .section:not(:last-child) {
    margin-bottom: 16px;
}

.private-seller-info .column {
    display: flex;
    flex-direction: column;
}

.private-seller-info .row {
    display: flex;
    align-items: center;
}

.private-seller-info > .title > b {
    color: var(--bt-color-primary);
    font-size: 28px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.96px;
    margin-right: 8px;
}

.private-seller-info > .title > span {
    font-size: 28px;
    font-weight: 200;
    color: #07324f;
    letter-spacing: -0.31px;
}

.private-seller-info > .subtitle {
    margin-bottom: 16px;
}

.private-seller-info > .subtitle > span {
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;
}

.private-seller-info > .section {
    background-color: #f5f9ff;
    padding: 24px;
    border-radius: 8px;
}

.private-seller-info > .section > .section-content {
    background-color: #ffffff;
    padding: 16px;
}

.private-seller-info ul {
    list-style: none;
    padding-left: 0;
}

.private-seller-info ul li::before {
    content: "•";
    font-size: 20px;
    vertical-align: middle;
    margin: auto 8px;
    display: inline-block;
}

.private-seller-info a {
    text-decoration: none;
    color: #119BF5;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.4px;
}

.private-seller-info > .section .subtitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.private-seller-info > .section .subtitle .deductible {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
}

.private-seller-info > .section .subtitle-tooltip {
    margin-left: 6px;
    cursor: pointer;
}

.private-seller-info > .section > .section-content {
    font-size: 16px;
    font-style: normal;
}

.private-seller-info > .section > .section-content > .highlight {
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0.4px;
}

.private-seller-info > .section > .section-content > .table {
    margin: 16px auto;
    border: 1px solid #F7F7F7;
    border-radius: 8px;
}

.private-seller-info > .section span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.4px;
}

.private-seller-info > .section > .section-content > .text {
    margin-bottom: 14px;
}

.private-seller-info > .section > .section-content > .table > .table-row {
    display: flex;
    justify-content: space-between;
    padding: 12px;
}

.private-seller-info > .section > .section-content > .table > .table-row:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.private-seller-info > .section > .section-content > .table > .table-row > span:last-child {
    text-align: right;
}

.private-seller-info > .section > .section-content > .table > .table-row.dark {
    background-color: #F7F7F7;
}
