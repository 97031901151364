
.carousel-container {
  height: 100%;
  position: relative;
  width: 100%;
  box-shadow: inset 0 0 2px rgba(0,0,0,.3);
}

.carousel-container > div {
  height: 100%;
  width: 100%;
}

.carousel-slider {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.carousel .slider-wrapper {
  height: 100%;
}

#root div.slider-wrapper.axis-horizontal {
  height: calc(100% - 94px);
}

.carousel .slider {
  height: 100%;
}

.carousel .slider.animated {
  transition: none !important;
}

.carousel .slide {
  background: #EEEEEE;
}

.carousel-container .carousel-item {
  overflow: hidden;
  width: auto;
  padding-bottom: 75%;
  position: relative;
  display: flex;
  height: 100%;
}

.carousel .slide .centered {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.carousel .centered {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.carousel-status, .hide {
  display: none;
}


.carousel-div-over {
  width: 100%;
  height: 100%;
  z-index: 101; /* this zindex is to overlap the div over the carousel to disable vieo playing */
  position: absolute;
}

.carousel .control-next.control-arrow::hover {
  opacity: 0;
}


.carousel .control-prev.control-arrow::hover {
  opacity: 0;
}


.carousel .control-next.control-arrow::before {
  border-left: 0;
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../icons/carousel-arrow-right.svg') no-repeat 50%;
  background-size: auto 100%;
}


.carousel .control-prev.control-arrow::before {
  border-right: 0;
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../icons/carousel-arrow-left.svg') no-repeat 50%;
  background-size: auto 100%;
}

.carousel.carousel-slider .control-arrow, .carousel .control-arrow {
  opacity: 1;
  padding: 3px;
}

.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0,0,0,0) !important;
}

@media screen and (min-width: 970px) {
  .carousel-container .carousel-item {
    height: 100%;
    padding-bottom: 0;
  }

  .carousel .carousel-item.zoom-in {
    cursor: pointer;
  }
  
  .modal-carousel .close-carousel-modal {
    top: 2rem;
    right: 48px;
    padding: 0;
    width: 12px;
    height: 12px;
  }

  .modal-carousel .close-carousel-modal:before {
    content: "Close";
    display: block;
    font-style: normal;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    line-height: 12px;
    position: absolute;
    right: 24px;
    top: 0;
  }

  .modal-carousel .carousel .carousel-status {
    display: block;
    right: 48%;
    font-weight: 700;
    font-size: 18px;
    line-height: 12px;
    margin: 0 auto;
  }

  .modal-carousel .thumbs-wrapper {
    display: none;
  }

  .modal-carousel .carousel-slider-modal {
    height: 95%;
    width: 880px;
    margin-right: calc(calc(100% - 880px) / 2);
  }
  .modal-carousel .carousel .slider-wrapper {
    width: 85%;
  }

  .modal-carousel .carousel .control-prev.control-arrow,
  .modal-carousel .carousel .control-next.control-arrow {
    border-radius: 100%;
    min-width: 60px;
    width: 60px;
    height: 60px;
    background-color: rgb(64, 64, 64, .6);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-carousel .carousel .control-prev.control-arrow{
    left: 48px;
  }
  .modal-carousel .carousel .control-next.control-arrow {
    right: 48px;
  }

  .modal-carousel .carousel.carousel-slider .control-arrow:hover {
    background-color: rgb(64, 64, 64, .7) !important;
  }

  .modal-carousel .carousel .control-next.control-arrow::before {
    background: url('../../icons/RightArrow.svg') no-repeat 50%;
    background-size: 30%;
    filter: brightness(0) invert(1);
  }

  .modal-carousel .carousel .control-prev.control-arrow::before {
    background: url('../../icons/LeftArrow.svg') no-repeat 40%;
    background-size: 30%;
    filter: brightness(0) invert(1);
  }

  .modal-carousel .carousel .slide,
  .modal-carousel .carousel .slide.selected.previous {
    background-color: #000;
    margin-top: 2rem;
  }

  .modal-carousel .carousel .item-wrapper .blur {
    display: none;
  }

  .modal-carousel .carousel .slide .centered {
    padding: 5px;
  }
}
