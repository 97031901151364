.seller-info-details {
  font-size: 14px;
  line-height: 1.3em;
  padding: 18px;
}

.seller-info-name {
  font-weight: bold;
  margin: 0 23px 8px 0;
  font-size: 18px;
}

.seller-info-location {
  background: url(../../icons/Location.svg) no-repeat;
  background-position: 0px 3px;
  padding-left: 24px;
}

.seller-info-location > address {
  font-style: normal;
}

.seller-info-tel {
  background: url(../../icons/Phone.svg) no-repeat;
  background-position: 0px 3px;
  margin-bottom: 8px;
  padding-left: 24px;
}

.seller-info-tel > a {
  color: #119BF5;
}

.seller-tel-anchor {
  display: block;
}

.seller-tel-anchor-desktop, .seller-tel-paragraph {
  display: none;
}

#oem-dealer-info {
  padding: 25px 96px 4px;
  border-bottom: none;
  margin-bottom: 0;
}

#oem-dealer-info .seller-info-name{
  font-size: 22px;
  color: #002A45;
  font-weight: 600;
}

#oem-dealer-info p.seller-proximity{
  font-size: 16px;
  color: #666666;
  font-style: italic;
}

#oem-dealer-info .seller-info-location > address,
#oem-dealer-info .seller-info-location > a > address{
  font-size: 16px;
  color: #119BF5;
}

#oem-dealer-info .seller-info-tel{
  cursor: pointer;
}

@media screen and (max-width: 971px) {
  #oem-dealer-info {
    padding: 16px 16px 4px;
  }
}

@media screen and (min-width: 1024px) {
  .seller-tel-anchor {
    display: none;
  }

  .seller-tel-paragraph {
    color: #119BF5;
    display: block;
    margin: 0;
  }

  .seller-tel-anchor-desktop {
    cursor: pointer;
    display: block;
  }

  .seller-tel-anchor-desktop.revealed {
    cursor: default;
  }
}