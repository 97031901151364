.search-alerts-filter-tags {
  padding: 0 0 7px 0;
  background-color: var(--bt-color-neutral-10);
  border-bottom: 1px solid #ddd;
}
.search-alerts-filter-tags .search-alerts-filter-tags-scroller {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 2px;
  margin-bottom: 16px;
}

.search-alerts-filter-tags .no-facets {
  font-size: 12px;
  line-height: 1.3;
}
/* ANA-1738 ANA-1745 */
.search-alerts-filter-tags .search-alerts-filter-tag-item {
  font-size: 12px;
  border-radius: 100px;
  padding: 4px 25px 4px 8px;
  color: var(--bt-color-neutral-100);
  line-height: 18px;
  border: 1px solid var(--bt-color-neutral-30);
  background: url(../../icons/CloseFilter.svg) no-repeat var(--bt-color-neutral-30);
  background-size: 10px;
  background-position: right 8px center;
  text-transform: capitalize; 
  letter-spacing: 0.4px;
}

/* ANA-1738 ANA-1745 */
.search-alerts-filter-tags .search-alerts-filter-options {
  padding: 4px 8px;
  border-radius: 100px;
  color: var(--bt-color-neutral-10);
  font-size: 12px;
  line-height: 18px;
  background: var(--bt-color-neutral-80);
  border: 1px solid var(--bt-color-neutral-30);
  letter-spacing: 0.4px;
}

.search-alerts-filter-tags .search-alerts-filter-tag-item.make {
  text-transform: none;
}

.search-alerts-filter-tags .search-alerts-filter-tag-item:hover,
.search-alerts-filter-tags .search-alerts-filter-options:hover {
  cursor: pointer;
}

.search-filter {
  position: relative;
  padding-bottom: 6px;
}

.search-filter .slider-container {
  margin-top: 15px;
  margin-bottom: 15px;
}

.search-filter .slider-container .rc-slider {
  position: relative;
  display: block;
  height: 14px;
  width: 100%;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.search-filter .slider-container .rc-slider .rc-slider-rail {
  height: 4px;
  width: 265px;
  border-radius: 6px;
  position: absolute;
  background-color: #e9e9e9;
}

.search-filter .slider-container .rc-slider .rc-slider-track {
  height: 4px;
  position: absolute;
  border-radius: 6px;
  background-color: var(--bt-color-primary);
}

.search-filter .slider-container .rc-slider .rc-slider-handle {
  position: absolute;
  top: -60%;
  width: 18px;
  height: 18px;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  border-radius: 50%;
  background-color: var(--bt-color-primary);
  border: 2px solid var(--bt-color-neutral-10);
  box-shadow: 0px 1px 4px 0px #0000004D;
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

.search-filter .search-filter-group {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.search-filter .search-filter-group-preloader {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  height: 50px;
}

.search-filter .search-filter-group-preloader .preloader {
  z-index: 3;
  background: url(//boattrader.com/static/img/carousel/preloader.gif) center
    center no-repeat #fff;
  height: 100%;
  width: 100%;
}

.search-filter .search-filter-group > span {
  margin: 0px 16px;
  font-size: 10px;
  color: #474c4a;
}

.search-filter .search-filter-group > span.current-location {
  display: block;
  text-align: center;
  font-size: 10px;
}

.search-filter .search-filter-group > .center {
  font-size: 12px;
  display: block;
  text-align: center;
}

.search-filter .search-filter-group > .filter-select {
  width: 110px; /* ANA-1745 */
  flex-grow: 1;
  display: inline-block;
  font-size: 10px;
  padding: 8px;
  background-color: #fff;
  height: 32px;
  z-index: 200;
  color: #474c4a;
  border-radius: 5px;
}

.location-filter-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 14px;
  row-gap: 16px;
}

.search-filter.location {
  width: 100%;
}

.search-filter.location .search-filter-group {
  padding-top: 19px;
  padding-bottom: 10px;
}

.search-filter input[type="text"],
.search-filter input[type="number"] {
  width: 100px; /* ANA-1745 */
  flex-grow: 1;
  border: 1px solid #a5a5a5;
  padding: 8px 8px 9px 8px;
  border-radius: 5px;
  background-color: #fff;
  color: #474c4a;
  box-sizing: border-box;
  font-size: 10px;
}

.search-filter .tab-content-item input[type="text"],
.search-filter .tab-content-item input[type="number"] {
  width: 100px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.search-filter input.small {
  flex-grow: 0;
  width: 90px; /* ANA-1745 */
}

.search-filter .filled-block {
  font-size: 13px;
  color: #2c272d;
  background: #e1e1e1;
  border: 1px solid #a5a5a5;
  border-radius: 5px;
  padding: 6px 18px;
  margin-left: 20px;
}

.search-filter .collapse-content {
  border: 0 none;
}

.search-filter .collapse-content .collapsible {
  padding: 0;
}

.search-filter .collapse-content .header {
  padding: 0;
  margin-top: 16px;
}

.search-filter .collapse-content .count {
  float: right;
  font-weight: normal;
  color: #119bf5;
  font-size: 8px;
  padding-right: 15px;
}

.search-filter .collapse-content .header span a {
  font-size: 12px;
  color: #474c4a;
}

.search-filter .collapse-content .header span a:hover {
  cursor: pointer;
}

.search-filter-group ul.opts {
  width: 100%;
}

.collapse-content .search-filter ul.opts li {
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
  background: var(--bt-color-neutral-10);
  border: 0.5px solid #E0E0E0;
  padding: 10px;
  border-radius: 6px;
  transition: all .3s ease;
}

.search-filter .collapse-content ul.opts li.filtered {
  display: none;
}

.collapse-content .search-filter ul.opts li:hover a {
  color: var(--bt-color-primary)
}

.collapse-content .search-filter ul.opts li .custom-option {
  flex-grow: 1;
  padding: 0;
}

.collapse-content .search-filter .price-reduction {
  font-size: 10px;
  color: #474c4a;
  width: 80%;
}

.engine-types ul.opts li a {
  margin-left: 8px;
}

.search-filter:after {
  content: "";
  display: block;
  opacity: 0;
  clip: rect(0px, 0px, 0px, 0px);
  transition: opacity 300ms, clip 0ms linear 300ms;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url("//boattrader.com/static/img/carousel/preloader.gif") center
    center no-repeat rgba(255, 255, 255, 0.3);
}

.search-filter.loading:after {
  clip: rect(0px, 5000px, 5000px, 0px);
  opacity: 1;
  transition: opacity 300ms, clip 0ms linear 0ms;
}

.search-filter .show-more.show {
  border-top: 1px solid #ddd;
}

.search-filter .filter-more a {
  color: #119bf5;
  font-weight: bold;
  font-size: 12px;
  padding: 7px 0;
  display: block;
}

.search-filter .filter-more a:hover {
  text-decoration: underline;
}
/* ANA-1745 */
.dollar {
  position: relative;
  font-size: 14px;
  color: #404040;
}
.dollar:before {
  position: absolute;
  top: 10px;
  content: "$";
  left: 8px;
}

.price-min,
.price-max {
  padding-left: 15px !important;
}
/*ANA-1738 ANA-1745*/
@media screen and (min-width: 970px) {
  .search-alerts-filter-tags {
    padding: 0 20px;
    border-bottom: 2px solid var(--bt-color-neutral-30);
  }
}

/* ANA-1738 */
.search-filter .search-filter-group,
.search-filter .search-filter-group-preloader {
  justify-content: center;
}

.search-filter .filled-block {
  background: none;
  border: none;
  font-size: 16px;
  color: #5e5e5e;
  margin-left: 12px;
  padding-left: 5px;
  padding-right: 1px;
}

.search-filter .search-filter-group {
  margin-bottom: 15px;
}

.search-filter .search-filter-group>span {
  font-size: 16px;
  color: #5e5e5e;
}

.search-filter .collapse-content .header span a {
  font-size: 16px;
  letter-spacing: 0.75px;
  font-weight: 500;
  color: #404040;
}

.search-filter input[type="number"],
.search-filter input[type="text"],
.search-filter .search-filter-group > .filter-select {
  border: 1px solid var(--bt-color-neutral-30);
  height: 40px;
  font-size: 14px;
  padding: 8px 12px 8px 8px;
  box-shadow: 0px 2px 3px 0px #0000001A inset;
  border-radius: 8px;
  color: #404040;
}

@media only screen and (max-width: 600px) {
  .search-filter-group input,
  .search-filter input[type="number"],
  .search-filter input[type="text"] {
    font-size: 16px;
  }
}

.search-filter-group .filter-select {
  appearance: none;
  background: url(../../icons/DownArrow.svg) no-repeat;
  background-size: 12px;
  background-position: calc(100% - 0.75rem) center !important;
}

::placeholder {
  color: #404040;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  color: #404040;
}

::-ms-input-placeholder {
  color: #404040;
}

.collapse-content .search-filter ul.opts li .custom-option,
.collapse-content .search-filter ul.opts li a {
  font-size: 14px;
  color: var(--bt-color-neutral-100);
}

.collapse-content .search-filter .price-reduction {
  line-height: 24px;
  font-size: 16px;
  color: #404040;
}

/*ANA-1738 ANA-1745*/
@media screen and (max-width: 969px) {
  .search-filter .slider-container {
    display: none;
  }

  .search-filter input.small {
    width: 30vw !important;
  }

  input.price-min,
  input.price-max {
    width: 35vw !important;
  }

}
