.frequently-questions-section {
  background-color: var(--bt-color-neutral-10);
  margin-bottom: 72px;
}

.frequently-questions-section h2 {
  color: #07324F;
}

.frequently-questions-section a {
  color: var(--bt-color-primary);
}

.frequently-questions-section .accordion {
  margin: 0; /* reset for global css pollution */
  box-shadow: none; /* reset for global css pollution */
  width: 100%;
}

.frequently-questions-section .accordion p {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.frequently-questions-section .accordion > button {
  display: flex;
  background-color: #07324F;
  color: var(--bt-color-neutral-10);
  cursor: pointer;
  padding: 18px;
  padding-left: 10px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.frequently-questions-section .accordion > button.active {
  color: var(--bt-color-neutral-10);
}

.frequently-questions-section .accordion > div {
  padding: 0 18px;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.frequently-questions-section .accordion .accordion-arrow {
  display: inline-block;
  margin-left: 10px;
  transition: transform 0.3s ease-in-out;
  height: 14px;
  margin-right: 12px;
}

.frequently-questions-section .accordion .accordion-arrow.open {
  transform: rotate(-90deg);
}

.frequently-questions-section > .content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  max-width: 1096px;
  margin: auto;
  margin-top: 51px;
  padding-left: 24px;
  padding-right: 24px;
}

.frequently-questions-section > .content .accordion {
  margin-bottom: 2px;
}

@media (max-width: 912px) {
  .frequently-questions-section h2 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .frequently-questions-section > .content {
    grid-template-columns: 1fr;
    row-gap: 12px;
    margin-right: 18px;
    margin-left: 18px;
    padding-left: 0;
    padding-right: 0;
  }
}