@media screen and (min-width: 970px) {
  .search-tools.refine-search {
    border-radius: 5px;
    margin-bottom: 5px;
    margin-right: 0;
    display: block;
    box-shadow: 0 1px 4px 1px rgb(0 0 0 / 0.1);
    padding: 4px 0;  /* ANA-1745 */
  }

  .search-tools.refine-search .tool-set {
    width: auto;
    left: 0;
    position: relative;
    display: block;
    overflow-y: visible;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .search-tools.refine-search .tool-set .buttons.hidden {
    background: 0;
  }

  .search-tools.refine-search .tool-set .inner {
    padding-bottom: 0;
    overflow-y: auto;
    min-width: 300px;
  }

  .search-tools.refine-search .tool-set li .custom-option {
    padding: 4px 0;
  }

  .search-tools.refine-search .tool-set .header.title {
    background-color: var(--bt-color-neutral-10);
    font-size: 14px;
    padding: 4px 10px;
  }

  .search-tools.refine-search .tool-set .header.title a {
    color: #0B3F65;
  }

  .search-tools.refine-search .tool-set .collapse-content .collapsible .header.empty {
    padding: 0 20px 0 0;
  }

  /* buttons */
  .search-tools.refine-search .buttons {
    width: 300px;
    height: 52px;
    bottom: 0;
    padding: 12px 0 0;
    left: auto;
    position: absolute;
    display: block;
    transition: height .2s ease-in-out;
    opacity: 1;
  }

  .search-tools.refine-search.submittable .buttons {
    transform: none;
  }

  .search-tools.refine-search .buttons.fixed {
    position: fixed;
  }

  .search-tools .tool-set .refine-top-nav {
    display: block;
    font-size: 1.125em;
    font-weight: 500;
    color: #e47e30;
    position: fixed;
    width: 292px;
    padding: 16px;
    right: 0;
    top: 0;
    background: #fff;
    box-shadow: 0 2px 2px rgba(0,0,0,0.2);
    transition: transform 300ms ease-in-out;
    transform: translateY(-105%);
  }

  .search-tools .tool-set .refine-top-nav.fixed {
    transform: translateY(0);
  }

  .search-tools .tool-set .refine-top-nav .icon {
    background: url(//boattrader.com/static/img/icons/chevron.png) no-repeat center -135px;
    width: 14px;
    height: 1.125em;
    display: block;
    float: right;
  }
}