ol.boat-list {
  margin: 12px 0;
  padding: 0;
  list-style: none;
}

ol.boat-list:after {
  display:table;
  content:"";
  clear:both;
}

div.alert {
  margin-bottom: 10px;
}

/* -- MOBILE --*/
@media screen and (min-width: 340px) and (max-width: 767px){
  ol.boat-list {
    margin: 10px !important;
  }
}

@media screen and (max-width: 339px){
  ol.boat-list {
    margin: 10px 2px !important;
    overflow: hidden;
  }
}
