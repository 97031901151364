.boat-types-container {
    font-style: normal;
}

.boat-types-container .type-section h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 10px;
    letter-spacing: 0.5px;
    color: #404040;
    margin-top: 40px;
}

.boat-types-container .type-section p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #404040;
    margin-left: 0;
    margin-bottom: 1rem;
}


.boat-types-container .type-section p a {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.boat-types-container .type-section p a:link,
.boat-types-container .type-section p a:visited,
.boat-types-container .type-section p a:active {
    color: var(--bt-color-secondary_action-main);
    text-decoration: none;
}

.boat-types-container .type-section p a:hover {
    text-decoration: underline;
}

.boat-types-container .specialTypeSection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin-bottom: 10px;
}

.boat-types-container .type-link{
    cursor: pointer;
    color: var(--bt-color-secondary_action-main);
    margin-top: 20.1px;
    margin-right: 10px;
    font-size: 16px;
}

.boat-types-container .intro-text-font {
    font-size: 16px;
    line-height: 20px;
}

@media only screen and (max-width: 767px) {
    .boat-types-container .specialTypeSection {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 1rem;
        padding: 0;
    }
}

@media only screen and (min-width: 1350px) {
    .boat-type-links-mobile {
        display: none;
    }

    .boat-types-container .all-type-links{
        display: flex;
        justify-content: space-between;
        width: 80%;
    }

    .boat-types-container .all-type-links .boat-type-links{
        width: 50%;
    }

    .boat-types-container .all-type-links .boat-type-links .boat-type-links-width{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
}

@media only screen and (min-width: 1050px) and (max-width: 1349px) {
    .boat-type-links-mobile {
        display: none;
    }

    .boat-types-container .all-type-links{
        display: flex;
        justify-content: space-between;
    }

    .boat-types-container .all-type-links .boat-type-links{
        width: 50%;
    }

    .boat-types-container .all-type-links .boat-type-links .boat-type-links-width{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
}

@media only screen and (min-width: 970px) and (max-width: 1049px) {
    .boat-types-container .all-type-links .boat-type-links{
        width: 100%;
    }

    .boat-type-links-mobile {
        display: none;
    }

}

@media only screen and (max-width: 969px) {
    .boat-types-container .all-type-links .boat-type-links {
        display: none;
    }

    .boat-type-links-mobile {
        margin-top: 16px;
        position: sticky;
        top: 0px;
        background: white;
        z-index: 1;
        padding-top: 15px;
    }

    .boat-type-links-mobile .type-link{
        cursor: pointer;
        color: black;
        margin-top: 20.1px;
        margin-right: 16px;
        font-size: 16px;
    }
    .boat-type-links-mobile .type-link-last {
        margin-right: 0;
    }

    .boat-type-links-mobile .active {
        color: var(--bt-color-secondary_action-main);
    }

    .boat-type-links-mobile .left {
        margin-top: 5px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .boat-type-links-mobile .right {
        margin-top: 5px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .boat-type-links-mobile-top {
        top: 52px;
    }

    .boat-type-links-mobile .boat-type-links-width-mobile{
        width: 450px;
        overflow-x: auto;
    }

    .boat-type-links-mobile .boat-type-overflow {
        overflow-x: auto;
        width: 91%;
        margin-left: 15px;
    }

    .boat-types-container .all-type-links{
        padding-top: 1px;
    }

    .boat-types-container .all-type-links h1{
        line-height: 1em;
    }
}

/* Add positions */
.boattype-add-desktop {
    display: none;
}
.boattype-add-mobile {
    background-color: var(--bt-color-neutral-10);
    width: 100%;
    padding: 1rem 0;
    margin-bottom: 1rem;
}

.boattypes-ads {
    margin: 0 auto;
    min-height: 100px;
    min-width: 300px;
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .boattype-add-mobile {
        display: none;
    }
    .boattype-add-desktop {
        display: block;
        background-color: #F1F1F1;
        width: 100%;
        padding: 1rem 0;
        margin-bottom: 1rem;
    }
    .boattypes-ads {
        margin: 0 auto;
        min-height: 90px;
        min-width: 728px;
    }
}
