
.spinner-preloader.hidden {
  display: none;
}

.spinner-preloader {
  width: 100%;
  position: absolute;
  margin: auto;
  top: -100%;
  right: -100%;
  left: -100%;
  bottom: -100%;
  background: url(//boattrader.com/static/img/carousel/preloader.gif) no-repeat center center;
}
