/* Inputs */
.prequalified-form input,
.prequalified-form select {
  font-size: 14px;
  line-height: 22px;
  padding: 4px 14px !important;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #BFC4C8 !important;
  border-radius: 4px;
  color: #666 !important;
  height: 48px;
}

@media only screen and (max-width: 600px) {
  .prequalified-form input,
  .prequalified-form select {
    font-size: 16px;
  }
}

.prequalified-form .form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.prequalified-form .fieldset {
  position: relative;
  border: 0 none;
  padding: 0;
  margin: 12px 0 0;
  box-sizing: border-box;
  width: 100%;
}

/* TypeAhead*/
.prequalified-form .fieldset .sta-wrapper-input{
  border: none;
}

.prequalified-form .fieldset .sta-container .sta-label{
  height: 0;
  opacity: 0;
}

.prequalified-form .fieldset .sta-options {
  margin-top: 7px;
  padding: 16px 14px;
  background: var(--bt-color-neutral-10);
  border: 1px solid #E0E0E0;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.prequalified-form .fieldset .sta-option {
  height: 32px;
  margin-top: 5px;
  color: #404040 !important;
}

.prequalified-form .fieldset .sta-option:hover,
.prequalified-form .fieldset.error .sta-option.selected:hover,
.prequalified-form .fieldset.selected .sta-option.selected{
  background: #E5F0FF;
  border: 1px solid #119BF5;
  border-radius: 4px;
  color: #404040;
}

.prequalified-form .fieldset.error .sta-option.selected{
  background: var(--bt-color-neutral-10);
  color: #404040;
  border: none;
}



.prequalified-form form.error .fieldset.error input,
.prequalified-form form.error .fieldset.error select {
  border-color: #d9534f !important;
}

.prequalified-form form.error .fieldset.error input::placeholder {
  color: #666;
}

.send-request-button {
  margin-top: 17px;
}

.message-error {
  color: #d9534f;
  font-weight: 400;
  font-size: 10px;
  line-height: 11.72px;
  margin-top: 4px;
}

.message-error.submit-error{
  margin: 12px 0;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
}

.message-error.hidden {
  display: none;
}

.pt-5 {
  padding-top: 5px;
}

.pre-qualified-get-info {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-direction: row;  
  color: #616161;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 12px;
  width: 100%;
}

.pre-qualified-get-info input {
  width: 25px;
}