.dealer-boats {
  margin: 0;
}
.dealer-boats h3 {
  color: #aeb4b5;
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  padding: 4px;
  border: 0
}
.dealer-boats ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.dealer-boats ul li {
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.09), 0px 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-bottom: 14px;
}

.dealer-boats ul li a {
  color: #2876aa;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  padding: 10px;
}

.dealer-boats ul li .boat-image a {
  padding: 0px;
}
.dealer-boats ul li .boat-image {
  width: 135px;
  height: 132px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.dealer-boats ul li .boat-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  object-fit: cover;
}
.dealer-boats ul li .information {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  max-width: 100%;
  justify-content: space-between;
}

.dealer-boats ul li .information .make-model,
.dealer-boats ul li .information .seller-name,
.dealer-boats ul li .information .price,
.dealer-boats ul li .information .location {
  font-size: 14px;
  line-height: 19px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  clear: both;
}
.dealer-boats ul li .information .make-model {
  font-size: 16px;
  line-height: 22px;
  color: #0B3F65;
  margin-bottom: 1px;
  font-weight: bold;
}
.dealer-boats ul li .information .price {
  font-size: 16px;
  line-height: 1.3;
  font-weight: bold;
  color: #119BF5;
  margin-bottom: 5px;
}

.dealer-boats ul li .information .location {
  color: #444444;
  margin-bottom: 5px;
}

.dealer-boats ul li .information .seller-name {
  color: #0B3F65;
}

.dealer-boats .preloader {
  width: 100%;
  position: absolute;
  margin: auto;
  top: -100%;
  right: -100%;
  left: -100%;
  bottom: -100%;
  z-index: 3;
  background: url(//boattrader.com/static/img/carousel/preloader.gif) center center no-repeat #fff;
  height: 100%;
}

.dealer-boats .preloader.hidden {
  opacity: 0;
  transition: opacity 0.5s ease 0.5s;
}

.dealer-boats ul li .boat-image .heart {
  transform: scale(1);
  background-size: 40%;
  background-position: 95% 50%;
  right: 25px;
  top: 0px;
}

.dealer-boats ul li .boat-image .heart:hover {
  background-color: initial;
  border-color: initial;
} 

.dealer-boats > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dealer-boats .dealer-website {
  text-align: right;
  color: #119bf5;
  display: block;
}
.dealer-boats .more-boats-button {
  color: #119bf5;
}

.boat-details.variant .dealer-boats .more-boats-button {
  display: none;
}

@media screen and (max-width: 900px) {
  .dealer-boats {
    padding: 0 10px;
  }
}

@media screen and (min-width:768px) {
  .dealer-boats ul {
    max-height: 300px;
  }

  .dealer-boats ul li {
    width: 49%;
  }
  .dealer-boats ul li:nth-child(odd) {
    margin-right: 11px;
  }

  .dealer-boats ul li .boat-image {
    height: 123px;
  }
}

@media screen and (min-width: 1200px) {
  .dealer-boats ul li .boat-image {
    width: 200px;
    height: 132px;
  }
}
