/**
 * This stylesheet is split into three sections:
 *  1. .modal-container
 *  2. .info-container
 *  3. .locator-container
 *
 * Be sure to keep new styles with the .dealer-locator-modal prefix, as this will help
 * keep these styles namespaced from other elemental hierarchies in the DOM.
 *
 * Ex. .dealer-locator-modal .modal-container {}
 *     .dealer-locator-modal .info-container header {}
 *     .dealer-locator-modal .locator-container .col-1 {}
 *
 */


/**
 * Modal container
 */
.dealer-locator-modal .modal-container {
  background: #fff;
  color: #002a45;
  position: relative;
  width: 100%;
}

.dealer-locator-modal .col-2 button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.dealer-locator-modal .oem-contact-form-from-dealer {
  max-width: 768px;
}

.dealer-locator-modal .modal-container {
  min-height: 555px;
  width: 768px;
}


/**
 * Desktop
 */
@media (min-width: 970px) {

  .dealer-locator-modal .modal-container {
    min-height: 555px;
    width: 768px;
  }

  .dealer-locator-modal button[disabled] {
    cursor: not-allowed;
  }
  .dealer-locator-modal button:hover {
    filter: invert(40%);
  }
  .dealer-locator-modal button:active {
    filter: invert(20%);
  }
}



/**
 * Information container
 */


/**
 * Header
 */
.dealer-locator-modal .info-container header {
  padding: 11px 0;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.dealer-locator-modal .info-container header::before {
  content: '';
  background: linear-gradient(180deg, rgba(234, 234, 234, 0.6) 0%, rgba(255, 255, 255, 0.6) 100%), rgba(234, 234, 234, 0.5);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: matrix(1, 0, 0, -1, 0, 0);
  z-index: -1;
}

.dealer-locator-modal .header-modal-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #eeeeee;
  height: 45px;
}

.dealer-locator-modal .header-modal-title .modal-title {
  justify-content: flex-start;
}

.dealer-locator-modal .header-modal-title p {
  justify-content: flex-start;
  padding: 1px 10px ;
}

.dealer-locator-modal-arrow {
  background: url(../../icons/LeftArrow.svg) no-repeat;
  background-position: 20px 18px;
  padding: 0px 30px 0px 41px;
  cursor: pointer;
}

.dealer-locator-modal .header-modal-title .modal-close {
  justify-content: end;
  color: #333;
  background: none;
  border: none;
  margin: 0;
  padding: 10px 20px;
  font-size: 20px;
}
.dealer-locator-modal .contact-form,
.dealer-locator-modal .details-contact {
  background: #fff;
  padding: 15px 40px 5px;
}

.oem-contact-form-from-dealer .details-contact {
  padding: 10px 50px 0px;
}

.dealer-locator-modal header .close {
  color: #333;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.dealer-locator-modal header .zipcode-message-error {
  color: #f81d1d;
  list-style: none;
  padding: 0;
  font-size: 12px;
  position: relative;
  text-align: left;
}

.dealer-locator-modal .info-container header button {
  font-size: 14px;
  line-height: 17px;
  vertical-align: middle;
}

.dealer-locator-modal .info-container header .back-btn {
  margin-left: 20px;
  border: none;
  background: none;
}

.dealer-locator-modal .info-container header .close-btn {
  float: right;
  margin-right: 11px;
  border: none;
  background: none;
}

.dealer-locator-modal .info-container header button img {
  vertical-align: middle;
}

.dealer-locator-modal .info-container header button span {
  margin-left: 8px;
}



/**
 * Dealer Locator
 */


/**
 * Header
 */

.dealer-locator-modal .locator-container {
  background: #fff;
}

.dealer-locator-modal .locator-container header {
  background: linear-gradient(180deg, #eaeaea 0%, var(--bt-color-neutral-10) 100%), #eaeaea;
  padding: 20px 10px 10px;
  position: sticky;
  top: 0;
}

.dealer-locator-modal .locator-container header .close-btn {
  height: 14px;
  position: absolute;
  top: 10px;
  right: 15px;
  width: 14px;
  z-index: 10;
  border: none;
  cursor: pointer;
}

.dealer-locator-modal .locator-container header .close-btn:hover {
  background: none;
}

.dealer-locator-modal .locator-container header h2 {
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}

.dealer-locator-modal .locator-container header form input {
  background: var(--bt-color-neutral-10);
  border: 1px solid #bfc4c8;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  height: 40px;
  margin-top: 10px;
  padding: 0 11px;
  width: 100%;
}

.dealer-locator-modal .locator-container header form button {
  background: var(--bt-color-primary);
  margin-top: 10px;
}


/**
 * Content
 */
.dealer-locator-modal .locator-container h4 {
  color: #474c4a;
  font-size: 16px;
  font-style: italic;
  font-weight: normal;
  line-height: 20px;
  margin: 5px 10px 6px;
}

.dealer-locator-modal .locator-container ol {
  display: inline-block;
  list-style: none;
  margin: 0 20px;
  padding: 0;
}

.dealer-locator-modal .locator-container ol li {
  display: flex;
  margin: 10px 0;
}

.dealer-locator-modal .locator-container .col-1 {
  max-width: 40px;
  width: 100%;
}

.dealer-locator-modal .locator-container .col-1 .rank {
  background: #eaeaea;
  border-radius: 50%;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
}

.dealer-locator-modal .locator-container .col-1 .distance p {
  color: #666;
  font-weight: bold;
  line-height: 0;
  text-align: center;
}

.dealer-locator-modal .locator-container .col-2 {
  flex: 1;
  margin-left: 8px;
}

.dealer-locator-modal .locator-container .col-2 h3 {
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  word-wrap: break-word;
}

.dealer-locator-modal .locator-container .col-2 address {
  color: #666;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  margin: 6px 0;
}

.dealer-locator-modal .locator-container .col-2 button {
  color: #119bf5;
  font-size: 16px;
  line-height: 20px;
}


/**
 * Tablet
 */
@media (min-width: 768px) {

  /**
   * Content
   */
  .dealer-locator-modal .locator-container ol {
    display: flex;
    flex-wrap: wrap;
    margin: 0 49px;
    padding: 0;
  }

  .dealer-locator-modal .locator-container ol li {
    width: 50%;
  }
}


/**
 * Desktop
 */
@media (min-width: 970px) {

  /**
   * Header
   */
  .dealer-locator-modal .locator-container header {
    padding: 32px 16px 16px;
  }

  .dealer-locator-modal .locator-container header h2 {
    font-size: 22px;
    line-height: 27px;
  }

  .dealer-locator-modal .locator-container header form input {
    width: 448px;
  }

  .dealer-locator-modal .locator-container header form button {
    margin-left: 10px;
    width: 278px;
    float: right;
    margin-top: 10px;
  }
  .dealer-locator-modal .locator-container header form button:hover {
    filter: brightness(110%);
  }
  .dealer-locator-modal .locator-container header form button:active {
    filter: brightness(90%);
  }


  /**
   * Content
   */
  .dealer-locator-modal .locator-container h4 {
    font-size: 18px;
    line-height: 22px;
    margin: 8px 16px 7px;
  }

  .dealer-locator-modal .locator-container .col-2 h3 {
    font-size: 18px;
    line-height: 22px;
    margin: 3px 0 0;
  }

  .dealer-locator-modal .locator-container .col-2 address {
    font-style: normal;
    margin: 4px 0;
  }


  /**
   * Footer
   */
  .dealer-locator-modal .locator-container footer {
    background: #eaeaea;
    bottom: 0;
    display: flex;
    left: 0;
    padding: 12px 0;
    position: absolute;
    right: 0;
  }

  .dealer-locator-modal .locator-container footer button {
    border: none;
    background: none;
  }



  .dealer-locator-modal .locator-container footer .previous-btn,
  .dealer-locator-modal .locator-container footer .next-btn {
    font-size: 16px;
    line-height: 19px;
  }

  .dealer-locator-modal .locator-container footer .previous-btn::before {
    content: '<';
    margin: 0 4px 0 16px;
  }

  .dealer-locator-modal .locator-container footer .next-btn::after {
    content: '>';
    margin: 0 16px 0 4px;
  }

  .dealer-locator-modal .locator-container footer .pagination-container {
    flex: 1;
    text-align: center;
  }

  .dealer-locator-modal .locator-container footer .pagination-container button {
    font-size: 18px;
    line-height: 22px;
    margin: 0 16px;
  }
}

@media screen and (max-width: 970px) {
  .dealer-locator-modal .modal-container {
    width: 100%;
    height: 100%;
  }
}
