#main-content {
  background: none;
}

.formModalWrapper{
  z-index: 1100;
  position: absolute;
}

.branded-header {
  display: flex;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1133px;
  padding: 0 10px 9px;
  background: #fff;
}

.branded-header .logo-container, .branded-header .hide-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.branded-header .logo-container .logo-frame {
  position: absolute;
}

.branded-header .logo-container .logo-frame .logo {
  width: 100%;
}

@media screen and (min-width: 971px) {
  .branded-header .logo-container .logo-frame .logo {
    max-height: 100px;
  }
}

.branded-header .hide-logo-container img {
  visibility: hidden;
}

.branded-header .information-brand-container h1 {
  margin: 0 0 10px;
  font-weight: bold;
  font-size: 18px;
  padding: 0;
  display: flex;
  color: #2C272D;
}

.branded-header .information-brand-container .broker-website-desktop {
  float: right;
  margin-top: -30px;
  font-weight: 400;
  font-size: 12px;
  color: #4492D0;
  display: none;
  text-decoration: underline;
}

.branded-header .information-brand-container .broker-website-desktop:before {
  content: "";
  background: url(../../icons/OpenNew.svg) no-repeat;
  width: 21px;
  height: 21px;
  display: inline-block;
  margin: 0 -5px -12px 10px;
}

.branded-header .information-brand-container .broker-website-mobile {
  font-weight: 400;
  font-size: 12px;
  color: #4492d0;
  display: flex;
  margin-top: 5px;
  text-decoration: underline;
}

@media screen and (min-width: 971px) {
  .branded-header .information-brand-container .broker-website-mobile {
    display: none;
  }

  .branded-header .information-brand-container .broker-website-desktop {
    display: block;
  }
}

.branded-header .information-brand-container .brand-address {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.branded-header .information-brand-container .brand-address:before {
  content: "";
  background: url(../../icons/Navigate.svg) no-repeat;
  width: 21px;
  height: 21px;
  display: inline-block;
  margin: 2px -5px 0 0;
}

.branded-header .information-brand-container .brand-description{
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  color: #2C272D;
  display: flex;
}

.branded-header .brand-information {
  display: block;
  margin: 0 20 0 0;
  position: relative;
  padding-top: 15px;
  width: 100%;
}

.branded-header .buttons-container {
  text-align: left;
  position: relative;
  margin: 3px 10px;
  display: flex;
}

.branded-header .information-brand-container .view-map {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: 0px;
  text-align: center;
  width: 150px;
  height: 30px;
  border-radius: 5px;
  background-color: #F06E2D;
  color: white;
  cursor: pointer;
  margin-bottom: 5px;
}

.branded-header .information-brand-container .call-now
 {
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  border: 0px;
  text-align: center;
  width: 150px;
  height: 30px;
  border-radius: 5px;
  background-color: #F06E2D;
  color: white;
  display: inline-block;
  margin-bottom: 5px;
  margin-left: 8px;
}

.branded-header .information-brand-container .call-now {
  background-color: #4492D0;
}

.branded-header .information-brand-container .call-now:before {
  content: "";
  background: url(../../icons/call-now.svg) no-repeat;
  width: 21px;
  height: 21px;
  display: inline-block;
  margin: 0 0 -10px 0;
}

.branded-header .buttons-container-flex {
  display: flex;
}

@media screen and (min-width: 971px) {

  .branded-header {
    max-width: 1440px;
  }

  .branded-header .logo-container, .branded-header .hide-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 455px;
    margin-right: 15px;
  }

  .branded-header .logo-container .logo-frame {
    padding: 15px;
    position: relative;
  }

  .branded-header .hide-logo-container .logo-frame {
    width: 320px;
    position: relative;
  }

  .branded-header .information-brand-container {
    margin: 0 0 10px 0;
  }

  .branded-header .buttons-container {
    text-align: right;
  }
}

.formModalWrapper {
  text-align: left;
}

.formModalWrapper p {
  padding-left: 0px;
}

@media screen and (min-width: 1071px) {

  .branded-header .brand-information {
    margin-right: 65px;
  }
}

@media screen and (min-width: 1440px) {

  .branded-header .brand-information {
    width: 51%;
  }

  .branded-header .logo-container, .branded-header .hide-logo-container {
    width: 340px;
  }
}

@media screen and (min-width: 768px) {
  .branded-header {
    padding: 0 14px;
  }
}

@media screen and (min-width: 971px) {
  .branded-header {
      display: flex;
  }
}

@media screen and (max-width: 970px) {
  .branded-header .single-button-container {
    text-align: center;
    margin: 0 0 10px 0 !important;
  }

  .branded-header .buttons-container {
    display: block;
  }

  .branded-header .buttons-container-flex {
    display: flex;
    justify-content: center;
  }

  .branded-header .information-brand-container .call-now, .branded-header .information-brand-container .view-map {
    min-width: 159px;
  }

  .branded-header .information-brand-container .no-margin {
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 970px) {

  .branded-header {
    display: inherit;
    padding-bottom: 1px;
  }

  .branded-header .logo-container .logo-frame {
    position: inherit;
  }

  .branded-header .information-brand-container {
    margin: 0 0 10px 0;
  }

  .branded-header .information-brand-container .brand-description {
    min-height: 0;
  }

  .branded-header .brand-information {
    margin: 0;
    max-width: 100%;
    text-align: left;
  }

  .branded-header .hide-logo-container {
    display: none;
  }
}

.branded-seperator {
  margin: 10px 0;
  border: 0;
  border-top: 1px solid #EAEAEA;
}
