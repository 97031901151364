.adandsponsorship-head {
  margin-left: auto;
  margin-right: auto;
  max-width: 1320px;
}

.adandsponsorship-head h1 {
  margin: 0;
  padding: 15px 15px 15px;
  color: #0B3F65;
  font-size: 20pt;
  line-height: 20pt;
}
.adandsponsorship-head span {
  padding: 0 15px;
  color: #333;
  font-size: 12pt;
  font-style: italic;
  display: inline-block;
}

.adandsponsorship-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1320px;
  background-image: linear-gradient(#EAEAEA, var(--bt-color-neutral-10) 280px);
  min-height: 200px;
  padding: 9px;
  margin-top: 10px;
}

.adandsponsorship-content .right-content {
  margin-top: 2em;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (min-width: 768px) {
  .adandsponsorship-content .left-content{
      padding-left: 0.5em;
      padding-right: 0.5em;
      justify-content: space-around;
  }
  .adandsponsorship-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
  }
}

@media screen and (min-width: 970px) {
  
  .adandsponsorship-content .left-content {
      width: 100%;
  }

  .adandsponsorship-content .right-content {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
  }
}

.adandsponsorship-content .left-content h2 {
  width: 100%;
}

.adandsponsorship-content .left-content ol {
  font-weight: 600;
}

.adandsponsorship-content .left-content p {
  font-weight: 100;
}