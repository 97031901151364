.drivewayDirect-process h2, .drivewayDirect-comments h2, .drivewayDirect-faq h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 54px;
}

/*
* Head
*/

.drivewayDirect-head {
  position: relative;
  padding: 40px;
  box-sizing: border-box;
  color: var(--bt-color-neutral-10);
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  background: #fff;
}

.drivewayDirect-head.funnel-head {
  grid-template-columns: 1fr;
  padding: 0;
}

.image-background {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.drivewayDirect-head .logo {
  width: 229px;
}

.drivewayDirect-head .container-text {
  padding: 40px 0;
  margin: 0 auto;
  width: 525px;
  z-index: 1;
}

.container-text h1 {
  text-shadow: 0 3px 13px rgba(0, 0, 0, 0.25);
  font-size: 72px;
  line-height: 72px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 32px 0;
}

.container-text p {
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  width: 480px;
  margin: 0;
}

/*
* Process
*/

.drivewayDirect-process {
  padding: 60px;
  text-align: center;
  box-sizing: border-box;
  background: #fff;
}

.drivewayDirect-process h2 {
  margin: 12px 0;
  color: #104F79;
}

.drivewayDirect-process p {
  color: #666666;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  margin: 0;
}

.container-step {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 37px auto;
}

.container-step .step-icon {
  margin: 0 auto;
  width: 65px;
  background: #fff;
}

.step-icon img {
  width: 100%;
}

.step {
  margin: 0 auto;
  max-width: 270px;
}

.step h3 {
  margin: 16px 0;
  color: #104F79;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
}

.step p {
  color: #666666;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

/*
* Comments
*/

.drivewayDirect-comments {
  background: #0F4D77;
  padding: 80px 40px;
  box-sizing: border-box;
  color: var(--bt-color-neutral-10);
  text-align: center;
}

.drivewayDirect-comments h2 {
  color: var(--bt-color-neutral-10);
  margin: 0 0 41px 0;
}

.drivewayDirect-comments p {
  color: var(--bt-color-neutral-10);
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin: 5px 0;
}

.comment {
  width: 600px;
  margin: 30px auto 0 auto;
  text-align: center;
}

.comment p {
  font-size: 36px;
  line-height: 43px;
  font-weight: normal;
}

.comment span {
  margin-top: 12px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  display: block;
}

.container-stars {
  display: flex;
  gap: 4px;
  margin: 0 0 15px 0;
  justify-content: center;
}

.star {
  width: 20px;
  height: 20px;
}

/*
* FAQ
*/

.drivewayDirect-faq {
  background: #ECF0F1;
  padding: 50px;
}

.drivewayDirect-faq h2 {
  color: #104F79;
  margin-top: 10px;
  text-align: center;
}

.container-questions {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr;
}

.wrapper-question{
  color: #333333;
}

.wrapper-question h3{
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
}

.wrapper-question p{
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 20px;
  box-sizing: border-box;
}

.button-offer {
  display: block;
  width: 282px;
  min-height: 51px;
  background: #E67D22;
  border-radius: 4px;
  text-align: center;
  padding: 15px 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  color: var(--bt-color-neutral-10);
  cursor: pointer;
  margin: 75px auto 0 auto;
}

@media (min-width: 1440px) {

  /*
  * Large sized screens - Process
  */
  .container-step {
    margin: 37px 260px;
  }
}

@media (max-width: 960px) {

  .drivewayDirect-process h2, .drivewayDirect-comments h2 {
    font-size: 36px;
    line-height: 54px;
    font-weight: 700;
  }

  /*
  * Tablet - Head
  */
  .drivewayDirect-head {
    width: 100%;
    padding: 50px;
    grid-template-columns: 1fr;
  }

  .drivewayDirect-head .container-text {
    width: 100%;
    padding: 0 0 40px 0;
  }

  .drivewayDirect-head .logo {
    width: 194px;
  }

  .container-text h1 {
    font-size: 60px;
    font-weight: 700;
    line-height: 72px;
  }

  .container-text p {
    font-size: 18px;
    line-height: 21px;
    width: 100%;
  }

  .image-background {
    height: 385px;
  }

  /*
  * Tablet - Process
  */
  .drivewayDirect-process {
    padding: 10px 60px;
  }

  .drivewayDirect-process p {
    font-size: 18px;
    line-height: 24px;
  }

  .container-step {
    margin: 50px auto;
    grid-template-columns: 1fr;
  }

  .step {
    margin: 30px auto;
  }

  /*
  * Tablet - Comments
  */
  .drivewayDirect-comments {
    padding: 60px 0;
  }

  .drivewayDirect-comments h2 {
    margin: 0 0 32px 0;
  }

  .drivewayDirect-comments p {
    font-size: 32px;
    line-height: 38px;
    margin: 0 0 31px 0;
  }

  .drivewayDirect-comments span {
    font-size: 16px;
    line-height: 19px;
  }

  .container-stars {
    margin: 0 0 19px 0;
  }

}

@media (max-width: 767px) {

  .drivewayDirect-process h2, .drivewayDirect-comments h2, .drivewayDirect-faq h2 {
    font-size: 24px;
    line-height: 28px;
  }

  /*
  * Mobile - Head
  */
  .drivewayDirect-head {
    grid-template-columns: 1fr;
    padding: 33px 10px;
  }

  .drivewayDirect-head .container-text {
    padding: 0;
  }

  .drivewayDirect-head .logo {
    width: 137px;
  }

  .container-text h1 {
    font-size: 42px;
    line-height: 42px;
    font-weight: 700;
    margin-top: 16px;
  }

  .container-text p {
    font-size: 16px;
    line-height: 19px;
    width: 100%;
    margin: 30px 0;
  }

  .image-background {
    height: 309px;
  }

  /*
  * Mobile - Process
  */
  .drivewayDirect-process p {
    font-size: 16px;
    line-height: 21px;
  }

  .step {
    margin: 20px auto;
  }

  /*
  * Mobile - Comments
  */
  .drivewayDirect-comments {
    padding: 50px 35px;
  }

  .drivewayDirect-comments h2 {
    line-height: 28px;
  }

  .drivewayDirect-comments p {
    font-size: 24px;
    line-height: 28px;
  }

  .comment {
    width: 100%;
    margin: 40px auto;
  }

  .comment span {
    margin: 20px 0;
    font-size: 16px;
    line-height: 19px;
  }

  /*
  * FAQ
  */

  .drivewayDirect-faq {
    padding: 20px;
  }

  .container-questions {
    grid-template-columns: 1fr;
  }

  .button-offer {
    width: 100%;
  }

}