.pagination {
  text-align: center;
  padding: 0;
}

.pagination li {
  list-style: none;
  display: inline-block;
}

.pagination a {
  color: #A5A5A5;
  padding: 5px 10px;
  display: block;
  font-weight: bold;
}

.pagination a.active {
  text-decoration: underline;
}

.pagination a.disabled {
  visibility: hidden;
}

.pagination li .prev {
  background: url(../../icons/LeftArrow.svg) no-repeat center;
  text-indent: -99999px;
}

.pagination li .next {
  background: url(../../icons/RightArrow.svg) no-repeat center;
  text-indent: -99999px;
}
