#bestimate-data {
  display: none;
}

.collapse-content {
  margin: 0;
  border-bottom: 2px solid var(--bt-color-neutral-30);
}

.collapse-content .criteria {
  display: block;
  font-size: 0.85em;
  color: #757575;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.collapse-content .header {
  background: none;
  padding: 16px 10px;
  max-height: 1000000px;
}

.collapse-content .title {
  background-color: #ecf0f1;
  padding: 12px 10px;
}

.collapse-content .header a {
  color: #474c4a;
  font-weight: bold;
  display: block;
  position: relative;
}

.collapse-content .header h3 {
  margin: 0;
}

.collapse-content .header h3 a,
.collapse-content .header .custom-option {
  font-size: 13px;
  outline: none;
  padding: 0;
}

.collapse-content .header a .icon {
  background-image: url(//boattrader.com/static/img/icons/plus-minus.png);
  width: 13px;
  height: 7px;
  position: absolute;
  right: 0;
  top: 5px;
  display: none;
}

.collapse-content:last-child {
  border-bottom: 0;
}

/* Second level */

.collapse-content .header a .icon {
  display: block;
}

.collapse-content .header h3 a .icon {
  height: 9px;
  width: 15px;
  background: url(../../icons/DownArrow.svg) no-repeat;
}

.collapse-content.open .header h3 a .icon {
  transform: rotate(180deg);
}

.collapse-content .collapsible .header .opts > li {
  font-size: 16px;
  border: 0;
  padding: 0;
  vertical-align: middle;
}

.collapse-content .collapsible .header .custom-option {
  color: #333;
}

.collapse-content .header h3 a:hover {
  cursor: pointer;
}

/* open / close */

.collapse-content > .collapsible {
  max-height: 999px;
  transition: max-height 900ms;
  overflow: hidden;
  padding: 0px 15px;
}

.collapse-content > .collapsible.no-pad {
  padding: 0;
}

.collapse-content > .collapsible.double-height {
  max-height: 1998px;
}

.collapse-content.closed > .collapsible {
  max-height: 0;
  transition: max-height 500ms;
}
/*
  .inner > .collapse-content {
    border-bottom: 1px solid #ddd;
  }
  */



/* ANA-1738 ANA-1745 */
.collapse-content .header .custom-option,
.collapse-content .header h3 a,
.collapse-content .header a {
  font-size: 16px;
  letter-spacing: 0.75px;
  font-weight: 500;
  color: #404040;
}

.collapse-content.closed>.collapsible {
  border: 0;
}

.mobile-search-filters .opts::-webkit-scrollbar, .refine-search .opts::-webkit-scrollbar {
  width: 9px;
}

.mobile-search-filters .opts::-webkit-scrollbar-thumb, .refine-search .opts::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #999;
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.mobile-search-filters .opts::-webkit-scrollbar-thumb:hover, .refine-search .opts::-webkit-scrollbar-thumb:hover {
  background: #737373;
}
/* ANA-1738 ANA-1745 */
@media screen and (max-width: 969px) {
  .collapse-content {
    margin-bottom: 4px;
    border-bottom: 0px;
    background: rgb(255, 255, 255);
  } 
}

@media screen and (min-width: 970px) {
  .collapse-content.condition.open {
    min-height: 94px;
  }
  
  .collapse-content.length.open {
    min-height: 124px;
  }
  
  .collapse-content.year.open{
    min-height: 121px;
  }
  
  .collapse-content.price.open {
    min-height: 149px;
  }
}
