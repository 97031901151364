.from-boat-trader-financing-main{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-bottom: 36px;
    margin-top: 25px;
    width: 100%;
}

.from-boat-trader-financing-main .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    margin: 0 auto !important;
    padding: 0 20px;
    max-width: 1061px;
}

.from-boat-trader-financing-main .container .title{
    color: #07324F;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin: 0;
}

.from-boat-trader-financing-main .container .text,
.from-boat-trader-financing-main .container .footer{
    color:#616161;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.18px;
}

.from-boat-trader-financing-main .container .list-container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 25px;
}

.from-boat-trader-financing-main .container .column{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    max-width: 285px;
}

.from-boat-trader-financing-main .container .column .title{
    color:#07324F;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.from-boat-trader-financing-main img{
    width: 395px;
}

.from-boat-trader-financing-main .container a{
    text-decoration: none;
    color: var(--bt-color-primary);
}

.from-boat-trader-financing-main .container .column ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding-left: 15px;
    font-size: 16px;
}

.from-boat-trader-financing-main .container .column ul>li>span {
    display: inline-block;
    vertical-align: middle;
    color: #404040;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.from-boat-trader-financing-main .container .column ul>li::marker {
    color: #07324F;
    margin-right: 8px;
    font-size: 20px;
}

/*Mobile */
@media (max-width: 765px) {

    .from-boat-trader-financing-main .container .title{
        font-size: 28px;
    }

    .from-boat-trader-financing-main .container .text,
    .from-boat-trader-financing-main .container .footer{
        font-size: 16px;
    }

    .from-boat-trader-financing-main .container .list-container{
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: unset;
        margin: 0 25px;
    }

    .from-boat-trader-financing-main .container{
        gap: 24px;
    }

    .from-boat-trader-financing-main .container .column{
        max-width: unset;
        width: 100%;
    }

    .from-boat-trader-financing-main img{
        display: none;
    }
}