#extendedServicesFaqs__accordion .accordion {
  margin: 2.3rem auto 0;
  max-width: 64rem;
}

#extendedServicesFaqs__accordion .extendedServicesFaqs__title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #0F4D77;
  text-align: center;
}

#extendedServicesFaqs__accordion .extendedServicesFaqs__subtitle {
  padding-left: 21px;
  color: #0F4D77;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#extendedServicesFaqs__accordion .accordion__panel {
  color: #666;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#extendedServicesFaqs__accordion .extendedServicesFaqs__disclaimer {
  display: flex;
  max-width: 66rem;
  margin: auto auto;
}

#extendedServicesFaqs__accordion .extendedServicesFaqs__disclaimer > p {
  margin: 0;
  color: #9E9E9E;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  padding: 51px 18px 0;
}

@media (max-width: 991px) {
  #extendedServicesFaqs__accordion .accordion {
    margin-top: 24px;
  }

  #extendedServicesFaqs__accordion .extendedServicesFaqs__title {
    font-size: 22px;
  }

  #extendedServicesFaqs__accordion .extendedServicesFaqs__disclaimer > p {
    text-align: center;
  }

  #extendedServicesFaqs__accordion .extendedServicesFaqs__subtitle {
    padding-left: 24px;
    font-size: 14px;
  }

  #extendedServicesFaqs__accordion .extendedServicesFaqs__disclaimer > p {
    padding-top: 24px;
  }

}
