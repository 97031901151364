#close-form{
  background-color: rgba(0,0,0,0);
  border: 0;
  color: inherit;
  font-weight: inherit;
  position: absolute;
  right: 5px;
}

#close-form:hover{
  cursor: pointer;
}

.modal.fade .details-contact {
  position: relative;
  background: white;
  box-shadow: inset 0px 0px 2px rgba(0,0,0,0.3);
  border-radius: 16px;
  display: block;
}
.modal.fade .details-contact.old-form {
  background: #EEEEEE;
}

.details-contact.old-form {
  background: #EEEEEE;
}

.details-contact.old-form.hidden{
  display: none !important;
}

.modal .email-lead-form div {
  box-shadow: none;
}

.email-lead-form > div > span {
  text-align: center;
  margin-bottom: 20px;
}
.email-lead-form > div > span > img {
  margin: 0 auto;
  max-width: 70% !important;
}

.details-contact .contact-area.open {
  height: auto;
  overflow: hidden;
  border-radius: 16px;
}
.details-contact.cta-modal.open:before {
  display: block;
  content: "";
  z-index: -1;
  top: -100vh;
  left: -100vw;
  width: 1000vw;
  height: 1000vh;
  opacity: 0.75;
  background: #000;
  position: absolute;
}

.details-contact .contact-area {
  height: 0px;
  padding: 0 10px;
  overflow: hidden;
  background-color: white;
}

.contact-area .contact-instructions-title {
  padding: 16px;
}
.details-contact .contact-area > .email-lead-form {
  border: none;
}

.top .details-contact .contact-area button.close-modal {
  display: none;
}

#details-contact-anchor {
  position: absolute;
  top: -107px;
  visibility: hidden;
}

.top .details-contact {
  display: none;
  border-radius: 0;
}
@media only screen and (max-width: 970px) {
  .top .details-contact {
      display: block;
  }
}

.details-contact .title {
  color: #07324f;
  font-size: 24px;
  line-height: 18px;
  font-weight: bold;
  padding: 18px 18px 0 18px;
  text-align: left;
}

.details-contact .title.info-btn-box {
  padding: 8px 18px 18px;
}

@media screen and (min-width: 970px) {
  .boat-details > .top {
    min-height: 402px;
  }

  .top .details-contact.open {
    display: flex;
    max-width: 420px;
    flex-direction: column;
  }
  .broker-dealer-next-previous > .boat-details > .broker-bdp > .broker-bdp-left > .content > .details-contact.open {
    max-width: unset;
  }
}

.details-contact .contact-area.open.oem-contact-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.details-contact .contact-area.open.oem-contact-area #oem-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 85px;
  width: 100%;
}

.details-contact .contact-area.open.oem-contact-area .brand-logo {
  max-width: 46%;
}

.modal {
  z-index: 1003;
}

.details-contact-modal {
  margin : 1.75rem auto;
  min-height : calc(100% - 3.5rem);
  max-width : 500px;
  display : flex;
  align-items : center;
  justify-content : center;
}

.details-contact-modal .modal-content{
  position: relative;
}

.details-contact .close-modal.open{
  position: absolute;
  right: 30px;
  top: 10px;
  cursor: pointer;
  background: url('modal-close-button.svg') no-repeat 50%;
  padding: 9px;
  border: 0;
  filter: brightness(0);
  width: auto;
  height: auto;
}

.details-contact-modal .modal-content .close-modal{
  position: absolute;
  right: 0px;
  top: -23px;
  cursor: pointer;
  background: url('modal-close-button.svg') no-repeat 50%;
  padding: 9px;
  border: 0;
  width: auto;
  height: auto;
}



.boat-details .modal.fade {
  background: rgba(0,0,00);
}

.boat-details .modal.fade:not(.hide) {
  animation: 200ms ease-out backgroundFadeIn forwards;
}

@keyframes backgroundFadeIn {
  0% {
    background: rgba(0,0,0,0);
  }
  100% {
    background: rgba(0,0,0,.8);
  }
}

.boat-details .modal.fade .modal-content {
  filter: drop-shadow(0px 6px 4px rgba(0,0,0,.35));
  transform: translateY(150%) scale(0);
  opacity: 0;
  border-radius: 16px;
  overflow: hidden;
}

.boat-details .modal.fade:not(.hide) .modal-content {
  animation: 150ms 200ms ease-out scaleIn forwards;
}

.boat-details .modal.fade div.close-modal {
  display: block;
  position: absolute;
  margin-left: -20px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  appearance: none;
  outline: none;
  cursor: pointer;
  z-index: 2;
  top: 8px;
  right: 19px;
  border: none;
  filter: brightness(0);
  animation: none;
  opacity: 1;
}

.boat-details .modal.fade .modal-body .close-modal {
  display: none;
}

.boat-details .modal.fade .details-contact-modal {
  margin: 0 auto;
  height: 100%;
}

 /* style for modal window */
.boat-details > .body > .content > .details-contact.cta-modal.open,
.broker-dealer-next-previous > .boat-details > .broker-bdp > .broker-bdp-left > .content > .details-contact.cta-modal.open,
.boat-details > .details-contact.cta-modal.open {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 20px 0px;
  box-shadow: none;
  background: none;
  z-index: 2002;
}

.boat-details > .body > .content > .details-contact.cta-modal.open .contact-area.open,
.broker-dealer-next-previous > .boat-details > .broker-bdp > .broker-bdp-left > .content > .details-contact.cta-modal.open .contact-area.open,
.boat-details > .details-contact.cta-modal.open .contact-area.open {
  position: relative;
  background: #eee;
  appearance: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 0;
  outline: none;
  filter: drop-shadow(0px 6px 4px rgba(0,0,0,.35));
  transform: translateY(150%) scale(0);
  opacity: 0;
  animation: 150ms 200ms ease-out scaleIn forwards;
  max-width: 420px;
}

.boat-details > .body > .content > .details-contact.cta-modal.open #details-contact-anchor,
.broker-dealer-next-previous > .boat-details > .broker-bdp > .broker-bdp-left > .content > .details-contact.cta-modal.open #details-contact-anchor,
.boat-details > .details-contact.cta-modal.open #details-contact-anchor {
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.2);
  transition: opacity 200ms ease-out;
  border: none;
  opacity: 1;
}

.boat-details > .body > .content > .details-contact.cta-modal #details-contact-anchor,
.broker-dealer-next-previous > .boat-details > .broker-bdp > .broker-bdp-left > .content > .details-contact.cta-modal #details-contact-anchor,
.boat-details > .details-contact.cta-modal #details-contact-anchor {
  opacity: 0;
}

.boat-details > .body > .content > .details-contact.cta-modal.open .contact-area.open > button.close-modal,
.broker-dealer-next-previous > .boat-details > .broker-bdp > .broker-bdp-left > .content > .details-contact.cta-modal.open .contact-area.open > button.close-modal,
.boat-details > .details-contact.cta-modal.open .contact-area.open > button.close-modal {
  position: absolute;
  right: 16px;
  top: 16px;
  background: transparent;
  width: 22px;
  height: 22px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  filter: brightness(0);
  margin: 0;
  border: none;
  z-index: 1000;
}

.boat-details .modal-carousel button.close-modal,
.boat-details > .details-contact.cta-modal.open .sticky-contact,
.boat-details .modal-carousel #details-contact-anchor,
.boat-details > .body > .content > .details-contact.cta-modal.open .sticky-contact,
.broker-dealer-next-previous > .boat-details > .broker-bdp > .broker-bdp-left > .content > .details-contact.cta-modal.open .sticky-contact {
  display: none;
}

@media screen and (max-width: 969px) {
  .boat-details > .body > .content > .details-contact.cta-modal.open,
  .broker-dealer-next-previous > .boat-details > .broker-bdp > .broker-bdp-left > .content > .details-contact.cta-modal.open,
  .boat-details > .details-contact.cta-modal.open {
    padding: 20px;
  }
}
/* -- ANA-1370 --*/
@media screen and (max-width: 969px) {
  .boat-details > .body > .content > .details-contact.open,
  .boat-details > .details-contact.open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: none;
    background: none;
    z-index: 1100;
  }

  .boat-details > .body > .content > .details-contact.open .contact-area.open,
  .boat-details > .details-contact.open .contact-area.open {
    position: relative;
    background: #eee;
    appearance: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: 0;
    outline: none;
    filter: drop-shadow(0px 6px 4px rgba(0,0,0,.35));
    transform: translateY(150%) scale(0);
    opacity: 0;
    animation: 150ms 200ms ease-out scaleIn forwards;
  }

  .boat-details > .body > .content > .details-contact.open #details-contact-anchor,
  .boat-details > .details-contact.open #details-contact-anchor {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.3);
    transition: opacity 200ms ease-out;
    border: none;
    opacity: 1;
  }

  .boat-details > .body > .content > .details-contact #details-contact-anchor,
  .boat-details > .details-contact #details-contact-anchor {
    opacity: 0;
  }

  .boat-details > .body > .content > .details-contact.open .contact-area.open > button.close-modal,
  .boat-details > .details-contact.open .contact-area.open > button.close-modal {
    position: absolute;
    right: 16px;
    top: 16px;
    background: transparent;
    width: 22px;
    height: 22px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    cursor: pointer;
    filter: brightness(0);
    margin: 0;
    border: none;
  }

  .boat-details .modal-carousel button.close-modal,
  .boat-details > .details-contact.open .sticky-contact,
  .boat-details .modal-carousel #details-contact-anchor,
  .boat-details > .body > .content > .details-contact.open .sticky-contact {
    display: none;
  }
}

@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: translateY(150%) scale(0);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* ANA-1784 ANA-1836*/
.details-contact .modal-checkbox-container {
  padding: 0 10px 16px;
  display: flex;
}
.details-contact .modal-checkbox-container.hide {
  display: none;
}
.details-contact .modal-checkbox {
  margin-left: 20px;
}
.details-contact .modal-checkbox-label {
  font-size: 16px;
  padding-left: 10px;
}