.collapse-content-details {
  border-top: 1px solid #cccccc;
  padding: 10px;
}

/* ANA-1728 */
@media screen and (max-width: 767px) {
  .collapse-content-details {
    border: 0px;
    border-radius: 6px;
    box-shadow: rgb(0 0 0 / 14%) 0px 1px 6px 0;
    margin: 10px;
    padding: 10px 10px 10px 13px;
  }
}

.collapse-content-details.open {
  padding-bottom: 20px;
}

.collapse-content-details .header a {
  font-size: 18px;
  line-height: 25px;
  color: #414d4a;
  display: block;
  position: relative;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.collapse-content-details .header a .icon {
  width: 14px;
  height: 14px;
  position: relative;
  float: left;
  order: -1;
  padding-right: 10px;
}

.collapse-content-details .header h3 {
  cursor: pointer;
  margin: 8px 0;
  font-weight: bold;
  display: block;
}

.collapse-content-details .header h3 a .icon {
  background: url(../../icons/Plus.svg) no-repeat;
}

.collapse-content-details.open .header h3 a .icon {
  background: url(../../icons/Minus.svg) no-repeat;
}

.collapse-content-details .collapsible {
  transition: max-height 900ms;
  overflow: hidden;
  padding: 0px 10px;
}

@media screen and (min-width: 1097px) {
  .collapse-content-details .collapsible {
    padding: 0px 26px;
  }
}

.collapse-content-details.closed .collapsible {
  max-height: 0;
  transition: max-height 500ms;
}
