.boat-type-modal-hidden {
  display: none;
}
.boat-type-modal {
  display: block;
  position: relative;
  z-index: 100;
}

.boat-type-modal p {
  width: inherit;
}

.boat-type-modal span > ul > li {
  flex: 1 1 30%;
  padding-bottom: 2px;
}

.boat-type-modal span > ul > li a {
  line-height: 20px;
}

.boat-type-modal h3 {
  flex: 1 1 100%;
}

.boat-type-modal h3 + a {
  flex: 1 1 100%;
  padding-left: 0 !important;
  margin-left: 0 !important;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .boat-type-modal span > ul >li {
    flex: 1 0 25%;
  }
}

@media only screen and (min-width: 1024px){
  .boat-type-modal span > ul > li {
    flex: 1 0 15%;
  }
}