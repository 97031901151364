.location-map-wrapper {
  position: relative;
}

.hidden-location-map-wrapper {
  display: none;
}

.location-map-wrapper .location-map {
  width: 100%;
  height: 200px;
  position: relative;
}

.location-map-wrapper .address {
  text-align: center;
}

.location-map-wrapper #location-map-anchor {
  position: absolute;
  top: -50px;
}

.close-map-modal {
  position: absolute;
  right: 0;
  top: -22px;
  cursor: pointer;
  background-color: #e67e22;
  color: #fff;
  padding: 5px;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -ms-border-radius: 3px 3px 0 0;
  -o-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 1em;
}

.calculate-route {
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  text-align: center;
}

.calculate-route .calculate-route-button {
  width: 45%;
  color: #fff;
  background: #119bf5;
  border: 1px solid #119bf5;
  box-sizing: border-box;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.modal-main {
  position:fixed;
  background: white;
  width: 80%;
  height: 70%;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

@media screen and (min-width: 768px) {

  .calculate-route .calculate-route-button {
    width: auto;
  }

  .modal-main {
    height: 80%;
  }

}