.virtual-tour-modal .modal-container {
  background: #000;
  color: #002a45;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.virtual-tour-modal header {
  display: inline-flex;
  justify-content: space-between;
  padding: 0 10px;
  background-color: #000;
  color: #fff;
}

.virtual-tour-modal header h2,
.virtual-tour-modal header button {
  display: flex;
  flex-grow: 1;
}

.virtual-tour-modal header h2{
  max-width: 85%;
  margin-left: 5%;
  font-size: 1em;
  font-weight: 500;
}

.virtual-tour-modal header button {
  justify-content: right;
  padding: 20px 25px 0 0;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  position: absolute;
  right: 0px;
}

@media screen and (max-width: 767px){
  .virtual-tour-modal header button{
    padding: 10px 10px 0 0;
  }
  .virtual-tour-modal header h2 {
    font-size: 1em;
  }
}

.virtual-tour-modal #virtual-tour-content {
  width: calc(100% - 30px);
  height: calc(100% - 57px);
  align-self: center;
}

.virtual-tour-modal .ReactModal__Content {
  background: #000;
  border: none;
  padding: 0;
  height: 100%;
  width: 100%;
}

.virtual-tour-modal .ReactModal__Overlay {
  background: #333333E5;
}