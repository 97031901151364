#recent-articles-review .articles div {
	cursor: pointer;
}

#recent-articles-review .articles > div {
    flex: 1 0 20%;
    max-width: 100%;
    margin-right: 0.8em;
    box-sizing: border-box;
	border: 0.1em solid #EAEAEA;
	border-radius: 0.25em;
}

#recent-articles-review .articles img {
    max-width:100%;
    width: 100%;
		height: 200px;
    object-fit: cover;
}

#recent-articles-review .content{
	padding: 1em;
}

#recent-articles-review .content > div{
	padding: 0.3em 0;
}

#recent-articles-review .category{
	font-weight: 400;
	font-size: 0.75em;
	line-height: 0.9em;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	color: #595959;
}

#recent-articles-review .title-link{
	font-weight: 700;
	font-size: 1em;
	line-height: 1.2em;
	color: #334556;
}

#recent-articles-review .excerpt{
	font-weight: 400;
	font-size: 0.9em;
	line-height: 1.1em;
	color: #686868;
}

@media screen and (max-width: 959px) {
    #recent-articles-review .articles > div {
		flex: 1 0 75%;
		margin-right: 0.6em;
	}
}

@media screen and (max-width: 500px) {
    #recent-articles-review .articles > div {
		flex: 1 0 75%;
		margin-right: 0.6em;
	}

    #recent-articles-review .articles > div:last-child {
		margin-right: 0;
	}
}