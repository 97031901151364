.dynamic__content {
  margin-bottom: 40px;
}

.dynamic__content p {
  margin-top: 5px;
}

.dynamic__content h2 {
  color: #0f4d77;
}

.dynamic__content h3 {
  color: #0f4d77;
}