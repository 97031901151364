.page-container {
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.social-browse-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: normal;
    background-color: #F5F5F5;
    overflow: hidden;
}

.social-browse-page .sbp-toolbar {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #2876aa;
    height: 50px;
    text-align: center;
}
.social-browse-page .sbp-toolbar svg {
    width: 162px;
}

.social-browse-page .sbp-carousel {
    flex-grow: 1;
    padding: 18px 0;
    box-sizing: border-box;
    height: calc(100% - 118px);
}
.social-browse-page .sbp-carousel>div>div>ul>li {
    padding-left: 18px;
    margin-left: 0;
    width: 80%;
}
.social-browse-page .sbp-carousel>div>div>ul>li:last-of-type {
    padding-right: 18px;
}

.social-browse-page .sbp-ad-slot {
    flex-shrink: 0;
    height: 50px;
    background-color: lightgray;
    margin: 0 auto 18px auto;
    width: 320px;
}

.social-browse-footer p {
    font-size: 12px;
    color: #707070;
    text-align: center;
    margin: 0;
    padding: 0px 18px;
}

.social-browse-footer p a {
    color: #707070;
}

.social-browse-footer p:first-of-type {
    padding: 18px 18px 0px;
}

.social-browse-footer #teconsent {
    padding-bottom: 18px;
}
.social-browse-footer span {
    padding: 0 0.5em
}

.social-browse-footer img.adchoices-icon {
    width: 10px;
    padding-left: 2px;
    vertical-align: middle;
    display: initial;
    margin-top: -2px;
    filter: invert(46%) sepia(0%) saturate(25%) hue-rotate(152deg) brightness(90%) contrast(69%);
}
/* social share */
.social-browse-page .social-share .social-dropdown .social-dropdown-modal {
    border-radius: 8px 8px 0px 0px;
}
  
.social-browse-page .social-dropdown-modal-anchors a {
    display: none;
}

.social-browse-page .social-share-overlay .social-share-trigger {
    display: none;
}
.social-browse-page .social-share-overlay .social-share-trigger::before {
    display: none;
}

.social-browse-footer #teconsent{
    margin: auto;
    max-width: fit-content;
}