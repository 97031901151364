.financing-parent-container {
  background-color: #F5F8FD;
  padding-top: 48px;
  width: 100%;
}

.financing-content-container {
  align-content: center;
  align-items: center; 
  display: flex; 
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1061px;
}

.tabset > input[type="radio"] {
  position: absolute;
  left: -200vw;
}

.tabset .boat-loans-tab-panel {
  display: none;
}

.financing-tab-content > a {
  color: var(--bt-color-primary);
}

.financing-tab-content ul>li::marker {
  color: #07324F;
  margin-right: 8px;
  font-size: 20px;
}

.tabset > input:first-child:checked ~ .boat-loans-tab-panels > .boat-loans-tab-panel:first-child,
.tabset > input:nth-child(3):checked ~ .boat-loans-tab-panels > .boat-loans-tab-panel:nth-child(2) {
  display: block;
}

.tabset > label {
  border: 1px solid transparent;
  border-bottom: 0;
  color:  #07324F;
  cursor: pointer;
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: normal;
  padding: 30px;
  text-align: center;
}

.tabset > input:checked + label {
  border-color: #ccc;
  border-bottom: 1px solid #F5F8FD;
  border-left: 1px solid #ccc;
  margin-bottom: -1px;
}

.boat-loans-tab-panel {
  border: 1px solid #ccc;
  border-top: 1px solid #ccc;
}

.boat-loans-tab-panel {
  min-height: 363px;
}

.apply-link-button-container {
  display: flex;
  justify-content: center;
  padding-top: 60px;
}

p.financing-intro-text {
  margin: 0
}

.financing-footer {
  background-color: white;
  background-size: auto;
  background-repeat:repeat-x; 
  padding-bottom: 8px;
  background-image: url(../../../../icons/flow.svg);
}

.iphone-credit-score {
  margin-top: 32px;
  margin-bottom: 32px;
  padding-right: 64px;
  height: 299px;
  width: 128px;
  margin-right: 54px;
}

.financing-tab-content {
  color:  #404040;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.16px;
  margin: 0px;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;
}

.financing-intro-header {
  color: #07324F;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.financing-intro-text {
  color:  #404040;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.16px;
  margin-left: 0px;
}

.financing-intro-text:not(:first-of-type) {
  margin-top: 20px;
}

.financing-intro-container {
  margin-bottom: 30px;
}

.bt-and-trident-logo-container {
  display: flex;
  justify-content: center;
}

.financing-intro-header-container {
  display: flex; 
  flex-direction: column; 
  margin-top: 20px; 
  margin-bottom: 20px;
}

.tab-content-with-img-container {
  display: flex;
  flex-direction: row;
}

.tab-content-with-img-text {
  display: flex;
  flex-direction: column;
  margin-right: 54px;
}

.financing-desktop-view {
  display: block
}

.financing-mobile-view {
  display: none
}

@media (max-width: 912px) {
  .financing-desktop-view {
    display: none;
  }

  .financing-mobile-view {
    display: block;
  }

  .financing-tab-content {
    margin: 0;
  }

  .bf-accordion > input[type="checkbox"] {
    position: absolute;
    left: -100vw;
  }

  .bf-accordion .mob-accordion-content {
    overflow-y: hidden;
    height: 0;
  }

  .bf-accordion > input[type="checkbox"]:checked ~ .mob-accordion-content {
    height: auto;
    overflow: visible;
  }

  .bf-accordion label {
    display: block;
    cursor: pointer;
    font-weight: normal;
    padding: 20px;
  }

  .bf-accordion > input[type="checkbox"]:checked ~ .handle {
    .arrow {
      transform: rotate(180deg);
    }
  }

  .bf-accordion > input[type="checkbox"]:checked ~ .mob-accordion-content {
    padding: 15px;
    border: 1px solid #C2C2C2;
    border-top: 0;
  }

  label.handle2, label.handle1 {
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
  }

  .bf-accordion .handle {
    margin: 0;
    font-size: 1.125em;
    line-height: 1.2em;
    border: 1px solid #C2C2C2;
  }
  
  .handle.last-option {
    border-top: none;
  }

  div.handle > div.mob-accordion-content {
    border: 1px solid #C2C2C2
  }

  .mob-finance-section-1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-bottom: none;
  }

  .iphone-credit-score {
    padding: 0;
    margin: 0;
    margin-bottom: 36px;
    margin-top: 36px;
  }

  .handle1 > img, .handle2 > img {
    height: 8px;
    width: 16px;
  }

  .bf-accordion {
    max-width: 65em;
  }

  .bf-accordion p:last-child {
    margin-bottom: 0;
  }
}