.data-details-wrapper {
    column-gap: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    flex-wrap: wrap;
    padding-left: 8px;
    padding-top: 8px;
    row-gap: 16px;
}

.data-details-cell {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: 14px;
    min-width: 200px;
    row-gap: 12px;
    width: 100%;
}

@media only screen and (max-width: 420px) {
    .data-details-cell {
        width: 70vw;
    }
}

.data-html {
    padding-left: 8px;
    padding-top: 8px;
}

.data-html h4,
.data-details-cell h4 {
    margin: 0;
}

.data-details-cell-content {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    row-gap: 12px;
}

.data-details-cell-content p {
    margin: 0;
    display: flex;
    column-gap: 12px;
    justify-content: space-between;
}

.data-details-cell-content p > span:first-child {
    text-wrap: nowrap;
}

.cell-content-title {
    font-weight: 600;
}

.cell-content-subtitle {
    padding-left: 12px;
}

.cell-content-value {
    text-align: right;
}

.data-html-inner-wrapper {
    max-height: 160px;
    overflow: hidden;
    position: relative;
}

.data-html-inner-wrapper.show-more {
    max-height: 100%;
}

.show-more-less-interaction {
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, var(--bt-color-neutral-10) 100%);
    bottom: -4px;
    line-height: 22px;
    position: absolute;
    text-align: right;
    right: 0;
}

.show-more-less-interaction button {
    background: none;
    border: none;
    color: var(--bt-color-secondary_action-main);
    cursor: pointer;
}