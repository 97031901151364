.details {
  position: relative;
}

.collapsible-details-section {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  padding: 24px 0
}

.collapsible-details-section>div:first-of-type {
  margin: 0 10px;
}

@media (width < 970px) {
  .additional-boat-details>div {
    margin: -5px 0 -7px;
  }
}

#bestimate-modal {
  position: absolute;
  z-index: 100;
  margin-left: 10px;
  margin-right: 10px;
  width: auto;
}

#bes-tooltip {
  z-index: 101;
}

@media (min-width: 500px) {
  #bes-tooltip {
    width: 300px !important;
    z-index: 101 !important;
  }

  #bestimate-modal {
    position: absolute;
    z-index: 100;
    right: 0;
    width: 350px;
  }
}

.security-info {
  border-top: 1px solid #ccc;
  padding: 10px;
  padding-bottom: 20px;
}

.security-info .header {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #404040;
  margin-top: 20px;
  margin-bottom: 8px;
}

.security-info p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-left: 0;
  margin-top: 0;
}

.cta-wrapper,
.infopanel-wrapper {
  padding: 20px 25px;
}

.infopanel-wrapper a {
  border-radius: 50px !important;
  background: var(--bt-color-neutral-10) !important;
  border: 1px solid var(--bt-color-primary) !important;
  color: var(--bt-color-primary) !important;
  display: block;
  padding: 10px;
  width: fit-content;
}

.infopanel-wrapper a label {
  color: var(--bt-color-primary) !important;
}

.infopanel-wrapper a:hover {
  background: var(--bt-color-neutral-20) !important;
}

.cta-visible .cta-wrapper,
.cta-visible .infopanel-wrapper {
  display: block;
}

.infopanel-wrapper {
  border-top: 1px solid #ccc;
}

.details .trident-funding-disclaimer-wrapper {
  padding-bottom: 30px;
}

.details .trident-funding-disclaimer-wrapper .disclaimer {
  color: #000;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.details .trident-funding-disclaimer-wrapper .disclaimer .underline {
  text-decoration-line: underline;
}

@media screen and (max-width: 1096px) {

  .cta-wrapper,
  .infopanel-wrapper {
    padding: 20px 8px;
  }
}

@media screen and (max-width: 767px) {
  .infopanel-wrapper {
    border-top: 0px;
    padding: 0px 8px;
  }
}

@media screen and (max-width: 969px) {
  .details .trident-funding-disclaimer-wrapper {
    padding-left: 29px;
    padding-right: 29px;
  }
}

@media screen and (max-width: 767px) {
  .accordion-details-wrapper {
    padding: 0 10px;
  }
}

@media (width < 970px) {
  .accordion-details-wrapper {
    margin-top: -7px;
  }
}


.accordion-details-section {
  background-color: var(--bt-color-primary_action-surface);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  row-gap: 16px;
  border: 1px solid var(--bt-color-neutral-30);
}

.accordion-details-section h2 {
  margin: 0;
  font-size: 20px;
}

.accordion-details-items {
  background-color: var(--bt-color-neutral-10);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
}

.accordion-details-items h3 {
  margin: 0;
  font-size: 16px;
}

.accordion-details-items details:last-child {
  border-bottom: none;
}

.boat-loans-services-section {
  display: flex;
  gap: 20px;
  flex-flow: row wrap;
  justify-content: center;
}

.boat-loans-services-section>div {
  min-width: 433px;
  max-width: 433px;
}

.video-reviews-section {
  background-color: var(--bt-color-primary_action-surface);
  border-radius: 8px;
  display: flex;
  row-gap: 20px;
  flex-direction: column;
  padding: 20px 16px;
}

@media only screen and (max-width: 767px) {
  .video-reviews-section {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.video-reviews-section iframe {
  height: auto;
  border-radius: 8px;
}

.video-reviews-section h2 {
  margin: 0;
  font-size: 20px;
}

.dealerListingsCarouselWrapper {
  border-radius: 8px;
  padding: 20px 16px;
  border: 1px solid var(--bt-color-neutral-30);
  background-color: var(--bt-color-primary_action-surface);
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.dealerListingsCarouselWrapper h3 {
  font-size: 20px;
  color: #414d4a; 
  flex: 1;
  overflow-y: hidden;
  margin: 0;
}

.dealerListingsCarouselWrapper .listingCarouselContainer > div {
  height: 258px;
  margin: 0;
  width: 100%;
}

.listingCarouselContainer > div {
  background-color: transparent;
  
}

.carouselElement{
box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
}

.tablet-ads-wrapper [class*="style-module_image-wrapper"].mobile-box-ad a {
  max-height: 250px;
  margin: 2px auto;
  overflow: hidden;
  max-width: 300px;
}

.tablet-ads-wrapper [class*="style-module_image-wrapper"].mobile-box-ad .ad-image {
  position: relative;
}