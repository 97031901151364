.main-section {
  background: #FFF !important;
}

.main-section .boatloans-info-v2 {
  background: linear-gradient(180deg, #F2F2F2 0%, rgba(242, 242, 242, 0) 100%);
  margin-top: 0 !important;
  background: #FFF !important;
  padding: 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0 !important;
  background: #FFF !important;
  padding: 0 !important;
}

.breadcrumb-container {
  height: 2.666rem;
  background-color: #FFF;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-left: 1.533rem;
}

footer.main-footer {
  margin-top: 0;
}

@media (max-width: 912px) {
  .main-section .boatloans-info-v2 {
    padding: 0 !important;
  }

  .breadcrumb-container {
    height: 2.266rem;
    padding-left: 0.4rem;
  }


  footer.main-footer {
    margin-top: 1.466rem;
  }
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.frequently-questions-section h2, .guide-tools-sections h2 {
  text-align: center;
  font-size: 40px;
  font-weight: 800;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 0;
}

@media (max-width: 912px) {
  .frequently-questions-section h2, .guide-tools-sections h2 {
    font-size: 28px;
    font-weight: 800;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 30px;
  }
}
