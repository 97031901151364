.boat-loan-expl-container {
  background-color: 'white';
  padding: 48;
  margin: 0 auto;
  margin-top: 64px;
  padding: 0 20px;
  max-width: 1061px;
  margin-bottom: 36px;
}

.explanation-text {
  color:  #616161;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.18px;
  margin-left: 0px;
  margin-top: 0px;
}

p.boat-loans-subtitle {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 24px;
  color: #616161;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

h2.boat-loans-page-header {
  margin: 0px;
}

.page-header-container {
  text-align: center;
}

.boat-riders-img {
  height: 480px; 
  width: 440px; 
  padding-bottom: 6px; 
  padding-right: 18px;
  float: left;
}

p.explanation-text > a {
  color: var(--bt-color-primary);
}

.boat-loans-page-header {
  font-size: 40px; 
  font-weight: 800; 
  line-height: normal;
}

 @media (max-width: 912px) {
  .explanation-body-container {
    display: flex;
    flex-direction: column;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
  }

  .boat-riders-img {
    height: 300px;
    width: 354px;
    padding: 0px;
    padding-bottom: 18px;
  }

  .boat-loans-page-header {
    font-size: 28px; 
    font-weight: 800; 
    line-height: normal;
  }

  .page-header-container {
    margin-top: 36px;
  }
} 