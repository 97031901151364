#oem-footer {
  display: block;
  box-sizing: border-box;
  margin: 24px auto 0 auto;
  padding: 0 20px 0 20px;
}

#oem-footer h2 {
  padding-left: 10px;
}

#oem-footer div {
  font-size: 16px;
}

@media screen and (max-width: 768px) {

  #oem-footer {
    padding: 0 10px;
  }

  #oem-footer div:first-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 1440px){
  #oem-footer {
    max-width: 1440px;
    background: white;
    margin-top: 24px;
  }
  #oem-footer > div {
    margin-bottom: 24px;
  }
  #oem-footer .info-dealer-panel {
    box-sizing: border-box;
    padding: 0 10px;
  }
}
