.detail-description {
  max-height: 160px;
  overflow: hidden;
  font-size: 14px;
  line-height: 22px;
  color: #454C4A;
  transition: max-height 1s ease;
  position: relative;
}

.detail-description p {
  margin-top: 0;
}

.detail-description.overlap.less {
  max-height: 100%;
  padding-bottom: 10px;
}

.detail-description .more-less {
  display: none;
  position: absolute;
  bottom: -5px;
  text-align: right;
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  color: #119BF5;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 0%, var(--bt-color-neutral-10) 100%);
}

.detail-description .more-less .toggle{
  cursor:pointer;
  outline: 0;
  background: #fff;
}

.detail-description.overlap .more-less {
  display:block;
}
