.mobile-filter-anchor {
  position: absolute;
  top: 0;
}

/* - ANA-1206 - */
body.filters-open {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
body.filters-open .mobile-search-filters {
  z-index: 1004;
  color: #404040;
}
body.filters-open .mobile-search-filters #tool-set-no-padding {
  top: 0;
}
/*ANA-1738*/
.mobile-search-filters {
  position: fixed;
  left: 0;
  top: 51px;
  width: 100%;
  z-index: 1001;
  /* border-bottom: 20px solid white; */
  padding: 0 9px;
  box-sizing: border-box;
  transition: top .2s linear;
}
.hide-header + .content .mobile-search-filters {
  top: 0;
}

.mobile-search-filters #tool-set-no-padding {
  z-index: 1001;
  padding: 0;
  left: 0;
  top: calc(100% + 100px);
  transition: top 100ms ease-out;
  transform: none;
}

.mobile-search-filters #tool-set-no-padding .tool-set-header {
  z-index: 1002;
  position: relative;
}

.mobile-search-filters .mobile-filter-header .search-alerts {
  filter: drop-shadow(0px -3px 2px rgba(0, 0, 0, 0.15));
  border-top: none;
  background: var(--bt-color-neutral-10);
}

.mobile-search-filters .mobile-filter-header .search-alerts .filter-button {
  font-weight: 600;
  width: 25%;
  cursor: default;
}
.mobile-search-filters
  .mobile-filter-header
  .search-alerts
  .search-alerts-button-mobile {
  font-weight: 600;
  width: 35%;
}
.mobile-search-filters
  .mobile-filter-header
  .search-alerts
  .filter-button:before {
  transform: scale(0.65);
  opacity: 0.8;
}
.mobile-search-filters
  .mobile-filter-header
  .search-alerts
  .search-alerts-button-mobile:before {
  background: url(../../../icons/save_search.svg) no-repeat;
  width: 24px;
  height: 24px;
  content: "";
  display: inline-block;
  margin-right: 1px;
  margin-left: -4px;
  margin-bottom: -2px;
  transform: scale(0.8);
  filter: grayscale(1);
  opacity: 0.8;
}

.mobile-search-filters .mobile-filter-header .search-alerts .search-tools.sort {
  width: 40%;
}

#engines-content
  .mobile-search-filters
  .mobile-filter-header
  .search-alerts
  .search-tools.sort {
  width: 75%;
}

.mobile-search-filters
  .mobile-filter-header
  .search-alerts
  .tool-set
  .header
  .criteria {
  display: block;
  font-weight: 600;
  color: #000;
  font-size: 12px;
}

.mobile-search-filters
  .mobile-filter-header
  .search-alerts
  .header
  > span:first-of-type {
  width: auto;
  max-width: calc(100% - 40px);
}

.mobile-search-filters
  .mobile-filter-header
  .search-alerts
  .search-tools.sort
  .tool-set
  ul.opts {
  box-shadow: none;
  filter: drop-shadow(3px 0 2px rgba(0, 0, 0, 0.15));
}
@media screen and (max-width: 767px) {
  .mobile-search-filters
    .mobile-filter-header
    .search-alerts
    .search-tools.sort
    .tool-set
    ul.opts {
    right: 10px;
  }
}
@media screen and (min-width: 970px) {
  .mobile-search-filters {
    display: none;
  }
}
.mobile-search-filters
  .mobile-filter-header
  .search-alerts
  .search-tools.sort
  .tool-set
  ul.opts
  .custom-option {
  max-height: 25px;
  box-sizing: border-box;
}

.mobile-search-filters #tool-set-no-padding .buttons {
  background: #fff;
  padding: 0px 12px 8px 12px;
  height: initial;
  transition: none;
  bottom: 0;
  filter: drop-shadow(0 -3px 2px rgba(0, 0, 0, 0.15));
}
.mobile-search-filters .tool-set .buttons .btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 46px;
}
#engines-content.engines-container .mobile-search-filters .tool-set .buttons .btn-container .tool-set-btn-search, 
.mobile-search-filters .tool-set .buttons .btn-container .tool-set-btn-search.tool-set-btn-search-branded {
  width: 100%;
}
#engines-content.engines-container .mobile-search-filters .tool-set .buttons .btn-container .search-alerts-container {
  width: 0%;
}

.mobile-search-filters .tool-set .buttons .btn-container > * {
  width: 49%;
}

/* ANA-1738 */
.mobile-search-filters .tool-set .buttons .btn-container .search-alerts-button,
.tool-set-btn-search {
  height: 40px;
  font-weight: 600;
  border-radius: 20px;
  font-size: 16px;
  line-height: 19px;
}
.mobile-search-filters .tool-set .buttons .btn-container .search-alerts-button {
  background: var(--bt-color-neutral-10);
  color: var(--bt-color-primary);
  border: 1px solid var(--bt-color-primary);
}

.mobile-search-filters
  .tool-set
  .buttons
  .btn-container
  .search-alerts-button::before {
  display: none;
}
.mobile-search-filters .tool-set .buttons .btn-container .tool-set-btn-search {
  background: var(--bt-color-primary);
  color: var(--bt-color-neutral-10);
  border: none;
}

.mobile-search-filters .tool-set .search-alerts-filter-tags {
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  padding: 12px 12px 4px 12px;
  border: none;
  filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.15));
  z-index: 1001;
  height: 50px;
  box-sizing: border-box;
  overflow: hidden;
}
.mobile-search-filters .tool-set .search-alerts-filter-tags:empty {
  display: none;
}
.mobile-search-filters
  .tool-set
  .search-alerts-filter-tags:not(:empty)
  + .collapse-content {
  margin-top: 50px;
}
#engines-content
  .mobile-search-filters
  .tool-set
  .search-alerts-filter-tags:not(:empty)
  + .collapse-content {
  margin-top: 50px;
}
.mobile-search-filters
  .tool-set
  .search-alerts-filter-tags
  .search-alerts-filter-tags-scroller {
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: hidden;
  overflow-x: scroll;
  height: 100px;
}
.mobile-search-filters
  .tool-set
  .search-alerts-filter-tags
  .search-alerts-filter-tags-scroller
  > * {
  flex-shrink: 0;
  flex-grow: 0;
}

.mobile-search-filters .tool-set #tool-set-inner-padding {
  padding: 0 16px 0;
  height: calc(100% - 149px);
  box-sizing: border-box;
  overflow-x: hidden;
  overscroll-behavior: contain;
  z-index: 1;
}

.mobile-search-filters .search-filter .slider-container .rc-slider {
  height: 20px;
  padding: 8px 0;
}

.mobile-search-filters
  .search-filter
  .slider-container
  .rc-slider
  .rc-slider-handle {
  margin-top: -8px;
  width: 16px;
  height: 16px;
}

.mobile-search-filters .tool-set #tool-set-inner-padding .spinner-preloader {
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.3);
}

/* ANA-1738*/
  .mobile-search-filters .tool-set {
    background-color: rgb(245, 245, 245);
  }

  .mobile-search-filters .tool-set #tool-set-inner-padding {
    height: calc(100% - 124px);
    padding: 6px 10px 0px 10px;
  }

  
