
.apply-now-button{
    display: flex;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    text-decoration: none;
    border-radius: 50px;
    background: var(--bt-color-primary);
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
    color: #FFF !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    width: 260px;
    height: 26px;
    cursor: pointer;
}

.apply-now-link{
    text-decoration: none;
    color: var(--bt-color-primary);
    cursor: pointer;
}

.apply-now-button.small {
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  width: 202px;
  height: 33px;
}