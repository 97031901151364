.dealer-filters-container {
  background: #4492D0 url('https://images.boatsgroup.com/images/1/upload/bg-dealers-blue.png') no-repeat;
  background-size: cover;
  background-position: center;
  padding: 15px;
}

.df-inputs {
  display: flex;
  flex-direction: column;
  --select-height: 34px;
}

.df-inputs label {
  margin-top: 5px;
}

.df-inputs .df-input, .df-inputs .df-select {
  flex: 1 1 auto;
  margin: 5px 0 0 0;
  padding: 0;
}

.df-input input, .df-select select {
  width: 100%;
  padding: 2px;
	color: var(--bt-color-neutral-90);
	background: #fafafa;
	border: 1px solid #ccc;
	outline: 0;
  box-sizing: border-box;
  border-radius: 4px;
}
@media only screen and (max-width: 600px) {
  .df-input input, .df-select select {
    font-size: 16px;
  }
}

.sta-container .sta-wrapper-input input {
	color: var(--bt-color-neutral-90) !important;
}

@media screen and (max-width: 500px) {
  .sta-container .sta-wrapper-input input {
    font-size: 16px !important;
  }
}

.df-select select {
  appearance: none;
  width: 100%;
  font-size: 13px;
  cursor: pointer;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41Z' fill='%23a5a5a5'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 8px;
  background-size: 16px;
}

.df-input input {
  padding: 8px;
  line-height: 10px;
}

.df-select select {
  border-right: 5px solid transparent;
  padding: 8px;
}

.df-searchbtn {
  margin-left: 5px;
}

.mobileHiddenSearch {
  display: none;
}

.mobileAdvSearch {
  text-align: center;
  cursor: pointer;
  width: fit-content;
  margin: 10px auto;
  font-weight: bold;
  font-size: 16px
}

.mobileAdvSearch-hidden {
  display: none;
}

/* typeahead select arrow */
.df-select .sta-container .sta-wrapper-input::before {
  background-size: 15px;
  height: 15px;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .dealer-filters-container {
    min-height: 200px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .df-inputs {
    display: flex;
    flex-direction: row;
    margin: 0 -5px;
  }

  .df-inputs .df-input, .df-inputs .df-select {
    flex: 1 1 20%;
    margin: 5px
  }
  .mobileAdvSearch {
    display: none;
  }
  .mobileHiddenSearch {
    display: contents;
  }
  .desktopSearch {
    display: contents;
  }
}
