.companion-ad {
    width: 100%;
    display: flex;
    margin: 20px 0;
    overflow: hidden;
    min-height: 220px;
    max-height: 400px;
    position: relative;
    justify-content: center;
    background-color: #ecf0f1;
}
.companion-ad.sticky {
    top: auto;
    bottom: 0;
    margin: 0;
    position: fixed;
    max-height: 100px !important;
    min-height: 100px !important;
}
.companion-ad.sticky .ad-image {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}
.companion-ad.hidden {
    visibility: hidden;
    display: none;
}
#companion-ad-home_companion {
    min-height: initial;
}
#companion-ad-home_companion .ad-image {
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    position: relative;
}
#companion-ad-home_companion .native-ad {
    max-width: 100% !important;
    max-height: 100% !important;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
}
#companion-ad-home_companion:has(.native-ad) {
    width: initial;
}
