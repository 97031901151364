.dealers-container {
  display: block;
}

.dealer-data > * {
  padding: 0;
  margin: 0;
}

.dealer-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.dealer-item {
  flex: 1 1 100%;
  filter: drop-shadow(0px 1px 2px rgba(0,0,0,0.4));
  background: var(--bt-color-neutral-10);
  margin-bottom: 20px;
  padding-bottom: 15px;
}

.dealer-card {
  position: relative;
}

.dealer-item > div {
  padding: 0;
  height: 100%;
}

.dealers-container {
  padding: 0 30px;
  box-sizing: border-box;
  max-width: 1430px;
}

.content .dealers-container .content-head .breadcrumb {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}

.dealers-container > h1 {
  line-height: 30px;
}

.dealer-data {
  padding: 15px 15px 0 15px;
}

.dealer-data-items {
  color: var(--bt-color-secondary_action-main);
  font-size: 14px;
}

.dealer-data-items > * {
  margin: 12px 0;
  padding: 0 0 0 16px;
}

.dealer-logo {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 10px 10px 10px 0;
}

.dealer-page-link {
  color: #0b3f65;
  text-decoration: none;
  font-size: 20px;
  line-height: 22px;
}

.dealer-location {
  background: url(../../icons/BlueLocation.svg) no-repeat left;
  padding-left: 20px;
  cursor: pointer;
}

.dealer-phone {
  background: url(../../icons/BluePhone.svg) no-repeat left;
  padding-left: 20px;
}

.dealer-phone a {
  color: var(--bt-color-secondary_action-main);
}

.dealer-card .contact, .dealer-orange-button, .dealer-card .inventory {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: center;
  margin: 10px 10px;
}

.dealer-card .inventory {
  background: white;
}


/* For the map modal */

.modal-skeleton {
  position: fixed;
  width: 100%;
  background-color: rgb(34 27 34 / 50%);
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  min-width: 200px;
}

.skeleton-container {
  padding: 15px;
}

.skeleton-content {
  left: auto;
  right: auto;
  bottom: auto;
  width: 95%;
  position: absolute;
  margin: 0;
  padding: 0;
  background-color: #fff;
  box-shadow: 0 5px 15px #333;
  z-index: 99;
}

.skeleton-content .title {
  padding: 0;
}

.skeleton-content button.contact-close {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 50px;
  height: 50px;
  border-width: 0;
  background: url(//boattrader.com/static/img/icons/close-x-mobile.png) no-repeat center center transparent;
  display: block;
}

.skeleton-content .title {
  color: #2876aa;
  font-weight: 700;
  margin: 5px 0 15px;
  text-align: left;
}

.address-map {
  position: relative;
  min-height: 250px;
}

.no-dealers p {
  margin-left: 0;
}

@media screen and (min-width: 768px) {
  .skeleton-content {
    padding: 0;
    max-width: 500px;
  }

  .skeleton-content .title {
    padding: 15px;
  }

  .skeleton-content button.contact-close {
    float: right;
    width: 16px;
    height: 16px;
    margin: 15px;
    background: url(//boattrader.com/static/img/icons/close-x.png) no-repeat 0 top;
    border-width: 0;
    cursor: pointer;
  }
  .skeleton-content .title {
    padding: 0;
  }
}

.modal-dealer-buttons {
  display: flex;
  margin: 14px 0;
  column-gap: 10px;
}

.modal-dealer-buttons a {
  display: flex;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  line-height: 24px;
  padding: 5px 20px;
  align-items: center;
  border-radius: 50px;
  letter-spacing: 0.75px;
}

.modal-dealer-contact-btn {
  padding-top: 2px;
  padding-bottom: 2px;
  color: var(--bt-color-neutral-10);
  background-color: var(--bt-color-primary);
}

.modal-dealer-call-btn {
  color: var(--bt-color-primary);
  border: 2px solid var(--bt-color-primary);
}

.modal-dealer-address div {
  font-size: 14px;
  margin: 0;
  padding: 0;
}
/* end map modal */

@media (max-width: 768px){
  .dealer-logo img {
    max-height: 150px;
    max-width: 100%;
    object-fit: contain;
  }

  .dealers-container {
    padding: 0 15px;
  }
}

@media (min-width: 769px) and (max-width: 900px) {
  .dealer-logo img {
    max-height: 225px;
    max-width: 100%;
    object-fit: contain;
  }
}

@media (max-width: 900px){
  .dealer-card .inventory {
    margin-top: 4px;
  }
}

@media screen and (min-width: 900px) {
  .skeleton-content {
    padding: 0;
    max-width: 650px;
  }

  .dealer-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -7px
  }

  .dealer-list::after {
    content: '';
    flex: 1 1 48%;
    margin: 0 7px 15px 7px;
  }

  .dealer-item {
    flex: 1 1 48%;
    margin: 0 7px 15px 7px;
    padding-bottom: 0;
    height: 170px;
  }

  .dealer-card {
    display: flex;
    align-items: stretch;
  }

  .dealer-logo {
    flex: 1 1 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: calc(100% - 335px);
    border-right: 1px solid #e6e6e6;
  }

  .dealer-logo img {
    max-width: 100%;
    max-height: 100%;
    border: 10px solid transparent;
    box-sizing: border-box;
  }

  .dealer-data {
    padding: 10px 10px 42px 15px;
    flex: 1 1 70%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .dealer-card .contact {
    position: absolute;
    bottom: 10px;
    right: 170px;
  }

  .dealer-card .inventory {
    position: absolute;
    bottom: 10px;
    right: 0;
  }
}
