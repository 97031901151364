.details-contact .contact-close {
  display: none;
}

.details-contact .contact-form {
  background: #EEEEEE;
  max-height: 0;
  overflow: hidden;
}

.details-contact .contact-form.open {
  max-height: 999px;
  margin: 1px;
}

.details-contact .contact-form .main-form {
  padding: 0 18px 16px;
}

.details-contact .contact-form .main-form .title {
  display: none;
}

.details-contact .contact-form .message-error,
.details-contact .contact-form .message-sent {
  padding: 8px 18px 18px;
}

.details-contact .contact-form .message-error p,
.details-contact .contact-form .message-sent p {
  font-size: 16px;
  line-height: 20px;
  color: #444444;
  margin-bottom: 10px;
}

.details-contact .contact-form .message-sent .title {
  background-color: #EEEEEE;
}

#oem-details-contact-form .main-form .message-error {
  text-align: left;
  padding-left: 0;
}

.details-contact .contact-form .message-error {
  color: #d9534f;
}

.details-contact .contact-form .message-error.hidden,
.details-contact .contact-form .message-sent.hidden ,
.details-contact .contact-form .main-form.hidden{
  display: none;
}

.details-contact .contact-form .form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.details-contact .contact-form .fieldset {
  position: relative;
  border: 0 none;
  padding: 0;
  margin: 0 0 10px;
  box-sizing: border-box;
  width: 100%;
}

.details-contact .contact-form .fieldset.hidden {
  display: none;
}

.details-contact .contact-form label {
  display: none;
}

.details-contact .contact-form input,
.details-contact .contact-form textarea {
  font-size: 14px;
  line-height: 22px;
  padding: 4px 14px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #BFC4C8;
  border-radius: 4px;
  color: #666;
  height: 48px;
}
.details-contact .contact-form textarea {
  min-height: 78px;
  padding: 8px 14px;
}

.details-contact .contact-form form.error .fieldset.error input,
.details-contact .contact-form form.error .fieldset.error textarea {
  border-color: #d9534f;
}

.details-contact .contact-form form.error .fieldset.error input::placeholder,
.details-contact .contact-form form.error .fieldset.error textarea::placeholder {
  color: #666;
}

.details-contact .contact-form .fieldset-name,
.details-contact .contact-form .fieldset-phone {
  width: 100%;
}

.details-contact .contact-form .btn-action {
  font-size: 16px;
  height: 44px;
  line-height: 22px;
  padding: 4px 13px 4px;
  font-weight: bold;
  text-transform: uppercase;
  border: 0 none;
  background: #e37a1f;
  box-shadow: none;
}

.details-contact .contact-form .btn-action:before {
  content: "";
  background: url(./Mail.svg) no-repeat;
  width: 21px;
  height: 16px;
  display: inline-block;
  margin: 0 10px -2px 0;
}

.success-alert, .error-alert {
  border: 1px solid #BDC3C7;
  border-radius: 5px;
  display: flex;
}

.success-alert::before {
  background-color: #5CB85C;
  background-image: url(../../icons/Check.svg);
  background-position: center;
  background-repeat: no-repeat;
  content: '';
  width: 60px;
}

.error-alert::before {
  background-color: #D63924;
  background-image: url(../../icons/Error.svg);
  background-position: center;
  background-repeat: no-repeat;
  content: '';
  width: 60px;
}

.message-box {
  background-color: #FFF;
  border-radius: 5px;
  margin: 0;
  padding: 0 18px 0;
  width: 100%;
}

.message-title {
  font-weight: bold;
}

.boat-listings .message-box {
  margin-top: 16px;
}
