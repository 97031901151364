/* BASE font-size: 15px */

/* Desktop */
.accordion {
  margin: 2.3rem auto 0;
  max-width: 64rem;
  background-color: #fff;
}

.accordion__title {
  font-style: normal;
  font-weight: 800;
}

.accordion-section-title {
  color: var(--bt-color-neutral-90, #404040);
  text-align: center;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px; /* 100% */
  letter-spacing: -0.36px;
}

/* Mobile */
@media only screen and (max-width: 991px) {
  .accordion {
    margin-top: 3.833rem;
  }
  .accordion__title {
    padding-left: 1.6rem;
  }

  .accordion__button:after {
    margin-right: 1.6rem;
  }

}
