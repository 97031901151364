ol.boat-list {
  margin: 12px 0;
  padding: 0;
  list-style: none;
}

ol.boat-list:after {
  display:table;
  content:"";
  clear:both;
}

div.alert {
  margin-bottom: 10px;
}

ol.boat-list .server-ad {
  position: relative;
  justify-content: center;
  margin-bottom: 16px;
}

ol.boat-list .server-ad .ad-image {
  position: relative;
}

ol.boat-list .tablet-ads-wrapper.lib-card.wrapper-card--ad {
  min-height: 350px;
  display: none;
  margin-left: 10px;
  margin-right: 0;
}

ol.boat-list .desktop-ads-wrapper.lib-card.wrapper-card--ad {
  position: relative;
  min-height: 355px;
  max-height: 355px;
  width: calc(32.7% - 5px);
  display: none;
  margin-left: 10px;
}

ol.boat-list .desktop-ads-wrapper.wrapper-large-bottom--ad {
  position: relative;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 1px rgba(0,0,0,.2);
  min-height: 90px;
  width: calc(100% - 10px);
  margin-left: 10px;
  margin-bottom: 15px;
  display: none;
  padding-top: 16px;
}

/* Native Ads */
ol.boat-list .desktop-ads-wrapper.lib-card.wrapper-card--ad:has(.native-ad),
ol.boat-list .tablet-ads-wrapper.lib-card.wrapper-card--ad:has(.native-ad) {
  padding: 0;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  max-height: 355px;
}
ol.boat-list .desktop-ads-wrapper.lib-card.wrapper-card--ad:has(.native-ad) [class*="image-wrapper"],
ol.boat-list .tablet-ads-wrapper.lib-card.wrapper-card--ad:has(.native-ad) [class*="image-wrapper"] {
  width: 100%;
  height: 355px;
}
ol.boat-list .desktop-ads-wrapper.lib-card.wrapper-card--ad:has(.native-ad) .native-ad,
ol.boat-list .tablet-ads-wrapper.lib-card.wrapper-card--ad:has(.native-ad) .native-ad {
  max-width: 100% !important;
  max-height: 100% !important;
  min-width: 100%;
  min-height: 100%;
}
.tablet-ads-wrapper.lib-card.wrapper-card--ad:not(:has(.native-ad)) {
  padding: 38px 0;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  background: #FFFFFF;
  min-height: 355px !important;
  max-height: 355px;
}

/* Default Ads */
ol.boat-list .desktop-ads-wrapper.lib-card.wrapper-card--ad:not(:has(.native-ad)):not(:has(iframe)) {
  padding: 0;
}
ol.boat-list .desktop-ads-wrapper.lib-card.wrapper-card--ad:not(:has(.native-ad)) {
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  background: #FFFFFF;
}
ol.boat-list .desktop-ads-wrapper.lib-card.wrapper-card--ad:not(:has(.native-ad)) [class*="image-wrapper"] {
  width: 100%;
  height: 355px;
}
ol.boat-list .desktop-ads-wrapper.lib-card.wrapper-card--ad:not(:has(.native-ad)) [class*="image-wrapper"] > a:first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* -- MOBILE --*/
@media screen and (min-width: 340px) and (max-width: 767px){
  ol.boat-list {
    margin: 10px !important;
  }
}

@media screen and (max-width: 339px){
  ol.boat-list {
    margin: 10px 2px !important;
    overflow: hidden;
  }
}

ol.boat-list > .lib-card.lib-card--ad {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 1px rgba(0,0,0,.2);
  font-size: 0;
  padding: 43px 0;
  text-align: center;
  height: 350px;
}
ol.boat-list > .wrapper-card--ad {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-sizing: border-box;
  filter: drop-shadow(0 2px 1px rgba(0, 0, 0, .2));
  font-size: 0;
  padding: 43px 0;
  text-align: center;
  min-height: 350px;
}

ol.boat-list.loading > .lib-card {
  height: 350px;
}
ol.boat-list.loading > .lib-card .inner {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-sizing: border-box;
  filter: drop-shadow(0 2px 1px rgba(0,0,0,.2));
  transform: translateZ(0);
  height: 100%;
}
ol.boat-list.loading > .lib-card .inner .image-container {
  min-width: 100%;
}

ol.boat-list > .lib-card {
  vertical-align: top;
}

@media screen and (max-width: 969px) {
  ol.boat-list > .lib-card {
    border: none;
  }
}

@media screen and (min-width: 970px) {
  ol.boat-list > .lib-card {
    border: none;
    width: calc(50% - 5px);
    display: inline-block;
    margin-right: 0;
    vertical-align: top;
  }
  ol.boat-list > .lib-card + .lib-card:nth-child(odd) {
    margin-left: 10px;
  }
  ol.boat-list > .oem-listing + .lib-card + .lib-card {
    margin-left: 10px;
  }
  ol.boat-list > .desktop-liner-ad + li + .lib-card + .lib-card {
    margin-left: 10px;
  }
  ol.boat-list > .oem-listing ~ .desktop-liner-ad + li + .lib-card + .lib-card ~ .lib-card:nth-child(odd) {
    margin-right: 10px;
    margin-left: 0;
  }
  ol.boat-list > .oem-listing ~ .desktop-liner-ad ~ .desktop-liner-ad + li + .lib-card + .lib-card {
    margin-left: 0;
  }
}

ol.boat-list .oem-listing {
  margin-left: 10px;
  width: calc(100% - 10px);
}

@media screen and (min-width: 1321px) {
  ol.boat-list .desktop-ads-wrapper.lib-card.wrapper-card--ad {
    display: inline-block;
  }

  ol.boat-list .desktop-ads-wrapper.wrapper-large-bottom--ad {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 1320px){
  ol.boat-list > .lib-card.lib-card--ad {
    width: calc(50% - 12px);
    margin-bottom: 15px;
  }

  ol.boat-list .tablet-ads-wrapper.lib-card.wrapper-card--ad {
    width: calc(50% - 12px);
    margin-bottom: 15px;
    display: inline-block;
  }

}

#main-content ol.boat-list,
#main-content .sponsored-boats {
  margin-left: -10px !important;
  margin-right: 0 !important;
}

#main-content ol.boat-list > li.lib-card,
#main-content .sponsored-boats > ul > li {
  width: calc(32% - 5px);
  margin-left: 10px;
  margin-right: 0;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  #main-content ol.boat-list > li.lib-card,
  #main-content .sponsored-boats > ul > li {
    width: 100%;
    margin: 0 0 15px 0;
  }
  #main-content ol.boat-list,
  #main-content .sponsored-boats {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  #main-content ol.boat-list > li.lib-card,
  #main-content .sponsored-boats > ul > li {
    width: calc(32.6% - 5px);
  }
}

@media screen and (min-width: 970px) {
  #main-content ol.boat-list > li.lib-card,
  #main-content .sponsored-boats > ul > li {
    width: calc(32.4% - 5px);
  }
}

@media screen and (min-width: 1330px) and (max-width: 1439px) {
  #main-content ol.boat-list > li.lib-card,
  #main-content .sponsored-boats > ul > li {
    width: calc(32.7% - 5px);
  }
}

@media screen and (min-width: 1440px) {
  #main-content ol.boat-list > li.lib-card,
  #main-content .sponsored-boats > ul > li {
    width: calc(32.8% - 5px);
  }
}
