.boat-types-container {
  min-height: 800px;
  padding: 0 1rem;
}

.boat-types-container .boat-type-content {
  max-width: 1430px;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.boat-types-container .boat-type-content p {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
}

.boat-types-container .boat-type-list {
  box-sizing: border-box;
  margin: 0;
  display: grid;
  column-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  padding: 1rem;
}
.boat-types-container .boat-type-list {
  display: block;
}
.boat-types-container .boat-type-list .boat-type {
  height: 320px;
  width: 100%;
  margin-bottom: 16px;
}

.boat-types-listings .modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.boat-types-listings section.boat-type-list {
  padding: 0;
}

.boat-types-listings section > div {
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .boat-types-container {
    margin: 0 auto;
    background-size: cover;
    background-position: center;
    display: block;
    padding: 0 1rem;
  }
  .boat-types-container .boat-type-list {
    box-sizing: border-box;
    margin: 0;
    display: grid;
    column-gap: 16px;
    row-gap: 16px;
    grid-template-columns: repeat(3, 1fr);
    padding: 1rem 0;
  }
  .boat-types-container .boat-type-list .boat-type {
    margin-bottom: 0;
  }
}

/*# sourceMappingURL=styles.css.map */
