.related__articles__container {
  display: flex;
  flex-direction: column;
}

.related__articles__wrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 3%;
}

.related__article__item_image_wrapper {
  display: flex;
  justify-content: center;
  background-color: lightgrey;
}

.related__article__item_image_wrapper a {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.related__article__item_image_wrapper img {
  width: 100%;
  object-fit: cover;
}

.related__article__item p {
  margin: 2px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  color: #000000;
}

.related__article__item a {
  text-decoration: none;
  color: inherit;
}

.related__article__item {
  width: 100%;
  margin: auto;
}

.related__article__item_image_wrapper {
  height: 50%;
}

.related__article__item h4 {
  margin: 2px 0;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 14px;
  color: #000000;
  height: 30px;
}

.related__articles__wrapper > div:not(:first-child) {
  display: none;
}

/* iPads / Tablets */
@media screen and (min-width: 481px) {
  .related__articles__wrapper > div:not(:first-child) {
    display: initial;
  }
  .related__article__item {
    width: 31.3%;
    margin: 0;
  }

  .related__article__item_image_wrapper {
    width: auto;
    height: 70%;
  }

  .related__article__item h4 {
    font-size: 10px;
  }
}

/* Small screens, Laptops */
@media screen and (min-width: 769px) {
  .related__article__item h4 {
    font-size: 12px;
  }
}
