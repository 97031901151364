body {
    margin: 0;
    font-size: 15px;
    line-height: 1.3em;
    color: #333;
    height: 100%;
}

.alert {
    border: 1px solid #efc939;
    line-height: 2em;
    display: flex;
    background: #fff;
}

.alert .icon {
    background-color:#efc939;
    background-image: url(../../../icons/alert.svg);
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: 50% 20%;
    float: left;
    width: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 50px;
}

.alert .text {
    padding: 5px;
    float: left;
}

.alert .bold {
    font-weight: bold;
}

.boat-listings:after{
    display: table;
    content: "";
    clear: both;
}

.content-head {
    margin-left: auto;
    margin-right: auto;
    max-width: 1133px;
}

.content-head h1 {
    margin: 0;
    padding: 3px 0 0 9px;
    color: #0B3F65;
    font-size: 20pt;
    line-height: 1.2;
}

.content-head p {
  margin: 0;
  padding: 3px 0 0 10px;
}

.content-head .results-header {
  margin-left: 10px;
}

#main-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1133px;
    background-image: linear-gradient(#EAEAEA, var(--bt-color-neutral-10) 280px);
}


#main-content.branded {
  background-image: none;
  background-color: white;
}

.sticky {
    position: fixed;
    top: 40px;
    margin-left: auto;
    z-index: 100;
    height: auto;
    background: #fff;
}

.sticky-ads {
    position: -webkit-sticky;
    position: sticky;
    top : 41px;
    z-index: 100;
    height: auto;
    background: #fff;
}

.sticky-leaderboard {
    z-index: 99;
}

.sticky-leaderboard > div {
    position: fixed;
    top: 0;
    z-index: 100;
    transition: top 200ms linear;
    width: 100% !important;
    left: 0;
    text-align: center;
    background: #fff;
    border-top: solid 8px #fff !important;
    border-bottom: solid 8px #fff !important;
    max-height: 50px;
}

.boat-list > li {
    z-index: 98;
    position: relative;
}

.sticky-leaderboard.header-present > div {
    top: 51px;
}

.box-holder {
  width: 300px;
  min-height: 250px;
  background-color: #F7F7F7;
}

.mobile-box-holder {
  width: 300px;
  height: 250px;
  margin-right: auto;
  margin-left: auto;
  background-color: #F7F7F7;
}

.calc-disclosure.mobile {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #616161;
  margin-left: 12px;
  margin-right: 7px;
}

@media screen and (min-width: 768px) {
    #main-content {
        padding: 0 20px;
    }

    .main-content-for-two-column {
      padding: 0 15% !important;
  }

    .search-results {
        max-width: 728px;
    }
}

@media screen and (max-width: 969px){
  #right-ad {
    display: none
  }
}

@media screen and (min-width: 970px) {
    .content-head h1 {
        padding: 12px 15px;
        color: #404040;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
    }

    .content-head p {
      padding: 0 0 16px 16px;
    }

    #main-content {
        display: flex;
        box-sizing: border-box;
        gap: 25.5px;
    }

    #main-content #right-content {
        flex-grow: 1;
        width: calc(100% - 350px);
    }

    #main-content #left-content {
        flex-shrink: 0;
        max-width: 300px;
    }

    #sort.sort .inner  {
      align-items: center;
      display: flex;
    }
}

@media screen and (max-width: 1439px){
  #far-right-content{
    display: none
  }
}

@media screen and (min-width: 1440px){
    .content-head{
      max-width: 1440px;
    }
    #main-content{
      max-width: 1440px;
      background: white;
    }

    #far-right-content{
      padding-top:16px;
      margin-right:16px;
      min-width:300px;
    }
    footer{
      display:inline-block;
      width: 100%;
    }
    footer.main-footer{
      padding:32px 0px 16px;
    }
    footer.main-footer .logo {
      margin: 3px 16px -5px;
    }
  }

/* --- ANA-1504 --- */
@media screen and (min-width: 970px){

  /* -- left content -- */
  .alternate #main-content #left-content #refine-search {
    box-shadow: 0 2px #0000000a, 0 5px 10px #00000014;
  }

  /* -- right content -- */
  .alternate #main-content #right-content {
    padding-top: 0;
  }

  /* -- sponsored boats -- */
  .alternate .sponsored-boats {
    padding-left: 5px;
    padding-right: 5px;
  }

  .alternate .sponsored-boats .boat-image {
    border-right: 1px solid #eaeaea;
    box-sizing: border-box;
  }

  .alternate .sponsored-boats li[data-listing-id] {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px #0000000a, 0 5px 10px #00000014;
    border: none;
  }

    /* -- image -- */
    .alternate .sponsored-boats li[data-listing-id] .boat-image {
      height: 119px;
    }

    .alternate .sponsored-boats li[data-listing-id] .heart {
      width: 24px;
      height: 24px;
    }

    /* -- information -- */
    .alternate .sponsored-boats li[data-listing-id] .information {
      height: 99px;
    }

    .alternate .sponsored-boats li[data-listing-id] .information .make-model {
      font-size: 14px;
      line-height: 14px;
      font-weight: normal;
      margin-bottom: 8px;
    }

    .alternate .sponsored-boats li[data-listing-id] .information .price.alternate {
      font-weight: 900;
      line-height: 18px;
      font-size: 18px;
      color: #07324f;
      margin-bottom: 13px;
      display: block;
    }

    .alternate .sponsored-boats li[data-listing-id] .information .price:not(.alternate) {
      display: none;
    }

    .alternate .sponsored-boats li[data-listing-id] .information > .location,
    .alternate .sponsored-boats li[data-listing-id] .information .seller-name {
      color: #666666;
      font-size: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 16px;
    }

    .alternate .sponsored-boats li[data-listing-id] .information > div > span:first-of-type {
      min-width: 18px;
      height: 18px;
      margin-right: 4px;
      margin-bottom: 0;
      display: inline-block;
    }

    .alternate .sponsored-boats li[data-listing-id] .information > div > span:last-of-type {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .alternate .sponsored-boats li[data-listing-id] .information > .location .location {
      background-image: url(../../../icons/place.svg);
    }
    .alternate .sponsored-boats li[data-listing-id] .information .seller-name .broker {
      background-image: url(../../../icons/anchor.svg);
    }

  /* -- results header -- */
  .alternate .results-header {
    padding-left: 5px;
  }

  .alternate .results-header #sort .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* -- contact form --*/
  ol.boat-list > li.boat-list-modal {
    position: unset;
  }

  .alternate ol.boat-list li[data-listing-id] #backgroundModal {
    display: none;
  }

  .alternate ol.boat-list > #backgroundModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    animation: backgroundFadeIn .2s ease-out forwards;
  }

  .alternate ol.boat-list #backgroundModal #results-contact-form {
    position: relative;
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    width: 420px;
    background-color: var(--bt-color-neutral-30);
    transform: translateY(150%) scale(0);
    animation: scaleIn .15s ease-out .2s forwards;
    border-radius: 16px;
  }

  .alternate ol.boat-list #backgroundModal #results-contact-form .seller-info-details {
    padding: 0 0 18px 0;
  }

  .alternate ol.boat-list #backgroundModal #results-contact-form .seller-info-details .seller-info-tel:empty {
    margin: 0;
  }

  .alternate ol.boat-list #backgroundModal #results-contact-form .message-box {
    margin-top: 0;
  }

  .alternate ol.boat-list #backgroundModal #results-contact-form .contact-close {
    position: absolute;
    right: 0;
    margin: 0 0 0 -20px;
    top: -60px;
    background: transparent;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    border: 2px solid #ccc;
    cursor: pointer;
    opacity: 0;
    animation: scaleIn .2s ease-out .7s forwards;
  }

  ol.boat-list #backgroundModal #results-contact-form .contact-close img {
    display: none;
  }
  .alternate ol.boat-list #backgroundModal #results-contact-form .contact-close img {
    display: block;
  }

  .alternate ol.boat-list #backgroundModal #results-contact-form .main-form {
    padding: 0 28px 18px 28px;
  }

  .alternate ol.boat-list #backgroundModal #results-contact-form .title {
    color: #07324f;
    font-size: 24px;
    line-height: 18px;
    font-weight: 700;
    padding-top: 18px;
    padding-bottom: 18px;
    margin: 0;
  }

  .alternate ol.boat-list #backgroundModal #results-contact-form input {
    font-size: 14px;
    line-height: 22px;
    padding: 4px 14px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #bfc4c8;
    border-radius: 4px;
    color: #666;
    height: 48px;
  }

  .alternate ol.boat-list #backgroundModal #results-contact-form .btn-action {
    border: none;
    box-shadow: none;
    font-size: 16px;
    height: 44px;
    line-height: 22px;
    padding: 4px 13px;
    font-weight: 700;
    text-transform: uppercase;
    background: #e37a1f;
  }

  .alternate ol.boat-list #backgroundModal #results-contact-form .btn-action:before {
    content: "";
    background: url(//boattrader.com/static/media/Mail.ecb7c66e.svg) no-repeat;
    width: 21px;
    height: 16px;
    display: inline-block;
    margin: 0 10px -2px;
  }

  .alternate ol.boat-list #backgroundModal #results-contact-form:after {
    display: none;
  }

  /* -- SRP list -- */

  .alternate ol.boat-list,
  .alternate ol.boat-list.loading {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .alternate ol.boat-list li[data-listing-id],
  .alternate ol.boat-list.loading li {
    height: 470px;
    width: calc(50% - 10px);
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px #0000000a, 0 5px 10px #00000014;
    border: none;
  }

  .alternate ol.boat-list.loading li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .alternate ol.boat-list li[data-listing-id]:hover,
  .alternate ol.boat-list li[data-listing-id]:active {
    box-shadow: 0 4px #0000000a, 0 7px 10px #00000014;
    transform: translateY(-2px);
  }

  .alternate ol.boat-list li[data-listing-id] #backgroundModal {
    display: none;
  }

  .alternate ol.boat-list.loading li .inner {
    width: 100px;
    height: 100px;
    align-items: center;
    justify-content: center;
  }

  .alternate ol.boat-list li.mobile-liner-ad,
  .alternate ol.boat-list li.mobile-native-ad {
    display: none;
  }

    /* -- cards -- */
    .alternate ol.boat-list li[data-listing-id] > div,
    .alternate ol.boat-list li[data-listing-id] .inner,
    .alternate ol.boat-list li[data-listing-id] > a {
      height: 100%;
    }

    .alternate ol.boat-list li[data-listing-id] > a {
      display: block;
    }

    .alternate ol.boat-list li[data-listing-id] #backgroundModal #results-contact-form {
      top: 40px;
      right: 32px;
      box-shadow: none;
    }

    .alternate ol.boat-list li[data-listing-id] > div > .banner,
    .alternate .available-banner ol.boat-list li[data-listing-id] > div > .banner {
      display: none;
    }

      /* -- image -- */
      .alternate ol.boat-list li[data-listing-id] .inner,
      .alternate ol.boat-list li[data-listing-id].premium .inner {
        flex-direction: column;
      }

      .alternate ol.boat-list li[data-listing-id] .pp_container {
        flex-direction: column-reverse;
        border: none;
        height: 100%;
      }

      .alternate ol.boat-list li[data-listing-id] .image-container,
      .alternate ol.boat-list li[data-listing-id].premium .image-container,
      .alternate ol.boat-list li[data-listing-id] .pp_boat_img {
        width: 100%;
        max-width: 100%;
        max-height: 234px;
        height: initial;
        position: relative;
        overflow: hidden;
        text-align: center;
        flex-grow: 0;
        flex-shrink: 0;
        border-bottom: 1px solid #eaeaea;
        box-sizing: border-box;
      }

      .alternate ol.boat-list li[data-listing-id] .heart {
        width: 24px;
        height: 24px;
        right: 10px;
        left: initial;
      }

      .alternate ol.boat-list li[data-listing-id] .price-cut {
        display: none;
      }

      /* -- description -- */
      .alternate ol.boat-list li[data-listing-id] .description,
      .alternate ol.boat-list li[data-listing-id].premium .description,
      .alternate ol.boat-list li[data-listing-id] .pp_boat_info {
        width: 100%;
        box-sizing: border-box;
        text-align: left;
        min-height: 234px;
        color: #bdc3c7;
        position: relative;
        line-height: 1;
        font-style: normal;
        font-weight: 400;
        padding: 10px;
        margin: 0;
        display: flex;
        flex-direction: column;
      }

        /* -- top -- */
        .alternate ol.boat-list li[data-listing-id] .description .top,
        .alternate ol.boat-list li[data-listing-id].premium .description .top,
        .alternate ol.boat-list li[data-listing-id] .pp_boat_tp {
          float: none;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #eaeaea;
        }

        .alternate ol.boat-list li[data-listing-id] .description .top .fire-icon.show {
          display: flex;
          flex-direction: row;
          align-items: center;
          position: absolute;
          top: 10px;
          right: 10px;
        }
        .alternate ol.boat-list li[data-listing-id] .description .top .fire-icon .fire {
          display: block;
          margin-right: 4px;
          width: 12px;
          height: 16px;
          background-image: url(../../../icons/flame.svg);
        }
        .alternate ol.boat-list li[data-listing-id] .description .top .fire-icon .fire-text {
          font-size: 10px;
          color: #07324F;
        }

        .alternate ol.boat-list li[data-listing-id] .description .top .banner {
          display: inline-block;
          font-size: 10px;
          line-height: 10px;
          height: initial;
          padding: 5px 9px;
          border-radius: 4px;
          margin-bottom: 10px;
        }

        .alternate ol.boat-list li[data-listing-id] .description .top .name,
        .alternate ol.boat-list li[data-listing-id].premium .description .top .name,
        .alternate ol.boat-list li[data-listing-id] .pp_boat_title {
          font-size: 14px;
          float: none;
          width: 100%;
          max-width: 75%;
          max-height: 100%;
          flex: initial;
          font-weight: normal;
          line-height: 14px;
          margin-bottom: 8px;
        }

        .alternate ol.boat-list li[data-listing-id] .description .top .name a,
        .alternate ol.boat-list li[data-listing-id].premium .description .top .name a,
        .alternate ol.boat-list li[data-listing-id] .pp_boat_title span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
        }

        .alternate ol.boat-list li[data-listing-id] .description .top .prices,
        .alternate ol.boat-list li[data-listing-id] .pp_boat_price {
          display: block;
          width: 100%;
          height: 18px;
          flex: initial;
        }

        .alternate ol.boat-list li[data-listing-id] .description .top .prices .price,
        .alternate ol.boat-list li[data-listing-id].premium .description .top .prices .price,
        .alternate ol.boat-list li[data-listing-id] .pp_boat_price span {
          color: #07324F;
          float: none;
          text-align: left;
          flex: initial;
          font-weight: 900;
          line-height: 18px;
          font-size: 18px;
        }

        .alternate ol.boat-list li[data-listing-id] .description .top .prices .price.alternate,
        .alternate ol.boat-list li[data-listing-id].premium .description .top .prices .price.alternate,
        .alternate ol.boat-list li[data-listing-id] .pp_boat_price span {
          display: block;
          margin-bottom: 0;
        }

        .alternate ol.boat-list li[data-listing-id] .description .top .prices .price.alternate .priceDiscount {
          font-weight: 700;
          text-decoration: line-through;
          margin-right: 8px;
        }

        .alternate ol.boat-list li[data-listing-id] .description .top .prices .price.alternate .discount {
          color: #269726;
        }

        .alternate ol.boat-list li[data-listing-id] .description .top .prices .price:not(.alternate),
        .alternate ol.boat-list li[data-listing-id].premium .description .top .prices .price:not(.alternate),
        .alternate ol.boat-list li[data-listing-id] .description .top .prices .delivery,
        .alternate ol.boat-list li[data-listing-id] .description .top .location,
        .alternate ol.boat-list li[data-listing-id] .pp_sb_dealer {
          display: none;
        }

        /* -- center --*/
        .alternate ol.boat-list li[data-listing-id] .description .center,
        .alternate ol.boat-list li[data-listing-id] .pp_boat_details {
          flex-grow: 1;
          padding-bottom: 10px;
          display: block;
        }

        .alternate ol.boat-list li[data-listing-id] .pp_boat_details p {
          color: #666666;
          font-size: 12px;
        }

        .alternate ol.boat-list li[data-listing-id] .pp_boat_details p:after {
          display: none;
        }

        .alternate ol.boat-list li[data-listing-id] .description .center > div {
          color: #666666;
          font-size: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 20px;
        }

        .alternate ol.boat-list li[data-listing-id] .description .center span {
          width: 18px;
          height: 18px;
          margin-right: 8px;
          display: inline-block;
        }

        .alternate ol.boat-list li[data-listing-id] .description .center .delivery {
          background-image: url(../../../icons/local_shipping.svg);
        }
        .alternate ol.boat-list li[data-listing-id] .description .center .video {
          background-image: url(../../../icons/live_tour_video.svg);
        }
        .alternate ol.boat-list li[data-listing-id] .description .center .location {
          background-image: url(../../../icons/place.svg);
        }
        .alternate ol.boat-list li[data-listing-id] .description .center .broker {
          background-image: url(../../../icons/anchor.svg);
        }

        /* -- bottom -- */
        ol.boat-list li[data-listing-id] .dealer-logo {
          display: contents;
        }

        .alternate ol.boat-list li[data-listing-id] .description .bottom,
        .alternate ol.boat-list li[data-listing-id] .pp_boat_btns {
          flex-grow: 0;
          justify-content: space-between;
          height: 32px;
        }

        .alternate ol.boat-list li[data-listing-id] .description .bottom .offered-by-alternate,
        .alternate ol.boat-list li[data-listing-id] .pp_dealer_logo {
          display: block;
          height: 100%;
        }
        .alternate ol.boat-list li[data-listing-id] .description .bottom .offered-by-alternate a,
        .alternate ol.boat-list li[data-listing-id] .pp_dealer_logo span {
          flex: initial;
          flex-direction: column;
          max-width: 100%;
          padding: 0 60px 0 0;
          justify-content: center;
          align-items: flex-start;
          height: 100%;
          border: none;
          display: flex;
        }
        .alternate ol.boat-list li[data-listing-id] .description .bottom .offered-by-alternate a img,
        .alternate ol.boat-list li[data-listing-id] .pp_dealer_logo span img {
          border: none;
          width: initial;
        }

        .alternate ol.boat-list li[data-listing-id] .pp_dealer_logo span img {
          height: 100%;
        }

        .alternate ol.boat-list li[data-listing-id] .description .bottom .contact,
        .alternate ol.boat-list li[data-listing-id] .pp_contact {
          margin: 0;
          padding: 0;
          width: initial;
        }

        .alternate ol.boat-list li[data-listing-id] .description .bottom .contact a,
        .alternate ol.boat-list li[data-listing-id] .description .bottom .contact a.contact-seller-alt + a,
        .alternate ol.boat-list li[data-listing-id] .pp_contact_btn {
          position: initial;
          margin: 0;
          width: 116px;
          text-align: center;
          text-transform: none;
          font-size: 14px;
          line-height: 16px;
          font-weight: 700;
          color: #fff;
          padding-top: 8px;
          padding-bottom: 8px;
          background: #e37a1f;
          border-radius: 4px;
          z-index: 4;
          cursor: pointer;
          display: block;
        }

        .alternate ol.boat-list li[data-listing-id] .description .bottom a.contact-seller-alt,
        .alternate ol.boat-list li[data-listing-id] .description .bottom .offered-by {
          display: none;
        }
}

/* --- ANA-1596 - contact form --*/
div.two-column-boat-list .contact-form-container-default#backgroundModal,
ol.boat-list .contact-form-container-default#backgroundModal,
#sponsored-boats #backgroundModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation: backgroundFadeIn .2s ease-out forwards;
}

div.two-column-boat-list .contact-form-container-default#backgroundModal #results-contact-form,
ol.boat-list .contact-form-container-default#backgroundModal #results-contact-form,
#sponsored-boats #backgroundModal #results-contact-form {
  position: relative;
  top: initial;
  left: initial;
  right: initial;
  bottom: initial;
  width: 90%;
  max-width: 420px;
  background-color: var(--bt-color-neutral-30);
  transform: translateY(150%) scale(0);
  animation: scaleIn .15s ease-out .2s forwards;
  border-radius: 16px;
}

div.two-column-boat-list .contact-form-container-default#backgroundModal #results-contact-form .seller-info-details,
ol.boat-list .contact-form-container-default#backgroundModal #results-contact-form .seller-info-details,
#sponsored-boats #backgroundModal #results-contact-form .seller-info-details {
  padding: 0 0 18px 0;
}

div.two-column-boat-list .contact-form-container-default#backgroundModal #results-contact-form .seller-info-details .seller-info-tel:empty,
ol.boat-list .contact-form-container-default#backgroundModal #results-contact-form .seller-info-details .seller-info-tel:empty,
#sponsored-boats #backgroundModal #results-contact-form .seller-info-details .seller-info-tel:empty {
  margin: 0;
}

.alternate div.two-column-boat-list #backgroundModal #results-contact-form .message-box,
.alternate ol.boat-list #backgroundModal #results-contact-form .message-box {
  margin-top: 0;
}

div.two-column-boat-list .contact-form-container-default#backgroundModal #results-contact-form .contact-close,
ol.boat-list .contact-form-container-default#backgroundModal #results-contact-form .contact-close,
#sponsored-boats #backgroundModal #results-contact-form .contact-close {
  position: absolute;
  right: 16px;
  top: 16px;
  background: transparent;
  width: 22px;
  height: 22px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  filter: brightness(0);
  margin: 0;
}

div.two-column-boat-list .contact-form-container-default#backgroundModal #results-contact-form .contact-close img,
ol.boat-list .contact-form-container-default#backgroundModal #results-contact-form .contact-close img,
#sponsored-boats #backgroundModal #results-contact-form .contact-close img {
  display: none;
}

div.two-column-boat-list .contact-form-container-default#backgroundModal #results-contact-form .contact-close img,
ol.boat-list .contact-form-container-default#backgroundModal #results-contact-form .contact-close img,
#sponsored-boats #backgroundModal #results-contact-form .contact-close img {
  display: block;
}

.alternate div.two-column-boat-list #backgroundModal #results-contact-form .main-form,
.alternate ol.boat-list #backgroundModal #results-contact-form .main-form,
.alternate #sponsored-boats #backgroundModal #results-contact-form .main-form {
  padding: 0 28px 18px 28px;
}

div.two-column-boat-list .contact-form-container-default#backgroundModal #results-contact-form .title,
ol.boat-list .contact-form-container-default#backgroundModal #results-contact-form .title,
#sponsored-boats #backgroundModal #results-contact-form .title {
  color: #07324f;
  font-size: 24px;
  line-height: 18px;
  font-weight: 700;
  padding-top: 4px;
  padding-bottom: 18px;
  margin: 0;
}

div.two-column-boat-list .contact-form-container-default#backgroundModal #results-contact-form input,
ol.boat-list .contact-form-container-default#backgroundModal #results-contact-form input,
#sponsored-boats #backgroundModal #results-contact-form input {
  font-size: 14px;
  line-height: 22px;
  padding: 4px 14px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #bfc4c8;
  border-radius: 4px;
  color: #666;
  height: 48px;
}

div.two-column-boat-list .contact-form-container-default#backgroundModal #results-contact-form .btn-action,
ol.boat-list .contact-form-container-default#backgroundModal #results-contact-form .btn-action,
#sponsored-boats #backgroundModal #results-contact-form .btn-action {
  border: none;
  box-shadow: none;
  font-size: 16px;
  height: 44px;
  line-height: 22px;
  padding: 4px 13px;
  font-weight: 700;
  text-transform: uppercase;
  background: #e37a1f;
}

div.two-column-boat-list .contact-form-container-default#backgroundModal #results-contact-form .btn-action:before,
boat-list-modal .contact-form-container-default#backgroundModal #results-contact-form .btn-action:before,
#sponsored-boats #backgroundModal #results-contact-form .btn-action:before {
  content: "";
  background: url(//boattrader.com/static/media/Mail.ecb7c66e.svg) no-repeat;
  width: 21px;
  height: 16px;
  display: inline-block;
  margin: 0 10px -2px;
}

div.two-column-boat-list .contact-form-container-default#backgroundModal #results-contact-form:after,
ol.boat-list .contact-form-container-default#backgroundModal #results-contact-form:after,
#sponsored-boats #backgroundModal #results-contact-form:after {
  display: none;
}

div.two-column-boat-list .contact-form-container-default#backgroundModal .seller-info-tel,
ol.boat-list .contact-form-container-default#backgroundModal .seller-info-tel {
  display: none;
}

div.two-column-boat-list .contact-form-container-default#backgroundModal .message-box,
ol.boat-list .contact-form-container-default#backgroundModal .message-box {
  margin-top: 0;
}

div.two-column-boat-list .contact-form-container-default#backgroundModal #results-contact-form .message-sent,
ol.boat-list .contact-form-container-default#backgroundModal #results-contact-form .message-sent {
  padding: 8px;
}

@media screen and (max-width: 969px){
  .boat-list.loading.two-column {
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 12px -9px 0 -9px;
  }

  .boat-list.loading.two-column li {
    max-width: calc(50% - 8px);
    display: flex;
    flex-direction: column;
    margin: 4px;
    border-radius: 4px;
    overflow: hidden;
    filter: drop-shadow(0 2px 1px rgba(0,0,0,.2));
    transform: translateZ(0);
  }

  .boat-list.loading.two-column li .inner,
  .boat-list.loading.two-column li .image-container {
    width: 100%;
    height: 100%
  }

  ol.two-column {
    columns: 2;
  }

  ol.two-column > li {
    height: 283px;
  }
}

@media screen and (max-width: 969px) {
  .content-head h1 {
    font-weight: 500;
    line-height: 28px;
    font-size: 18px;
  }

  .sticky-leaderboard>div {
    top: 44px;
  }
  .sticky-leaderboard.header-present>div {
    top: 95px;
}
}

#ai-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
#ai-search label {
  width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 15px;
  color: #0b3f65;
}
#ai-search label i {
  font-weight: normal;
  font-size: 10px;
  color: #333;
}
#ai-search input {
  border: 1px solid #e0e0e0;
  height: 40px;
  font-size: 14px;
  padding: 8px;
  box-shadow: inset 0 2px 3px rgba(0,0,0,.1);
  border-radius: 4px;
  color: #404040;
  box-sizing: border-box;
  flex-grow: 1;
}

#ai-search input::placeholder {
  color: #404040;
  opacity: 1;
}

#ai-search input::-ms-input-placeholder {
  color: #404040;
}

#ai-search button {
  display: block;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  text-rendering: geometricprecision;
  cursor: pointer;
  outline: 0;
  width: 100%;
  border-radius: 5px;
  height: 40px;
  background: #fff;
  color: var(--bt-color-primary);
  border: 1px solid var(--bt-color-primary);
  width: 270px;
  margin-left: 20px;
}
#ai-search .ai-suggestions {
  border: 1px solid var(--bt-color-neutral-30);
  border-radius: 4px;
  padding: 0;
  color: #616161;
  background: #f5f5f5;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  min-height: 0;
  height: auto;
  animation: growAI 250ms ease-out forwards;
  transition: height 25ms ease-out;
}
@keyframes growAI {
  0% {
    height: 0;
	}
	100% {
    min-height: 66px;
    height: auto;
    padding: 8px;
	}
}
#ai-search .ai-suggestions img {
  margin: 0 16px 0 8px;
  max-width: 50px;
  transform: translateX(-66px);
  animation: slideAIImage 250ms 250ms ease-out forwards;
}
@keyframes slideAIImage {
  0% {
    transform: translateX(-66px);
	}
	100% {
    transform: translateX(0);
	}
}
#ai-search .ai-suggestions .ai-loading {
  width: 0;
  height: 50px;
  background: linear-gradient(90deg, #eeeeee, #d0d0d0);
  animation: pulse 1.5s ease infinite;
  animation-delay: 500ms;
}
@keyframes pulse {
  0% {
		width: 0;
	}
	50% {
		width: 100%;
	}
	100% {
		width: 0;
    opacity: 0;
	}
}

#ai-search .ai-suggestions p {
  white-space: pre-wrap;
  margin: 0;
  line-height: 1.3em;
  font-size: 14px;
  margin-right: 8px;
}
@media screen and (max-width: 969px) {
  #ai-search {
    margin: 20px 0;
  }
}
@media screen and (max-width: 767px) {
  #ai-search {
    margin: 20px 10px;
  }
  #ai-search button {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
}

#main-content .sponsored-boats {
  max-width: calc(100% + 10px)
}
#main-content .sponsored-boats > ul {
  display: inline-block;
  width: 100%;
}
#main-content .results-count {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 969px) {
  #main-content .search-results {
      margin: 0 auto;
  }
  #main-content #right-content {
    max-width: 100%;
  }
  .content-head:not(#engines .content-head) .breadcrumb {
    padding-left: 9px;
  }
  .content-head:not(#engines .content-head) {
    padding-left: 7px;
  }
}
@media screen and (max-width: 767px) {
  #main-content {
      padding: 0 20px;
  }
  #main-content .sponsored-boats {
    margin: 0 !important;
  }
}
@media screen and (min-width: 768px) {
  #main-content .search-results {
    max-width: 100%;
  }
}
@media screen and (max-width: 1439px) {
  .content-head:not(#engines .content-head)  {
    max-width: 100%;
  }
  #main-content {
    max-width: 100%;
  }
}

#main-content > .ai-convo {
  position: fixed;
  right: 34px;
  bottom: 0px;
  width: 300px;
  height: calc(100% - 154px);
  background: #fff;
  box-shadow: 0 1px 4px 1px rgb(0 0 0/.1);
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 9999;
}
#main-content > .ai-convo .ai-convo-header {
  padding: 15px;
  background: #e6f0ff;
  border: 1px solid #c7d9f3;
  border-radius: 5px 5px 0 0;
  border-bottom: 2px solid var(--bt-color-neutral-30);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 15px;
}
#main-content > .ai-convo .ai-convo-header img {
  min-width: 50px;
  max-height: 50px;
  margin-right: 15px;
}
#main-content > .ai-convo .ai-convo-header h3 {
  font-size: 16px;
  font-weight: 500;
  color: #0e4d77;
}

#main-content > .ai-convo .ai-convo-header h3 i {
  font-size: 14px;
  font-weight: 400;
}

#main-content > .ai-convo .ai-convo-body {
  flex-grow: 1;
  padding: 0 0 0 15px;
  overflow: hidden;
  width: 300px;
  box-sizing: border-box;
}
#main-content > .ai-convo .ai-convo-body > div > div > img {
  width: 200px;
  margin: 0 auto;
  filter: grayscale(1);
  opacity: 0;
  animation: displayAiLoader 250ms 500ms ease-out forwards;
}
@keyframes displayAiLoader {
  0% {
    opacity: 0;
	}
	100% {
    opacity: 1;
	}
}
#main-content > .ai-convo .ai-convo-body > div {
  height: 100%;
  overflow-y: scroll;
  overscroll-behavior: contain;
  width: 310px;
  box-sizing: border-box;
}
#main-content > .ai-convo .ai-convo-body > div > div {
  display: grid;
  align-items: flex-start;
  justify-content: flex-start;
  width: 270px;
  overflow: hidden;
}

#main-content > .ai-convo .ai-convo-body .ai-message {
  border: 1px solid var(--bt-color-neutral-30);
  border-radius: 4px;
  padding: 8px;
  color: #616161;
  background: #f5f5f5;
  max-width: 90%;
  box-sizing: border-box;
}

#main-content > .ai-convo .ai-convo-body .ai-message:not(:last-child) {
  margin-bottom: 15px;
}
#main-content > .ai-convo .ai-convo-body .ai-message.user {
  justify-self: flex-end;
  color: #0f4d77;
  border-color: #c7d9f3;
  background: #e6f0ff;
  transform: translateX(200%);
  animation: slideUserMessage 250ms ease-out forwards;
}
@keyframes slideUserMessage {
  0% {
    transform: translateX(200%);
	}
	100% {
    transform: translateX(0);
	}
}
#main-content > .ai-convo .ai-convo-body .ai-message p {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: .4px;
  white-space: pre-wrap;
}
#main-content > .ai-convo .ai-convo-body .ai-message button {
  display: block;
  font-size: 12px;
  font-weight: 700;
  text-rendering: geometricprecision;
  cursor: pointer;
  padding: 8px 16px;
  outline: 0;
  border-radius: 5px;
  background: #fff;
  color: var(--bt-color-primary);
  border: 1px solid var(--bt-color-primary);
  margin-top: 8px;
  width: 100%;
}
#main-content > .ai-convo .ai-convo-body .ai-message button:hover {
  background: #e6f0ff
}

#main-content > .ai-convo .ai-convo-footer {
  flex-shrink: 0;
  width: 100%;
  overflow: hidden;
  margin-top: 15px;
}
#main-content > .ai-convo .ai-convo-footer form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
#main-content > .ai-convo .ai-convo-footer input {
  border: 1px solid #e0e0e0;
  height: 40px;
  font-size: 14px;
  padding: 8px 15px 8px 18px;
  box-shadow: inset 0 2px 3px rgba(0,0,0,.1);
  border-radius: 0;
  color: #404040 !important;
  box-sizing: border-box;
  border-width: 1px 0px;
  margin-left: -3px;
  outline: none;
  flex-grow: 1;
  background-color: #fff !important;
}

#main-content > .ai-convo .ai-convo-footer button {
  outline: none;
  border: none;
  border-top: 1px solid #c7d9f3;
  background: #e6f0ff;
  font-weight: 500;
  color: #0f4d77;
  padding: 8px 16px;
  display: block;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  line-height: 23px;
  width: 104px;
}

#main-content > .ai-convo .ai-message-records {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 7px;
  flex-wrap: wrap;
  font-size: 0;
  padding-bottom: 2px;
  max-width: 90%;
}
#main-content > .ai-convo .ai-message-records > a {
  width: 118px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  filter: drop-shadow(0 1px 1px rgba(0,0,0,.2));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
#main-content > .ai-convo .ai-message-records h3 {
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  margin: 3px 0;
  padding: 0 3px;
  color: #404040;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#main-content > .ai-convo .ai-message-records h4 {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin: 0;
  padding: 0 3px;
  color: #404040;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#main-content > .ai-convo .ai-message-records p {
  font-weight: 400;
  font-size: 8px;
  line-height: 8px;
  margin: 3px 0;
  padding: 0 3px;
  color: #9e9e9e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media screen and (max-width: 970px) {
  .paying-brand-header-image {
    margin-top: 96px;
  }
}

.server-ad .ad-image {
    position: relative;
}

.boat-search_banner-top-container{
  width: 100%;
}
