.guide-tools-sections {
  color: #676767;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  background: linear-gradient(to bottom, #0A2240 70%, #F5F8FD 30%);
  width: 100%;
  padding-bottom: 60px; 
}

.guide-tools-sections h2 {
  color: var(--bt-color-neutral-10);
}

.guide-tools-sections a {
  color: var(--bt-color-primary);
}

.guide-tools-sections h3 {
  color: #0a2240;
  font-size: 28px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  margin-bottom: 12px;
}

.guide-tools-sections > .content {
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding-top: 123px;
}

.guide-tools-sections > .content > div {
  margin: 0 24px 0 24px;
}

.guide-tools-sections {
  --icon-size: 143px;
}

.guide-tools-sections .tool-card {
  position: relative;
  background-color: var(--bt-color-neutral-10);
  max-width: 528px;
  padding-top: calc(var(--icon-size) / 2);
  display: flex;
  justify-content: center;
  flex: 1;
}

.guide-tools-sections .tool-card > .content {
  margin: 0 26px 26px 26px;
}

.guide-tools-sections .card-svg {
  position: absolute;
  color: #fff;
  width: var(--icon-size);
  height: var(--icon-size);
  background-position: 0;
  border: none;
  border-radius: initial;
  top: calc((var(--icon-size) / 2) * -1);
}

.guide-tools-sections .boat-svg {
  background-size: var(--icon-size);
}

.guide-tools-sections .calc-svg {
  background-size: var(--icon-size);
}

@media (max-width: 912px) {
  .guide-tools-sections {
    --icon-size: 100px;
    background: linear-gradient(to bottom, #0A2240 80%, #F5F8FD 20%);
    font-size: 16px;
    letter-spacing: 0.01em;
  }

  .guide-tools-sections h3 {
    font-size: 28px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
  }

  .guide-tools-sections > .content {
    flex-direction: column;
    align-items: center;
    padding-top: 0;
  }

  .guide-tools-sections > .content > div {
    margin-top: calc(var(--icon-size)/2 + 12px);
  }

  .guide-tools-sections .tool-card {
    max-width: 400px;
  }
}