.editorial-content-container {
    font-style: normal;
    min-height: 800px;
    padding: 0 1rem;
    max-width: 1430px;
    margin: 0 auto;
    margin-bottom: 1rem;
}

.editorial-content-container .featured-image {
    text-align: center;
}

.editorial-content-container a {
    text-align: center;
    color: #139af4;
}

.editorial-content-container h1 {
    margin-bottom: 40px;
}

.editorial-content-container h4 {
    margin-top: 40px;
    font-weight: normal;
}

.editorial-content-container .editorial-data {
    margin-bottom: 40px;
}

@media screen and (max-width: 970px) {
    .editorial-content-container .featured-image img {
        width: 100%;
    }

    .editorial-content-container .editorial-data {
        text-align: justify;
    }
}