/* BASE font-size: 15px */


.trust-pilot-main-container{
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 36px 11rem 36px 11rem;
  background: #F5F9FF;
  z-index: 2000;
}

.trust-pilot-main-container .trust-pilot-header{
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 auto;
}

.trust-pilot-main-container .trust-pilot-title{
  color: var(--bt-color-neutral-90, #404040);
  text-align: center;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px; /* 100% */
  letter-spacing: -0.36px;
}

.trust-pilot-main-container .trust-pilot-text{
  color: var(--bt-color-neutral-90, #404040);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.trust-pilot-main-container .trust-pilot-cta{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.trust-pilot-main-container .prequalify-button-calc,
.trust-pilot-main-container .sticky-prequalify-button-calc {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--bt-color-primary);
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    text-rendering: geometricprecision;
    cursor: pointer;
    background: var(--bt-color-primary); ;
    width: 300px;
    border-radius: 50px;
    height: 43px;
    transition: 0.3s;
    transition-property: background, font-size;
    padding: 3px 50px;
    white-space: nowrap;
}

/* Desktop */
.trust-pilot-main-container .trust-pilot-section.desktop,
.trust-pilot-main-container .trust-pilot-section.mobile {
  text-align: center;
  width: 100%;
  padding: 0 !important
}

.trust-pilot-main-container .trust-pilot-section.desktop {
  display: block;
}

.trust-pilot-main-container .trust-pilot-section.desktop .trustpilot-widget-loading {
  height: 140px;
}

.trust-pilot-main-container .trust-pilot-section.mobile {
  display: none;
}

/* Mobile */
@media only screen and (max-width: 991px) {


  .trust-pilot-main-container .trust-pilot-text{
    text-align: left;
  }

  .trust-pilot-main-container .trust-pilot-section.desktop {
    display: none;
  }
  
  .trust-pilot-main-container .trust-pilot-section.mobile {
    display: block;
  }

  .trust-pilot-main-container .trust-pilot-section.mobile .trustpilot-widget-loading {
    height: 350px;
  }

  .trust-pilot-main-container{
    padding: 24px 1rem;
  }

}

.trust-pilot-main-container .sticky-buttons{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--bt-color-neutral-10); ;
  z-index: 2001;
  padding: 10px 10px 24px;
  box-shadow: 0 -1px 3px rgba(0,0,0,.3);
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 16px;
  justify-content: center;
}

.trust-pilot-main-container .sticky-buttons.hide{
  display: none;
}