.broker-modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10012;
  background-color: rgba(51, 51, 51, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.broker-modal {
  width: 53%;
  height: fit-content;
  z-index: 10013;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.broker-modal-container .broker-modal .header {
  width: 100% !important;
  height: 36px;
  display: inline-block;
}

.broker-modal-container .broker-modal .header.bar {
  background: linear-gradient(180deg, rgba(234, 234, 234, 0.6) 0%, rgba(255, 255, 255, 0.6) 100%), rgba(234, 234, 234, 0.5);
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.broker-modal .header .back-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 10px;
  left: 20px;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.broker-modal .header .back-container button.back-button {
  width: 13.3px;
  height: 13.3px;
  background: url('./back-button.svg') no-repeat center center;
  margin-right: 7.33px;
  border: none;
  cursor: pointer;
}

.broker-modal .header .back-container .back-text {
  font-size: 14px;
  line-height: 17px;
  color: #002A45;
}

.broker-modal .header button.contact-close {
  width: 14px;
  height: 14px;
  align-self: flex-end;
  background: url('./close-icon.svg') no-repeat center center transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

@media screen and (max-width: 970px) {
  .broker-modal {
    width: 100%;
    height: 100%;
  }
}
