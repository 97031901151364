.recommended-boats {
  margin: 0 12px;
}

.recommended-boats ol.boat-list  {
  margin: 12px 0;
  padding: 0;
  list-style: none;
}

.recommended-boats ol.boat-list>li  {
  margin: 0px;
  width: auto;
  background: none;
  border: 0px;
}

.recommended-boats ol.boat-list  li .centred {
  text-align: center;
}
.recommended-boats ol.boat-list  li .inner {
  position: relative;
  display: block;
  margin-bottom: 12px;
  height: auto;
  overflow: hidden;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.recommended-boats ol.boat-list  li .image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  text-align: center;
  max-width: none;
}
.recommended-boats ol.boat-list  li .image-container .dummy {
  padding-top: 66.666666666666%;
}
.recommended-boats ol.boat-list  li .image-container .image {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.recommended-boats ol.boat-list  li .image-container .image .image-results,
.recommended-boats ol.boat-list  li .image-container .image .preloader {
  width: 100%;
  position: absolute;
  margin: auto;
  top: -100%;
  right: -100%;
  left: -100%;
  bottom: -100%;
}
.recommended-boats ol.boat-list  li .image-container .image .image-results.wide,
.recommended-boats ol.boat-list  li .image-container .image .preloader.wide {
  height: 100%;
  width: auto;
}
.recommended-boats ol.boat-list  li .image-container .image .preloader {
  background: url('//boattrader.com/static/img/carousel/preloader.gif') center center no-repeat #fff;
  height: 100%;
  width: 100%;
  z-index: 0;
}
.recommended-boats ol.boat-list  li .media-info {
  display: none;
  pointer-events: none;
}
.recommended-boats ol.boat-list li .description {
  display: block;
  color: #bdc3c7;
  font-weight: bold;
  text-align: center;
  position: relative;
  width: 100%;
  line-height: inherit;
  padding: 0px;
  overflow: unset;
}
.recommended-boats ol.boat-list  li .description .info {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: url('//boattrader.com/static/img/img-gradient.png') left bottom repeat-x;
}
.recommended-boats ol.boat-list  li .description .field-name {
  color: #bdc3c7;
  display: block;
  font-size: 8px;
  letter-spacing: 0.5px;
  line-height: 1;
  text-transform: uppercase;
  padding-bottom: 2px;
}
.recommended-boats ol.boat-list  li .description .name {
  font-size: .9em;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 6px;
}
.recommended-boats ol.boat-list  li .description .price, .recommended-boats ol.boat-list  li .description .location, .recommended-boats ol.boat-list  li .description .offered-by, .recommended-boats ol.boat-list  li .description .contact {
  font-size: .8em;
  float: left;
  clear: none;
  overflow: hidden;
  white-space: normal;
  line-height: 1.1em;
  padding-bottom: 7px;
}
.recommended-boats ol.boat-list  li .description .price .txt,
.recommended-boats ol.boat-list  li .description .location .txt,
.recommended-boats ol.boat-list  li .description .offered-by .txt,
.recommended-boats ol.boat-list  li .description .contact .txt {
  display: inline-block;
  overflow: hidden;
  width: auto;
  text-align: left;
  margin: 0 auto;
  max-height: 3em;
}
.recommended-boats ol.boat-list  li .description .price .txt .data, .recommended-boats ol.boat-list  li .description .location .txt .data, .recommended-boats ol.boat-list  li .description .offered-by .txt .data, .recommended-boats ol.boat-list  li .description .contact .txt .data {
  overflow: hidden;
  text-overflow: ellipsis;
}
.recommended-boats ol.boat-list  li .description .offered-by {
  display: none;
}
.recommended-boats ol.boat-list  li .description .price {
  color: var(--bt-color-secondary_action-main);
  width: 30%;
}
.recommended-boats ol.boat-list  li .description .location {
  width: 40%;
}
.recommended-boats ol.boat-list  li .description .contact {
  width: 30%;
  padding-top: 8px;
  text-align: center;
}
.recommended-boats ol.boat-list  li .save-boat, .recommended-boats ol.boat-list  li .save-boat:active {
  overflow: hidden;
  box-sizing: content-box;
  display: block;
  text-align: left;
  position: absolute;
  width: 25px;
  height: 25px;
  padding: 0;
  border: none;
  top: 10px;
  right: 15px;
  white-space: nowrap;
  cursor: pointer;
  background: none;
}
.recommended-boats ol.boat-list  li .save-boat .text, .recommended-boats ol.boat-list  li .save-boat:active .text {
  position: absolute;
  right: 26px;
  font-size: 12px;
  font-weight: bold;
  top: 5px;
}
.recommended-boats ol.boat-list  li .save-boat .icon, .recommended-boats ol.boat-list  li .save-boat:active .icon {
  background: url('//boattrader.com/static/img/icons/star.png') no-repeat right 0;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 5px;
  top: 5px;
}
.recommended-boats ol.boat-list  li .save-boat:hover, .recommended-boats ol.boat-list  li .save-boat:active:hover {
  background-color: #fff;
}
.recommended-boats ol.boat-list  li .save-boat:hover .icon, .recommended-boats ol.boat-list  li .save-boat:active:hover .icon {
  background-position: right -35px;
}
.recommended-boats ol.boat-list  li.premium {
  width: 100%;
  position: relative;
}
.recommended-boats ol.boat-list  li.premium .inner {
  height: auto;
  background-color: #fff;
  border: 2px solid #07324f;
}
.recommended-boats ol.boat-list  li.premium .txt {
  padding: 8px 10px;
  color: #07324f;
}
.recommended-boats ol.boat-list  li.premium .description {
  position: relative;
  margin-top: 0;
  background-color: #fff;
  color: #07324f;
  background: none;
  text-align: left;
  height: auto;
  clear: both;
  overflow: hidden;
}
.recommended-boats ol.boat-list  li.premium .description .field-name {
  display: block;
}
.recommended-boats ol.boat-list  li.premium .description .name {
  border-bottom: 1px solid #ecf0f1;
  color: #07324f;
  font-size: 16px;
  padding: 8px;
  float: none;
}
.recommended-boats ol.boat-list  li.premium .description .location, .recommended-boats ol.boat-list  li.premium .description .price, .recommended-boats ol.boat-list  li.premium .description .offered-by {
  display: block;
  clear: both;
  float: none;
  width: auto;
}
.recommended-boats ol.boat-list  li.premium .description .location .txt, .recommended-boats ol.boat-list  li.premium .description .price .txt, .recommended-boats ol.boat-list  li.premium .description .offered-by .txt {
  white-space: normal;
}
.recommended-boats ol.boat-list  li.premium .description .location {
  padding-bottom: 0;
}
.recommended-boats ol.boat-list  li.premium .description .price {
  padding-bottom: 0;
  border-bottom: 1px solid #ecf0f1;
  z-index: 9999;
}
.recommended-boats ol.boat-list  li.premium .description .offered-by {
  padding-bottom: 0;
  background-color: #07324f;
}
.recommended-boats ol.boat-list  li.premium .description .offered-by .txt {
  height: 30px;
  padding: 12px 120px 8px 8px;
  color: #fff;
}
.recommended-boats ol.boat-list  li.premium #results-contact-form {
  z-index: 99;
  position: absolute;
  left: 10px;
  top: auto;
  bottom: 10px;
  right: 10px;
  width: auto;
  margin: 0;
  padding: 0;
  background-color: #fff;
}
.recommended-boats ol.boat-list  li.premium #results-contact-form .title {
  color: #2876aa;
  font-weight: 700;
  margin: 5px 0 15px;
}
.recommended-boats ol.boat-list  li.premium #results-contact-form.open {
  display: block;
}
.recommended-boats ol.boat-list  li.premium #results-contact-form button.contact-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  border-width: 0;
  background: url('//boattrader.com/static/img/icons/close-x-mobile.png') no-repeat center center transparent;
  display: block;
}
.recommended-boats ol.boat-list  li.premium #results-contact-form .main-form {
  padding: 15px;
}
.recommended-boats ol.boat-list  li.premium #results-contact-form .errorTxt, .recommended-boats ol.boat-list  li.premium #results-contact-form .message-error {
  color: #d9534f;
  clear: both;
  padding: 15px;
}
.recommended-boats ol.boat-list  li.premium #results-contact-form .errorTxt .title, .recommended-boats ol.boat-list  li.premium #results-contact-form .message-error .title {
  color: #d9534f;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1em;
  margin: 10px 50px 15px;
}
.recommended-boats ol.boat-list  li.premium #results-contact-form .message-sent {
  color: #2876aa;
  padding: 15px;
}
.recommended-boats ol.boat-list  li.premium #results-contact-form .message-sent .title {
  font-size: 1.3em;
  font-weight: 700;
}
.recommended-boats ol.boat-list  li.premium .contact {
  color: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 118px;
  height: 34px;
  padding: 8px 0;
}
.recommended-boats ol.boat-list  li.premium .contact .txt {
  padding: 0;
  text-align: center;
}
.recommended-boats ol.boat-list  li.premium .contact .contact-seller {
  background: #e47e30;
  border: 0;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 7px;
  text-align: center;
  text-decoration: none;
}
.recommended-boats ol.boat-list  li.premium .contact .contact-seller:hover {
  background: #eea236;
}

/*NoScript*/
/*----------------------------------------------*/

body.no-script .recommended-boats ol.boat-list  li .image-container .image .preloader {
  display: none;
}

.recommended-boats .heart {
  background: url('//boattrader.com/static/img/icons/unfilled_heart.svg') no-repeat !important;
  z-index: 20;
  position: absolute;
  width: 45px;
  height: 42px !important;
  cursor: pointer;
  right: 0px;
  left: auto;
  margin: 5px 0 0 !important;
}

.recommended-boats .heart.filled,
.recommended-boats .heart.liked {
  background: url('//boattrader.com/static/img/icons/filled_heart.svg') no-repeat !important;
  margin: 5px 0 0 !important;
}

.recommended-boats .browse-listings-photo .save-listing .listing-image .heart {
  background-size: 40px!important;
}

.recommended-boats .boat-list .inner .heart {
  background-position: 6px 2px !important;
}

#homepage .main-content.home {
  background-color: #07324f;
}

#homepage ol.boat-list li .description .data,
#homepage ol.boat-list li .description a.contact-seller {
  font-weight: 700;
}

@media screen and (max-width: 696px) {
  #homepage ol.boat-list li .description .data,
  #homepage ol.boat-list li .description a.contact-seller {
    color: #bdc3c7;
  }

  .recommended-boats ol.boat-list  li .description .price .txt,
  .recommended-boats ol.boat-list  li .description .location .txt,
  .recommended-boats ol.boat-list  li .description .offered-by .txt,
  .recommended-boats ol.boat-list  li .description .contact .txt {
    display: block;
    margin: 0 3px;
  }
  .recommended-boats ol.boat-list li .description .info {
    background: linear-gradient(180deg,rgba(10, 10, 10, 0.1) 0%, var(--bt-color-neutral-100) 84%);
  }
}

@media screen and (min-width: 500px) {

  .recommended-boats ol.boat-list {
    margin-right: -12px;
 }
  .recommended-boats ol.boat-list li {
    width: 50%;
    float: left;
 }
  .recommended-boats ol.boat-list li .inner {
    margin-right: 12px;
 }
  .recommended-boats ol.boat-list li .description .price, .recommended-boats ol.boat-list li .description .location, .recommended-boats ol.boat-list li .description .contact {
    white-space: normal;
    line-height: 1.1em;
 }
  .recommended-boats ol.boat-list li .description .price .txt,
  .recommended-boats ol.boat-list li .description .location .txt,
  .recommended-boats ol.boat-list li .description .contact .txt {
    max-width: 90%;
 }
  .recommended-boats ol.boat-list li.premium .description .price {
    width: 50%;
    float: left;
 }
  .recommended-boats ol.boat-list li.premium .description .price .txt {
    border-right: 1px solid #ecf0f1;
    display: block;
    max-width: 100%;
 }
  .recommended-boats ol.boat-list li.premium .description .location {
    width: 50%;
    float: left;
    clear: none;
 }
  .recommended-boats ol.boat-list li.premium .description .location .txt {
    white-space: nowrap;
    text-overflow: ellipsis;
 }
  .recommended-boats ol.boat-list li.premium #results-contact-form {
    right: 22px;
    bottom: 22px;
 }

}


@media screen and (min-width: 700px) {
  #home #homepage .recommended-boats ol.boat-list  li .description .price .data {
    color: #2876aa;
 }
  #home #homepage .recommended-boats ol.boat-list  li .description .location .data, #home #homepage .recommended-boats ol.boat-list  li .description .offered-by .data {
    color: #07324f;
 }
  #home #homepage .recommended-boats ol.boat-list  li .description a.contact-seller {
    color: var(--bt-color-secondary_action-main);
 }



.recommended-boats ol.boat-list li.premium .inner {
  border: none;
  background: #032239;
}
.recommended-boats ol.boat-list li.premium .media-info {
  bottom: 0;
  width: 75%;
  padding: 35px 0 0;
}
.recommended-boats ol.boat-list li.premium .media-info .txt {
  color: #fff;
  padding: 0;
}
.recommended-boats ol.boat-list li.premium .image-container {
  padding-bottom: 0px;
  width: 75%;
}
.recommended-boats ol.boat-list li.premium .description {
  position: absolute;
  padding-top: 35px;
  width: 25%;
  top: 0;
  right: 0;
}
.recommended-boats ol.boat-list li.premium .description .contact .contact-seller {
  height: auto;
}
.recommended-boats ol.boat-list li.premium .description .name {
  padding: 0 15px;
  font-size: 1em;
  color: #fff;
  font-weight: 300;
  text-shadow: 0 2px 0 rgba(34,34,34,0.5);
  border: none;
  max-height: 2.6em;
  white-space: normal;
}
.recommended-boats ol.boat-list li.premium .description .price {
  font-size: 1.2em;
  font-weight: normal;
  text-shadow: 0 2px 0 rgba(34,34,34,0.5);
  text-align: right;
  width: auto;
  border: none;
}
.recommended-boats ol.boat-list li.premium .description .price .txt {
  border: none;
  color: var(--bt-color-secondary_action-main);
  padding: 7px 15px;
}
.recommended-boats ol.boat-list li.premium .description .price .field-name {
  display: none;
}
.recommended-boats ol.boat-list li.premium .description .field-name {
  color: #fff;
  opacity: .5;
}
.recommended-boats ol.boat-list li.premium .description .location, .recommended-boats ol.boat-list li.premium .description .offered-by {
  background: none;
  border-bottom: 0;
  border-left: 0;
  color: #fff;
  height: auto;
  width: auto;
  padding: 5px 15px 7px;
  margin: 0;
}
.recommended-boats ol.boat-list li.premium .description .location .txt, .recommended-boats ol.boat-list li.premium .description .offered-by .txt {
  padding: 0;
  color: #fff;
  text-overflow: ellipsis;
  white-space: normal;
  border: none;
  height: auto;
}
.recommended-boats ol.boat-list li.premium .description .location {
  clear: both;
}
.recommended-boats ol.boat-list li.premium .contact {
  background: none;
  width: 25%;
}
.recommended-boats ol.boat-list li.premium .contact a.contact-seller {
  height: auto;
  display: block;
  margin: 0 10px;
}
.recommended-boats ol.boat-list li.premium #results-contact-form {
  left: auto;
  right: 22px;
  bottom: 70px;
  width: 280px;
}
.recommended-boats ol.boat-list li.premium #results-contact-form:after {
  content: "";
  position: absolute;
  bottom: -12px;
  right: 20px;
  border-width: 12px 9px 0;
  border-style: solid;
  border-color: #fff transparent;
  display: block;
  width: 0;
}


.recommended-boats {
  position: relative;
}
.recommended-boats ol.boat-list {
  margin-right: -12px;
}
.recommended-boats ol.boat-list li {
  width: 33.3333%;
  float: left;
}
.recommended-boats ol.boat-list li .inner {
  margin-right: 12px;
  background-color: #fff;
}
.recommended-boats ol.boat-list li .media-info {
  display: none;
  text-align: center;
  padding-top: 35px;
  height: 25px;
  position: absolute;
  margin-top: -60px;
  width: 100%;
  font-size: 13px;
  line-height: 1;
  background: url('//boattrader.com/static/img/img-gradient.png') repeat-x;
}
.recommended-boats ol.boat-list li .media-info .photo, .recommended-boats ol.boat-list li .media-info .video {
  width: 40%;
  text-align: center;
  color: #fff;
  opacity: .6;
  overflow: hidden;
  white-space: nowrap;
}
.recommended-boats ol.boat-list li .media-info .photo .icon, .recommended-boats ol.boat-list li .media-info .video .icon {
  background: url("//boattrader.com/static/img/icons/media-white.png") no-repeat;
  height: 14px;
  width: 15px;
  margin-right: 3px;
  font-size: 0;
  float: left;
}
.recommended-boats ol.boat-list li .media-info .photo .txt, .recommended-boats ol.boat-list li .media-info .video .txt {
  float: left;
}
.recommended-boats ol.boat-list li .media-info .video .icon {
  background-position: 0 -26px;
}
.recommended-boats ol.boat-list li .description {
  overflow: hidden;
  color: #07324f;
  background: none;
  text-align: left;
}
.recommended-boats ol.boat-list li .description .info {
  top: 0;
  background: none;
}
.recommended-boats ol.boat-list li .description .dummy {
  padding-top: 115px;
}
.recommended-boats ol.boat-list li .description .txt {
  padding: 7px 10px;
  line-height: 1.3em;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recommended-boats ol.boat-list li .description .field-name {
  display: block;
}
.recommended-boats ol.boat-list li .description .name {
  color: #07324f;
  text-align: left;
  border-bottom: 1px solid #ecf0f1;
  padding: 7px 10px;
  font-size: 14px;
}
.recommended-boats ol.boat-list li .description .price {
  width: 50%;
  padding-bottom: 0;
  border-bottom: 1px solid #ecf0f1;
}
.recommended-boats ol.boat-list li .description .price .txt {
  display: block;
  border-right: 1px solid #ecf0f1;
  color: #2876aa;
}
.recommended-boats ol.boat-list li .description .location {
  width: 50%;
  border-bottom: 1px solid #ecf0f1;
  padding-bottom: 0;
}
.recommended-boats ol.boat-list li .description .location .txt {
  display: block;
  min-height: 26px;
}
.recommended-boats ol.boat-list li .description .offered-by {
  display: block;
  width: 50%;
  padding-bottom: 0;
}
.recommended-boats ol.boat-list li .description .offered-by .txt {
  display: block;
  border-right: 1px solid #ecf0f1;
}
.recommended-boats ol.boat-list li .description .contact {
  padding: 0;
  width: 50%;
  text-align: left;
  background: url('//boattrader.com/static/img/icons/contact-small.png') no-repeat 10px center;
}
.recommended-boats ol.boat-list li .description .contact .txt {
  display: block;
  padding: 12px 10px 12px 25px;
  color: var(--bt-color-secondary_action-main);
}
.recommended-boats header {
  overflow: hidden;
}
.recommended-boats header h2 {
  margin: 0;
  white-space: nowrap;
}
.recommended-boats header h2 small {
  display: inline;
  padding-left: 5px;
}
.recommended-boats header a.view-all {
  float: right;
  padding: 3px 0 0 5px;
}


}

@media screen and (min-width: 970px) {
  .recommended-boats ol.boat-list {
    margin-right: -12px;
 }
  .recommended-boats ol.boat-list li {
    /* width: 50%; */
    float: left;
 }
  .recommended-boats ol.boat-list li .inner {
    margin-right: 12px;
    background-color: #fff;
 }
  .recommended-boats ol.boat-list li .media-info {
    display: none;
    text-align: center;
    padding-top: 35px;
    height: 25px;
    position: absolute;
    margin-top: -60px;
    width: 100%;
    font-size: 13px;
    line-height: 1;
    background: url('//boattrader.com/static/img/img-gradient.png') repeat-x;
 }
  .recommended-boats ol.boat-list li .media-info .photo, .recommended-boats ol.boat-list li .media-info .video {
    width: 40%;
    text-align: center;
    color: #fff;
    opacity: .6;
    overflow: hidden;
    white-space: nowrap;
 }
  .recommended-boats ol.boat-list li .media-info .photo .icon, .recommended-boats ol.boat-list li .media-info .video .icon {
    background: url("//boattrader.com/static/img/icons/media-white.png") no-repeat;
    display: inline-block;
    height: 14px;
    width: 15px;
    margin-right: 3px;
    font-size: 0;
    float: left;
 }
  .recommended-boats ol.boat-list li .media-info .photo .txt, .recommended-boats ol.boat-list li .media-info .video .txt {
    float: left;
 }
  .recommended-boats ol.boat-list li .media-info .video .icon {
    background-position: 0 -26px;
 }
  .recommended-boats ol.boat-list li .description {
    overflow: hidden;
    color: #07324f;
    background: none;
    text-align: left;
 }
  .recommended-boats ol.boat-list li .description .info {
    top: 0;
    background: none;
 }
  .recommended-boats ol.boat-list li .description .dummy {
    padding-top: 115px;
 }
  .recommended-boats ol.boat-list li .description .txt {
    padding: 7px 10px;
    line-height: 1.3em;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
 }
  .recommended-boats ol.boat-list li .description .field-name {
    display: block;
 }
  .recommended-boats ol.boat-list li .description .name {
    color: #07324f;
    text-align: left;
    border-bottom: 1px solid #ecf0f1;
    padding: 7px 10px;
    font-size: 14px;
 }
  .recommended-boats ol.boat-list li .description .price {
    width: 50%;
    padding-bottom: 0;
    border-bottom: 1px solid #ecf0f1;
 }
  .recommended-boats ol.boat-list li .description .price .txt {
    display: block;
    border-right: 1px solid #ecf0f1;
    color: #2876aa;
 }
  .recommended-boats ol.boat-list li .description .location {
    width: 50%;
    border-bottom: 1px solid #ecf0f1;
    padding-bottom: 0;
 }
  .recommended-boats ol.boat-list li .description .location .txt {
    display: block;
 }
  .recommended-boats ol.boat-list li .description .offered-by {
    display: block;
    width: 50%;
    padding-bottom: 0;
 }
  .recommended-boats ol.boat-list li .description .offered-by .txt {
    display: block;
    border-right: 1px solid #ecf0f1;
 }
  .recommended-boats ol.boat-list li .description .contact {
    padding: 0;
    width: 50%;
    text-align: left;
    background: url('//boattrader.com/static/img/icons/contact-small.png') no-repeat 10px center;
 }
  .recommended-boats ol.boat-list li .description .contact .txt {
    padding: 12px 10px 12px 25px;
    color: var(--bt-color-secondary_action-main);
    display: block;
 }
  .recommended-boats ol.boat-list li .save-boat, .recommended-boats ol.boat-list li .save-boat:active {
    background-color: #fff;
    overflow: hidden;
    display: none;
 }
  .recommended-boats ol.boat-list li .save-boat:hover, .recommended-boats ol.boat-list li .save-boat:active:hover {
    width: 120px;
 }
  .recommended-boats ol.boat-list li .save-boat .icon, .recommended-boats ol.boat-list li .save-boat:active .icon {
    background-position: right -35px;
 }
  .recommended-boats ol.boat-list li:hover .save-boat, .recommended-boats ol.boat-list li:hover .save-boat:active {
    display: block;
 }
  .recommended-boats ol.boat-list li:hover .media-info .photo, .recommended-boats ol.boat-list li:hover .media-info .video {
    opacity: 1;
 }
  .recommended-boats ol.boat-list li.premium .location .txt, .recommended-boats ol.boat-list li.premium .offered-by .txt {
    display: inline-block;
    max-height: 5em;
 }
  .recommended-boats ol.boat-list li.premium #results-contact-form button.contact-close {
    float: right;
    width: 16px;
    height: 16px;
    margin: 15px;
    background: url('//boattrader.com/static/img/icons/close-x.png') no-repeat 0 top;
 }
  .recommended-boats ol.boat-list li.premium #results-contact-form button.contact-close:hover {
    background-position: 0 bottom;
    cursor: pointer;
 }
  .recommended-boats ol.boat-list li.premium #results-contact-form h5 {
    margin: 0 0 15px;
 }
  .recommended-boats ol.boat-list li.desktop-liner-ad {
    width: 100%;
    margin: 0 auto 12px;
    text-align: center;
 }
}

@media screen and (min-width: 1360px) {
  .recommended-boats ol.boat-list li {
    width: 25%;
 }
  .recommended-boats ol.boat-list li .description .info {
    width: 100%;
    height: 100%;
 }
  .recommended-boats ol.boat-list li .description .txt {
    padding: 2px 10px;
 }
  .recommended-boats ol.boat-list li .description .name, .recommended-boats ol.boat-list li .description .price, .recommended-boats ol.boat-list li .description .location, .recommended-boats ol.boat-list li .description .offered-by, .recommended-boats ol.boat-list li .description .contact {
    vertical-align: middle;
    line-height: 1em;
    align-items: center;
    display: flex;
    height: 35%;
 }
  .recommended-boats ol.boat-list li .description .name .txt,
  .recommended-boats ol.boat-list li .description .price .txt,
  .recommended-boats ol.boat-list li .description .location .txt,
  .recommended-boats ol.boat-list li .description .offered-by .txt,
  .recommended-boats ol.boat-list li .description .contact .txt {
    margin: 0;
  }

  .recommended-boats ol.boat-list li .description .contact .txt {
   display: block;
  }

  .recommended-boats ol.boat-list li .description .name {
    display: block;
    height: auto;
    line-height: 1.8em;
    padding: 7px 10px;
 }
  .recommended-boats ol.boat-list li .description .location, .recommended-boats ol.boat-list li .description .contact {
    border-left: 1px solid #ecf0f1;
    margin-left: -1px;
 }
  .recommended-boats ol.boat-list li .description .price .txt, .recommended-boats ol.boat-list li .description .offered-by .txt {
    border: 0;
 }
  .recommended-boats ol.boat-list li .description .dummy {
    padding-top: 35.5%;
 }
  .recommended-boats ol.boat-list li.premium {
    width: 50%;
 }
  .recommended-boats ol.boat-list li.premium .description .price .txt {
    font-size: 0.8em;
    white-space: nowrap;
 }
  .recommended-boats ol.boat-list li.premium .description .name {
    line-height: 1.1em;
    max-height: 2.2em;
    display: block;
 }
  .recommended-boats ol.boat-list li.premium .description .location, .recommended-boats ol.boat-list li.premium .description .offered-by {
    padding: 3px 15px;
 }
  .recommended-boats ol.boat-list li.premium .description .location .txt, .recommended-boats ol.boat-list li.premium .description .offered-by .txt {
    max-height: 3.5em;
 }
  .recommended-boats ol.boat-list li.nr {
    clear: left;
 }

 .recommended-boats ol.boat-list li {
  width: 25%;
}
.recommended-boats ol.boat-list li .description .dummy {
  padding-top: 120px;
}

.recommended-boats ol.boat-list li .description .name .txt,
.recommended-boats ol.boat-list li .description .price .txt,
.recommended-boats ol.boat-list li .description .location .txt,
.recommended-boats ol.boat-list li .description .offered-by .txt,
.recommended-boats ol.boat-list li .description .contact .txt {
  border: 0;
}


}
