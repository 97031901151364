.service-item {
  width: 100%;
  background: var(--bt-color-neutral-10);
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(224, 161, 161, 0.25);
  border-radius: 5px;
  display: flex;
  margin-bottom: 16px;
  margin-right: 9px;
}

.service-item.service-ad {
  border: none;
}

.service-item a{
  display: flex;
  flex-grow: 1;
  padding: 10px 15px 10px;
}

.service-item .icon {
  width: 48px;
  height: 48px;
  background: #119BF5;
  border-radius: 5px;
  margin-right: 11px;
}

.service-item .icon.finance {
  background: url(./icons/finance.png) no-repeat center;
  background-size: 48px;
}

.service-item .icon.warranty {
  background: url(./icons/warranty.jpg) no-repeat center;
  background-size: 48px;
}

.service-item .icon.documentation {
  background: url(./icons/documentation.png) no-repeat center;
  background-size: 48px;
}

.service-item .service-title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #666;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 768px) {

  .service-item {
    width: calc(50% - 9px);
  }

}

@media screen and (min-width: 970px) {

  .service-item a{
    padding: 10px 15px 10px;
  }

}
