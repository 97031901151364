#homepage .main-content.home {
  background-color: #07324f;
  display: block;
  width: 100%;
}

  #homepage .main-content.home::before,
  #homepage .main-content.home::after {
    content: "";
    display: table;
  }

  #homepage .bt-inc-release {
    margin: 0;
    font-size: 15px;
    line-height: 1.3em;
    color: #333;
    zoom: 1;
  }

  #homepage .bt-inc-release::after,
  #homepage .bt-inc-release::before {
    content: "";
    display: table;
  }

  .home-search {
    margin: 0 2px;
    padding: 0 6px;
    overflow: hidden;
    box-sizing: border-box;
    height: 491px;
    background-color: var(--bt-color-tertiary);
    z-index: 1;
  }

  @media screen and (min-width: 700px) {
    #homepage .main-content.home {
      display: flex;
    }
    .bt-inc-release .home {
      zoom: 1;
    }

    #homepage .bt-inc-release::after,
    #homepage .main-content.home::after {
      clear: both;
    }

    .home-search {
      float: left;
      width: 324px;
      height: 473px;
    }

  }

  #recommended-boats {
    background-color: #ecf0f1;
  }

  .bt-inc-release h2 {
    line-height: 1.3em;
  }


.ad-heading::before {
  color: #bdc3c7;
  content: "Advertisement";
  display: block;
  font-size: 0.9em;
  letter-spacing: 3px;
  position: relative;
  margin: 4px 0 11px 0;
  padding-bottom: 11px;
  border-bottom: 1px solid #dee2e3;
  text-align: center;
}

/* Breakpoint 3 */
@media screen and (min-width: 970px) {
  .bt-inc-release .side-bar {
    width: 300px;
    float: left;
    padding: 6px 12px 12px;
  }

  .bt-inc-release .side-bar.right {
    background-color: #ecf0f1;
    float: right;
    clear: right;
  }

  .bt-inc-release .bp3 {
    display: block;
  }
}

@media screen and (min-width: 1116px) {
  .bt-inc-release .ad.w728 {
    display: block;
    height: auto;
    max-height: 250px;
  }

  .bt-inc-release .ad.w728.footer {
    clear: both;
    margin: 0 auto 40px;
    text-align: center;
  }
}

.main-content div[data-testid="ad-component"] {
  flex: 1 1;
  height: 290px;
}

@media (width > 700px) {
  .main-content div[data-testid="ad-component"] {
    height: 472px;
    overflow: hidden !important;
  }
}

.main-content div[data-testid="ad-component"] a {
  display: flex;
  overflow: hidden;
  position: relative;
  height: 290px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media (width > 700px) {
  .main-content div[data-testid="ad-component"] a {
      height: 472px;
    } 
}

.ad-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  position: absolute;
  left: 0;
} 