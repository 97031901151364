.map-container {
  height: 200px;
}

.map-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.map-modal-content {
  position: fixed;
  width: 90%;
  height: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.map-modal-close-btn {
  position: absolute;
  right: 0;
  top: -22px;
  cursor: pointer;
  background-color: #e67e22;
  color: #fff;
  padding: 5px;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -ms-border-radius: 3px 3px 0 0;
  -o-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 1em;
}

.map-modal-input-route {
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  text-align: center;
}

.map-modal-input-route button {
    width: 45%;
    color: #fff;
    background: var(--bt-color-secondary_action-main);
    border: 1px solid var(--bt-color-secondary_action-main);
    box-sizing: border-box;
}
