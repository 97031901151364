ol.boat-list > li {
  width: 100%;
  background: var(--bt-color-neutral-10);
  border: 1px solid var(--bt-color-neutral-30);
  border-radius:8px;
  box-sizing: border-box;
  margin-bottom: 15px;
  margin-right: 10px;
  position: static;
}

li.lib-card a > span[class*="style-module_listing"] {
  min-height: 355px;
}

li.lib-card.premium ul > li > button:last-of-type {
  border-radius: 50px;
  background: var(--bt-color-neutral-10);
  border: 1px solid var(--bt-color-primary);
}

li.lib-card.premium ul > li > button > label {
  color: var(--bt-color-primary);
}

li.lib-card.premium ul > li > button:hover {
  background: var(--bt-color-neutral-20) !important;
}

#sponsored-boats ul > li > button:last-of-type {
  border-radius: 50px;
  background: var(--bt-color-neutral-10);
  border: 1px solid var(--bt-color-primary);
}

#sponsored-boats ul > li > button:last-of-type > label {
  color: var(--bt-color-primary);
}

#sponsored-boats ul > li > button:last-of-type:hover {
  background: var(--bt-color-neutral-20) !important;
}

ol.boat-list li .inner {
  position: relative;
  height: auto;
  overflow: hidden;
  display: flex;
}

ol.boat-list li .image-container .image .tooltip {
  position: absolute !important;
  bottom: 8px;
  left: 8px;
  padding: 0;
  text-align: start;
  z-index: 99999999;
}

ol.boat-list .delivery {
  background: url(../../icons/Shipping.svg) no-repeat;
  width: 25px;
  height: 15px;
  filter: brightness(0) invert(1) drop-shadow(0 0 0.75rem black);
}


/*-- LEFT SIDE --*/
ol.boat-list li .image-container {
  position: relative;
  overflow: hidden;
  width: 45%;
  max-width: 253px;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
}
ol.boat-list li .image-container:not(.oem-items li.carousel-listing .image-container) {
  border-radius: 8px 0px 0px 8px;
}

ol.boat-list li .image-container .dummy {
  padding-top: 66.666666666666%;
}

ol.boat-list li .image-container .image {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

ol.boat-list li .image-container .image .price-cut {
  font-size: 11px;
  background-color: #0A4165;
  padding: 2px 7px;
  color: var(--bt-color-neutral-10);
  position: absolute;
  top: 0px;
  right: 0;
  left: 0;
}

ol.boat-list li .image-container .image .image-results {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

ol.boat-list li .image-container .image .image-results.default {
  position: absolute;
  margin: auto;
  top: -100%;
  right: -100%;
  left: -100%;
  bottom: -100%;
  height: auto;
}

ol.boat-list li .image-container .image .preloader {
  width: 100%;
  position: absolute;
  margin: auto;
  top: -100%;
  right: -100%;
  left: -100%;
  bottom: -100%;
}

ol.boat-list li .image-container .preloader {
  z-index: 3;
  background: url(//boattrader.com/static/img/carousel/preloader.gif) center center no-repeat #fff;
  height: 100%;
  width: 100%;
}

ol.boat-list li .image-container .preloader.hidden {
  opacity: 0;
  transition: opacity 0.5s ease 0.5s;
}


/*-- RIGHT SIDE --*/
ol.boat-list li .description {
  color: var(--bt-color-neutral-100);
  text-align: center;
  position: relative;
  width: 55%;
  line-height: 1;
  flex-grow: 1;
  font-style: normal;
  font-weight: normal;
  padding: 10px;
  display: flex;
  flex-direction: column;
}


/*-- TOP --*/
ol.boat-list li .description .top {
  float: left;
  width: 100%;
}

ol.boat-list li .description .top .location-distance{
  display: none;
}

ol.boat-list li .description .top .name {
  float: left;
  clear: both;
  font-size: 14px;
  line-height: 14px;
  color: var(--bt-color-neutral-100);
  text-align: left;
  max-height: 80px;
  display: flex;
  flex-flow: column nowrap;
}

ol.boat-list li .description .top .name a:not(a.main-link) {
  color: inherit;
  margin-bottom: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 15px;
}

ol.boat-list li .description .top .name a.main-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

ol.boat-list li .description .top .prices{
  margin-bottom: 5px;
}

ol.boat-list li .description .top .prices .price {
  float: left;
  clear: both;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 2px;
  font-weight: 700;
  text-align: right;
}

ol.boat-list li:not(.premium) .description .top .prices .tooltip {
  display: none;
}

ol.boat-list li .description .top .prices .tooltip {
  margin-right: -10px;
}

ol.boat-list li .description .top .prices .price-cut{
  order:3;
  text-align: left;
  font-size: 10px;
  line-height: 12px;
}

ol.boat-list li .description .top .name .location {
  font-size: 10px;
  line-height: 10px;
  color: var(--bt-color-neutral-100);
  float: left;
  clear: both;
  margin-bottom: 3px;
  font-weight: 400;
  text-overflow: ellipsis;
}

ol.boat-list li .description .top .name .location a {
  color: inherit;
}

ol.boat-list li .banner {
  color: white;
  width: auto;
  height: 20px;
  line-height: 16px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*-- BOTTOM --*/
ol.boat-list li .description .bottom {
  display: flex;
  flex-grow: 1;
  width: 100%;
  align-items: flex-end;
}

ol.boat-list li .description .bottom .offered-by {
  font-size: 10px;
  line-height: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  text-align: start;
  margin-right: 5px;
}

ol.boat-list li .description .bottom .offered-by .sold-by {
  display: none;
}

ol.boat-list li .description .bottom .contact {
  position: relative;
}

ol.boat-list li .description .bottom .contact a {
  color: #fff;
  background: #E67D22;
  z-index: 4;
  cursor: pointer;
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 150px;
  height: 34px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border: 1px solid #E67D22;
  border-radius: 50px;
}

ol.boat-list li .description .bottom .contact a.contact-seller-alt {
  color: #E67D22;
  background: #fff;
  display: flex;
}
ol.boat-list li .description .bottom .contact .contact-seller-alt span, ol.boat-list li .description .bottom .contact a.contact-seller{
  font-size: 16px;
  font-weight: 500;
}

ol.boat-list li .description .bottom .contact a.contact-seller-alt .tag {
  color: #2ba617;
  font-size: 1.2em;
  position: absolute;
  right: -12px;
  top: -10px;
  background: #fff;
  padding: 2px 4px;
}

ol.boat-list li .description .bottom .contact a.contact-seller-alt + a {
  margin-top: 6px;
}

/* -- DESKTOP --*/
@media screen and (min-width: 768px) {

  /*-- LEFT --*/
  ol.boat-list li .image-container .image .price-cut {
    font-size: 12px;
    top: 16px;
    left: auto;
  }
  ol.boat-list li .description .top .prices .price-cut{
    font-size: 12px;
    text-align: right;
  }



  /*-- TOP --*/
  ol.boat-list li .description .top .name .location {
    font-size: 12px;
    line-height: 12px;
  }

  ol.boat-list li .description .top .name {
    clear: none;
    font-size: 16px;
    line-height: 16px;
    max-width: 70%;
  }

  ol.boat-list li .description .top .prices {
    flex: 0 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  ol.boat-list li .description .top .prices .price {
    clear: none;
    font-size: 16px;
    float: right;
  }

  ol.boat-list li .description .top .prices .tooltip {
    display: block !important;
    margin-bottom: -25px;
  }

  ol.boat-list li .description .top .tooltip {
    padding: 0;
  }

  ol.boat-list li .description .top .tooltip .delivery {
    max-width: 25px;
  }

  ol #div-gpt-leaderboard-alpha {
    min-height: 90px;
  }

  /*-- BOTTOM --*/
  ol.boat-list li .description .bottom .offered-by {
    font-size: 12px;
  }

  ol.boat-list li .description .bottom .contact a.contact-seller-alt .video_icon {
    display: none;
  }
  ol.boat-list li .description .bottom .contact a.contact-seller-alt .video_text {
    display: inline-block;
  }
  ol.boat-list li .description .bottom .contact a.contact-seller .contact-seller-text {
    display: inline-block;
  }
}


/* -- MOBILE --*/
@media screen and (max-width: 767px) {

  ol.boat-list li:not(.premium) .description{
    overflow: hidden;
    padding: 8px;
  }

  /*-- LEFT --*/
  ol.boat-list li:not(.premium) .image-container .image .heart:not(:last-of-type) {
    top: 24px;
  }


  /*-- BOTTOM --*/

  ol.boat-list li .description .bottom .contact {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
  }

  ol.boat-list li .description .bottom .contact a {
    width: 34px;
  }

  ol.boat-list li .description .bottom .contact a.contact-seller-alt+a{
    margin-left: 6px;
  }
  ol.boat-list li .description .bottom .contact a.contact-seller-alt .video_icon {
    background: url(../../icons/Video-alt.svg) no-repeat;
    width: 23px;
    height: 15px;
    display: inline-block;
    transform: scale(.75);
  }
  ol.boat-list li .description .bottom .contact a.contact-seller-alt .video_text {
    display: none;
  }
  ol.boat-list li .description .bottom .contact a.contact-seller .contact-seller-text {
    display: none;
  }

  ol #div-gpt-leaderboard-alpha {
    min-height: 0px;
  }

  ol.boat-list li:not(.premium) .description .top,
  ol.boat-list li:not(.premium) .description .top .prices{
    display: flex;
    flex-direction: column;
  }

  ol.boat-list li:not(.premium) .description .top .prices .price:not(.hide){
    display: flex;
    justify-content: flex-start;
  }
}


/*-- TOOLTIP --*/
.react-tooltip-lite {
  background: linear-gradient(to right, #0E4D77 , #0B3F65);
  font-weight: 200;
  font-size: .8em;
  color: #ececec;
  z-index: 999999 !important;
  margin-top: 0;
}

.react-tooltip-lite-arrow {
  z-index: 999999 !important;
  border-color: #0B3F65;
  margin-top: 0;
}
