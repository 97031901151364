#dealers-faqs {
  background: #EAEAEA;
  padding: 1em 0 3em 0;
}

#dealers-faqs h3 {
  font-size: 1.5em;
  line-height: 1.75em;
  color: #2C272D;
  font-weight: 700;
}

#dealers-faqs .dealers-faqs-container {
  margin: 0 auto;
  max-width: 1430px;
}

#dealers-faqs .dealers-faqs-container > div {
  padding: 0 30px;
}

#dealers-faqs input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

 /* Accordion styles */

#dealers-faqs .tab {
  width: 100%;
  color: #000;
  overflow: hidden;
}

#dealers-faqs .tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em 1em 1em 2em;
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.2em;
  cursor: pointer;
  color: #2C272D;
}

#dealers-faqs .tab-label::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transform: rotate(90deg);
  transition: all 0.35s;
}

#dealers-faqs .tab-content {
  max-height: 0;
  padding: 0 2em;
  margin-top: 0;
  color: #2c3e50;
  border-bottom: 1px solid #A5A5A5;
  transition: all 0.35s;
}

#dealers-faqs input:checked + .tab-label {
  background: #CCCCCC;
  opacity: 0.5;
}

#dealers-faqs input:checked + .tab-label::after {
  transform: rotate(-90deg);
}

#dealers-faqs input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em 2em;
}
