/* BASE font-size: 15px */

/* Desktop */
.hero-section-container {
  height: 28rem;
  width: 100%;
  background: linear-gradient(85.27deg, transparent 0%, transparent 99.79%);
  max-height: 400px;
  }

.hero-section-container .hero-section {
  position: relative;
  background-color: transparent !important;
  padding-top: 3rem;
  padding-bottom: 3rem;
  width: 100%;
}

.hero-section-container .hero-section-image {
  position: absolute;
  opacity: 0.7;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-width: 100%;
  height: 28rem;
  background-position: 60% 51%;
  max-height: 400px;
}

.hero-section-container .hero-content .hero-title {
  font-size: 72px;
  font-weight: 900;
  letter-spacing: 0.01em;
  text-align: left;
  color: #07324F;
}

.hero-section-container .flex {
  display: flex;
}

.hero-section-container .items-center {
  align-items: center;
}

.hero-section-container .hero-section .hero-content {
  margin-left: 8%;
  max-width: 375px;
  min-width: 280px;
}

.hero-section-container .hero-section .hero-subtitle {
  margin-top: 1rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #07324F;
}

.hero-section-container .hero-content .trustpilot-widget {
  margin-bottom: 10px;
  text-align: left;
  width: fit-content;
  margin-left: -10px;
}

.hero-section-container .hero-content .trustpilot-widget-loading {
  margin-bottom: 10px;
  text-align: left;
  width: 300px;
  height: 24px;
  margin-left: -10px;
}

/* Mobile */
@media only screen and (max-width: 991px) {
    .hero-section-container .hero-section-container {
    height: 28rem;
  }

  .hero-section-container .hero-section-image {
    height: 27.5rem;
    background-position: 16% 50%;
    transform: scale(3, 3);
    object-position: -60px 73px;
    max-height: 218px;
    /* max-height: 180px;   */
    overflow: hidden;
    opacity: .3
  }

  .hero-section-container .hero-section .hero-content {
    margin-left: 22%;
    margin-right: 22%;
  }

  .hero-section-container .hero-content .hero-title {
    font-size: 42px;
    font-weight: 900;
    line-height: 49px;
    letter-spacing: 0.01em;
    text-align: center;
  }

  .hero-section-container .hero-section .hero-subtitle {
    font-size: 20px;
    text-align: center;
    margin-top: 35px 
  }

  .hero-section-container .hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/* Small Mobile */
@media only screen and (max-width: 450px) {
  .hero-section-container .hero-section .hero-content {
    margin-left: 0px;
    margin-right: 0px;
  }
}