.card {
  width: 288px;
  height: 200px;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  flex-shrink: 0;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08),
              0px 3px 6px rgba(0, 0, 0, 0.08),
              0px 1px 3px rgba(0, 0, 0, 0.1),
              0px 1px 3px rgba(0, 0, 0, 0.1),
              0px 1px 1px rgba(0, 0, 0, 0.1);
}

.quote-button {
  background: #E67D22;
  border-radius: 5px;
  padding: 11px 70px;
  height: 40px;
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.secondary .quote-button {
  background: white;
  color: #E67D22;
  border: 1px solid #E67D22;
  padding: 11px 45px;
}