.sticky-contact {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 2001;
  padding: 10px;
  box-shadow: 0px -1px 3px rgba(0,0,0,0.3);
  transition: bottom .8s ease-in-out;
}

.sticky-contact.fade{
  bottom: -100px;
}

 .sticky-contact .contact-buttons a {
  flex: 1;
  

} 

.sticky-contact .contact-buttons button {
  flex: 1;
  max-width: none;
}

.sticky-contact .contact-buttons button.contact-button-oem {
  width: 100%;
}



 