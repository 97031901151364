.ws-narrative-wrapper {
  display: block;
  max-width: 1133px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem 2rem;
  box-sizing: border-box;
  background-color: white;
  padding-top: 2rem;
}

.ws-narrative-wrapper h3 {
  color: #0f4d77;
  margin-top: 0;
}

.ws-narrative-wrapper p {
  margin-top: 0;
  margin-bottom: 30px;
}