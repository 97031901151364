.modal-carousel {
  height: 100%;
  width: 100%;
}

.modal-carousel.modal-carousel-legacy {
  background: #000000;
}

.modal-main-carousel {
  position:fixed;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  top:0;
  left:50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  height: 100%;
}
.modal-main-carousel .close-modal {
  display: block !important;
  position: absolute;
  background: none;
  cursor: pointer;
  border: none;
  right: 22px;
  top: 17px;
  z-index: 10;
}
.modal-main-carousel .close-modal img {
  filter: brightness(0);
}

.modal-carousel-legacy .modal-main-carousel {
  background: #000000;
}

[flex*="grow"] {
  flex-grow: 1;
}

[flex*="column"] {
  display: flex;
  flex-direction: column;
}

[flex*="justify-center"] {
  justify-content: center;
}

.carousel-slider-modal {
  width: 100%;
  height: 55%;
}

.close-carousel-modal {
  z-index: 4;
  position: absolute;
  right: 24px;
  top: 18px;
  cursor: pointer;
  background: url('modal-close-button.svg') no-repeat 50%;
  padding: 9px;
  border: 0;
  width: 18px;
  height: 18px;
}

.modal-carousel .details-contact {
  display: block;
  z-index: 3;
  margin-top: 6px;
}

.modal-carousel .details-contact .sticky-contact {
  display: none;
}

@media screen and (min-width: 768px) {

  .modal-carousel {
    z-index: 1010;
  }

  .carousel-slider-modal {
    width: 100%;
  }

  .modal-carousel .ad.w728{
    display: flex;
    justify-content: center;
    min-height: 90px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 0;
    margin: 0 auto;
  }

  .modal-carousel .details-contact {
    bottom: auto;
  }

}

@media screen and (min-width: 970px) {

  modal-carousel .ad.w728 {
    order: 1;
    margin: 1.5rem auto 0;
  }

  .modal-carousel {
    height: 100%;
    width: 100%;
    z-index: 1010;
  }

  .modal-carousel.modal-carousel-legacy {
    background: #000000;
  }

  .modal-carousel .modal-main-carousel {
    background: #000000;
  }
  
  .modal-main-carousel {
    margin: 0 auto;
  }
 
  .modal-carousel .modal-main-carousel .carousel-display-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    order: 2;
  }
  .modal-carousel .modal-main-carousel {
    max-width: 100%;
  }
}

.close-carousel-gallery-modal {
  display: none;
}