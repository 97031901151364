#boatDealers {
  background-color: var(--bt-color-neutral-10);
  width: 100%;
}

#boatDealers.page-container>.content.nav-slide {
 background-color: white;
}

#boatDealers footer.main-footer {
  margin-top: 2rem;
}

.dealers-spinner {
  position: relative;
  min-height: 200px;
  background-color: white;
}

.dealers-spinner .spinner-preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  transform: translate(-50%, -50%);
}

.dealers-container, .dealers-locations, .dealers-makes, .dealers-articles {
  padding: 1rem 2rem;
}

.dealers-locations {
  padding-bottom: 0;
}

.dealers-container {
  background-color: var(--bt-color-neutral-10);
}

.dealer-filters-container {
  color: #fff;
}

.dealers-container .dealers-reasults-text {
  font-weight: 400;
  font-size: 1em;
  line-height: 1.3em;
  color: black;
  margin-left: 0;
}

.dealer-filters-title {
  font-style: normal;
  font-size: 24px;
  font-weight: 900;
  line-height: 28px;
  padding: 0;
  margin: 0;
}

.dealers-ads {
  margin: 8px auto !important;
}

/*
Default sizes:
@media (min-width: 1440px)
@media (max-width: 960px)
@media (max-width: 767px)
*/

@media screen and (min-width: 768px) {
  .dealers-container, .dealers-locations, .dealer-filters-content, .dealers-makes,
  .dealers-articles {
    padding: 1rem 30px;
    max-width: 1430px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
   }

   .dealer-filters {
    min-height: 200px;
  }

   .dealers-ads {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
   }

  .dealer-filters-content {
    width: 100%;
    box-sizing: border-box;
  }

  .dealer-filters-title {
    font-style: normal;
    font-size: 32px;
    font-weight: 900;
    line-height: 38px;
    padding: 0;
    margin: 0 0 1rem 0;
  }
}

@media screen and (min-width: 900px) {
  .dealer-filters-container {
    background: #4492D0 url('https://images.boatsgroup.com/images/1/upload/bg-dealers-blue.png') no-repeat;
    min-height: 200px;
    background-size: cover;
    background-position: center;
  }
}
