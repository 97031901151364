/* BASE font-size: 15px */

/* Desktop */
.main-section .extended-services-faqs {
  background-color: #F2F2F2;
  padding: 3.2rem 16rem 2.6rem;
}

.breadcrumb-container {
  height: 2.666rem;
  background-color: #FFF;
  width: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-left: 1.533rem;
}

footer.main-footer {
  margin-top: 0;
}

/* Mobile */
@media only screen and (max-width: 991px) {
  .main-section .extended-services-faqs {
    padding: 2.4rem 1rem 1.2rem;
  }

  .breadcrumb-container {
    height: 2.266rem;
    padding-left: 0.4rem;
  }


  footer.main-footer {
    margin-top: 1.466rem;
  }
}
