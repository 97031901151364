/* BASE font-size: 15px */

/* Desktop */
.trust-pilot-section.desktop,
.trust-pilot-section.mobile {
  padding-top: 3rem;
  padding-bottom: 3rem;
  text-align: center;
  width: 75%;
  margin: 0 auto;
}

.trust-pilot-section.desktop {
  display: block;
}

.trust-pilot-section.desktop .trustpilot-widget-loading {
  height: 140px;
}

.trust-pilot-section.mobile {
  display: none;
}

/* Tablet */
@media only screen and (max-width: 1282px) {
}

/* Mobile */
@media only screen and (max-width: 991px) {
  .trust-pilot-section.desktop {
    display: none;
  }
  
  .trust-pilot-section.mobile {
    display: block;
  }

  .trust-pilot-section.mobile .trustpilot-widget-loading {
    height: 350px;
  }
}
