.services-head {
    margin-left: auto;
    margin-right: auto;
    max-width: 1320px;
}

.services-head h1 {
    margin: 0;
    padding: 15px;
    color: #0B3F65;
    font-size: 20pt;
}
.services-head h2 {
    margin-left: 15px;
    padding: 0;
    color: #333;
    font-size: 13pt;
}

.services-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1320px;
    background-image: linear-gradient(#EAEAEA, var(--bt-color-neutral-10) 280px);
    min-height: 200px;
    padding: 0 9px;
}

.service-group .service-group__header {
    background-repeat: 'no-repeat';
    background-position: 'center';
    background-size: cover;
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
    margin-bottom: 0.5em;
}

.service-group .service-group__title {
    color: #FFF;
    font-size: 1.25em;
    text-align: center;
    line-height: 1.4;
    text-shadow: 0 0 6px rgba(0,0,0,0.50), 0 0 1px rgba(0,0,0,0.50);
    padding: 2em;
}

.service-group .service-group__link {
    display: block;
    margin-top: 0.25rem;
    color: var(--bt-color-secondary_action-main);
    padding-left: 0.5em;
}

.services-content .right-content {
    margin-top: 2em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

@media screen and (min-width: 768px) {
    .services-content .left-content{
        display: flex;
        flex-wrap: wrap;
        padding-left: 0.5em;
        padding-right: 0.5em;
        justify-content: space-around;
    }
    .services-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .left-content .service-group { 
        flex-basis: 31%;
        padding: 0.5em;
    }
}

@media screen and (min-width: 970px) {
    
    .services-content .left-content {
        width: 950px;
    }

    .services-content .right-content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}