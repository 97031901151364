.main-section {
  background: #FFF !important;
}

.main-section .boatloans-rates {
  background: linear-gradient(180deg, #F2F2F2 0%, rgba(242, 242, 242, 0) 100%);
  margin-top: 0 !important;
  background: #FFF !important;
  padding: 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0 !important;
  background: #FFF !important;
  padding: 0 !important;
}

.breadcrumb-container {
  height: 2.666rem;
  background-color: #FFF;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-left: 1.533rem;
}

@media (max-width: 912px) {
  .main-section .boatloans-rates {
    padding: 0 !important;
  }

  .breadcrumb-container {
    height: 2.266rem;
    padding-left: 0.4rem;
  }

}
