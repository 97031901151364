/* ANA-1738*/
/* .tool-set-radio-group {
  display: flex;
  border: 1px solid #c2c2c2;
  border-radius: 6px;
  flex-grow: 1;
  overflow: hidden;
}

.tool-set-radio-item {
  flex-grow: 1;
}

.tool-set-radio-item label {
  padding: 8px 6px;
  display: block;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  background: var(--bt-color-neutral-10);
  color: #616161;
}

.tool-set-radio-item.checked label {
  font-weight: 500;
  background: #e6f0ff;
  color: #0f4d77;
}

.tool-set-radio-item + .tool-set-radio-item {
  border-left: 1px solid #a5a5a5;
} */

/* ANA-1745*/
.tool-set-radio-group {
  display: flex;
  flex-grow: 1;
}
.tool-set-radio-item {
  flex-grow: 1;
  border-width: 1px;
  border-color: #c2c2c2;
  border-style: solid;
  overflow: hidden;
}
.tool-set-radio-item:first-child {
  border-radius: 6px 0px 0px 6px;
  border-style: solid none solid solid;
}
.tool-set-radio-item:last-child {
  border-radius: 0px 6px 6px 0px;
  border-style: solid solid solid none;
}
.tool-set-radio-item.checked {
  border-color: #0f4d77;
  border-style: solid;
}
.tool-set-radio-item.checked + .tool-set-radio-item{
  border-left-style: none;
}
.tool-set-radio-item label {
  padding: 8px 6px;
  display: block;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  background: var(--bt-color-neutral-10);
  color: #616161;
}
.tool-set-radio-item.checked label {
  font-weight: 500;
  background: #e6f0ff;
  color: #0f4d77;
}


