
.thumbs-wrapper {
  position: relative;
  height: 77px;
  contain: layout size style;
  overflow: hidden;
}

.carousel-thumbs {
  height: 200px;
  overflow-x: scroll;
  will-change: scroll-position;
  contain: size style layout;
}

  .carousel-thumbs__wrapper {
    white-space: nowrap;
    position: relative;
    transition: right 0.5s;
  }

    .carousel-thumbs__img-wrapper {
      width: 19%;
      height: 70px;
      display: inline-block;
      overflow: hidden;
      margin-top: 1%;
      margin-left: 0.5%;
      margin-right: 0.5%;
    }

    .carousel-thumbs__img-wrapper.selected {
      position: relative;
    }

    .carousel-thumbs__img-wrapper.selected:before {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      box-shadow: inset 0 0 0 4px #dc6925;
    }

      .carousel-thumbs__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }


.carousel .control-next.control-arrow::hover {
  opacity: 0;
}


.carousel .control-prev.control-arrow::hover {
  opacity: 0;
}


.thumbs-wrapper .control-next.control-arrow {
  border-left: 0;
  position: absolute;
  display: inline-block;
  width: 1.1em;
  height: 2.2em;
  background: url('../../icons/carousel-arrow-right.svg') no-repeat 50%;
  background-size: auto 100%;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}


.thumbs-wrapper .control-prev.control-arrow {
  border-right: 0;
  position: absolute;
  display: inline-block;
  width: 1.1em;
  height: 2.2em;
  background: url('../../icons/carousel-arrow-left.svg') no-repeat 50%;
  background-size: auto 100%;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (min-width: 768px) {
  .thumbs-wrapper {
    height: 117px;
  }
  .carousel-thumbs__img-wrapper {
    height: 110px;
  }
}