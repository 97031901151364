.btn-action {
  box-shadow: 0 2px 0 rgba(255, 255, 255, 0.35) inset;
  border-radius: 4px;
  font-size: 1.2em;
  display: block;
  cursor: pointer;
  border: 1px solid #eea236;
  padding: 12px 0;
  background-color: #e47e30;
  color: #fff;
  width: 100%;
  height: 50px;
  line-height: 1.33em;
}

.btn-action:hover,
.btn-action:focus,
.btn-action:active {
  border: none;
}

.contact-form {
  background: #0f4d77;
  border-radius: 0 3px 3px 0;
  z-index: 5;
}

.contact-form form .footer{
  width: 100%;
}

.contact-form .title{
  font-size: 1em;
  margin: 0 0 15px;
  font-weight: 700;
}

.contact-form fieldset{
  position: relative;
  padding: 0;
  margin: 0 0 10px;
  display: block;
  clear: both;
  border: none;
}



.contact-form form.error fieldset.error input[type="text"],
.contact-form form.error fieldset.error input[type="number"]{
  border: 2px solid #f00;
  padding: 6px 9px;
}

.contact-form form.error fieldset.error textarea{
  border: 2px solid #f00;
  padding: 6px 9px;
}

.contact-form fieldset.hidden{
  display: none;
}

.contact-form input[type="text"]{
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid #dee2e3;
  font-size: 1em;
  line-height: 1.2em;
  height: 2.4em;
  width: 100%;
  z-index: 2;
  padding: .5em 10px;
  -webkit-appearance: none;
  box-shadow: none;
}

.contact-form input[type="number"]{
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid #dee2e3;
  font-size: 1em;
  line-height: 1.2em;
  height: 2.4em;
  width: 100%;
  z-index: 2;
  padding: .5em 10px;
}

.contact-form label{
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid #dee2e3;
  font-size: 1em;
  line-height: 1.2em;
  height: 2.4em;
  width: 100%;
  z-index: 2;
  padding: .5em 10px;
  pointer-events: none;
  position: absolute;
  border-color: transparent;
  top:0;
  left: 0;
  color: #777;
}

.contact-form label.hidden {
  display: none;
  left: -999px;
}
#oem-contact-form .contact-form textarea,
.contact-form textarea {
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid #dee2e3;
  font-size: 1em;
  line-height: 1.2em;
  height: 2.4em;
  width: 100%;
  z-index: 2;
  padding: 7px 0 0 10px;
  resize: vertical;
  height: 7em;
  color: #666;
}

.contact-form.private-seller {
  background: transparent;
}

.contact-form .contact-tabs {
  display: none;
}

.contact-form .form-heading {
  display: block;
	margin-bottom: 12px;
	font-weight: bold;
}

.contact-form .message-sent {
  color: #fff;
}

.contact-form .message-error {
  color: #fff;
  text-align: center;
}

.contact-form .message-sent.hidden, .contact-form .message-error.hidden {
  display:none;
}

.contact-form .message-sent h4, .contact-form .message-error h4{
	margin: 10px 0;
  font-size: 2em;
  font-weight: normal;
}

.contact-form .message-sent p, .contact-form .message-error p {
  font-size: 14px;
}

.contact-form .errorTxt{
  font-size: .8em;
  margin-top: -5px;
  margin-bottom: 10px;
  display: block;
}

.contact-form .errorTxt.hidden{
  display: none;
}

.contact-form .policies{
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 13px;
  font-size: 13px;
}
.contact-form .title.underlined{
  border-bottom: 1px solid #EAEAEA;
}

#results-contact-form {
  z-index: 99999;
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
  width: auto;
  margin: 0;
  padding: 0;
  background-color: #fff;
  box-shadow:0 5px 15px rgba(34, 34, 34, 0.5);
}

#contact-form-modal .title, #results-contact-form .title {
  color: #2876aa;
  font-weight: 700;
  margin: 5px 0 15px;
  text-align: left;
}

#contact-form-modal .subtitle, #results-contact-form .subtitle {
  color: #2876aa;
  font-weight: normal;
  font-size: .85em;
  margin-bottom: 15px;
}

#contact-form-modal button.contact-close, #results-contact-form button.contact-close {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 50px;
  height: 50px;
  border-width: 0;
  background: url('//boattrader.com/static/img/icons/close-x-mobile.png') no-repeat center center transparent;
  display:block;
}

#contact-form-modal .main-form, #results-contact-form .main-form,
#sponsored-boats #results-contact-form .main-form {
  padding: 15px;
}

#contact-form-modal .main-form.hidden, #results-contact-form .main-form.hidden {
  display: none;
}

#contact-form-modal .message-error, #results-contact-form .message-error {
  color: #d9534f;
  clear: both;
  padding: 0 15px 15px 15px;
  text-align: left;
  font-size: 12px;
}

#contact-form-modal .message-error .title, #results-contact-form .message-error .title {
  color: #d9534f;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1em;
  margin: 10px 50px 15px;
}

#contact-form-modal .message-sent, #results-contact-form .message-sent {
  color: #2876aa;
  padding: 15px;
}

#contact-form-modal .message-sent .title, #results-contact-form .message-sent .title {
  font-size: 1.3em;
  font-weight: 700;
}

textarea#comments {
  overflow-y: auto;
  resize: none;
}

.message-list{
  font-size: 16px;
  margin-bottom: 10px;
  color: #444;
}

#oem-contact-form {
  background: #fff;
  padding: 0 96px;
  margin-bottom: 20px;
}

#oem-contact-form button.contact-close{
  display: none;
}

#oem-contact-form .main-form{
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

#oem-contact-form .main-form.hidden{
  display: none;
}

#oem-contact-form .message-error .error-alert,
#oem-contact-form .message-sent{
  margin-top: 30px;
}

#oem-contact-form .message-sent{
  color: #474C4A;
}
#oem-contact-form .message-title {
  margin: 11px 0 9px;
}

#oem-contact-form .main-form .title{
  font-size: 22px;
  font-weight: bold;
  color: #002A45;
}
#oem-contact-form .main-form .title.centered{
  text-align: center;
}

#oem-contact-form .main-form .title.underlined{
  margin: 0 0 8px;
  padding-bottom: 15px;
}

#oem-contact-form .main-form .subtitle{
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: normal;
  color: #333333;
}

#oem-contact-form .message-error{
  font-size: 14px;
  color: #D9534F;
  text-align: left;
  margin-bottom: 7px;
  margin-top: 0;
}

#oem-contact-form .main-form form{
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#oem-contact-form .main-form form fieldset{
  margin-bottom: 15px;
}

#oem-contact-form .main-form form fieldset.fieldset.fieldset-name,
#oem-contact-form .main-form form fieldset.fieldset.fieldset-phone,
#oem-contact-form .main-form form fieldset.fieldset.fieldset-email,
#oem-contact-form .main-form form fieldset.fieldset.fieldset-zip
{
  width: 48%;
}

#oem-details-contact-form .main-form form fieldset.hidden,
#oem-contact-form .main-form form fieldset.hidden{
  display: none;
}

#oem-details-contact-form .main-form form fieldset.fieldset.fieldset-comments,
#oem-contact-form .main-form form fieldset.fieldset.fieldset-comments{
  width: 100%;
  margin-bottom: 0;
}

#oem-contact-form .main-form form fieldset label{
  padding: 0;
  position: unset;
  font-size: 14px;
  height: auto;
}

#oem-contact-form .main-form form .footer{
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

#oem-contact-form .main-form form button.btn-action{
  width: 50%;
  margin-top: 16px;
  background: #e37a1f;
  box-shadow: none;
  border: none;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

#oem-contact-form .main-form form .policies{
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-size: 13px;
  margin-top: 4px;
  color: #333333;
}

#oem-contact-form .main-form form .policies a{
  color: #333333;
  text-decoration: underline;
}

#oem-details-contact-form .main-form form fieldset label {
  display: block;
  padding: 0;
  position: unset;
  font-size: 14px;
  height: auto;
}

#oem-details-contact-form .main-form form fieldset.fieldset.fieldset-name,
#oem-details-contact-form .main-form form fieldset.fieldset.fieldset-email {
  width: 100%;
}

#oem-details-contact-form .main-form form fieldset.fieldset.fieldset-phone,
#oem-details-contact-form .main-form form fieldset.fieldset.fieldset-zip {
  width: 100%;
}

#oem-details-contact-form .main-form form fieldset.fieldset.fieldset-comments {
  margin-bottom: 10px;
}

.financing-checkbox {
  margin-top: 10px;
  font-size: 12px;
}

.financing-checkbox input[type='checkbox'] {
  background-color: #2566b0 !important;
  margin-right: 8px;
  margin-bottom: -1px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #2566b0;
  width: 14px !important;
  height: 14px !important;
  padding: 0px 0px !important;
  border: 1px solid #2566b0 !important;
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: 2px !important;
}

.financing-checkbox input[type='checkbox']:checked {
  background-color: #2566b0 !important;
  position: relative;
}

.financing-checkbox input[type='checkbox']:hover {
  background-color: #2566b0 !important;
}

.financing-checkbox input[type='checkbox']:checked:hover {
  background-color: #2566b0 !important;
}

.financing-checkbox input[type='checkbox']:not(:checked):hover {
  background-color: #fff !important;
}

.financing-checkbox input[type='checkbox']:checked::after {
  background-color: #2566b0 !important;
  position: relative;
  content: '';
  position: absolute;
  top: 0px;
  left: 3px;
  width: 14px;
  height: 14px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.financing-checkbox label {
  color: #333;
  position: relative;
  padding: unset;
}

@media only screen and (max-width: 600px) {
  .financing-checkbox {
      margin-top: 5px !important;
  }
}

@media (min-width: 970px) {
  #contact-form-modal button.contact-close, #results-contact-form button.contact-close {
    float: right;
    width: 16px;
    height: 16px;
    margin: 15px;
    background: url('//boattrader.com/static/img/icons/close-x.png') no-repeat 0 top;
  }

  #contact-form-modal button.contact-close:hover, #results-contact-form button.contact-close:hover {
    background-position: 0 bottom;
    cursor: pointer;
  }

  #contact-form-modal h5, #results-contact-form h5 {
    margin: 0 0 15px;
  }

}

@media screen and (max-width: 971px) {
  #oem-contact-form {
    padding: 0 16px;
  }

  #oem-contact-form .main-form form .footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #oem-contact-form .main-form form fieldset.fieldset.fieldset-email,
  #oem-contact-form .main-form form fieldset.fieldset.fieldset-zip
  {
    width: 100%;
  }

  #oem-contact-form .main-form form .policies{
    margin: 13px 0;
    justify-content: center;
  }
}

.information-brand-container .contact-dealer {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: 0;
  text-align: center;
  width: 150px;
  height: 30px;
  border-radius: 5px;
  background-color: #f06e2d;
  color: #fff;
  cursor: pointer;
  margin-bottom: 5px;
}

.contact-dealer:before {
  content: "";
  background: url(./Mail.svg) no-repeat;
  width: 21px;
  height: 16px;
  display: inline-block;
  margin: 0 8px -3px;
}

/**
  Custom styles for contact form modal on Dealer Page
 */
.contact-form-container-modal {
  position: fixed;
  width: 100%;
  background-color: rgb(34 27 34 / 50%);
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1100;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

#contact-form-modal {
    left: auto;
    right: auto;
    bottom: auto;
    width: 95%;
    position: absolute;
    margin: 0;
    padding: 0;
    background-color: #fff;
    box-shadow: 0 5px 15px #333;
    z-index: 99;
}

.contact-form-container-modal .success-alert .message-box  {
  text-align: center;
  padding: 20px;
}

@media screen and (min-width:768px) {
  #contact-form-modal {
    max-width: 500px;
  }
}

.grecaptcha-badge { visibility: hidden; }

.contact-form-private-seller-summary {
  z-index: 101;
  width: 326px;
}

.contact-form-private-seller-summary button.contact-close {
  width: 14px;
  height: 14px;
  border: none;
  cursor: pointer;
  overflow: hidden;
}

.contact-form-private-seller-summary .contact-form {
  border-radius: 12px;
  border: 1px solid #EEE;
  background: #FFF;
  box-shadow: 0 10px 20px 0 rgba(255, 255, 255, 0.90), 0 9px 22px 0 rgba(0, 0, 0, 0.10);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 16px 16px 20px 16px;
}

.contact-form-private-seller-summary .contact-form .main-form .message-error {
  color: red;
  padding-bottom: 1rem;
}

.contact-form-private-seller-summary .title-and-close-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.contact-form-private-seller-summary .main-form {
  width: 100%;
}

.contact-form-private-seller-summary .main-form input::placeholder {
  color: #404040;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  box-shadow: unset;
  border: none;
}

.contact-form-private-seller-summary .main-form textarea {
  height: 73px;
}

.contact-form-private-seller-summary .form-inline-title {
  color: #404040;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.contact-form-private-seller-summary .main-form .footer .btn-action {
  display: flex;
  width: 100%;
  height: 46px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 50px;
  background: #2666b0;
  border: none;
  box-shadow: unset;
}

.contact-form-private-seller-summary .contact-form .message-sent message-box-for-private-seller {
  width: 357px;
  height: 110px;
  border-radius: 4px;
  border: 1px solid #00AC91;
  background: #FFF;
}

.contact-form-private-seller-summary .contact-form-container{
  margin-bottom: 12px;
}

.contact-form-private-seller-summary .contact-form-address{
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: normal;
  text-decoration: none;
  text-transform: none;
}

.contact-form-private-seller-summary .contact-form-phone{
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: normal;
  text-decoration: none;
  text-transform: none;
  color: var(--bt-color-secondary_action-main);
  padding-bottom: 4px;
}


@media screen and (max-width: 969px) {
  .contact-form-private-seller-summary .contact-form {
    box-shadow: 0 8px 20px 0 rgba(27, 38, 55, 0.10);
    width: 326px;
  }
}
