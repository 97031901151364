.sell-your-boat {
    background-color: var(---bt-color-tertiary);
    padding: 16px;
    margin: 14px -8px 0 -8px;
    border-top: 1px solid var(--bt-color-neutral-20);
}

.sell-your-boat h2, 
.sell-your-boat p {
    display: none;
}

@media screen and (width < 700px) {
    .home-search {
        height: 440px !important;
    }

    .sell-your-boat {
        border-top: none;
        margin: 0px -8px 0 -8px;
    }
    
    .sell-your-boat a {
        min-height: 48px;   
    }
}

@media screen and (min-width: 700px) {
    .sell-your-boat {
        display: block;
        padding:16px;
        margin-top: 0px;
    }
      
    .sell-your-boat h2 {
        margin: 0 0 8px;
        font-size: 1.4em;
        line-height: 1.1em;
        color: #fff;
        margin-bottom: 4px;
    }
      
    .sell-your-boat p{
        margin:.4em .4em .4em 0;
        padding-bottom: 5px;
        color: #abbdce;
        font-size: 0.8125em;
    }
      
    .sell-your-boat h2, 
    .sell-your-boat p {
          display: block;
    }

    .sell-your-boat a {
        min-height: 34px;   
    }
      
}


.fsbo-banner-wrapper {
    margin: 0 12px -12px;
    padding: 24px 0;
}

.fsbo-banner-wrapper--last {
    margin: 0 12px 12px;
}

.fsbo-banner {
    padding: 0;
    background-color: #FFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
}
  
.fsbo-banner__content {
    box-sizing: border-box;
    width: 40%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  
.fsbo-banner__title {
    font-size: 24px;
    color: rgb(15, 77, 119);
    margin: 0;
}
  
.fsbo-banner__text {
    font-size: 18px;
    color: rgb(102, 102, 102);
    margin-top: 16px;
    margin-bottom: 0;
    line-height: 1.5;
}
  
.fsbo-banner__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
  
.fsbo-banner__spacer {
    width: 8px;
}
  
.fsbo-banner__btn {
    display: inline-block;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    color: #FFF;
    margin-top: 16px;
    margin-bottom: 0;   
    background: #5cb85c;
    align-self: flex-start;
    border-radius: 4px;  
    border: 2px solid #5cb85c;
    box-sizing: border-box;
    width: 50%;
    text-align: center;
}
  
.fsbo-banner__btn:last-of-type {
    background: #fff;
    border: 2px solid rgb(15, 77, 119);
    color: rgb(15, 77, 119);
}
    

      
@media screen and (min-width: 500px) {
    .fsbo-banner__img-box {
        display: flex;
        overflow: hidden;
        align-items: center;
        max-height: 320px;
        width: 60%;
        margin-top: 0;
    }
}
  
.fsbo-banner__img {
    width: 100%;
}
  
@media screen and (max-width: 420px) {
    .fsbo-banner__buttons {
        flex-direction: column;
    }
  
    .fsbo-banner__btn {
        width: 100%;
    }
}
  
@media screen and (max-width: 878px) {
    .fsbo-banner {
        flex-direction: column-reverse;
    }
    
    .fsbo-banner__img-box, .fsbo-banner__content {
        width: 100%;
    }
}
  
@media screen and (min-width: 879px) {
    .fsbo-banner__img-box, .fsbo-banner__content {
        width: 50%;
    }
}
  
@media screen and (min-width: 970px) and (max-width: 1319px) {
    .fsbo-banner {
        flex-direction: column-reverse;
    }
    
    .fsbo-banner__img-box, .fsbo-banner__content {
        width: 100%;
    }
}
