
.related__content {
  border: 1px solid #000;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 10px;
  border-radius: 4px;
  margin-bottom: 40px;
}

.related__content h2 {
  flex: 0 0 auto;
  margin: 0 auto;
  flex-basis: 100%;
  line-height: 1;
  border-bottom: 2px solid #C4C4C4;
  padding-bottom: 10px;
  color: #0f4d77;
  font-size: 1.17em;
}

.related__model {
  display: flex;
  flex-flow: row wrap;
}

.related__condition {
  display: flex;
  flex-flow: row wrap;
  margin-top: 20px;
}

.related__list_item {
  width: 50%;
  margin: 0 auto;
  display: inline-flex;
  padding: 0;
  border-bottom: 1px solid #C4C4C4;
  justify-content: space-between;
  flex: 1 50%;
}

.related__list_item a, .related__list_item div {
  margin: 0;
  padding: 10px 15px;
  align-self: flex-start;
}

.related__list_item span {
  padding: 10px 15px;
}

.related__listing_italic {
  font-style: italic;
}

@media screen and (min-width: 970px) {

  .related__list_item a, .related__list_item div {
    max-width: 350px;
  }

}

@media screen and (max-width: 767px) {
  .related__list_item {
    width: 100%;
    flex: none;
  }
}