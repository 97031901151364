.home-search .search-window {
  z-index: 100;
  white-space: nowrap;
  transform: translateX(0%);
  width:100%;
  height: 280px;
}

.home-search .search-window.search-window-engine {
  transform: translateX(-100%);
}

.home-search .search-window::after {
  position: absolute;
  top:355px;
  left:11px;
  width:50px;
  height:11px ;
  z-index: 101;
  display: block;
}

.home-search .tabs-container {
  color: white;
  margin: 6px 0 8px 0;
  width: 100%;
  display: inline-block;
  z-index: 1;
  padding: 0 8px;
  box-sizing: border-box;
}

.home-search .tabs-container .tabs {
  display: block;
  width: 100%;
  height: 50px;
  margin: 0;
  padding: 0;
  position: relative;
  list-style-type: none;
  overflow:hidden;
}

.home-search .tabs-container .tabs li {
  display: block;
  margin:  0;
  padding: 0;
  float: left;
  width: 50%;
}

.home-search .tabs-container .tabs li a {
  display: block;
  background: none;
  color: white;
  text-decoration: none;
  font-size: 16px;
  float: left;
  height: 30px;
  width:100%;
  text-align: center;
  border-bottom: 1px solid #FFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 0;
  opacity: .6;
}

.home-search .tabs-container .tabs li a img {
  width: 46px;
}

.home-search .tabs-container .tabs li a span {
  font-weight: bold;
  font-size: 14px;
}

.home-search .tabs-container .tabs li a.boats span {
  margin-left: 12px;
}

.home-search .tabs-container .tabs li a.engines img {
  margin-left: -12px;
}

.home-search .tabs-container .tabs li a:hover {
  opacity: 1;
  border-bottom: 3px solid #1e90ff;
}

.home-search .tabs-container .engines,
.home-search .tabs-container .boats{
  position:relative;
}

.home-search .tabs-container .engines.active:after,
.home-search .tabs-container .boats.active:after {
  content:none;
}

.home-search .tabs-container li a.active{
  border-bottom: 3px solid #1e90ff;
  opacity: 1;
}

.home-search .custom-select {
  position: relative;
  outline: none;
}

.home-search .custom-select .heading {
  line-height: 31px;
  color: #444;
  padding: 4px 12px;
  position: relative;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  background: #fff;
}

.home-search .custom-select .heading .icon {
  background: url("//boattrader.com/static/img/icons/select-chevron.png") no-repeat 0 0;
  width: 8px;
  height: 8px;
  position: absolute;
  right: 10px;
  top: 19px;
}

.home-search .custom-select .custom-dropdown.custom-dropdown-open {
  display: block;
}

.home-search .custom-select .custom-dropdown {
  position: absolute;
  width: 100%;
  display: none;
  padding-bottom: 6px;
  background-color: #fff;
  -webkit-border-top-right-radius:    0;
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius:  4px;
  -webkit-border-top-left-radius:     0;

  -moz-border-radius-topright:        0;
  -moz-border-radius-bottomright:     4px;
  -moz-border-radius-bottomleft:      4px;
  -moz-border-radius-topleft:         0;

  border-top-right-radius:            0;
  border-bottom-right-radius:         4px;
  border-bottom-left-radius:          4px;
  border-top-left-radius:             0;
  z-index: 10;
}

.home-search .custom-select .text-filter {
  background-color: #fff;
  padding: 0 4px;
  margin-bottom: 10px;
}

.home-search .custom-select .text-filter input.filter-input {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  display: none;
  width: 100%;
  border: 1px solid #bdc3c7;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  min-height: 36px;
  padding: 7px 20px 7px 7px;
  color: #000;
  font-size: 1em;
  margin: 0;
  background: url("//boattrader.com/static/img/icons/icon-search.png") no-repeat right center;
}

.home-search .custom-select.open .heading {
  -webkit-border-top-right-radius:    4px;
  -webkit-border-bottom-right-radius: 0px;
  -webkit-border-bottom-left-radius:  0px;
  -webkit-border-top-left-radius:     4px;
  -moz-border-radius-topright:        4px;
  -moz-border-radius-bottomright:     0px;
  -moz-border-radius-bottomleft:      0px;
  -moz-border-radius-topleft:         4px;

  border-top-right-radius:            4px;
  border-bottom-right-radius:         0px;
  border-bottom-left-radius:          0px;
  border-top-left-radius:             4px;

}

.home-search .custom-select.open .heading .txt,
.home-search .custom-select.open .heading .icon {
  opacity: 0.5;
}

.home-search .custom-select.open input.filter-input {
  display: block;
}

.home-search .custom-select ul {
  clear: both;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 10px 4px 0px;
}

.home-search .custom-select ul::-webkit-scrollbar {
  width: 12px;
}

.home-search .custom-select ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  -moz-border-radius-topleft: 4px;

  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px
}

.home-search .custom-select ul::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
  background-color: #aeb4b5;
  height: 10%;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px
}

.home-search .custom-select ul li {
  background-color: #fff;
  cursor: pointer;
  margin: 0;
  min-height: 1em;
  padding: 3px 0 4px;
  text-indent: 7px;
  width: auto;
  overflow: hidden;
}

.home-search .custom-select ul li:hover,
.home-search .custom-select ul li.focus,
.home-search .custom-select ul li.selected {
  background-color: var(--bt-color-secondary_action-main);
  color: #fff;
}

.home-search .custom-select ul li.filtered {
  height: 0;
  min-height: 0;
  padding: 0 7px;
  opacity: 0;
}

.home-search .custom-select ul li.group-heading {
  font-weight: bold;
  cursor: default;
}

.home-search .custom-select ul li.group-heading:hover {
  background-color: #fff;
  color: #000;
}

.home-search .custom-select ul li.group-heading.filtered {
  height: auto;
  min-height: 1em;
  opacity: 1;
  padding: 3px 7px 4px;
}

.home-search .custom-select ul li ul {
  display: block;
  max-height: none;
  overflow-y: hidden;
  position: static;
  top: 0;
  margin: 0;
  padding: 4px 0 0;
  font-weight : normal;
}

.home-search .custom-select ul li:not(.group-heading) {
  text-indent: 12px;
}

.home-search .custom-select ul li.filtered:not(.group-heading) {
  text-indent: 12px;
}

.quick-search {
  background-color: initial;
  display: inline-block;
  width: 100%;
  white-space: normal;
  float:none;
  padding:0;
  margin:0;
  vertical-align: top;
  margin: 0px;
}

.quick-search form {
  padding:0 8px 8px 8px;
}

.quick-search form .btn-action {
  border: none;
  box-shadow: none;
  height: 44px;
}

.quick-search h1 {
  margin: 0 0 14px;
  color: #fff;
  padding:8px 8px 0px 8px;
  font-size: 1.40em;
  line-height: 1.4em;
  font-weight: 500;
}

.quick-search fieldset {
  border: none;
  margin: 0 0 18px;
  padding: 0;
}

.quick-search fieldset label {
  background: #fff;
  display: block;
  color: #bdc3c7;
  font-size: 0.5625em;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 11px;
  margin: 0;
  padding: 8px 15px 5px;
  text-transform: uppercase;
  border-bottom: 1px solid #ecf0f1;
  -webkit-border-top-right-radius:    4px;
  -webkit-border-bottom-right-radius: 0px;
  -webkit-border-bottom-left-radius:  0px;
  -webkit-border-top-left-radius:     4px;
  -moz-border-radius-topright:        4px;
  -moz-border-radius-bottomright:     0px;
  -moz-border-radius-bottomleft:      0px;
  -moz-border-radius-topleft:         4px;

  border-top-right-radius:            4px;
  border-bottom-right-radius:         0px;
  border-bottom-left-radius:          0px;
  border-top-left-radius:             4px;
}

.quick-search fieldset select {
  border: none;
  width: 100%;
  padding-left: 10px;
  color: #444;
  font-size: 1em;
  height: 2.1em;
  -webkit-border-top-right-radius:    0;
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius:  4px;
  -webkit-border-top-left-radius:     0;

  -moz-border-radius-topright:        0;
  -moz-border-radius-bottomright:     4px;
  -moz-border-radius-bottomleft:      4px;
  -moz-border-radius-topleft:         0;

  border-top-right-radius:            0;
  border-bottom-right-radius:         4px;
  border-bottom-left-radius:          4px;
  border-top-left-radius:             0;
}

.quick-search fieldset.locationSet {
  background-color: initial;
  text-align: center;
  position: relative;
  -webkit-border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -webkit-border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
  -moz-border-radius-bottomleft: 3px;
  -moz-border-radius-topleft: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  max-height: 43px; 
}

.quick-search fieldset.locationSet label {
  display: inline;
  border: none;
  padding: 0;text-transform: none;
  font-size: 1em;
  line-height: 43px;
  font-weight: normal;
  color: #fff;
  background: transparent;
}

.quick-search fieldset.locationSet select {
  max-width: 90px;
  width: auto;
  height: auto;
  padding: 0;
  color: #fff;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.quick-search fieldset.locationSet select.distance-filter {
  display: none;
}

.quick-search fieldset.locationSet .location-display {
  color: #00acfa;
  text-decoration: underline;
  cursor: pointer;
  outline: none;
}

.quick-search fieldset.locationSet .location-display.open {
  color: var(--bt-color-secondary_action-main);
  text-decoration: none;
}

.quick-search fieldset.locationSet .location-display:after {
  content: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCA4IDYiIHdpZHRoPSI4IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im00IDUuNDY5OTcgNC00LS45NC0uOTM5OTk5LTMuMDYgMy4wNTMzMjktMy4wNi0zLjA1MzMyOS0uOTQuOTM5OTk5eiIgZmlsbD0iIzI1NjZiMCIvPjwvc3ZnPg==");
  height: 34px;
  padding: 0 0 0 6px;
}

.quick-search fieldset.locationSet .location-entry {
  background: #fff;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  position: absolute;
  left: 41%;
  top: 45px;
  padding: 7px;
  display: none;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
	-moz-box-shadow:    0 6px 12px rgba(0, 0, 0, 0.176);
	box-shadow:         0 6px 12px rgba(0, 0, 0, 0.176);
}

.quick-search fieldset.locationSet .location-entry:after {
  border-color: transparent transparent var(--bt-color-neutral-10);
  border-style: solid;
  border-width: 0 5px 8px;
  content: "";
  height: 0;
  left: 67%;
  margin-left: -5px;
  position: absolute;
  top: -8px;
  width: 0;
}

.quick-search fieldset.locationSet .location-entry.open {
  display: block;
}

.quick-search fieldset.locationSet .location-entry .location-heading {
  font-size: 11px;
  font-weight: 600;
}

.quick-search fieldset.locationSet .location-entry .location-value {
  width: 150px;
  background: transparent;
  color: #000;
  border: 1px solid #dee2e3;
  font-size: 1em;
  min-height: 26px;
  border-radius: 4px;
}

.quick-search fieldset.locationSet .location-entry .location-error {
  border: 1px solid red;
}

.quick-search fieldset.locationSet .custom-select.distance-filter {
  display: none;
}

/* .quick-search fieldset.locationSet .custom-select .inline(); */
.quick-search fieldset.locationSet .custom-select .heading {
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.quick-search fieldset.locationSet .custom-select .heading .txt {
  color: #fff;
  text-decoration: underline;
}

.quick-search fieldset.locationSet .custom-select .heading .icon {
  display: none;
}

.quick-search fieldset.locationSet .custom-select .custom-dropdown {
  width: 160px;
  margin: 8px 0 0 -50%;
  padding: 0;
  background: transparent;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
	-moz-box-shadow:    0 6px 12px rgba(0, 0, 0, 0.176);
	box-shadow:         0 6px 12px rgba(0, 0, 0, 0.176);
}

.quick-search fieldset.locationSet .custom-select .custom-dropdown:after {
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 0 5px 8px;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  top: -8px;
  width: 0;
}

.quick-search fieldset.locationSet .custom-select .custom-dropdown .text-filter {
  display: none;
}

.quick-search fieldset.locationSet .custom-select .custom-dropdown ul {
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  max-height: none;
  line-height: 1.3em;
  padding: 0;
  height: 150px;
  overflow: auto;
}

.quick-search fieldset.locationSet .custom-select .custom-dropdown ul li {
  text-indent: 0;
}

.quick-search fieldset.locationSet .custom-select.open .heading .txt, 
.quick-search fieldset.locationSet .custom-select.open .heading .icon {
  color: var(--bt-color-secondary_action-main);
  text-decoration: none;
  opacity: 1;
}

.home-search .fixedSizeList{
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

@media screen and (min-width: 700px) {
  .home-search .tabs-container {
    padding-left: 8px;
  }
  .home-search .search-window{
    height: 269px;
  }

  .quick-search fieldset.locationSet {
    text-align: left;
  }

  .quick-search fieldset.locationSet .location-display {
    color: #1e90ff;
    text-decoration: underline;
  }

  .quick-search fieldset.locationSet .location-display.open {
    color: var(--bt-color-secondary_action-main);
    text-decoration: none;
  }

  .quick-search fieldset.locationSet label{
    line-height: 20px;
    margin: 0 0 10px 8px;
  }

  .quick-search fieldset.locationSet label:first-child {
    margin-left: 0;
  }

}
