.prequalified-modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10012;
  background-color: rgba(51, 51, 51, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.prequalified-container {
  max-width: 330px;
  width: 330px;
  height: fit-content;
  z-index: 10013;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 1rem;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
}

.prequalified-bar {
  width: 100% !important;
  height: 36px;
  display: flex;
  justify-content: space-between;
}

.prequalified-modal-container button.modal-close,
.inline-prequalified-form button.modal-close,
.inline-prequalified-success button.modal-close {
  width: 14px;
  height: 14px;
  background: url('./close-icon.svg') no-repeat center center transparent;
  border: none;
  cursor: pointer;
}

.prequalified-instructions {
  margin: 15px 0 5px !important;
  font-weight: 300 !important;
  font-size: 12px !important;
  line-height: 14.06px !important;
}

.prequalified-title {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
}

img.prequalified-icon-boat {
  width: 42px;
  height: 42px;
}

.prequalified-logo-financing {
  display: flex !important;
  flex-direction: column !important;
}

.prequalified-financing {
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 15px !important;
  line-height: 25px !important;
  color: #0B3F65 !important;
}

/* Footer */
.prequalified-powered-by, .prequalified-privacy-policy {
  margin-top: 19px;
  display: flex;
  justify-content: center;
}

.prequalified-powered-by .powered-by-title,
.prequalified-privacy-policy a{
  font-weight: 300;
  font-size: 12px;
  padding-right: 8px;
  color: #757575;
}

.prequalified-privacy-policy a {
  text-decoration: underline;
  color: #757575;
}


@media screen and (max-width: 970px) {
  .prequalified-container{
    width: 100%;
    height: 100%;
    padding: 1rem;
  }
}

/* Toast */
.prequalified-success{
  box-sizing: border-box;
  max-width: 330px;
  width: 330px;
  height: 67px;
  border: 1px solid #5CB85C;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  border-radius:  1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  justify-content: space-evenly;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
  align-items: center;
}

.prequalified-success-logo {
  width: 20px;
  height: 20px;
  align-self: center;
  background: url('./check-circle.svg') no-repeat center center transparent;
  border: none;
}

.prequalified-success-info{
  padding: 14px;
  width: 238px;
}

/* Toast */
.prequalified-success{
  box-sizing: border-box;
  max-width: 330px;
  width: 330px;
  height: 67px;
  border: 1px solid #5CB85C;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  border-radius:  1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  justify-content: space-evenly;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
  align-items: center;
}

.prequalified-success-logo {
  width: 20px;
  height: 20px;
  align-self: center;
  background: url('./check-circle.svg') no-repeat center center transparent;
  border: none;
}

.prequalified-success-info{
  padding: 14px;
  width: 238px;
}


.inline-prequalified-form {
  z-index: 1;
  margin-top: 10px;
  margin-right: 0;
  display: block; 
  box-shadow: 0 1px 4px 1px rgb(0 0 0 / 10%);
  width: 328px;
  padding: 8px 20px;
  border-radius: 10px;
  background-color: #fff;
}

@media only screen and (max-width: 991px) {

  .inline-prequalified-form{
    max-width: 308px;
  }
}