.dealer-viewall-modal {
  position: relative;
  height: 99%;
  padding: 10px;
}
.dealer-viewall-modal h2 {
  padding: 0 0 10px 0;
  margin: 0;
}

.dealer-viewall-modal .contact-close {
  width: 16px;
  height: 16px;
  margin: 3px 0;
  background: url(//boattrader.com/static/img/icons/close-x.png) no-repeat 0 top;
  border: none;
  position: absolute;
  right: 15px;
  cursor: pointer;
}

.dealer-viewall-content {
  display: flex;
  flex-direction: row;
  overflow: auto;
  height: 90%;
  width: 100%;
}
