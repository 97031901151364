.information-wrapper {
    display: block;
    max-width: 1133px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1rem 2rem;
    box-sizing: border-box;
  }

  .information-wrapper-position-bottom {
    background-color: white;
    padding-top: 2rem;
  }

  .interesting-information-image {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }

  .interesting-information-title {
    color: #0f4d77;
    margin-top: 0;
    font-size: 1.17em;
  }

  .interesting-information-link, .interesting-information-description a {
    color: rgb(23, 134, 242);
    cursor: pointer;
  }

  .information-container {
    display: flex;
  }

  .interesting-information-description p {
    margin-top: 0;
  }

  @media screen and (max-width: 768px) {
    .information-container {
        flex-direction: column;
        justify-content: center;
        padding-left: 0;
        padding-right: 0;
    }
  }

  .information-wrapper-position-top {
    display: block;
  }

  @media screen and (min-width: 970px) {
    .information-wrapper-position-top {
      max-width: 1440px;
      color: #404040;
      font-size: 14px;
      padding: 0 15px 24px;
    }
    .interesting-information-description a, .interesting-information-link {
      color: var(--bt-color-secondary_action-main);
    }
  }
