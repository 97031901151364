span label{
    font-size: 12px !important;
  }
  .boat-payment-container{
      display: flex;
      align-items: center;
      column-gap: 4px;
      width: 100%;
      margin-top: 4px;
      color: var(--bt-color-primary_action-main)
  }
  .payment-tooltip{
    margin-top: 2px;
    cursor: pointer;
  }
  .customize-link{
      color:  var(--bt-color-primary_action-main);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      padding-right: 10px;
  }
   .react-tooltip-lite {
    font-size: 12px !important;
    font-weight: 400 !important;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.10)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.10)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.10));
  }
  @media only screen and (max-width: 991px) {
    .financeable-boat-summary-boat-description{
      padding: 14px 10px 0
    }
  }