.tool-set {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1000;
  transition: transform 300ms ease;
  padding: 15px 15px 60px;
  box-sizing: border-box;
}

.tool-set.open {
  transform: translateX(100%);
}

.tool-set .inner {
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 60px;
}

.tool-set ul {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background: #F7F7F7;
  border: 1px solid var(--bt-color-neutral-30);
}

.tool-set .opts {
  max-height: 270px;
  overflow-y: auto;
}

.tool-set .opts input[type="radio"] {
  margin: 0;
  width: 16.67px;
  height: 16.67px;
  accent-color: var(--bt-color-primary);
}

.tool-set .opts input[type="checkbox"] {
  align-content: var(--bt-color-primary);
  cursor: pointer;
  height: 15px;
  margin: 0;
  width: 15px;
}

.tool-set li:hover {
  background-color: #fafafa;
}

.tool-set li.filtered {
  display: none;
}

.tool-set li .custom-option {
  cursor: pointer;
  padding: 12px 0;
  outline: none;
  overflow: hidden;
}

.tool-set li .custom-option:after {
  opacity: 0;
  transition: all 200ms ease-in-out;
  width: 16px;
  margin-right: 0;
  height: 12px;
  float: right;
  transform: translateX(35px);
}

.tool-set li .custom-option.selected:after {
  opacity: 1;
  transform: translateX(0);
}

.tool-set .buttons {
  box-sizing: border-box;
  display: block;
  height: 54px;
  z-index: 1100;
  transition: transform 300ms ease, opacity 300ms ease;
  opacity: 0;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 12px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 1) 15%
  );
  transform: translateY(100%);
}

.tool-set.open .buttons {
  opacity: 1;
  transform: translateY(0);
  transition: transform 300ms ease 500ms, opacity 300ms ease;
}

.tool-set .btn-container {
  height: 30px;
  margin: 0;
  overflow: hidden;
  position: relative;
}

.tool-set .filter-txt label {
  display: block;
  font-weight: 700;
  font-size: 0.875em;
  margin-bottom: 5px;
}

.tool-set .filter-txt.filter-txt-icon input[type="text"] {
  padding-left: 44px;
  position: relative;
}

.tool-set .filter-txt.filter-txt-icon:before {
  content: "";
  background: url(../../icons/Search.svg) no-repeat;
  position: absolute;
  height: 20px;
  width: 20px;
  display: block;
  background-size: contain;
  top: 4px;
  left: 5px;
  margin-left: 12px;
}

.tool-set-header {
  background: #0f4d77;
  display: flex;
  height: 55px;
  justify-content: space-between;
}

.tool-set-header-title {
  margin: 0;
  /* width: 83%; */
  padding: 19px 16px;
  font-size: 16px;
  color: var(--bt-color-neutral-10);
}

.tool-set-btn-close {
  background: url(../../icons/CloseFilters.svg) no-repeat;
  background-size: 17px;
  background-position: center;
  border: none;
  width: 14%;
  cursor: pointer;
}

.tool-set-btn-search {
  box-shadow: none;
  border-radius: 5px;
  font-size: 16px;
  display: block;
  cursor: pointer;
  border: 1px solid #e69752;
  padding: 8px 0;
  background-color: #e37a1e;
  color: #fff;
  width: 100%;
  height: 56px;
  line-height: 20px;
  text-align: center;
  transition: transform 0.3s ease;
  font-weight: bold;
}

#tool-set-no-padding {
  padding: 0 0 150px 0;
}

#tool-set-no-padding .buttons {
  bottom: 26px;
}

#tool-set-no-padding .btn-container {
  height: 56px;
}

#tool-set-inner-padding {
  padding: 8px 15px 60px;
}

@media screen and (min-width: 515px) {
  .tool-set-btn-close {
    width: 8%;
  }

  .tool-set-header-title {
    width: 90%;
  }
}

  .tool-set .filter-txt input[type="text"] {
    width: 100%;
    font-size: 14px;
    color: var(--bt-color-neutral-100);
    padding: 8px 15px;
    border-radius: 4px;
    background: var(--bt-color-neutral-10);
    border: 1px solid var(--bt-color-neutral-30);
    box-shadow: 0px 2px 3px 0px #0000001A inset;
  }

  .tool-set .filter-txt.filter-txt-icon:before {
    z-index: 10;
    height: 22px;
    width: 22px;
    top: 10px;
    left: 5px;
    background: #404040;
    -webkit-mask-image: url(../../icons/Search.svg);
    mask-image: url(../../icons/Search.svg);
    -webkit-mask-size: 80%;
    mask-size: 80%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }

  .tool-set-header {
    background: white;
    border-bottom: 1px solid #F5F5F5;
  }
  .tool-set-header-title {
    color: #343434;
  }
  .tool-set-btn-close {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.48514 7.00022L12.9723 2.51308L13.8976 1.58774C14.0341 1.45123 14.0341 1.22941 13.8976 1.09291L12.9075 0.102816C12.771 -0.0336886 12.5492 -0.0336886 12.4127 0.102816L7.00022 5.5153L1.58774 0.102378C1.45123 -0.0341261 1.22941 -0.0341261 1.09291 0.102378L0.102378 1.09247C-0.0341261 1.22898 -0.0341261 1.4508 0.102378 1.5873L5.5153 7.00022L0.102378 12.4127C-0.0341261 12.5492 -0.0341261 12.771 0.102378 12.9075L1.09247 13.8976C1.22898 14.0341 1.4508 14.0341 1.5873 13.8976L7.00022 8.48514L11.4874 12.9723L12.4127 13.8976C12.5492 14.0341 12.771 14.0341 12.9075 13.8976L13.8976 12.9075C14.0341 12.771 14.0341 12.5492 13.8976 12.4127L8.48514 7.00022Z' fill='%23404040'/%3E%3C/svg%3E");
  }

@media only screen and (max-width: 600px) {
  .tool-set .filter-txt input[type="text"] {
    font-size: 16px;
  }
}
