ol.boat-list li.premium .inner {
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}


/*-- LEFT SIDE --*/
ol.boat-list li.premium .image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  height: 50%;
}

ol.boat-list li.premium .image-container .dummy {
  padding-top: 46%;
}

ol.boat-list li.premium .image-container .image .price-cut {
  left: auto;
  top: 16px;
}


/*-- RIGHT SIDE --*/
ol.boat-list li.premium .description  {
  box-sizing: border-box;
  min-height: 138px;
  width: 50%;
}


/*-- TOP --*/
ol.boat-list li.premium .description .top {
  flex-wrap: wrap;
  padding-bottom: 15px;
  justify-content: space-between;
}

ol.boat-list li.premium .description .top .name {
  flex: 1 60%;
  max-width: 60%;
}

ol.boat-list li.premium .description .top .name a {
  margin-right: 0;
}

ol.boat-list li.premium .description .top .prices .price {
  flex: 1 40%;
}

/*-- BOTTOM --*/
ol.boat-list li.premium .description .bottom .offered-by .sold-by {
  color: #525252;
  display: block;
}
ol.boat-list li.premium .description .bottom .offered-by .dealer-logo {
  text-align: start;
}

ol.boat-list li.premium .description .bottom .offered-by .dealer-logo img {
  width: 75px;
  height: auto;
  object-fit: contain;
  border: none;
  margin: 10px 0;
  display: block;
}




/* -- DESKTOP --*/
@media screen and (min-width: 768px) {
  ol.boat-list li.premium .inner {
    position: relative;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    width: 100%;
  }


  /*-- LEFT SIDE --*/
  ol.boat-list li.premium .image-container {
    position: relative;
    overflow: hidden;
    width: 50%;
    height: 100%;
    flex-grow:0;
    flex-shrink:0;
  }

  ol.boat-list li.premium .image-container .dummy {
    padding-top: 66.666666666666%;
  }


  /*-- TOP --*/
  ol.boat-list li.premium .description .top {
    display: flex;
  }
  ol.boat-list li.premium .description .top .tooltip {
    padding: 0;
    position: relative;
    left: -360px;
    bottom: -160px;
  }
    

  /*-- BOTTOM --*/

  ol.boat-list li.premium .description .bottom .offered-by .sold-by {
    color: #525252;
  }
}


/* -- MOBILE --*/
@media screen and (max-width: 767px) {

  ol.boat-list li.premium .image-container {
    border-radius: 8px 8px 0 0 !important;
}

  /*-- TOP --*/
  ol.boat-list li.premium .description {
    width: 100%;
  }
  ol.boat-list li.premium .description .top {
    display: flex;
    flex-direction: row;
  }
  ol.boat-list li.premium .description .top .name {
    justify-content: flex-start;
    max-width: none;
    width: 50%;
  }

  ol.boat-list li.premium .description .top .name a {
    display: block;
    max-width: calc(100% - 50px);
  }

  ol.boat-list li.premium .description .top .prices {
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  ol.boat-list li.premium .description .top .prices .price {
    flex: unset;
  }

  ol.boat-list li.premium .description .top .prices .tooltip {
    position: absolute !important;
    right: 10px;
    top: 14px;
  }

  ol.boat-list li.premium .description .top .tooltip {
    position: relative;
    top: -83px;
    left: -15px;
  }
}
