#concierge-page-content {
  min-width: 100%;
}

#concierge-page-content .concierge {
  min-width: 100%;
  margin: 20px 0;
  box-shadow: inset 0 5px 5px #e0e0e0;
}

#concierge-page-content .concierge-header {
  padding: 20px;
  background: #e6f0ff;
  border: 1px solid #c7d9f3;
  border-radius: 5px 5px 0 0;
  border-bottom: 2px solid var(--bt-color-neutral-30);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 15px;
}

#concierge-page-content .concierge-header img {
  min-width: 40px;
  max-height: 40px;
  margin-right: 20px;
}
#concierge-page-content .concierge-header h3 {
  font-size: 16px;
  font-weight: 500;
  color: #0e4d77;
  margin: 0;
}
#concierge-page-content .concierge-header h3 i {
  font-size: 14px;
  font-weight: 400;
}

#concierge-page-content .concierge-body {
  flex-grow: 1;
  padding: 0 0 0 20px;
  overflow: hidden;
  width: calc(100% - 20px);
  box-sizing: border-box;
  min-height: 328px;
  max-height: 328px;
}
#concierge-page-content .concierge-body > div {
  width: calc(100% + 100px);
  max-height: 328px;
  overflow-y: scroll;
}
#concierge-page-content .concierge-body > div > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 100px);
}
#concierge-page-content .concierge-body > div > div > img {
  width: 25%;
  margin: 0 auto;
  filter: grayscale(1);
  transform: translateY(100%);
  opacity: 0;
  animation: displayAiLoader 250ms 500ms ease-out forwards;
}

#concierge-page-content .concierge-message {
  border: 1px solid var(--bt-color-neutral-30);
  border-radius: 4px;
  padding: 10px;
  color: #616161;
  background: #f5f5f5;
  max-width: 80%;
  box-sizing: border-box;
}
#concierge-page-content .concierge-message:not(:last-child) {
  margin-bottom: 15px;
}
#concierge-page-content .concierge-message.user {
  justify-self: flex-end;
  color: #0f4d77;
  border-color: #c7d9f3;
  background: #e6f0ff;
  align-self: flex-end;
  transform: translateY(100%);
  animation: slideUserMessage 250ms ease-out forwards;
}
#concierge-page-content .concierge-message.assistant {
  transform: translateX(-100%);
  animation: slideAssistantMessage 250ms ease-out forwards;
}
#concierge-page-content .concierge-message p {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: .4px;
  white-space: pre-wrap;
}
#concierge-page-content .concierge-message > a {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  text-rendering: geometricprecision;
  cursor: pointer;
  padding: 20px;
  outline: 0;
  border-radius: 5px;
  background: #fff;
  color: var(--bt-color-primary);
  border: 1px solid var(--bt-color-primary);
  margin-top: 20px;
}
#concierge-page-content .concierge-message > a::after {
  border-style: solid;
	border-width: 0.25em 0.25em 0 0;
	content: '';
	display: inline-block;
	height: 0.45em;
	position: relative;
	top: 0.15em;
	transform: rotate(-45deg);
	vertical-align: top;
	width: 0.45em;
  left: 0;
	transform: rotate(45deg);
  border-color: var(--bt-color-primary);
  margin: 2px 0 0 10px;
}

#concierge-page-content .concierge-footer {
  flex-shrink: 0;
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
}
#concierge-page-content .concierge-footer form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
#concierge-page-content .concierge-footer input {
  border: 1px solid #e0e0e0;
  height: 58px;
  font-size: 14px;
  padding: 20px;
  box-shadow: inset 0 2px 3px rgba(0,0,0,.1);
  border-radius: 0;
  color: #404040 !important;
  box-sizing: border-box;
  border-width: 1px 0px;
  margin-left: 1px;
  outline: none;
  flex-grow: 1;
  background-color: #fff !important;
}
#concierge-page-content .concierge-footer button {
  outline: none;
  border: none;
  border-top: 1px solid #c7d9f3;
  background: #e6f0ff;
  font-weight: 500;
  color: #0f4d77;
  padding: 0 20px;
  height: 58px;
  display: block;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  line-height: 23px;
  width: 104px;
}

#concierge-page-content .concierge-message-records {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  font-size: 0;
  margin-top: 20px;
}
#concierge-page-content .concierge-message-records > a {
  width: 118px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  filter: drop-shadow(0 1px 1px rgba(0,0,0,.2));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
#concierge-page-content .concierge-message-records h3 {
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  margin: 5px 0;
  padding: 0 5px;
  color: #404040;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#concierge-page-content .concierge-message-records h4 {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin: 0;
  padding: 0 5px;
  color: #404040;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#concierge-page-content .concierge-message-records p {
  font-weight: 400;
  font-size: 8px;
  line-height: 8px;
  margin: 5px 0;
  padding: 0 3px;
  color: #9e9e9e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes slideUserMessage {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideAssistantMessage {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes displayAiLoader {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}