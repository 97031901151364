.boat-class-container {
    font-style: normal;
    min-height: 800px;
    padding: 0 1rem;
    max-width: 1028px;
    margin: 0 auto;
    margin-bottom: 1rem;
}

.boat-class-container h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 34.8px;
  color: var(--bt-color-neutral-100);
  margin-bottom: 1rem;
  padding-top: 1.5rem;
}

.boat-class-container h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
  color: #000000;
  margin-bottom: 1rem;
}

.boat-class-container h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 21.6px;
  color: #000000;
  margin-bottom: 1rem;
}

.boat-class-container p {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
  padding: 0;
  margin: 1rem 0;
}

.boat-class-container figure {
  margin: 1rem auto;
  padding: 0;
}

.boat-class-container img {
    width: 100%;
    height: auto;
}

.boatclass-content {
  display: block;
}
.boatclass-content ul {
  margin: 1rem;
  padding: 0;
}

.boatclass-content li, .boatclass-content li a, .boatclass-content li a:active {
  color: var(--bt-color-secondary_action-main);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.boatclass-content ol li {
  color: #000000;
}

.boatclass-article .boatclass-related-articles {
  display: block;
}

.boatclass-article .boatclass-related-article {
  padding: 0;
  border: 1px solid #f4f4f4;
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  overflow: hidden;
}

.boatclass-article .boatclass-related-article a > div {
  padding: 10px;
}

.boatclass-article .boatclass-related-article a img {
  height: 70%;
  object-fit: cover;
}

.boatclass-article .boatclass-related-article h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 14.4px;
  color: var(--bt-color-secondary_action-main);
  text-transform: uppercase;
}
.boatclass-article .boatclass-related-article h43 {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.boatclass-article .boatclass-cta {
  z-index: 4;
  cursor: pointer;
  display: flex;
  box-sizing: border-box;
  width: 90%;
  margin: 0 auto;
  height: 39px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 20px;
  font-weight: 400;
  border-radius: 50px;
  font-size: 16px;
  background: var(--bt-color-primary);
  color: var(--bt-color-neutral-10);
}
.boatclass-article .boatclass-cta:Hover {
  background: var(--bt-color-secondary_action-pressed);
}

.boatclass-article .boatclass-cta.sticky-down {
  top: 55px;
}

.boat-class-container .boatclass-cta a {
  text-align: center;
}

.boatclass-article .boatclass-makes {
  margin-bottom: 2rem;
}

.boatclass-article .boatclass-makes figure {
  border: 1px solid #000000;
  border-radius: 5px;
  margin: 1rem 0;
  padding: 0 1rem 1.4rem 1rem;
}

.boatclass-article .popular-makes, .boatclass-article .featured-makes {
  display: flex;
  flex-wrap: wrap;
}

.boatclass-article .popular-makes span {
  flex: 0 0 50%;
  padding: 0.3rem 1rem;
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
}

.boatclass-article .featured-makes a {
  flex: 0 0 25%;
  justify-content: center;
  align-items: center;
}

.boatclass-article .popular-makes a, .boatclass-article .popular-makes a:visited {
  color: var(--bt-color-secondary_action-main);
  font-weight: 400;
  font-size: 14px;
  max-height: 60px;
}

.boatclass-article .boatclass-cta a, .boatclass-article .boatclass-cta a:active {
  color: #fff;
}

@media screen and (min-width: 768px) {
  .boatclass-article .boatclass-cta {
    width: 400px;
  }
  .boatclass-article .boatclass-related-articles {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  .boatclass-article .boatclass-related-article {
    flex: 0 0 calc(50% - 7.5px);
  }
}
