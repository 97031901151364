.sponsored-boats {
  margin: 0;
  max-width: 728px;
}

.sponsored-boats > ul {
  margin: 0;
  padding: 0;
}
.sponsored-boats > ul > li {
  display: flex;
  position: relative;
  overflow: hidden;
  background: #fff;
  margin-bottom: 12px;
  border-radius: 8px;
  border: 1px solid var(--bt-color-neutral-30);
  width: 100%;
  height: 130px;
}
.sponsored-boats ul li a {
  display: block;
  overflow: hidden;
  color: initial;
}
.sponsored-boats ul li .boat-image {
  float: left;
  position: relative;
  overflow: hidden;
  width: 45%;
  max-width: 253px;
  height: 130px;
  flex-shrink: 0;
}
.sponsored-boats ul li .boat-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  object-fit: cover;
}
.sponsored-boats ul li .information {
  padding: 10px;
  color: var(--bt-color-neutral-100);
  font-weight: 400;
  display:flex;
  flex-direction: column;
}

.sponsored-boats ul li .information .make-model,
.sponsored-boats ul li .information .seller-name,
.sponsored-boats ul li .information .price,
.sponsored-boats ul li .information .location {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  clear: both;
}
.sponsored-boats ul li .information .make-model {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 3px;
  order: 1;
}
.sponsored-boats ul li .information .price {
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  order: 3;
}

.sponsored-boats ul li .information .location {
  font-size: 10px;
  line-height: 10px;
  margin-bottom: 8px;
  order: 2;
}

.sponsored-boats ul li .information .seller-name {
  font-size: 10px;
  line-height: 10px;
  margin-top: 50px;
  order: 4;
}
.sponsored-boats ul li:nth-child(n+3) {
  display: none;
}

.sponsored-boats .preloader {
  width: 100%;
  position: absolute;
  margin: auto;
  top: -100%;
  right: -100%;
  left: -100%;
  bottom: -100%;
  z-index: 3;
  background: url(//boattrader.com/static/img/carousel/preloader.gif) center center no-repeat #fff;
  height: 100%;
}

.sponsored-boats .preloader.hidden {
  opacity: 0;
  transition: opacity 0.5s ease 0.5s;
}

.sponsored-boats li .banner.sponsored {
  display: flex;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.4px;
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 1;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #E3F1FE;
  color: #07324F;
  height: auto;
}

/* -- DESKTOP --*/
@media screen and (min-width:768px) {

  .sponsored-boats > ul {
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
  .sponsored-boats > ul > li {
    margin-bottom: 18px;
  }

  .sponsored-boats ul li:nth-child(n+3) {
    display: flex;
  }

  .sponsored-boats ul li .information .make-model {
    font-size: 16px;
    line-height: 18px;
  }
  .sponsored-boats ul li .information .price {
    font-size: 16px;
    line-height: 16px;
  }

  .sponsored-boats ul li .information .location {
    font-size: 12px;
    line-height: 12px;
  }

  .sponsored-boats ul li .information .seller-name {
    font-size: 12px;
    line-height: 12px;
    margin-top: 40px;
  }
}

@media screen and (max-width:969px) {
  .sponsored-boats {
    margin-top: 17px;
  }
}

/* -- MOBILE --*/
@media screen and (min-width: 340px) and (max-width: 767px){
  .sponsored-boats {
    margin: 17px 10px 0!important;
  }
}

@media screen and (max-width: 339px){
  .sponsored-boats {
    margin: 17px 2px !important;
    overflow: hidden;
  }
  .sponsored-boats > ul > li {
    width: 99%;
  }
}

.sponsored-boats > ul {
  grid-column-gap: 10px;
}
.sponsored-boats > ul > li {
  overflow: visible;
  box-shadow: none;
  margin-bottom: 10px;
  background: none;
  height: auto;
  border: none;
  vertical-align: top;
  height: 350px;
  width: 100%;
}
.sponsored-boats ul li .boat-image {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-sizing: border-box;
  filter: drop-shadow(0 2px 1px rgba(0,0,0,.2));
  transform: translateZ(0);
  height: 100%;
  min-width: 100%;
}
.sponsored-boats > ul > li > span > ul {
  display: flex;
}
.sponsored-boats > ul > li > span > ul > li {
  display: flex;
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .sponsored-boats {
      max-width: 728px;
  }
}
@media screen and (max-width: 969px) {
  .sponsored-boats {
      margin: 17px auto 0;
  }
}
