.summary-cta {
  transition: transform .3s ease;
  transform: translateY(-100%);
  z-index: 2000;
}

.summary-cta.show-summary-cta {
  transform: translateY(0);
}

.page-container.datalink > .content.nav-slide {
  margin-top: 0;
}

.panel-spacer:not(:first-child) {
  margin: 20px 0;
}
.panel-spacer > div {
  min-width: auto !important;
}
@media screen and (max-width: 767px) {
  .panel-spacer:first-child {
    margin-top: 20px;
  }
}
@media screen and (max-width: 970px) {
  .panel-spacer > div {
    margin-left: auto;
    margin-right: auto;
  }
}
.broker-bdp-right > div > div > span > img {
  margin: 0 auto 15px;
  max-width: 70% !important;
}

.boat-details {
  background: #fff;
  margin-left: auto;
  margin-right: auto;
}

.boat-details-gradient {
  background: linear-gradient(180deg, #f7f7f7 0%, transparent 20%) !important;
}

@media screen and (max-width: 970px) {
  .boat-details-banner-wrapper {
    min-height: 50px;
  }
}

.boat-details.variant .offered-by {
  display: block;
}

.boat-details .offered-by {
  display: none;
}

.boat-details.variant .carousel-button-tray-right-bottom {
  display: none;
}

.boat-details.variant .details-button-tray .social-share, .boat-details.variant .details-button-tray button.heart {
  display: flex;
}

.boat-details.variant .details-button-tray a.more-boats-button {
  display: block;
}

.boat-details .details-button-tray .social-share, .boat-details .details-button-tray button.heart, .boat-details .details-button-tray a.more-boats-button {
  display: none;
}

.boat-details .social-share a.more-boats-button {
  display: none;
}

.boat-details.variant p.seller-info-name {
  display: block;
}

.boat-details p.seller-info-name {
  display: block;
}

.boat-details .ad {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.boat-details .ad div:has(>iframe) {
  width: auto;
}

.boat-details .top {
  max-width: 1440px;
  margin: 0 auto;
}

span .heart {
  top: unset;
  right: unset;
  background: url(../../../icons/heart.svg) no-repeat 50% 50%;
}

span .heart:active, span .heart:hover  {
  background-color: transparent;
}

.boat-details .top .ad {
  min-height: 50px;
}

.boat-details .body {
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
}

.contact-form-wrapper {
  display: none;
}

.boat-details .breadcrumb {
  padding-left: 0px;
  margin-left: 10px;
}

.boat-details .body .content {
  flex-grow: 1;
}

.carousel-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
}

.boat-details .w728 {
  display: none;
}

#root-anchor {
  position: absolute;
  top: -50px;
}

.carousel-wrapper .banner {
  color: white;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 3;
  width: 120px;
  height: 24px;
  line-height: 24px;
}

.boat-details.variant .carousel-wrapper .banner {
  display: block;
}

.boat-details .carousel-wrapper .banner {
  display: none;
}

.carousel-wrapper .banner.mobile {
  top: 0;
  width: 120px;
  height: 20px;
  line-height: 19px;
}

.carousel-wrapper .banner::before {
  vertical-align: middle;
  font-size: 14px;
  font-weight: bold;
}

.carousel-wrapper .banner.mobile::before {
  font-size: 12px;
}

.broker-dealer-next-previous .next-previous{
  height: 36px;
  background-color: #F7F7F7;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  box-shadow: unset;
}

.broker-dealer-next-previous .next-previous.next-previous-top {
  box-shadow: 0px 1px 1px rgba(0,0,0,0.2);
  background: rgba(255, 255, 255, 0.90);
}

.broker-dealer-next-previous .next-previous > div {
  max-width: 1330px;
}

.broker-dealer-next-previous .next-previous .heart:after {
  color: #616161;
}

.broker-dealer-next-previous .next-previous .heart {
  background: url(../../../icons/heart-blue.svg) no-repeat center/contain !important;
  background-position: 50% 6% !important;
  background-size: 35% !important;
}

.broker-dealer-next-previous .next-previous .heart.liked {
  background: url(../../../icons/heart-blue-filled.svg) no-repeat center/contain !important;
}

.broker-dealer-next-previous .breadcrumb {
  color: #616161;
  margin-left: 10px;
  padding: 12px 10px 12px 0;
}

.broker-dealer-next-previous .next-previous-info {
  color: #616161;
  background: transparent;
}
.broker-dealer-next-previous .next-previous.show-info .breadcrumb {
display: none;
}
.broker-dealer-next-previous .next-previous-info>div+div {
  border-left: 1px solid #616161;
}

.broker-dealer-next-previous .next-previous-info .next-previous-listing-name {
    font-weight: 400;
}

.broker-dealer-next-previous .next-previous-button {
  color: var(--bt-color-secondary_action-main);
  font-size: 12px;
  font-weight: 400;
  background-color: unset;
}

.broker-dealer-next-previous .next-previous-button.next-previous-next {
  margin-left: 16px;
}

.broker-dealer-next-previous .next-previous-button.next-previous-prev:before,
.broker-dealer-next-previous .next-previous-button.next-previous-next:after,
.broker-dealer-next-previous .next-previous-button.next-previous-back span {
  display: none;
}

.broker-dealer-next-previous .next-previous-button.next-previous-next span,
.broker-dealer-next-previous .next-previous-button.next-previous-prev span {
  display: inline;
}

.boat-details.boat-details-gradient .top > div:nth-child(2) {
  margin-top: 0;
}

.boat-details.boat-details-gradient .top-right {
  margin: 0 auto;
}

.boat-details.boat-details-gradient .top-right .email-lead-form {
  display: none;
}

.boat-details.boat-details-gradient .top-right .email-lead-form.hidden {
  display: none !important;
}

.boat-details.boat-details-gradient .top-right .email-lead-form .recaptcha-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
}

.ad-leaderboard-top {
  padding: 16px 0;
  display: flex;
  justify-content: center;
  height: 90px;
  background-color: var(--bt-color-neutral-20a);
}

.mobile-ad-leaderboard-top {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 87px 0 0 0;
  min-height: 50px;
  background-color: var(--bt-color-neutral-20a);
  padding: 12px 0;
}


/* Carousel Modal */
#root [class*="style-module_blocker"][class*="style-module_blackOverlay"],
#root [class*="style-module_modal"][class*="style-module_transparent"],
#root .modal.display-block.modal-carousel.showThumbs,
#root section.modal-main-carousel,
#root li.slide.selected.previous {
  background-color: #202020;
}

/* container */
#root .carousel-root.carousel-slider-modal,
#root .slider-wrapper.axis-horizontal,
#root img.centered.image-results.wide,
#root li.slide.selected.previous {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* pagination */
#root .modal-main-carousel .carousel-root p.carousel-status {
  font-size: 16px;
  font-weight: 400;
  right: 0;
  top: 12px;
  width: 100%;
  text-align: center;
}


@media (width < 970px) {
  .broker-dealer-next-previous .next-previous{
    background-color: var(--bt-color-neutral-10_85);
    box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.05);
  }

  .broker-dealer-next-previous .breadcrumb {
    padding-bottom: 0px;
  }

}

@media screen and (min-width: 768px) {
  .boat-details .breadcrumb {
    margin-left: 30px;
  }

  .broker-dealer-next-previous .breadcrumb {
    margin-left: 10px;
    padding: 12px 15px 12px 0;
  }

  .boat-details .body {
    display: flex;
    padding: 0 30px;
  }

  .boat-details .body .tablet-ads-wrapper {
    display: flex;
    justify-content: space-evenly;
  }

  .boat-details.boat-details-gradient .details {
    margin-top: 44px;
  }
}

@media screen and (min-width: 768px) and (max-width: 970px) {
  .broker-dealer-next-previous .next-previous-button.next-previous-next {
    margin-left: 0;
  }
}

@media screen and (min-width: 970px) {
  .boat-details .body .content {
    width: calc(100% - 356px);
    margin-right: 31px;
    flex-grow: 0;
  }

  .boat-details .top {
    display: flex;
    justify-content: space-evenly;
  }

  .carousel-wrapper {
    width: 72.88%;
  }

  .contact-form-wrapper {
    display: block;
    width: 27.22%;
    height: 0;
    padding-bottom: 33.8%;
  }

  .boat-details .body {
    display: flex;
  }

  .boat-details > .breadcrumb {
    display: none;
  }

  .boat-details .body .right-ad {
    padding-top: 24px;
    flex-shrink: 0;
    width: 300px;
    margin-left: 20px;
  }

  .boat-details .body .right-ad .right-rail-ad {
    margin-bottom: 25px;
  }

  #div-gpt-leaderboard-bottom {
    max-width: 1440px;
    margin: 8px auto;
  }

  .broker-bdp {
    box-sizing: border-box;
  }

  .boat-details.boat-details-gradient .broker-bdp-right {
    padding: 0 0 0 20px;
  }

  .boat-details.boat-details-gradient .top-right .email-lead-form {
    display: block;
  }
}

@media screen and (min-width: 1097px) {

  .boat-details .body {
    padding: 0 56px;
  }

  .boat-details .body .right-ad {
    margin-left: 25px;
  }

}

.box-holder {
  width: 300px;
  min-height: 250px;
  background-color: #F7F7F7;
}

.mobile-box-holder {
  width: 300px;
  height: 250px;
  margin-right: auto;
  margin-left: auto;
  background-color: #F7F7F7;
}

/*Carousel left ad*/
.modal-carousel .modal-main-carousel .ad-wrapper {
  display: none;
}

@media print {
  .main-footer, .right-ad, .ad.w728, .right-rail-ad,
  .live-video-tour-button, .more-boats-button, .payment-calculator-wrapper, .location-map-wrapper,
  .more-from-dealer-collapsible, .services-collapsible, .control-arrow,
  .sticky-contact, .social-share-overlay {
    display: none;
  }

  .more-less, .page-header, .navbar-toggle, .main-nav, .next-previous,
  .carousel-button-tray-left-bottom, .ad, .social-dropdown.open, .contact-group-in-line {
    display: none !important;
  }

  .collapse-content-details.closed > .collapsible {
    max-height: max-content;
    padding-bottom: 10px;
  }

  .detail-description {
    max-height: max-content;
  }

  .carousel-wrapper {
    text-align: center;
    height: 500px;
    width: 800px;
  }
}

@media screen and (max-width: 969px) {
  span .heart {
    width: 20px;
    height: 20px;
  }

  span .heart.liked {
    background-size: 50%;
    background-position: 50%;
    background-color: transparent;
  }
}

@media screen and (min-width: 970px) {

  .modal-carousel .modal-main-carousel .ad-wrapper {
    width: calc((calc(100% - 880px) / 2) - 40px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40px;
    min-height: 250px;
    max-height: 600px;
  }

  .boat-details .broker-bdp {
    display: flex;
    justify-content: space-evenly;
    max-width: 1300px;
  }

  .boat-details.boat-details-gradient {
    padding: 0 20px;
  }

  .boat-details.boat-details-gradient .broker-bdp-left {
    max-width: 662px;
    margin: 0 auto;
  }

  .boat-details.boat-details-gradient .broker-bdp-right {
    max-width: 303px;
    padding: 0 0 0 20px;
  }

  .boat-details.boat-details-gradient .next-previous {
    padding: 5px 0;
    max-width: 1300px;
    margin: 0 auto;
  }

  .boat-details.boat-details-gradient .next-previous a {
    padding: 0;
  }

  .boat-details.boat-details-gradient .top .summary {
    margin-left: 0;
    padding-left: 0;
  }

  .boat-details.boat-details-gradient .top .ad {
    min-height: initial;
    padding: 0;
  }
  .boat-details.boat-details-gradient .right-ad {
    margin: 20px auto 0 auto;
    overflow: visible;
  }
  .boat-details.boat-details-gradient .add-tmp {
    margin: 0 auto;
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1024px) {
  .boat-details.boat-details-gradient .add-tmp {
    min-height: 250px;
    width: 300px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 970px) {
  .top-ad .bdp-ad {
    min-height: 122px;
  }
}

@media screen and (min-width: 1200px) {
  .boat-details .w728 {
    display: flex;
    justify-content: center;
    min-height: 90px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .bdp-ad {
    min-height: 90px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .top-ad .bdp-ad {
    margin-top: 0;
    margin-bottom: 0;
  }



  .boat-details .w728 div:has(>iframe) {
    width: auto;
  }

  .boat-details #div-gpt-mobile-box-2 {
    display: none;
  }

  .boat-details.boat-details-gradient .broker-bdp {
    gap: 20px;
  }

  .boat-details.boat-details-gradient .broker-bdp-left {
    padding: 0;
    margin: 0;
    max-width: 856px;
  }

  .boat-details.boat-details-gradient .broker-bdp-right {
    max-width: initial;
    width: 400px;
    max-width: 400px;
    padding: 0;
  }
  .boat-details.boat-details-gradient .right-ad {
    overflow: visible;
  }
  .boat-details.boat-details-gradient .right-ad > div:first-child {
    margin: 0 auto;
    overflow: visible;
  }
  .boat-details.boat-details-gradient .add-tmp {
    margin: 0 auto;
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1400px) {
 .boat-details.boat-details-gradient .broker-bdp {
    gap: 42px;
  }
   .boat-details.boat-details-gradient {
    padding: 0 70px;
   }
  .boat-details.boat-details-gradient .broker-bdp-left {
    width: 890px;
    max-width: 890px;
  }
}

@media screen {
  
}

.right-ad [class*="style-module_image-wrapper"].box-ad a {
  display: block;
  position: relative;
  max-width: 300px;
  max-height: 600px;
  margin: 2px auto;
}

.right-ad [class*="style-module_image-wrapper"].box-ad .ad-image {
  position: relative;
}

.bottom-ad{
  margin-bottom: 24px;
}
.bottom-ad .ad-image{
  position: relative;
}

.top-ad .ad-image{
  position: relative;
}

