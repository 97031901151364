/* BASE font-size: 15px */

/* Desktop */
.calc-calculator-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.calc-calculator-sub-container {
  margin-top: 5.66rem;
}

.calc-calculator-mode-selector {
  display: flex;
  justify-content: center;
}

.calc-calculator-body {
  display: flex;
  flex-flow: row wrap;
  border: 1px solid #C4C8CA;
  border-radius: 0.66rem;
  overflow: hidden;
  width: 55.7rem;
  margin-top: 2rem;
  height: auto;
}

.calc-calculator-form {
  box-sizing: border-box;
  width: 50%;
  background-color: var(--bt-color-neutral-10);
  padding: 2.66rem;
  height: 550px;
}

.form-fieldset {
  display: contents;
}

.form-label {
  font-style: normal;
  font-weight: 500;
  font-size: 0.93rem;
  padding-bottom: 0.4rem;
  color: #222222;
}

.calc-form-data {
  font-style: normal;
  font-weight: 300;
  font-size: 1.06rem;
  line-height: 1.26rem;
  color: #555555;
  margin-bottom: 0.33rem;
  width: 22.86rem;
  height: 2.66rem;
  border-radius: 0.26rem;
}

.calc-boat-loans-link{
  text-decoration: underline;
  color: var(--bt-color-neutral-100);
}

.calc-form-data.input {
  width: 100%;
  margin-bottom: 1rem;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.26rem;
  padding-left: .8rem;
}

.calc-form-data.input#interest-rate {
  width: 13.53rem;
  height: 2.66rem;
  border-radius: 0.26rem;
}

.calc-form-data.input.year {
  margin-bottom: 0.66rem;
}

.calc-form-data.input::placeholder {
  color: #555;
  opacity: .3;
  font-size: 1rem;
}

.calc-calculator-summary {
  box-sizing: border-box;
  height: auto;
  width: 50%;
  background-color: #0F4D77;
  padding: 30px 41px .5rem;
}

.calc-summary-title {
  font-style: normal;
  font-weight: 700;
  font-size: 1.33rem;
  color: var(--bt-color-neutral-10);
  margin-bottom: 0.53rem;
  line-height: 2.33rem;
  text-align: center;
}

.calc-summary-small-print {
  font-style: normal;
  font-weight: 400;
  font-size: 0.666rem;
  line-height: 120%;
  text-align: justify;
  color: #FFF;
  padding-top: 1.66rem;
}

.calc-summary-data {
  font-style: normal;
  font-size: 1.06rem;
  line-height: 175%;
  color: var(--bt-color-neutral-10);
}

.calc-payment-label {
  text-align: center;
  margin-top: 1.33rem;
  padding-top: 0.8rem;
}

.calc-summary-data-value {
  float: right;
}

.calc-monthly-payment,
.calc-loan-amount {
  font-weight: 800;
  font-size: 3.46rem;
  line-height: 4.13rem;
  text-align: left;
  padding-bottom: 0.66rem;
  letter-spacing: 0.05em;
  text-align: center;
}
.calc-real-loan-amount{
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
}
.calc-real-loan-amount-label{
  padding-bottom: 0px !important;
}


.calc-calculator-selector-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 1.06rem;
  line-height: 1.26rem;
  text-align: center;
  margin: 0rem;
  color: #707070;
  background-color: var(--bt-color-neutral-10);
  border: 1px solid #C4C8CC;
  height: 2.46rem;
  width: 13.13rem;
}

.calc-calculator-selector-button.monthly-mode {
  border-radius: 0.26rem 0rem 0rem 0.26rem;
}

.calc-calculator-selector-button.loan-amount-mode {
  border-radius: 0rem 0.26rem 0.26rem 0rem;
}

.calc-calculator-selector-button.selected {
  border: 2px solid var(--bt-color-secondary_action-main);
  color: #222222;
}

.calc-calculate-row {
  display: flex;
  align-items: baseline;
}

.calc-calculate-interest-row {
  display: flex;
  flex-direction: row;
  margin-right: 15px;
  width: 100%;
}

.calc-summary-apply-button .cta {
  color: var(--bt-color-neutral-10);
}

.cta.srp-apply-now-button {
  font-size: 15px;
}

.calc-free-text-input-error {
  color: red;
  margin-top: -1rem;
  margin-left: 63%;
  font-style: normal;
  font-size: 0.73rem;
}

.calc-form-label.loan-amount-or-monthly-payment,
.calc-form-label.interest-rate {
  margin-top: 1rem;
}
.calc-free-text-input-error.interest-rate {
  margin-top: 0.46rem;
  margin-left: 18%;
}

.calc-or-text {
  font-style: normal;
  font-weight: 500;
  font-size: 0.93rem;
  line-height: 1.13rem;
  color: #CCCCCC;
  margin-bottom: 0.66rem;
}

.calc-percent-sign {
  color: #000;
  font-weight: 600;
  margin-top: 0.7rem;
  margin-left: -1.4rem;
  margin-right: 0.5rem;
  z-index: 1000;
}

.calc-summary-apply-button .cta-icon {
  display: none;
}

.calc-summary-button-row {
  display: flex;
  flex-direction: row;
  max-width: 22rem;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  width: 200px;
}

.calc-get-prequal-button {
  display: none; /* Hidden for /boat-loans/calculator/ */
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 10.066rem;
  height: 2.66rem;
  background: var(--bt-color-neutral-10);
  border: 1px solid #119BF5;
  border-radius: 6.66rem;
  font-style: normal;
  font-weight: 700;
  font-size: 0.93rem;
  line-height: 1.6rem;
  color: var(--bt-color-neutral-100);
  cursor: pointer;
}

.calc-or {
  display: none; /* Hidden for /boat-loans/calculator/ */
  font-style: normal;
  font-weight: 700;
  font-size: 1.06rem;
  text-align: center;
  color: #404040;
  margin-left: .433rem;
  margin-right: .433rem;
}

.calc-hidden {
  display: none;
}

/* Mobile */
@media only screen and (max-width: 991px) {
  .calc-calculator-container {
    height: 100%;
    margin-top: 2rem;
  }

  .calc-calculator-sub-container {
    padding-top: 6rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-top: 0.66rem;
  }

  .calc-calculator-form {
    width: 100%;
    background-color: var(--bt-color-neutral-10);
    padding: 1.6rem 1.33rem 2.73rem;
    height: 535;
  }

  .calc-calculator-summary {
    width: 100%;
    padding: 1.86rem 1.13rem 1.6rem;
    height: auto;
    margin-top: 4.66rem;
  }

  .calc-calculate-row {
    flex-direction: column;
  }

  .calc-calculate-button {
    width: 100%;
    margin: 0rem;
    padding: 0rem;
  }

  .calc-calculator-selector-button {
    height: 4.2rem;
    width: 8.93rem;
  }

  .calc-calculator-body {
    width: 90%;
    margin-top: 1.73rem;
    height: unset;
  }

  .calc-summary-data {
    line-height: 150%;
  }

  .calc-summary-apply-button {
    width: 20.73rem;
  }

  .calc-summary-wrapper {
    padding: 18px 0;
  }

  .calc-summary-small-print {
    line-height: 155%;
    padding-top: 2rem;
  }

  .calc-free-text-input-error {
    margin-left: 63%;
  }

  .calc-form-data.input#interest-rate {
    width: 100%;
    height: 2.66rem;
    border-radius: 0.26rem;
    margin-bottom: 2.13rem;
  }

  .calc-free-text-input-error.interest-rate {
    margin-top: 0.46rem;
    margin-left: 63%;
  }

  .calc-get-prequal-button {
    margin-top: 1rem;
  }

  .calc-or {
    padding-left: 0.46rem;
    padding-right: 0.46rem;
  }
}
