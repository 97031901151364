.listingCarouselContainer > div {
  height: 294px;
  margin: 0 0 15px 10px;
  width: calc(100% - 15px);
}

@media (width <= 768px) {
  .listingCarouselContainer > div {
    margin: 0 0 15px 0;
    width: 100%;
  }
}

.listingCarouselContainer button {
  background-color: #fff;
  filter: drop-shadow(-8px 0px 12px rgba(0, 0, 0, .25));
  height: 40px;
  position: absolute;
  top: 30%;
}

.carouselElement p {
  font-weight: 400 !important;
}

.dealer-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dealer-links > a {
  color: #0051AD;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .dealer-links {
    flex-direction: column;
    row-gap: 20px
  }
}
