
.myBoats {
  display: block;
  width: 100%;
}

.boat-card.for-sale{
  height: auto !important;
  padding-bottom: 10px;
}

.myBoats .for-sale .buttons .button {
  border: 1px solid #1b6497;
  color: #1b6497;
  background-color: #fff;
  border-radius: 50px !important;
}

.for-sale .buttons.clear {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.for-sale .buttons .button.renew {
  background-color: var(--bt-color-primary) !important;
  border-color: var(--bt-color-primary);
  color: #fff !important;
}

.myBoats .for-sale .buttons .button.renew.single,
.myBoats .for-sale .buttons .button.deactivate.single {
  position: inherit !important;
}

.for-sale .buttons .button.deactivate {
  background-color: #fff !important;
  color: var(--bt-color-primary) !important;
  border-color: var(--bt-color-primary);
}

@media screen and (max-width: 969px) {
  .myBoats {
    float: left;
    margin-top: 50px;
  }
}