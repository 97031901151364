.prequalify-container {
  display: flex;
  padding: 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid #EEE;
  background: #FFF;
}

.prequalify-container .prequalify-title{
    color: var(--bt-color-neutral-90); ;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .prequalify-container .summary-step-text{
    color: var(--bt-color-neutral-90); ;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: start;
    flex-direction: column;
    gap: 8px;
    margin: 0;
  }

  .prequalify-container .summary-step-text li {
    text-align: left;
    display: flex;
  }

  .prequalify-container .summary-step-text li:before {
    content: " ";
    display: block;
    width: 3px;
    height: 10px;
    border: solid var(--bt-color-primary);
    border-width: 0 .1em .1em 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-right: 15px;
    margin-left: 5px;
  }

  @media only screen and (min-width: 992px) {
    .prequalify-container .prequalify-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid var(--bt-color-primary);
        color: var(--bt-color-primary);
        text-decoration: none;
        font-size: 16px;
        font-weight: bold;
        text-rendering: geometricprecision;
        cursor: pointer;
        background: var(--bt-color-neutral-10); ;
        width: -webkit-fill-available;
        width: -moz-available;
        border-radius: 50px;
        height: 43px;
        transition: 0.3s;
        transition-property: background, font-size;
        padding: 3px 50px;
        white-space: nowrap;
    }
  }

  @media only screen and (max-width: 991px) {

    .prequalify-container .prequalify-button {
        color: var(--bt-color-primary_action-main);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        border: none;
        padding: none;
        background: none;
      }

  }