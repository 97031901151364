.oauth-redirect.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 331px;
}

.oauth-redirect .panel {
  width: 357px;
  background: var(--bt-color-neutral-10);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.oauth-redirect h1.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  margin: 12px 0px;
}

.oauth-redirect p.body {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0px 33px;
}

.oauth-redirect div.image-container {
  display: flex;
  justify-content: space-between;
  width: 220px;
  margin: 21px 0px;
}