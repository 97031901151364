.dealer-locator-container {
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  background: hsl(0, 0%, 93%);
  font-size: 14px;
  line-height: 22px;
  width: 100%;
  max-height: 999px;
  margin: 1px;
  padding: 10px 10px;
  box-sizing: border-box; 
  color: #333333;
}

.dealer-locator-container.secondary{
  display: block;
  background: transparent;
  padding: 0px;
}

.dealer-locator-logo{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 2;
}

.flex-logo {
  display: flex;
  order: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.flex-logo img {
  display: flex;
  max-width: 75%;
  margin: 14px 0 0;
}

.flex-title {
  order:2;
  display: flex;
  margin-top: 30px;
  justify-content: center;
  font-weight: 700;
  width: 100%;
}

.dealer-locator-container .flex-button {
  order: 3;
  flex-basis:5%;
  width: 100%;
}

.dealer-locator-container .btn-action {  
  font-size: 16px;
  height: 40px;
  line-height: 22px;
  padding: 4px 13px 4px;
  font-weight: bold;
  text-transform: uppercase;
  border: 0 none;
  background: #e37a1f;
  box-shadow: none;
  flex-basis: auto;
}

@media (max-width: 969px) {
  .dealer-locator-container .btn-action { 
    margin: 16px 0;
  }
}

@media (min-width: 970px) {
  .dealer-locator-container {
    display: flex;
    left: auto;
    right: 20px;
    bottom: 80px;
    width: 420px;
  }
  .btn-action.w-auto-md{
    width: auto;
  }
}