/* BASE font-size: 15px */

/* Desktop */
.calc-hero-container {
  display: flex;
  padding: 48px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: linear-gradient(0deg, rgba(37, 102, 176, 0.80) 0%, rgba(71, 146, 207, 0.80) 156.1%);
}

.calc-hero-text {
  color: var(--bt-color-neutral-10, #FFF);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.calc-hero-title {
  color: var(--bt-color-neutral-10, #FFF);
  text-align: center;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px; /* 100% */
  letter-spacing: -0.36px;
  margin: 0;
}