.hero-ad {
  overflow: hidden;
  position: relative;
  height: 290px;
}

.hero-ad .div {
  text-align: center;
}

ol.boat-list li.desktop-liner-ad, ol.boat-list li.mobile-liner-ad,
ol.boat-list li.desktop-native-ad, ol.boat-list li.mobile-native-ad {
  border: 0px none;
}

.hero-ad .div iframe {
  margin: 0 auto;
  height: 290px;
  position: absolute;
  top:0;
  left: 0;
  width: 1px;
  min-width: 100%;
}

.hero-ad #hero-tablet{
  display: none;
}

.hero-ad #hero-desktop-small{
  display: none;
}

.hero-ad #hero-desktop-large {
  display: none;
}

.ad.w728, .ad.ad-desk-native {
  display: none;
}

.ad.w728max {
  display: block;
  height: auto;
}

.ad-320x80 {
  width: 320px;
  height: auto;
  margin: 0 auto;
}

.ad-300 {
  margin-bottom: 5px;
}

.ad-inline-mobile-box, .ad-mobile-native {
  text-align: center;
  margin-bottom: 12px;
}

@media screen and (min-width: 700px) {
  .hero-ad {
    height: 472px;
  }

  .hero-ad div iframe {
    height: 472px;
  }

  .bp2a {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .ad-300 {
    width: 300px;
  }
}

@media screen and (min-width: 768px) {
  ol.boat-list li.desktop-liner-ad, ol.boat-list li.desktop-native-ad {
    width: 100%;
    margin: 0 auto 12px;
    text-align: center;
  }

  .ad.w728 {
    display: block;
    height: auto;
    max-height: 180px;
  }

  .ad.w728.footer {
    clear: both;
    margin: 0 auto 12px;
    text-align: center;
  }

  .ad.w728max {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .ad-inline-mobile-box, .ad-mobile-native {
    display: none;
  }

  .ad.ad-desk-native {
    display: block !important;
  }
}

@media screen and (min-width: 1575px) {
  .hero-ad #hero-desktop-large {
    display: table-cell;
    width: 980px;
    margin: 0;
    text-align: left;
  }

  .hero-ad .redesign-msg {
    display: table-cell;
    background: url('//boattrader.com/static/img/wood-bg.png');
    max-height: 410px;
    vertical-align: middle;
  }

  .hero-ad .redesign-msg img {
    width: 100%;
  }
}
