.alert-modal-container {
  display: block;
}
.alert-modal-container.modal {
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  height: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100000;
}
.alert-modal {
  box-sizing: border-box;
  box-shadow: 0 5px 15px rgba(34, 34, 34, 0.5);
  display: flex;
  flex-direction: column;
  gap: 16px;
  grid-gap: 16px;
  position: absolute;
  width: 85%;
  border-radius: 4px;
}

.alert-modal .alert-message {
  display: block;
  padding: 20px 43px 20px 63px;
  color: #444;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.alert-modal.success .alert-message {
  background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z' fill='%2357BA55'/%3E%3C/svg%3E") 25px 24px #F1F8F0 no-repeat;
}

.alert-modal.failure .alert-message {
  background: url("../../icons/Error-red.svg") 20px 20px #FFE6E6 no-repeat;
}

.alert-modal button.close {
  background: url('//boattrader.com/static/img/icons/modal-close-button.svg') no-repeat center center transparent;
  border-width: 0;
  display: block;
  height: 18px;
  margin: 27px;
  right: 0px;
  top: 0px;
  filter: brightness(0.5);
  position: absolute;
  width: 18px;
  cursor: pointer;
}

.alert-modal .preloader {
  width: 100%;
  position: absolute;
  margin: auto;
  top: -100%;
  right: -100%;
  left: -100%;
  bottom: -100%;
  z-index: 3;
  background: url(//boattrader.com/static/img/carousel/preloader.gif) center center no-repeat #fff;
  height: 100%;
}

.alert-modal .preloader.hidden {
  opacity: 0;
  transition: opacity 0.5s ease 0.5s;
}


@media screen and (min-width: 769px) {
  .alert-modal {
    grid-template-rows: auto auto 35px auto;
    width: 430px;
    max-width: none;
    top: 20px;
  }
}


@media screen and (max-width:768px) {
  .alert-modal {
    width: 100%;
    border-radius: 0px;
    padding: 0px;
    top: 0px;
  }

  .alert-modal button.close {
    right: 10px;
  }
}
