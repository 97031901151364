/* Mobile nav */
header.main {
  background:  var(--bt-color-neutral-20);
  width: 100%;
  min-height: 51px;
  height: 51px;
  min-width: 210px;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.navbar-toggle,
button.navbar-toggle:active {
  width: 35px;
  height: 33px;
  background: none;
  border-radius: 6px;
  border: none;
  position: absolute;
  top: 10px;
  left: 8px;
  cursor: pointer;
  padding: 0;
}

button.navbar-toggle .icon-bar,
button.navbar-toggle:active .icon-bar {
  margin: 0 auto;
  border-radius: 4px;
  display: block;
  height: 2px;
  width: 18px;
  background-color: var(--bt-color-tertiary);
  margin-bottom: 4px;
}

#main-nav {
  opacity: 1;
  background: var(--bt-color-neutral-20); 
  min-height: 100%;
  display: block;
  width: 260px;
  left: -260px;
  top: 0;
  position: fixed;
  transition: transform 300ms ease;
  z-index: 101;
  box-shadow: 0px 0.1px 11px 2px rgba(68, 89, 88, 0.11);
}

#main-nav:after {
  display: table;
  clear: both;
  content: '';
}

#main-nav .authentication {
  float: left;
  margin-right: 0px;
  margin-bottom: 20px;
  background: transparent;
}

#modal-root .tabs {
  width: 100%;
}

#modal-root .modal-box {
  border-radius:12px;
}

#modal-root .user-modal-content .user-form-button-container > button {
  border-radius: 50px;
}

#modal-root .user-modal-content .user-form-message {
  padding-bottom: 0px;
  text-align: left;
  > p {
    margin-left: 0px;
  }
  
}

#modal-root .user-modal-content .social .social-signin-button img {
   border-radius: 50px;
   outline: 1px solid #000;
   outline-offset: -1px;
}

#modal-root .user-modal-content .social .social-signin-img {
  width: unset;
}

#main-nav ul {
  list-style: none;
  margin: 0 0 0 -15px;
  padding: 0 0 0 12px;
}

#main-nav ul.errors li.error {
  color: #D63924;
  padding: 2px 3px;
  width: 100%;
}

  #main-nav ul li {
    font-size: 11px;
    color: #6a7c89;
    padding: 0;
  }

  #main-nav ul a {
    color: var(--bt-color-tertiary);
    font-size: 15px;
    font-weight: 300;
    padding: 4px 0;
    display: block;
  }

  #main-nav ul li.nav-right a {
    padding-top: 4px;
    margin-left: 24px;
    padding-bottom: 9px;
  }

  #main-nav ul li.nav-right {
    float: left;
  }

    #main-nav ul li.nav-right.hidden {
      display: none;
    }

  #main-nav ul li.nav-left {
    margin-top: 10px;
    float: left;
  }

    #main-nav ul li.nav-left a {
      padding-top: 4px;
      margin-left: 24px;
      padding-bottom: 9px;
      box-sizing: border-box;
    }

    #main-nav ul li.nav-left a.trackFind {
      width: 112px;
    }
    #main-nav ul li.sell-accordian a {
      width: 182px;
      text-align: center;
    }
    #main-nav ul li.services a {
      width: 157px;
    }
    #main-nav ul li.research a {
      width: 148px;
    }

    #main-nav ul li.nav-left.find {
      margin-left: 0;
      position: relative;
    }

  #main-nav ul.dropList {
    margin: 0 0 15px;
    padding: 0;
    float: left;
    transition: all .3s ease;
    position: relative;
    width: 100%;
  }

    #main-nav ul.dropList li {
      line-height: 1.1em;
      margin-left: 20px;
    }

    #main-nav ul.dropList li a:hover {
      text-decoration: underline;
    }

  #main-nav ul li.titles {
    color: var(--bt-color-primary);
    border-top: none;
    padding: 10px 0 5px 20px;
  }

#main-nav ul li .droplist-indent {
  padding-left: 16px !important;
}

.nav-slide {
  transition: transform 300ms ease, top 200ms linear;
}

.page-header.nav-open #main-nav,
.page-header.nav-open .nav-slide {
  transform: translateX(260px);
  top: 0;
}

.page-header.nav-open #main-nav {
  max-height: 100%;
  overflow-y: scroll;
}

.nav-open {
  height: 100%;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1003;
}

ul.validation-error-list {
  margin: 0 !important;
  text-indent: 0;
  list-style-type: none;
  font-size: 12px;
  padding: 5px 0 !important;
  line-height: 19px;
  color: #d63924;
}

ul.validation-error-list>li {
  float: left !important;
  clear: both;
  font-size: 11px;
  padding-left: 4px !important;
  padding-top: 2px !important;
}

ul.validation-error-list>li.error-state-false  {
  padding-left: 12px !important;
  color: #626262 !important;
}

#main-nav .user-menu .user-name {
  background-color: var(--bt-color-neutral-20);
  color: var(--bt-color-tertiary);
}

#main-nav .authentication .menu-item {
  background-color: var(--bt-color-neutral-20);
  color: var(--bt-color-tertiary);
}

@media screen and (min-width: 970px) {
  .page-header.nav-open #main-nav {
    transform: none;
    top: 0px;
    overflow-y: hidden;
  }

  .page-header.nav-open .nav-slide {
    transform: none;
    top: 5px;
  }
  
  .user-menu {
    position: absolute;
    right: 0;
  }

  .user-name {
    padding: 15px 20px 8px 27px;
  }

  .user-name:after {
    content: url(//cdn.boattrader.com/img/tol-design/btol/bt-inc-release/dropdown-arrow.svg);
  }

  header.main {
    height: 40px;
    min-height: 40px;
    width: auto;
    text-align: left;
    position: absolute;
    top: 12px;
    background: 0 0;
  }

  button.navbar-toggle,
  button.navbar-toggle:active {
    display: none;
  }

  #main-nav {
    min-height: 62px;
    border: none;
    width: auto;
    position: relative;
    opacity: 1 !important;
    left: 0;
    z-index:1001;
    box-shadow: 0px 0.1px 11px 2px rgba(68, 89, 88, 0.11);
  }

    #main-nav ul a {
      font-weight: 500;
    }

    #main-nav ul li {
      float: left;
      line-height: 1.1em;
      width: auto;
      position: relative;
      cursor: pointer;
    }

    #main-nav ul li.nav-left {
      margin-top: 0;
    }

      #main-nav ul li.nav-left.find {
        margin-left: 195px;
      }

      #main-nav ul li.nav-left a {
        margin-left: 0;
        padding: 23px 20px 26px 25px;
      }

      #main-nav ul li.nav-right a {
        margin-left: 0;
        padding: 23px 17px 26px 18px;
      }

    #main-nav ul.dropList {
      background: var(--bt-color-neutral-20);
      border: 1px solid rgba(255,255,255,0.1);
      box-shadow: 0 5px 15px rgba(0,0,0,0.1);
      border-radius: 4px;
      left: 0;
      position: absolute;
      width: 220px;
      opacity: 0;
      clip: rect(0px, 0px, 0px, 0px);
      transition: opacity 300ms, clip 0ms linear 300ms;
    }

      #main-nav ul li:hover .dropList {
        clip: rect(-10px, 500px, 500px, 0px);
        opacity: 1;
        transition: opacity 300ms, clip 0ms linear 0ms;
      }

        #main-nav ul li .dropList li {
          margin-left: 0;
          margin-right: 0;
          border-top: 1px solid rgba(255,255,255,0.07);
          width: 100%;
        }

          #main-nav ul.dropList li:nth-child(1) {
            border: none;
          }

          #main-nav ul.dropList li a {
            padding: 4px 0;
            display: block;
            font-size: 14px;
            margin: 8px 12px;
          }

          #main-nav ul li.titles + li {
            border-top: none;
          }

    #main-nav ul li.user {
      float: right;
      width: 220px;
      margin-right: 40px;
      position: relative;
    }

      #main-nav ul li.nav.user a {
        padding: 18px 20px 20px 27px;
      }

      #main-nav ul li.nav.user .userList li a {
        margin: 7px 35px;
        padding: 4px 0;
      }

      #main-nav ul li.nav.user .userList li:last-of-type {
        text-align: center;
      }

      #main-nav ul li.no-user {
        float: right;
      }
}



@media screen and (min-width: 1126px) {
  #main-nav ul li.nav-left.find {
    margin-left: 341px;
  }
}

@media screen and (width < 1225px) {
  #main-nav ul li.nav-left.find {
    margin-left: 260px;
    width: 58px;
  }

  #main-nav ul li.nav-left.sell-accordian {
    width: 105px;
  }

  #main-nav ul li.nav-left.financing {
    width: 90px;
    margin-right: 17px;
  }

  #main-nav ul li.nav-left.services {
    width: 84px;
  }

  #main-nav ul li.nav-left {
    max-width: 120px;
    margin-right: 23px;
  }

  #main-nav ul li.nav-left > a{
    padding-left: 0;
    padding-right: 0;
    text-align: left;
  }

}

@media screen and (min-width: 970px) {
  #main-nav .authentication {
    float: right;
    margin-right: 0px;
    margin-bottom: 0px;
  }

  #modal-root .tabs {
    width: 280px;
  }

  #main-nav .authentication .user-menu-button {
    padding: 22px 17px 26px 18px;
    background: var(--bt-color-neutral-20);
    color: var(--bt-color-tertiary);
  }

  #main-nav .authentication .menu-items {
    margin-top: -3px;
    box-shadow: 0 5px 15px rgb(34 34 34 / 50%);
    display: none;
    transition: opacity .3s,clip 0ms linear .3s;
    background: var(--bt-color-neutral-20);
  }

  #main-nav .authentication .menu-items .menu-item{
    border-top: 1px solid hsla(0,0%,100%,.07);
    font-size: 14px;
    line-height: 1.1em;
  }

  #main-nav .authentication .user-menu:hover .menu-items{
    display: block;
  }

  #main-nav .authentication .user-menu .sign-out-button{
    text-align: center;
    font-size: 14px;
    padding-left: 0px;
    color: var(--bt-color-tertiary);
    background: var(--bt-color-neutral-20);
    width: 122px;
    margin: 0 0 5px 5px;
  }
}

li.nav.nav-left.services {
  display: block;
}

@media screen and (max-width: 969px) {
  header.main {
    border-bottom: 1px solid var(--bt-color-neutral-40); 
  }
  
  #main-nav > ul {
    margin: 0;
    padding: 0;
  }

  #main-nav > ul > li > div {
    font-weight: bold;
    margin: 10px 0 5px 15px;
    color: var(--bt-color-tertiary);
    font-size: 15px;
    cursor: default;
  }

  #main-nav > ul > li.nav-left {
    width: 100% !important;
    max-width: none;
    margin: 0 !important;
    border-bottom: 1px solid var(--bt-color-neutral-40);
    padding: 5px 0 15px;
  }

  #main-nav .user-menu {
    background: none;
  }

  #main-nav {
    overscroll-behavior: contain;
    background: var(--bt-color-neutral-20);
  }

  .user-menu .user-menu-button {
    color: var(--bt-color-tertiary);
    font-size: 16px;
  }

  .user-menu .user-name {
    color: var(--bt-color-tertiary);
  }

  .user-menu:hover .user-name {
    background-color: var(--bt-color-neutral-20);
  }

  .user-menu .menu-item {
    color: var(--bt-color-tertiary);
  }

  .user-menu .menu-items .sign-out-button {
    color: var(--bt-color-tertiary);
    background-color: var(--bt-color-neutral-20);
  }

  .nav-slide:not(.show-header) {
    top: -51px;
  }
  .sell-accordian {
    display: none;
  }

  .authentication {
    padding: 15px 0 15px 15px;
    width: calc(100% - 15px);
    float: none !important;
    margin-bottom: 0px !important;
    overflow: overlay;
    border-bottom: 1px solid #E0E0E0;
  }

  body.nav-open {
    overflow: hidden;
  }
  body.nav-open .bt-ana-1099 > .nav-cover {
    display: block;
    background: rgba(0,0,0, 0.75);
  }
  .bt-ana-1099 > .nav-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    background: rgba(0,0,0, 0);
    transition: background 300ms ease;
  }

  .bt-ana-1099 #main-nav:after {
    content: none;
  }

  .bt-ana-1099 #main-nav > ul > li {
    float: none;
    width: auto;
    margin: 0;
  }

  .bt-ana-1099 #main-nav > ul > li > ul.dropList {
    margin: 0 0 0 30px;
    float: none;
    width: auto;
    overflow: hidden;
    height: auto;
  }
  .bt-ana-1099 #main-nav > ul > li > ul.dropList > li {
    line-height: initial;
    margin: 0;
  }
  .bt-ana-1099 #main-nav > ul > li > ul.dropList > li.titles {
    display: none;
  }

  .bt-ana-1099 #main-nav > ul > li > div.closed + ul {
    height: 0;
  }

  .bt-ana-1099 #main-nav > ul > li a {
    padding: 0;
    margin: 0;
  }
  .bt-ana-1099 #main-nav > ul > li a.hidden-xs {
    display: none;
  }
  .bt-ana-1099 #main-nav > ul > li > div:after {
    content: none;
  }

  .bt-ana-1099 #main-nav > ul > li > ul > li > a {
    padding: 8px 0;
    line-height: 16px;
    font-size: 15px;
  }

  .bt-ana-1099 #main-nav > ul > li > div > img {
    padding: 0;
    margin: 5px 0;
    transition: transform 100ms ease;
    position: absolute;
    right: 20px;
    transform: scaleY(-1);
  }
  .bt-ana-1099 #main-nav > ul > li > div.closed > img {
    transform: rotate(0deg);
  }

  .bt-ana-1099 #main-nav > ul > li.user > a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
  }
  .bt-ana-1099 #main-nav > ul > li.user > a:after {
    content: none;
  }
  .bt-ana-1099 #main-nav > ul > li.user > a > img {
    float: none;
    margin: -4px 10px 0 0;
  }
  .bt-ana-1099 #main-nav > ul > li.user > a > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
  }
  .bt-ana-1099 #main-nav > ul > li.user > a > div > span {
    line-height: 11px;
    margin-bottom: 4px;
  }
  .bt-ana-1099 #main-nav > ul > li.user > a > div > .user-name {
    float: none;
    width: 170px;
    line-height: 15px;
    font-weight: bold;
  }

  .bt-ana-1099 #main-nav > ul > li.no-user.login:not(.hidden) {
    width: calc(50% - 15px);
    background: none;
    margin: 0;
    display: inline-block;
    text-align: center;
  }
  .bt-ana-1099 #main-nav > ul > li.no-user.login:not(.hidden).hidden-xs {
    display: none;
  }
  .bt-ana-1099 #main-nav > ul > li.no-user.login + .login {
    margin-left: 15px;
  }
  
  .bt-ana-1099 #main-nav > ul > li.sell {
    margin-top: 12px;
    text-align: center;
    width: 100%;
    display: flex;
  }

  .bt-ana-1099 #main-nav > ul > li.buy {
    width: calc(100% - 15px);
    margin-top: 25px;
    text-align: center;
  }

  #main-nav > ul > li.sell > a {
    background: none;
    padding: 0;
    height: 30px;
    line-height: 30px;
    width: 100%;
    border: 1px solid var(--bt-color-primary);
    border-radius: 15px;
    font-weight: bold;
    font-size: 16px;
    color: var(--bt-color-primary);
    margin: 0 15px;
  }

  .bt-ana-1099 #main-nav > ul > li.buy > a {
    background: #2876aa;
    padding: 5px 0;
  }

  .bt-ana-1099 #main-nav > ul > li.buy {
    margin-top: 10px;
  }

  .bt-ana-1099 #main-nav > ul > li > div.non-expandable > img, .bt-ana-1099 #main-nav > ul > li > div.non-expandable > a {
    display: inline-block;
    font-weight: 700;
    font-size: 15px;
  }
}
