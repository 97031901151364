#bt-footer ul {
  list-style-type: none;
  margin: 0;
}
/* fixed global style for footer in blog. */
.bt_blog_body #bt-footer .bt-footer-disclaimer ul li {
  margin-bottom: 0;
}
/* temporary debugging div for social links*/
.bt-footer-social {
  background-color: #0F4D77;
  padding: 0;
  margin: 0;
  color: #FFF;
}
.bt-footer-social .bt-container {
  padding: 0 12px;
  /* subscribe form */
}
.bt-footer-social .bt-container img {
  display: inline-block;
}
.bt-footer-social .bt-container .connect-us {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.bt-footer-social .bt-container .connect-us,
.bt-footer-social .bt-container .device-stores {
  display: block;
  padding: 15px 0;
  margin: 0;
  text-align: center;
  font-size: 16px;
}
.bt-footer-social .bt-container .connect-us .social-caption,
.bt-footer-social .bt-container .device-stores .social-caption,
.bt-footer-social .bt-container .connect-us .social-item,
.bt-footer-social .bt-container .device-stores .social-item {
  display: block;
  padding: 10px 0;
}
.bt-footer-social .bt-container .connect-us a {
  padding: 0 15px;
}
.bt-footer-social .bt-container .connect-us a:first-child {
  padding: 0 15px 0 0;
}
.bt-footer-social .bt-container .connect-us a:last-child {
  padding: 0 0 0 15px;
}
.bt-footer-social .bt-container .device-stores .social-item a:first-child {
  margin-right: 10px;
}
.bt-footer-social .bt-container .footer-email {
  margin: auto;
}
.bt-footer-social .bt-container .footer-email input {
  background: var(--bt-color-neutral-10);
  color: #a6a6a6;
  border-radius: 3px 0 0 3px;
  border: none;
  margin: -3px;
  padding: 10px;
  width: 200px;
  font-size: 13px !important;
  height: auto;
  letter-spacing: normal;
}
.bt-footer-social .bt-container .footer-submit {
  display: inline;
}
.bt-footer-social .bt-container .footer-submit input {
  background: #e47d30;
  color: var(--bt-color-neutral-10);
  border-radius: 0 3px 3px 0;
  width: 100px;
  text-transform: none;
  line-height: normal;
  letter-spacing: normal;
}
.bt-footer-disclaimer {
  background-color: #07324F;
  font-size: 12px;
  padding: 15px;
  line-height: 14px;
}
.bt-footer-disclaimer > div {
  color: white;
}
.bt-footer-disclaimer a {
  text-decoration: none;
}
.bt-footer-disclaimer a:hover {
  text-decoration: underline;
}
.bt-footer-disclaimer .disclaimer-links {
  padding: 15px 0 0 0;
}
.bt-footer-disclaimer .disclaimer-links li {
  padding-right: 1.5em;
  padding-bottom: 1.5em;
  display: block;
}
.bt-footer-disclaimer .copyright-info {
  padding: 15px 0;
}
.bt-footer-disclaimer .copyright-info .copyright {
  margin-right: 12px;
}
.bt-footer-links {
  background-color: #FFF;
}
.bt-footer-links .bt-container {
  display: block;
}
.bt-footer-links ul {
  padding: 0;
}
.bt-footer-links ul li {
  padding: 0.3em 0em;
  display: inline-block;
  width: 100%;
}
.bt-footer-links ul li a:link {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #2C272D;
  text-decoration: none;
}
.bt-footer-links ul li a:visited {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #2C272D;
  text-decoration: none;
}
.bt-footer-links ul li a:active {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #2C272D;
  text-decoration: none;
}
.bt-footer-links ul li a:hover {
  color: var(--bt-color-secondary_action-main);
  text-decoration: underline;
  text-underline-offset: 4px;
}
.bt-footer-links ul li:first-child {
  padding-top: 1em;
}
.bt-footer-links .tab-label {
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  color: #2C272D;
}
.bt-footer-links img.logo {
  width: 206px;
  height: 27px;
}
.bt-footer-links input.accordion-footer {
  display: none;
}
.disclaimer-links * {
  color: white;
}
.copyright-info {
  border-top: 1px solid #89A2BC;
  line-height: 200%;
}
.copyright-info * {
  color: #89A2BC;
}
.copyright-info span.copyright {
  border-right: none;
  display: block;
}
.copyright-info span:last-child {
  border-right: none;
}
.copyright-info span img.adchoices-icon {
  width: 10px;
  padding-left: 2px;
  vertical-align: middle;
  display: initial;
  margin-top: -2px;
  /* courtesy of https://isotropic.co/tool/hex-color-to-css-filter/ */
  filter: invert(64%) sepia(62%) saturate(160%) hue-rotate(170deg) brightness(91%) contrast(81%);
}
.copyright-info .add-policy > span {
  color: #89A2BC;
  padding: 0 0.5em;
}

#bt-footer .submit_e_one {
  cursor: pointer;
}

.truste_border_none.truste_cursor_pointer {
  max-width: 150px;
}

#teconsent {
  margin-top: 10px;
}

/* styles for mobile devices, plain css */
@media screen and (max-width: 1023px) {
  /* fixed react filters on mobile */
  #root .page-container #bt-footer {
    padding: 32px 0 0 0;
  }
  #root .page-container #homepage + #bt-footer {
    padding-top: 0px;
  }
  .bt-footer-links {
    background: #FFF;
    padding: 2em 1em;
    display: block;
  }
  .bt-footer-links img.logo {
    margin-bottom: 1.5rem;
    margin-left: 0;
  }
  .bt-footer-links input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  .bt-footer-links ul li {
    padding: 0.4em 0;
    margin-bottom: 1em;
  }
  .bt-footer-links ul li a:link,
  .bt-footer-links ul li li a:visited,
  .bt-footer-links ul li li a:active {
    font-size: 14px;
  }
  .bt-footer-links .tab {
    display: block;
    width: 100%;
    color: #000;
    overflow: hidden;
  }
  .bt-footer-links .tab-label {
    display: flex;
    justify-content: space-between;
    padding: 1em 1em 1em 0em;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    color: #2C272D;
  }
  .bt-footer-links .tab-label::after {
    content: "❯";
    width: 1em;
    height: 1em;
    text-align: center;
    transform: rotate(90deg);
    transition: transform 0.35s ease-out;
  }
  .bt-footer-links .tab-content {
    max-height: 0;
    padding: 0;
    margin-top: 0;
    color: #2C272D;
    border-bottom: 1px solid #A5A5A5;
    transition: max-height 0.35s ease-out;
  }
  .bt-footer-links input:checked + .tab-label::after {
    transform: rotate(-90deg);
  }
  .bt-footer-links input:checked ~ .tab-content {
    max-height: 100vh;
    padding: 0;
  }
  /* fix blog image size */
  .bt_blog_body .bt-footer-links .img.logo {
    height: 100%;
  }
  #root #engines.page-container #bt-footer {
    padding: 32px 0 0px;
  }
  #engines .bt-footer-disclaimer {
    padding-bottom: 45px;
  }
}
/* footer disclaimer-links on tablet on one line */
/*
  width chosen taking into account nexus devices according to:
  https://css-tricks.com/snippets/css/media-queries-for-standard-devices/
 */
@media (min-width: 601px) {
  #root .page-container #homepage + #bt-footer {
    padding: 0px;
  }
  #bt-footer {
    padding: 0;
  }
  .copyright-info span.add-policy {
    padding: 0 0.5em 0 0;
  }
  .bt-footer-disclaimer ul {
    display: flex;
  }
  .bt-footer-disclaimer li {
    padding-right: 1.5em;
    padding-bottom: 0;
    display: block;
  }
}

@media (min-width: 1024px) and (max-width: 1319px) {
  .bt-footer-links .bt-container {
    display: flex;
    width: 95%
  }
}

@media (min-width: 1024px) {
  .bt-footer-disclaimer,
  .bt-footer-links,
  .bt-footer-social {
    margin: 0 auto;
  }
  .bt-container {
    margin: 0 auto;
    padding: 0;
    width: 80%;
    max-width: 1480px;
  }
  .bt-footer-links {
    padding: 50px 0;
  }
  .bt-footer-links .bt-container {
    display: flex;
  }
  .bt-footer-links .tab {
    flex: 0 0 20%;
  }
  .bt-footer-disclaimer {
    padding: 0;
  }
  .bt-footer-disclaimer > div {
    display: block;
  }
  .bt-footer-disclaimer ul {
    display: flex;
  }
  .bt-footer-disclaimer .bt-container {
    padding: 15px 0;
  }
  .bt-footer-disclaimer .bt-container .disclaimer-links {
    padding: 15px 0 0 0;
  }
  .bt-footer-disclaimer .bt-container .copyright-info {
    padding: 15px 0;
  }
  .copyright-info span:first-child {
    display: inline-block;
  }
  .bt-footer-social .bt-container {
    display: flex;
    justify-content: space-between;
  }
  .bt-footer-social .bt-container .connect-us {
    flex: 0 1 20%;
    order: 0;
  }
  .bt-footer-social .bt-container .connect-us .social-caption {
    text-align: center;
  }
  .bt-footer-social .bt-container .connect-us .social-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .bt-footer-social .bt-container .device-stores {
    order: 2;
  }
  .bt-footer-social .bt-container .device-stores .social-caption {
    text-align: center;
  }
  .bt-footer-social .bt-container .device-stores .social-item a:first-child {
    margin-right: 10px;
  }
}

@media (min-width: 1320px) {
  #teconsent {
    float: right;
    margin-top: 0;
  }
  .bt-footer-links .bt-container {
    display: flex;
    width: 80%
  }
}

/**
 * Modal container
 */
.marketing-modal button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.marketing-modal .contact-close {
  width: 16px;
  height: 16px;
  margin: 3px 0;
  background: url(//boattrader.com/static/img/icons/close-x.png) no-repeat 0 top;
  border: none;
  position: absolute;
  right: 15px;
  cursor: pointer;
}

.marketing-modal .contact-close-div {
  height: 25px;
}

.marketing-modal .success-alert .message-box  {
  text-align: center;
  padding: 20px;
}

@media screen and (min-width: 1024px) {
  #engines .bt-footer-disclaimer {
    padding-bottom: 125px;
  }
}


