/* BASE font-size: 15px */

/* Desktop */
.boat-loans-rates-hero-section-container {
  height: 18rem;
  width: 100%;
  background: linear-gradient(85.27deg, transparent 0%, transparent 99.79%);
  max-height: 373px;
  }

.boat-loans-rates-hero-section-container .hero-section {
  position: relative;
  background-color: transparent !important;
  padding-top: 3rem;
  width: 100%;
}

.boat-loans-rates-hero-section-container .hero-section-image {
  position: absolute;
  opacity: 0.4;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-width: 100%;
  height: 18rem;
  object-position: 60% 44%;
  max-height: 373px;
}

.boat-loans-rates-hero-section-container .hero-content .hero-title {
  font-size: 72px;
  font-weight: 900;
  letter-spacing: 0.01em;
  text-align: left;
  color: #07324F;
  margin-bottom: 50px;
}

.boat-loans-rates-hero-section-container .flex {
  display: flex;
  max-width: 1150px;
  margin: auto;
}

.boat-loans-rates-hero-section-container .items-center {
  align-items: center;
}

.boat-loans-rates-hero-section-container .hero-section .hero-content {
  margin-left: 0;
  margin-right: 0;
  max-width: 550px;
  min-width: 280px;
}

.boat-loans-rates-hero-section-container .hero-section .hero-subtitle {
  margin-top: 32px;
  margin-bottom: 32px;
  font-size: 20px;
  font-family: Lato;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: left;
  color: #07324F;
  margin-left: 0;
  margin-right: 0;
}

/* Mobile */
@media only screen and (max-width: 991px) {
  .boat-loans-rates-hero-section-container .hero-section {
    padding-top: 2rem;
  }
  
  .boat-loans-rates-hero-section-container {
    height: 256px;
  }

  .boat-loans-rates-hero-section-container .hero-section-image {
    height: 17rem;
    background-position: unset;
    transform: unset;
    object-position: unset;
    max-height: 322px;
    overflow: hidden;
    opacity: .3
  }

  .boat-loans-rates-hero-section-container .hero-section .hero-content {
    max-width: unset !important;
    min-width: unset !important;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .boat-loans-rates-hero-section-container .hero-content .hero-title {
    font-size: 48px;
    font-weight: 900;
    line-height: 49px;
    letter-spacing: 0.01em;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 0;
  }

  .boat-loans-rates-hero-section-container .hero-section .hero-subtitle {
    font-size: 20px;
    text-align: center;
    margin-top: 32px;
    font-family: Lato;
    margin-bottom: 32px;
  }

  .boat-loans-rates-hero-section-container .flex {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

}

@media only screen and (max-width: 1280px) {
  .boat-loans-rates-hero-section-container .flex {
    margin: 0 120px;
  }
}