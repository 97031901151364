@media only screen and (max-width: 600px) {
    .imageCarousel > div {
        height: 280px !important;
        margin-top: 8px;
    }

    .financing-checkbox {
        margin-top: 5px !important;
    }
}

.stacked-gallery-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    height: 100%;
    background-color: #fff;
    overflow: auto;
}

.stacked-gallery-overlay iframe {
    max-width: 100%;
}

.stacked-gallery-item {
    width: 100%;
}

.stacked-gallery-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.bdp-gallery-mobile {
    position: relative;
}

#prequalified-and-contact-seller{
    padding-top: 8px;
}
.pre-qualify-cta-wrapper, .private-connect-wrapper{
    padding: 0px 10px;
}

.bdp-gallery-mobile-tools {
    position: absolute;
    z-index: 2;
    right: 16px;
    bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.bdp-gallery-mobile-tools__item {
    position: relative;
    border: none;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.bdp-gallery-mobile-carousel {
     --height-mobile: 280px;
     --height-mobile-large: 320px;
     --height-tablet: 450px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
}
.bdp-gallery-mobile-carousel > div {    
    width: 100%;
}
.bdp-gallery-mobile-carousel .video-player {
    width: 100%;
}

.bdp-gallery-mobile-carousel--video {
    background: var(--bt-color-neutral-100);
}

.video-player-holder {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.email-lead-form-container {
    display: flex;
    justify-content: center;
}

.financing-checkbox {
    margin-top: 10px;
    font-size: 12px;
}

.financing-checkbox input[type='checkbox'] {
    background-color: #fff !important;
    margin-right: 8px;
    margin-bottom: -1px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 14px;
    height: 14px;
    border: 1px solid #2566b0;
    cursor: pointer;
    display: inline-block;
    position: relative;
    border-radius: 2px;
  }
  
.financing-checkbox input[type='checkbox']:checked {
    background-color: #2566b0 !important;
    position: relative;
}
  
.financing-checkbox input[type='checkbox']:hover {
    background-color: #2566b0 !important;
}

.financing-checkbox input[type='checkbox']:checked:hover {
    background-color: #2566b0 !important;
}

.financing-checkbox input[type='checkbox']:not(:checked):hover {
    background-color: #fff !important;
}

.financing-checkbox input[type='checkbox']:checked::after {
    background-color: #2566b0 !important;
    position: relative;
    content: '';
    position: absolute;
    top: 0px;
    left: 3px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

@media only screen and (max-width: 430px) {
    .bdp-gallery-mobile-carousel,
    .bdp-gallery-mobile-carousel > div {
        height: var(--height-mobile);
    }
}

@media only screen and (min-width: 431px) and (max-width: 749px) {
    .bdp-gallery-mobile-carousel,
    .bdp-gallery-mobile-carousel > div {
        height: var(--height-mobile-large);
    }
}

@media only screen and (min-width: 750px) {
    .bdp-gallery-mobile-carousel,
    .bdp-gallery-mobile-carousel > div {
        height: var(--height-tablet);
    }
}

.email-lead-form h3 {
    width: 92%;
}

@media screen and (min-width: 970px) {
    .summary-section {
        display: flex;
        padding: 16px;
        border-radius: 8px;
        flex-direction: column;
        align-items: flex-start;
        background: var(--bt-color-neutral-10);
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .2);
        border: 1px solid var(--bt-color-neutral-30);
    }
    .summary-section .email-lead-form > div {
        box-shadow: none;
    }
}

.boat-summary {
    width: 100%;
}
.summary-section:empty, .summary-section:has(> .hidden) {
    display: none;
}


.srp-email-lead-form {
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, .2);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.srp-email-lead-form > div{
    box-shadow: unset;
}

.srp-email-lead-form .financing-checkbox{
    margin-left: 16px;
    margin-top: 0;
    margin-bottom: 16px;
}

.srp-email-lead-form .contact-close{
    position: relative;
    left: 42%;
    top: 30px;
    height: 0;
    background: transparent;;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    cursor: pointer;
    -webkit-filter: brightness(0);
    filter: brightness(0);
    margin: 0;
    border: none;
    z-index: 1000;
}

.srp-email-lead-form h3{
    max-width: 320px !important;
    white-space: wrap !important;
}