.results-header {
  display: flex;
  justify-content: space-between;
  margin: 16px 0px;
  align-items: center;
  letter-spacing: normal;
  line-height: normal;
}

.results-header .results-count, .results-header .results-count h2 {
  color: #0B3F65;
  font-size: 13px;
  line-height: 2.3;
  flex-grow: 1;
  padding-left: 12px;
  font-weight: normal;
}

.results-header .search-tools {
  display: none;
}

@media screen and (min-width: 970px) {
  .results-header .search-tools {
    display: block;
  }
  .results-header .results-count, .results-header .search-tools .text{
    padding-left: 0;
    font-size: 14px;
    color: #404040;
 
  }
}

