.boat-loan-types-main{
   background-color: #07324F;
}

.boat-loan-types-main .container{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 56px;
    margin: 0 auto !important;
    padding: 0 20px;
    max-width: 1061px;
}

.boat-loan-types-main .title{
    color: #FFF;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding-top: 40px;
}

.boat-loan-types-main .accordion-container{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.boat-loan-types-main .image-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.boat-loan-types-main .image-container img{
    max-width: 449px;
    max-height: 449px;
}

.boat-loan-types-main .container a{
    text-decoration: none;
    color: var(--bt-color-primary);
}

/* #Accordion */

.boat-loan-types-main .accordion{
    background-color: transparent;
    box-shadow: none;
    margin: 0;
    width: 100%;
}

.boat-loan-types-main .accordion__button{
    background-color: transparent;
    border: none;
}

.boat-loan-types-main .accordion__button h3{
    color:#FFF;
    text-align: center;
    font-size: 22px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.boat-loan-types-main .accordion__panel{
    background-color: transparent;
    border: none;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.18px;
    background-color: transparent;
    color: #FFF;
    padding: 24px 0;
}

.boat-loan-types-main .accordion__button[aria-expanded=true]:after, .accordion__button[aria-selected=true]:after {
    background-position: 50%;
    background-size: 50%;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    border: none;
    background-color: transparent;
    filter: invert(43%) sepia(99%) saturate(2498%) hue-rotate(184deg) brightness(105%) contrast(92%);
}

.boat-loan-types-main .accordion__button:after {
    content: "";
    margin: 20px 30px;
    min-height: 48px;
    min-width: 48px;
    height: 48px;
    width: 48px;
    background-position: 50%;
    background-size: 50%;
    cursor: pointer;
    border: none;
    margin: 0;
    filter: invert(43%) sepia(99%) saturate(2498%) hue-rotate(184deg) brightness(105%) contrast(92%);
}

.boat-loan-types-main .accordion__item+.accordion__item{
    border-top: none;
}

.boat-loan-types-main .accordion__item{
    border-bottom: 2px solid #FFF;
}

.boat-loan-types-footer-container img{
    width: 100%;
    max-height: 131px;
}

/*Bottom shape*/
@media (max-width: 1061px) {

    .boat-loan-types-main .container{
        flex: column;
        flex-direction: column-reverse;
        gap: 13px;
    }

    .boat-loan-types-main .accordion__panel{
        font-size: 16px;
    }
    
    .boat-loan-types-main .image-container img{
        max-width: 256px;
        max-height: 256px;
    }

    .boat-loan-types-main .title{
        font-size: 28px;
        padding-top: 35px;
    }

    .boat-loan-types-main .accordion-container{
        height: auto;
    }

    .boat-loan-types-footer-container img{
        width: 100%;
        max-height: 86px;
    }

}