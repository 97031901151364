.tooltip-modal-actionable > div  {
  background: unset !important;
}

.tooltip-modal-actionable > div > div {
  background: unset !important;
  border: unset !important;
}

.monthly-price {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  width: 100%;
  z-index: 1000;
  color: var(--bt-color-secondary_action-main);
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 2px;
}

.monthly-price .info-icon {
  background: url(../../../../icons/InfoSRP.svg) center no-repeat;
  width: 12px;
  height: 12px;
  margin-left: 3px;
}

.tooltip-wrapper .react-tooltip-lite {
  width: 232px;
  height: 174px;
  background: none;
  margin: -1px;
  padding: unset !important;
}

.tooltip-wrapper .react-tooltip-lite .tooltip-monthly {
  display: flex;
  flex-flow: column nowrap;
  justify-items: center;
  align-items: center;
  box-sizing: border-box;
  background: #15446A;
  border: 1px solid var(--bt-color-neutral-30);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  width: 232px;
  height: auto;
  color: var(--bt-color-neutral-30);
  padding: 16px 12px;
  gap: 8px;
  margin-left: 12px;
}

.tooltip-wrapper .tooltip-monthly .tooltip-monthly-head {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.tooltip-wrapper .react-tooltip-lite .tooltip-monthly .tooltip-monthly-head-monthlyPayment {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #FFF;
}

.tooltip-wrapper .react-tooltip-lite .tooltip-monthly  .tooltip-monthly-head-close {
  cursor: pointer;
}

.tooltip-wrapper .react-tooltip-lite .tooltip-monthly  .tooltip-monthly-head-close > img {
  width: 12px;
  opacity: .8;
}

.tooltip-wrapper .react-tooltip-lite .tooltip-monthly  .tooltip-monthly-head-close > img:hover {
  opacity: 1;
}

.tooltip-wrapper .react-tooltip-lite .tooltip-monthly .tooltip-monthly-body .tooltip-monthly-body-paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #F5F5F5;
  margin: 0;
  padding-bottom: 8px;
}

/* Mobile */
@media screen and (max-width: 767px) {

  .tooltip-wrapper {
    width: fit-content !important;
  }

  .tooltip-wrapper .monthly-price{
    justify-content: flex-start;
    font-size: 10px;
  }
  .monthly-price .info-icon {
    width: 10px;
    height: 10px;
  }

  .tooltip-wrapper .tooltip-monthly {
    background: var(--bt-color-neutral-10) !important;
    box-shadow: -10px 4px 10px rgba(0, 0, 0, 0.1) !important;
    width: auto !important;
    padding: 5px 9px !important;
    border-radius: 8px !important;    
    margin-right: 4px;
  }

  .tooltip-wrapper .react-tooltip-lite + .react-tooltip-lite-arrow {
    border-color: var(--bt-color-neutral-10);
    z-index: 99 !important;
  }

  .tooltip-wrapper .react-tooltip-lite {
    height: unset !important;
    width: auto !important;
    z-index: 99 !important;
  }

  .tooltip-wrapper .react-tooltip-lite .tooltip-monthly {
    gap: 2px !important;
  }

  .tooltip-wrapper .tooltip-monthly .tooltip-monthly-body-paragraph, 
  .tooltip-wrapper .tooltip-monthly .tooltip-monthly-head-monthlyPayment{
    color: #404040 !important;
    font-size: 10px !important;
  }

  .tooltip-wrapper .react-tooltip-lite-right-arrow {
    margin-left: 12px;
  }
  
  .tooltip-wrapper.right:not(.enhanced) .tooltip-monthly {
    margin-top: -45px;
    max-width: 170px;
  }
  .tooltip-wrapper.right:not(.enhanced) .react-tooltip-lite-right-arrow {
    margin-top: -25px;
  }
  
}