/* BASE font-size: 15px */

/* Desktop */
.extended-services-hero-container {
  height: 460px;
  width: 100%;
  background-size: cover;
  background-position: 60% 50%;
}

.extended-services-hero-container .hero-sub-container {
  display: flex;
  flex-flow: row nowrap;
  padding: 0rem 0rem 0rem;
  max-width: 89rem;
  margin: auto;
  padding-left: 13rem;
}

.extended-services-hero-container .hero-sub-container input {
  height: 2.8rem;
}

.extended-services-hero-container .hero-sub-container .powered-by-title {
  font-size: 0.53rem;
}

.extended-services-hero-container .hero-sub-container .prequalified-privacy-policy {
  margin-top: 0.4rem;
}

.extended-services-hero-container .hero-sub-container .prequalified-privacy-policy a {
  font-size: 0.666rem;
}

.extended-services-hero-container .hero-sub-container .services-lead-title {
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: center;
  color: #0F4D77;
}

.extended-services-hero-container .hero-text-container {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.8rem;
  width: 50%;
  padding-top: 6.66rem;
  margin-right: 7rem;
}

.extended-services-hero-container .hero-title {
	font-weight: 900;
  font-size: 40px;
  line-height: 48px;
	letter-spacing: 0em;
	margin: 0;
  color: white;
	text-align: left;
	max-width: 600px;
  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.42);
}

.extended-services-hero-container .hero-subtitle {
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.42);
}

.extended-services-hero-container .hero-subtitle .plan-price {
  color: #00FF00;
}

.extended-services-hero-container .hero-perks {
  padding: 0 0 0 0;
  font-size: 20px;
	font-weight: 700;
  line-height: 25px;
	letter-spacing: 0em;
	text-align: left;
  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.42);
}

.extended-services-hero-container .hero-perks .phone {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.42);
}


/* Tablet */
@media only screen and (max-width: 1282px) {
  .extended-services-hero-container .hero-sub-container {
    gap: 1rem;
  }

  .extended-services-hero-container .hero-title {
    font-size: 2.466rem;
    line-height: 3.16rem;
  }
}

/* Mobile */
@media only screen and (max-width: 991px) {
  .extended-services-hero-container {
    height: auto;
    background-size: 300% 466px;
    background-position: -170px 0;
    background-repeat: no-repeat;
  }

  .extended-services-hero-container .hero-sub-container {
    flex-flow: column nowrap;
    padding: 1rem 1.4rem 0;
    align-items: center;
  }

  .extended-services-hero-container .hero-text-container {
    gap: 2.2rem;
    width: 100%;
    flex-flow: column;
    align-items: center;
    text-align: center;
    margin: 0;
    padding-top: 0;
  }

  .extended-services-hero-container .hero-title {
    font-size: 2.133rem;
    line-height: 2.533rem;
    text-align: center;
  }

  .extended-services-hero-container .hero-subtitle{
    text-align: center;
  }

  .extended-services-hero-container .hero-perks {
    text-align: center;
    font-size: 16px;
  }

}
