/* BASE font-size: 15px */

/* Desktop */

.main-section{
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.boatloans-info{
  background-color: #F2F2F2;
  padding: 3.2rem 16rem 2.6rem;
}

.main-section .calculator-container {
  margin-top: 48px;
  padding: 0 16rem;
}

.main-section .calculator-container .private-calc-fin-calculator-body{
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.20);
}

.main-section .calculator-container .private-calc-fin-calculator-body fieldset{
  padding: 0;
  margin: 0;
}

.main-section .calculator-container .calc-form-label.loan-amount-or-monthly-payment {
  margin-top: 0 !important;
}



.breadcrumb-container {
  height: 2.666rem;
  background-color: #FFF;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-left: 1.533rem;
}

footer.main-footer {
  margin-top: 0;
}

/* Mobile */
@media only screen and (max-width: 991px) {
  .boatloans-info {
    padding: 2.4rem 1rem 1.2rem;
  }

  .main-section .calculator-container {
    padding: 0 1rem ;
  }

  .breadcrumb-container {
    height: 2.266rem;
    padding-left: 0.4rem;
  }


  footer.main-footer {
    margin-top: 1.466rem;
  }
}

@media only screen and (min-width: 992px) {

  .main-section .calculator-container .private-calc-fin-calculator-body, .private-calc-fin-calculator-body {
    min-width: 670px;
    max-width: 888px;
  }
  
  .main-section .loan-process-body,
  .main-section .trust-pilot-header,
  .main-section .trust-pilot-section,
  .main-section .accordion-section-title,
  .boatloans-info .accordion {
    min-width: 670px;
    max-width: 890px;
  }

  .main-section .private-calc-fin-calculator-sub-container,
  .main-section .boat-loans-process-component{
    display: flex;
    justify-content: center;
  }
  
}