.next-previous {
  position: fixed;
  top: 51px;
  left: 0;
  right: 0;
  padding: 8px 10px;
  background: #fff;
  display: flex;
  height: 30px;
  box-sizing: border-box;
  align-items: center;
  z-index: 110;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
  transition: transform 300ms ease,top 200ms linear, 300ms ease;
}

.next-previous > div {
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
}

.next-previous-top {
  top: 0px !important;
}

.next-previous.hidden {
  display: none;
}

.next-previous-button {
  font-size: 10px;
  line-height: 1;
  text-align: center;
  padding: 7px 12px;
  display: block;
  cursor: pointer;
  font-weight: 800;
  color: #2C272D;
  border-radius: 4px;
  background-color: #EAEAEA;
}
.next-previous-back{
  background-color: transparent;
}
.next-previous-next{
  margin-left: 3px;
}

.next-previous-back:before {
  content: "<";
  display: inline-block;
  margin-right: 4px;
}

.next-previous-info-container {
  flex-grow: 1;
  margin: 0 10px;
  position: relative;
  height: 32px;
  overflow: hidden;
  align-items: center;
  display: none;
}

.next-previous-info-container .loading-text {
  flex-grow: 1;
  margin-bottom: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.next-previous-info {
  font-size: 12px;
  line-height: 16px;
  color: #4A4B4B;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  height: 16px;
  background: white;
  padding: 8px 0;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 300ms;
  display: flex;
  align-items: center;
}

.next-previous-info > * {
  display: inline-block;
  vertical-align: top;
}

.next-previous-info > div + div {
  padding-left: 6px;
  margin-left: 6px;
  border-left: 1px solid #D8D8D8;
}

.next-previous-listing-name {
  font-weight: bold;
  max-width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.next-previous-listing-location {
  display: none;
}

.next-previous-info .next-previous-listing-heart {
  display: flex;
  height: 16px;
  padding-left: 0;
}

.next-previous .breadcrumb {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.next-previous .breadcrumb li {
  float: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.next-previous-prev span, .next-previous-next span{
  display: none;
}

.next-previous-button-tray {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: flex-end;
}

.next-previous .heart {
  position: relative;
  width: 14px;
  height: 13px!important;
  background: url(../../../icons/unfilled_heart.svg) no-repeat!important;
  background-size: contain!important;
  margin: 0;
  top: 0;
  left: -5px;
}

.next-previous .heart.liked {
  background: url(../../../icons/filled_heart.svg) no-repeat!important;
  background-size: contain!important;
}

.next-previous-contact {
  display: none;
  opacity: 0;
  transition: opacity 300ms;
  position: relative;
  height: 30px;
}

.next-previous-contact .contact-group {
  clip: rect(0px, 0px, 0px, 0px);
  transition: clip 0ms linear 300ms;
  position: absolute;
  width: 100%;
}

.next-previous .contact-group .contact-button,
.next-previous .contact-group .call-button {
  font-size: 14px;
}

.next-previous.show-info .next-previous-info {
  transform: translateY(0%);
}

@media screen and (min-width: 768px) {
  .next-previous {
    height: 56px;
    padding: 5px 20px;
  }
  .next-previous-info > div + div {
    padding-left: 10px;
    margin-left: 10px;
  }
  .next-previous-listing-location {
    display: inline-block;
  }

  .next-previous-prev span, .next-previous-next span {
    display: inline;
  }
  .next-previous .heart:after {
    content: "Save";
    position: absolute;
    display: block;
    font-size: 12px;
    margin-left: 55px;
    top: 1px;
    font-weight: normal;
  }
  .next-previous .heart.liked:after {
    content: "Saved";
    margin-left: 55px;
  }

  .next-previous-button {
    font-size: 14px;
    padding: 11px 15px;
    font-weight: bold;
    color: #119BF5;
    background-color: transparent;
  }

    .next-previous-button:hover {
      background: #ECF0F1;
    }

  .next-previous-back:before,
  .next-previous-prev:before {
    content: "<";
    display: inline-block;
    margin-right: 4px;
  }

  .next-previous-next:after {
    content: ">";
    display: inline-block;
    margin-left: 4px;
  }
}

@media screen and (min-width: 970px) {
  .next-previous {
    position: sticky;
    top: 0;
  }
  .next-previous-info-container {
    display: flex;
  }

  .next-previous-button-tray.no-next-previous {
    display: none;
  }

  .next-previous.show-info .next-previous-contact .contact-group {
    clip: rect(0px, 500px, 500px, 0px);
    transition: clip 0ms linear 0ms;
  }
}

.next-previous .button-tray {
  display: none;
}
