.social-browse-page #backgroundModal {
  background-color: rgba(0,0,0,.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: fadeIn .2s ease-out forwards;
  animation: fadeIn .2s ease-out forwards;
  opacity: 0;
  z-index: 30;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-social-contact-form {
  background: white;
  border-radius: 8px;
  padding: 16px;
  position: relative;
  width: 352px;
  box-sizing: border-box;
}

#modal-social-contact-form .main-form .title {
  padding-right: 30px;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 12px;
}

#modal-social-contact-form .contact-close {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 5px;
  right: 5px;
  -webkit-filter: invert(1);
  filter: invert(1);
  background: transparent;
  appearance: none;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 13px;
}

#modal-social-contact-form .contact-close img {
  width: 14px;
  height: 14px;
}

#modal-social-contact-form .fieldset-phone {
  display: none;
}

#modal-social-contact-form .footer button[type="submit"] {
  height: 40px;
  background: linear-gradient(180deg, #E46A2B 0%, #CF6026 100%);
  border: 1px solid #CE6026;
  border-radius: 20px;
  box-sizing: border-box;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 12px;
  box-shadow: none;
}

#modal-social-contact-form .message-error {
  color: #d9534f;
}

#modal-social-contact-form input[type=number],
#modal-social-contact-form input[type=text],
#modal-social-contact-form textarea {
  background: white;
  border: 1px solid #E0E0E0;
  box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: #616161;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0.5em 10px;
}

#modal-social-contact-form form.error fieldset.error input[type=number],
#modal-social-contact-form form.error fieldset.error input[type=text],
#modal-social-contact-form form.error fieldset.error textarea {
  border: 2px solid #d9534f;
}

#modal-social-contact-form .hidden {
  display: none;
}

#modal-social-contact-form .message-sent {
  color: #333;
}

.social-browse-page .contact-group .contact-button {
  height: 38px;
  background: linear-gradient(180deg, #E46A2B 0%, #CF6026 100%);
  border: 1px solid #CE6026;
  border-radius: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
}
