.boat-loans-rates-frequently-questions-section {
  width: 100%;
  background: #FFF;
}

.boat-loans-rates-frequently-questions-section .rates-faq-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto;
  max-width: 1150px;
}

.ai-image-disclaimer-text {
  display: flex;
  justify-content: center;
  
  p {
    max-width: 542px;
  }
}

.boat-loans-rates-frequently-questions-section h2 {
  color: var(--bt-color-tertiary);
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  text-align: center;
}

.boat-loans-rates-frequently-questions-section a {
  color: var(--bt-color-primary);
}

.boat-loans-rates-frequently-questions-section .accordion {
  margin: 0; /* reset for global css pollution */
  box-shadow: none; /* reset for global css pollution */
  width: 100%;
}

.boat-loans-rates-frequently-questions-section .col {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.boat-loans-rates-frequently-questions-section .accordion p {
  color:#404040;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  margin: 0 !important;
  padding: 10px !important;
}

.boat-loans-rates-frequently-questions-section .accordion > button {
  display: flex;
  background-color: #C9DCF2;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  text-align: left;
  color:#07324F;
  font-family: Roboto;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
}

.boat-loans-rates-frequently-questions-section .accordion h3 {
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 14px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color:#07324F !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  margin: 0 !important;
}

.boat-loans-rates-frequently-questions-section .accordion > button.active {
  color: #07324F;
}

.boat-loans-rates-frequently-questions-section .accordion > div {
  padding: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.boat-loans-rates-frequently-questions-section .accordion .accordion-arrow {
  display: inline-block;
  margin: 0px 5px;
  transition: transform 0.3s ease-in-out;
  height: 24px;
  transform: rotate(90deg);
}

.boat-loans-rates-frequently-questions-section .accordion .accordion-arrow.open {
  transform: rotate(-90deg) !important;
}

.boat-loans-rates-frequently-questions-section .content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
}

.boat-loans-rates-frequently-questions-section .button-container {
  display: flex;
  flex-direction: column;  
  align-items: center;
}

.boat-loans-rates-frequently-questions-section ul {
  margin: 0;
  padding-left: 20px;
}

/* Small Mobile */
@media only screen and (max-width: 991px) {
  .boat-loans-rates-frequently-questions-section .rates-faq-content {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
}

@media only screen and (max-width: 1280px) {

  .boat-loans-rates-frequently-questions-section .rates-faq-content {
    margin: 0 120px;
    flex-direction: column !important;
    gap: 24px;
  }

  .boat-loans-rates-frequently-questions-section .content {
    display: flex;
    flex-direction: column !important;
    gap: 24px;
  }
}

@media only screen and (max-width: 1023px) {
  .boat-loans-rates-frequently-questions-section .rates-faq-content {
    padding-bottom: 16px !important;
  }

}