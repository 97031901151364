@media screen and (min-width: 320px) {
  .recommended-boats .loading .inner {
    height: 194.6px !important;
  }
  .recommended-boats ol.boat-list li .image-container {
    height: inherit;
  } 
}
@media screen and (min-width: 375px) {
  .recommended-boats .loading .inner {
    height: 220.6px !important;
  }
  .recommended-boats ol.boat-list li .image-container {
    height: inherit;
  } 
}
@media screen and (min-width: 425px) {
  .recommended-boats .loading .inner {
    height: 254px !important;
  }
  .recommended-boats ol.boat-list li .image-container {
    height: inherit;
  } 
}
@media screen and (min-width: 768px) {
  .recommended-boats .loading .inner {
    height: 275px !important;
  }
  .recommended-boats ol.boat-list li .image-container {
    height: inherit;
  } 
}
@media screen and (min-width: 1024px) {
  .recommended-boats .loading .inner {
    height: 331px !important;
  }
  .recommended-boats ol.boat-list li .image-container {
    height: inherit;
  } 
}
@media screen and (min-width: 1440px) {
  .recommended-boats .loading .inner {
    height: 350px !important;
  }
  .recommended-boats ol.boat-list li .image-container {
    height: inherit;
  } 
}

@media screen and (min-width: 1890px) {
  .recommended-boats .loading .inner {
    height: 427.5px !important;
  }

  .recommended-boats ol.boat-list li .image-container {
    height: inherit;
  } 

}

/* Breakpoint 3 */
@media screen and (min-width: 2560px) {
  .recommended-boats .loading .inner {
    height: 536.32px !important;
  }

  .recommended-boats ol.boat-list li .image-container {
    height: inherit;
  } 

}

