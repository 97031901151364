/* BASE font-size: 15px */

/* Desktop */
.how-to-apply-section {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background: #F5F8FD;
}

.how-to-apply-section .steps-container {
  display: flex;
  align-items: flex-start;
}

.how-to-apply-section .steps-container .apply-title,
.how-to-apply-section .apply-title-top {
  text-align: left;
  margin-bottom: 2rem;
  font-size: 40px;
  font-weight: 900;
  line-height: 48px;
  letter-spacing: 0.01em;
  color: #07324F;
  margin-left: 8%;
  text-align: left;
}

.how-to-apply-section .steps-container .step-section {
  text-align: center;
  width: 26%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.how-to-apply-section .steps-container .step-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 9999px;
  background-color: white;
  color: #07324F;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid #07324F;
  margin-left: auto;
  margin-right: auto;
}

.how-to-apply-section .steps-container .step-name {
  font-size: 28px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #07324F;
  margin-top: 0px;
  margin-bottom: 14px;
}

.how-to-apply-section .steps-container .step-text {
  margin-left: 70px;
  margin-right: 70px;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #404040;
}

.how-to-apply-section .steps-container .step-text.middle {
  margin-bottom: 30px;
}

.how-to-apply-section .apply-title-top {
  display: none;
}

.how-to-apply-section a {
  color: var(--bt-color-primary);
}

/* Tablet */
@media only screen and (max-width: 1282px) {
  .how-to-apply-section .steps-container .apply-title {
    display: none;
  }
  .how-to-apply-section .steps-container .apply-title,
  .how-to-apply-section .apply-title-top {
    margin-left: 0;
  }

  .how-to-apply-section .apply-title-top {
    display: block;
    text-align: center;
  }

  .how-to-apply-section .steps-container .step-section {
    width: 35%;
  }
}

/* Mobile */
@media only screen and (max-width: 991px) {
  .how-to-apply-section .steps-container {
    flex-direction: column;
    align-items: center;
  }
  .how-to-apply-section .step-section {
    width: 100%;
    margin-bottom: 2rem;
  }

  .how-to-apply-section .steps-container .apply-title,
  .how-to-apply-section .apply-title-top {
    margin-bottom: 2rem;
    font-size: 28px;
    font-weight: 900;
    line-height: 48px;
    letter-spacing: 0.01em;
    color: #07324F;
    text-align: center;
  }

  .how-to-apply-section .steps-container .step-section {
    width: 50%;
  }
}

/* Smaller Mobile */
@media (max-width: 550px) {
  .how-to-apply-section .steps-container .step-section {
    width: unset;
  }
}
