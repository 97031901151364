.location-based-header {
  border-bottom: 1px solid #dee2e3;
  padding: 10px 0 10px;
}

.location-based-header h2 {
  font-size: 1em;
  margin: 0;
  font-weight: 700;
}

.location-based-header h2 small {
  color: #aeb4b5;
  font-size: 0.9em;
  display: inline;
  font-weight: 500;
}

/* Breakpoint 2 */
@media screen and (min-width: 500px) {
  .location-based-header h2 small {
    display: block;
    padding-left: 0px;
  }
}

/* Breakpoint 3a */
@media screen and (min-width: 1200px) {
  .location-based-header h2 small {
    display: inline;
    padding-left: 5px;
  }
}
