#oemHeader {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 24px auto;
  box-sizing: border-box;
  padding: 0 16px;
}

.oem-header-content {
  display: flex;
  max-width: 1133px;
  flex-direction: row;
  background-color: white;
  margin-left: 0px;
  margin-right: 0px;
}

.oem-header-content .left-content {
  box-sizing: border-box;
  width: 310px;
  max-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.oem-header-hl {
  display: flex;
  width:100%;
  height: 1px;
  background-color: #ccc;
  border: none;
}

#oemHeader .left-content img {
  max-width: 220px;
  max-height: 100px;
  margin-left: 20px;
}

.oem-header-content .right-content {
  width: calc(100% - 343px);
  display: flex;
  flex-direction: column;
  padding: 20px 20px 12px 20px;
}

.oem-header-content .right-content .title {
  color: #333333;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 24px;
  text-transform: uppercase;
}

.oem-header-content .right-content .intro {
  text-align: justify;
  font-weight: bold;
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  text-transform: uppercase;
}

.oem-intro-container {
  display: block;
}

.oem-intro-container [aria-label="Contact"]  {
  border-radius: 50px !important;
  background: var(--bt-color-primary) !important;
  border: none !important;
  color: var(--bt-color-neutral-10) !important;
}
.oem-intro-container [aria-label="Contact"]:hover  {
  background: var(--bt-color-secondary_action-pressed) !important;
}
.oem-intro-container [aria-label="Contact"]:before  {
  background: none !important;
}

.introduction-content {
  color: var(--neutral-neutral-90, #404040);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.introduction-content a {
  color: var(--secondary-action-main, #139AF5);
  line-height: normal;
}

#oemHeader .right-content .button-container {
  width: auto;
}

#oemHeader .right-content .oem-button {
  max-height: 36px;
  width: 143px;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 7px 26px;
  font-size: 14px;
  line-height: 17px;
  height: auto;
  font-weight: 700;
  margin-top: 24px;
  background: #e37a1f;
  border-radius: 4px;
  color: #fff;
  text-transform: none;
  cursor: pointer;
}

.oem-intro-container > .ad-container {
  padding: 12px;
  height: 262px;
  align-items: center;
  text-align: center;
  margin-top: 12px;
  position: relative;
}

.oem-intro-container > .ad-container .server-ad {
  display: block;
}

.oem-intro-container > .ad-container .server-ad .ad-image {
  position: relative;
}

@media screen and (min-width: 768px) {

  .oem-intro-container {
    display: flex;
    min-height: 300px;
    gap: 14px;
  }

  .oem-intro-container > :first-child {
    flex: 1;
  }

  .oem-intro-container > .ad-container {
    padding: 12px;
    height: 300px;
    width: 720px;
    margin-top: 0;
  }
}

@media screen and (min-width: 1440px){
  #oemHeader {
    max-width: 1440px;
  }

  .oem-header-content {
    max-width: 1440px;
  }
  .oem-header-content .left-content{
    width: 360px;
  }
  .oem-header-content .left-content img {
    max-width: 300px;
    max-height: 200px;
  }
}

@media screen and (max-width: 969px) {
    .oem-header-content {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      margin-bottom: -10px;
      margin: 20px 0px -10px 0px;
    }
    .oem-header-content .left-content {
      width: 100%;
      padding: 5px 0 0 0;
    }
    #oemHeader .left-content img {
      max-width: 220px;
      max-height: 100px;
      margin-left: 0;
    }

    .oem-header-content .right-content {
      width: 100%;
      padding: 0;
    }

    .oem-header-content .right-content .intro {
      margin: 30px 35px 0;
      text-align: center;
    }

    #oemHeader .right-content .oem-button {
      width: 100%;
      margin: 16px 0;
      font-size: 16px;
      line-height: 19.5px;
      box-sizing: border-box;
    }
    #oemHeader .right-content .button-container {
      margin: 0 10px;
    }
}


@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1180px)
  and (-webkit-min-device-pixel-ratio: 1) {
    #oemHeader .right-content .button-container {
      font-size: 16px;
      line-height: 19.5px;
      box-sizing: border-box;
    }

    #oemHeader .left-content img {
      max-width: 200px;
      max-height: 140px;
      margin-left: 20px;
    }

}
