#engines .content-head h4 {
  margin: 0;
  padding: 1.5rem;
}

#engines .content-head .head-paragraph {
  padding: 10px;
  margin: 0;
  font-size: 15px;
  line-height: 1.3em;
  color: #333;
}

.engines-container {
  display: block;
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto;
}

.engines-container .left-content, .engines-container .right-content {
  display: block;
  padding: 1rem;
}

.engines-container ol.boat-list li .image-container .image {
  padding: 5px;
}


@media screen and (min-width: 970px) {
    .engines-container {
      display: flex;
    }

    .engines-container {
      display: flex;
      padding-left: 0;
      padding-right: 0;
      min-height: 400px;
    }

    .engines-container .right-content {
        flex-grow: 1;
        padding-right: 15px;
    }

   .engines-container .left-content {
        flex-shrink: 0;
        width: 300px;
        /*padding-left: 15px;
        padding-right: 15px;
        display: block;*/
    }

    .engines-container ol.boat-list li .description .top .name {
      font-size: 17px;
    }
}

@media screen and (max-width: 1439px){
  .far-right-content{
    display: none
  }
}

@media screen and (min-width: 1440px) {
  .content-head, .engines-container {
    max-width: 1440px;
  }
  .engines-container {
    max-width: 1440px;
  }
  .engines-container .left-content {
    width: 320px;
    /* comments match the boats SRP while we wait for content,
    left here as reference
    color to debug while waiting for content */
    /*padding-left: 16px;*/
    /*padding-right: 32px;*/
  }

  .engines-container .right-content{
    max-width:66%;
    /* comments match the boats SRP while we wait for content,
    left here as reference
    color to debug while waiting for content */
    /*padding-right: 32px;*/
    /*padding-top:16px;*/
  }

  .far-right-content {
    min-width:300px;
    padding-top:16px;
    margin-right:16px;
  }
}

#div-gpt-mobile-box-1 {
  min-height: 250px;
}

#div-gpt-mobile-box-1, #div-gpt-mobile-box-2, #div-gpt-mobile-box-3 {
  text-align: center;
}

#engines-content .adhesion-ads {
  position: fixed;
  bottom: 0;
  left: calc(50% - 160px);
  z-index: 999;
}
#engines-content .bttBtn.show {
  bottom: 100px;
}

@media screen and (min-width: 970px) {
  #engines-content .adhesion-ads {
    left: calc(50% - 355px);
  }
  #engines .content-head .head-paragraph {
    padding: 15px;
  }
}