.breadcrumb {
  list-style-type: none;
  overflow: hidden;
  margin: 0;
  padding: 7px 10px;
  font-size: 12px;
  line-height: 16px;
  color: #757575;
}

.breadcrumb li {
  float: left;
}

.breadcrumb li a {
  color: inherit;
}
.breadcrumb li a:hover {
  color: var(--bt-color-primary);
}

.breadcrumb li .divider {
  padding: 0 4px;
}

.breadcrumb li:not(:first-child) {
  text-transform: capitalize;
}

.breadcrumb li.make {
  text-transform: none;
}

.breadcrumb li .divider .divider-desktop {
  display: none;
}

@media screen and (min-width: 768px) {
  .breadcrumb {
    padding: 7px 15px;
  }
  .breadcrumb li .divider .divider-desktop {
    display: inline;
  }
  .breadcrumb li .divider .divider-mobile {
    display: none;
  }
}
@media screen and (max-width: 969px) {
  .content .content-head .breadcrumb {
    margin-top: 96px;
    margin-bottom: -4px;
  }

  .content .content-head .payingBrandSRP {
    margin-top: 0;
  }
}
