.extended-services-info .title {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: 0em;
    text-align: center;
    color: #0F4D77;
    margin: 28px 0 0 0;
}

.extended-services-info .subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0em;
    text-align: center;
    color: #404040;
    margin: 12px 0 0 0;
}

/* TOP SECTION */

.extended-services-info .top-section{
    background: #FFF;
    width: 100%;
}

.extended-services-info .top-section .list-items{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 22px;
    margin-bottom: 30px;
    margin-top: 24px;
}

.extended-services-info .top-section .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 306px;
}

.extended-services-info .top-section .item img {
    margin-bottom: 10px;
    width: 90px;
    height: 90px;
}

.extended-services-info .top-section .item .item-title {
    color: #404040;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 150%; /* 27px */
    margin-bottom: 20px;
    text-align: center;
}

.extended-services-info .top-section .item .item-text {
    color: #404040;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    text-align: center
}


/* MIDDLE SECTION */
.extended-services-info .middle-section {
    background: #0F4D77;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.extended-services-info .middle-section .title {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: 0em;
    text-align: center;
    color: #FFF;
}

.extended-services-info .middle-section .sub-title {
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 2rem;
    letter-spacing: 0em;
    text-align: center;
    color: #FFF;
    margin: 20px 0;
    text-align: center;
    width: 980px;
}

.extended-services-info .middle-section .send-request-button {
    display: block;
    color: #fff;
    font-size: 22px;
    font-weight: 800;
    text-rendering: geometricprecision;
    cursor: pointer;
    background: var(--bt-color-secondary_action-main);
    border: 0 none;
    width: 260px;
    border-radius: 100px;
    margin-top: 17px;
    height: 50px;
    transition: 0.3s;
    transition-property: background, font-size;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  
  .extended-services-info .middle-section .send-request-button:active:hover {
    background: var(--bt-color-primary);
  }

  /* BOTTOM SECTION */
.extended-services-info .bottom-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6px;
}

.extended-services-info .bottom-section .list-cards{
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-bottom: 24px;
    margin-top: 24px;
}

.extended-services-info .bottom-section .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 170px;
    width: 414px;
    border-radius: 10px;
    background: #FBFBFB;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
}

.extended-services-info .bottom-section .card img {
    width: 105px;
    height: 65px;
}

.extended-services-info .bottom-section .card .card-title {
    color: #404040;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 150%; /* 27px */
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
}

.extended-services-info .bottom-section .card .card-text {
    color: #404040;
    font-size: 16px;
    font-weight: 300;
    line-height: 150%;
    text-align: center
}



/* Mobile */
@media only screen and (max-width: 991px) {

    .extended-services-info .middle-section {
        padding: 24px 20px;
    }

    .extended-services-info .title {
        margin: 28px 24px 24px;
    }

    .extended-services-info .subtitle {
        margin: 12px 24px;
    }

    .extended-services-info .middle-section .sub-title {
        width: unset;
    }

    .extended-services-info .bottom-section .list-cards {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .extended-services-info .top-section .list-items {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .extended-services-info .bottom-section .card {
        width: unset;
        margin-left: 20px;
        margin-right: 20px;
    } 
}