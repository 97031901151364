/* BASE font-size: 15px */

/* Desktop */
.rates-calculator-container {
  width: 100%;
  background: #FFF;
  margin: 36px 0;
}

.rates-calculator-container .rates-calculator-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0 auto;
  max-width: 1150px;
}

.rates-calculator-container .rates-calculator-content .title {
  color: #07324F;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 !important;
}

.rates-calculator-container .info-text {
  color: #404040;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; 
  margin: 0 !important;
}

/* Small Mobile */
@media only screen and (max-width: 991px) {
  .rates-calculator-container .rates-calculator-content {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
}

@media only screen and (max-width: 1280px) {
  .rates-calculator-container .rates-calculator-content {
    margin: 0 120px;
  }
}