.services {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media screen and (min-width: 768px) {
  .services {
    max-height: 328px;
  }
}

.service-item img.ad-image{
      position: inherit;
      max-height: 48px;
    }
