/* Override Keyword component styles https://boats-group.atlassian.net/browse/BT-984 */

.keyword-override {
  background-color: var(--bt-color-neutral-10);
}

.keyword-override button:first-child {
  color: var(--bt-color-neutral-90) !important;
  height: auto !important;
  padding: 16px 10px !important;
}

.keyword-override button img {
  height: 9px;
  width: 15px;
  content: url(../../../icons/DownArrow.svg);
}

@media only screen and (max-width: 600px) {
  .keyword-override input[type="text"] {
    font-size: 16px;
  }
}
