.search-alerts-container {
  padding: 0;
}

#tool-set-inner-padding .search-alerts-container {
  margin-bottom: 8px;
}

#tool-set-inner-padding .search-alerts-button {
  font-weight: bold;
}

.search-alerts {
  font-size: 10px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0);
  transition: box-shadow 300ms, background-color 300ms;
  background: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
}

.search-alerts .search-alerts-button-mobile {
  display: none;
}

@media screen and (max-width: 969px) {
  .search-alerts.search-alerts-light .search-alerts-button-mobile {
    display: block;
  }
}

@media screen and (min-width: 970px) {
  .search-alerts-container {
    padding: 0 15px;
    height: 35px;
    margin-bottom: 15px; /* ANA-1745 */
  }
  .search-alerts-button {
    height: 35px;
    font-weight: bold;
  }
}

.filter-button {
  display: block;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  background-image: linear-gradient(to right, #0e4d77, #0b3f65);
  float: left;
  border: 0 none;
  position: relative;
  padding: 9px 24px;
  outline: 0;
}

.filter-button:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 8px solid #0b3f65;
  position: absolute;
  top: 0;
  right: -7px;
}
