.login-landing-container .modal-container {
  position: relative;
  display: flex;
  justify-content: center;
  top: unset;
  bottom: unset;
  left: unset;
  right: unset;
  padding: 20px;
  z-index: 999;
  overflow-y: hidden;
}

.login-landing-container .modal-container .modal-box {
  top: unset;
  left: unset;
  transform: unset;
  -webkit-transform: unset;
}

.login-landing-container .modal-container .modal-backdrop {
  display: none;
}
